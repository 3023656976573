import * as React from 'react';
import {
	Grid,
	InputLabel,
	MenuItem,
	Typography,
	Box,
	FormControl,
	Select,
	CardContent,
	Card,
	Stack,
} from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { StatisticsCard } from '../../components/StatisticsCard/StatisticsCard';
import { datasetsLoggedUsers, datasetsSession } from './data/statisticsData';
import { ChartLine } from '../../components/Chart/ChartLine';
import { DoughnutCard } from '../../components/DoughnutCard/DoughnutCard';
import authIcon from '../../assets/icons/authIcon.svg';
import SessionsIcon from '../../assets/icons/SessionsIcon.svg';
import { data, options, optionsByUser } from './data/sessionsData';
import { Bar } from 'react-chartjs-2';
import { ChartBar } from '../../components/Chart/ChartBar';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const SessionsPage: React.FC = () => {
	const [windowsClients, setWindowsClients] = React.useState(0);
	const [totalWindowsClients, setTotalWindowsClients] = React.useState(0);
	const [macosClients, setMacosClients] = React.useState(0);
	const [totalMacosClients, setTotalMacosClients] = React.useState(0);

	React.useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			setWindowsClients(48);
			setTotalWindowsClients(5093);
			setMacosClients(0);
			setTotalMacosClients(1013);
		}
	}, []);

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					paddingBottom: 1,
				}}
			>
				<Typography variant='h5'>Sessions statistics</Typography>

				<FormControl>
					<InputLabel id='date-range-select-label'>Date range</InputLabel>
					<Select
						labelId='date-range-select-label'
						id='date-range-select'
						value='10'
						label='Date range'
						//onChange={handleChange}
					>
						<MenuItem value={10}>This week</MenuItem>
						<MenuItem value={20}>Last week</MenuItem>
						<MenuItem value={30}>This month</MenuItem>
						<MenuItem value={40}>Last month</MenuItem>
						<MenuItem value={50}>This year</MenuItem>
						<MenuItem value={60}>Last year</MenuItem>
						<MenuItem value={70} disabled>
							Custom
						</MenuItem>
					</Select>
				</FormControl>
			</Box>

			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Sessions'
							count={106}
							prevCount={91}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Logged accounts'
							count={1243}
							prevCount={1567}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='All sessions'
							count={5}
							prevCount={10}
							negativeIsGood
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Online sessions'
							count={60}
							prevCount={60}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='OTP sessions'
							count={60}
							prevCount={60}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Manual sessions'
							count={60}
							prevCount={60}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box
				sx={{
					paddingTop: 2,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Sessions' dataSet={datasetsSession} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Logged Users' dataSet={datasetsLoggedUsers} />
					</Grid>

					<Grid item xs={12} md={6}>
						<DoughnutCard
							label='# of clients'
							title='Authentication status'
							data={
								new Map([
									['Verifed', 1200],
									['Failed', 1450],
									['Pending', 1000],
									['Timeouted', 500],
								])
							}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<DoughnutCard
							label='# of clients'
							title='Local vs RDP Sessions'
							data={
								new Map([
									['RDP', 1200],
									['Local', 1450],
								])
							}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<ChartBar options={options} data={data} title='Top authentications by client' />
					</Grid>

					<Grid item xs={12} md={6}>
						<ChartBar options={optionsByUser} data={data} title='Top authentications by user' />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
