import * as React from 'react';
import { TextField, Typography } from '@mui/material';
import { Controller, FieldError } from 'react-hook-form';
import { TextareaProps } from './types';
import { EFieldVariant } from './enums';

export const Textarea: React.FC<TextareaProps> = (props): JSX.Element => {
	const {
		name,
		label,
		error,
		helperText,
		rows = 6,
		disabled = false,
		fullWidth = true,
		variant = EFieldVariant.OUTLINED,
	} = props;

	const getTextareaProps = React.useCallback(
		(fieldError: FieldError | undefined) => ({
			label,
			error: !!fieldError,
			disabled,
			variant,
			fullWidth,
			InputLabelProps: {
				shrink: true,
			},
			helperText:
				fieldError && typeof fieldError.message === 'string' ?
					<Typography component='span' color='error' sx={{ fontSize: 12 }}>
						{fieldError.message}
					</Typography>
				:	helperText,
		}),
		[label, disabled, variant, fullWidth, helperText, rows],
	);

	if (props.register) {
		const { register } = props;

		return (
			<TextField
				{...(register && register(name))}
				{...getTextareaProps(error)}
				InputProps={{
					rows: rows,
					multiline: true,
					inputComponent: 'textarea',
				}}
			/>
		);
	} else if (props.control) {
		const { control, rules } = props;

		return (
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field, fieldState }) => (
					<TextField
						id={`${name}-textarea`}
						value={field.value || ''}
						onChange={field.onChange}
						InputProps={{
							rows: rows,
							multiline: true,
							inputComponent: 'textarea',
						}}
						{...getTextareaProps(fieldState.error)}
					/>
				)}
			/>
		);
	}

	return (
		<TextField
			{...getTextareaProps(error)}
			InputProps={{
				rows: rows,
				multiline: true,
				inputComponent: 'textarea',
			}}
		/>
	);
};
