export const circleOptions: google.maps.CircleOptions = {
	fillColor: 'coral',
	fillOpacity: 0.3,
	strokeWeight: 2,
	strokeColor: 'coral',
	clickable: true,
	editable: false,
	zIndex: 1,
};

export const circleSystemOptions: google.maps.CircleOptions = {
	...circleOptions,
	fillColor: 'darkGreen',
	strokeColor: 'darkGreen',
};

export const mapRestriction: google.maps.MapRestriction = {
	latLngBounds: {
		north: 85,
		south: -85,
		west: -180,
		east: 180,
	},
	strictBounds: true,
};
