import { TFunction } from 'i18next';
import { z } from 'zod';

export const getMailServerFormSchema = (t: TFunction) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	const portRegex = /^\d+$/;

	return z.object({
		name: z
			.string()
			.min(3, { message: t('page.smtp.edit.form.name.Error', { minLength: 3 }) })
			.max(64, { message: t('page.smtp.edit.form.name.ErrorMaxLength', { maxLength: 64 }) }),
		description: z
			.string()
			.max(255, { message: t('page.smtp.edit.form.description.ErrorMaxLength', { maxLength: 255 }) })
			.optional(),
		host: z
			.string()
			.min(3, { message: t('page.smtp.edit.form.host.Error', { minLength: 3 }) })
			.max(255, { message: t('page.smtp.edit.form.host.ErrorMaxLength', { maxLength: 255 }) }),
		port: z
			.string()
			.refine((val) => portRegex.test(val), t('page.smtp.edit.form.port.Error'))
			.refine((value) => parseInt(value, 10) >= 0 && parseInt(value, 10) <= 65535, {
				message: t('page.smtp.edit.form.port.ErrorMaxLength', { maxLength: 65535 }),
			}),
		username: z
			.string()
			.max(64, { message: t('page.smtp.edit.form.username.ErrorMaxLength', { maxLength: 64 }) })
			.optional(),
		password: z
			.string()
			.max(64, { message: t('page.smtp.edit.form.password.ErrorMaxLength', { maxLength: 64 }) })
			.optional(),
		sender: z
			.string()
			.min(5, { message: t('page.smtp.edit.form.sender.Error', { minLength: 5 }) })
			.max(64, { message: t('page.smtp.edit.form.sender.ErrorMaxLength', { maxLength: 64 }) })
			.refine((val) => emailRegex.test(val), t('page.smtp.edit.form.sender.ErrorInvalidEmail')),
	});
};

export const getEmailVerificationFormSchema = (t: TFunction) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

	return z
		.object({
			email: z
				.string()
				.min(5, { message: t('page.smtp.edit.form.sender.Error', { minLength: 5 }) })
				.max(64, { message: t('page.smtp.edit.form.sender.ErrorMaxLength', { maxLength: 64 }) })
				.refine((val) => emailRegex.test(val), t('page.smtp.edit.form.sender.ErrorInvalidEmail')),
			retypeEmail: z
				.string()
				.min(5, { message: t('page.smtp.edit.form.sender.Error', { minLength: 5 }) })
				.max(64, { message: t('page.smtp.edit.form.sender.ErrorMaxLength', { maxLength: 64 }) })
				.refine((val) => emailRegex.test(val), t('page.smtp.edit.form.sender.ErrorInvalidEmail')),
		})
		.refine((data) => data.email === data.retypeEmail, {
			message: t('page.smtp.edit.form.retypeEmail.ErrorMatch'),
			path: ['retypeEmail'],
		});
};

export const getVerificationCodeFormSchema = (t: TFunction) =>
	z.object({
		verificationCode: z.string().length(6, { message: t('page.smtp.edit.form.verificationCode.Error') }),
	});
