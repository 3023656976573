import { useEffect } from 'react';
import { usePathName } from './usePathName';

export const useScrollToTop = () => {
	const pathname = usePathName();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};
