import { TFunction } from 'i18next';
import { z } from 'zod';

export const getTargetGroupSchema = (t: TFunction) =>
	z.object({
		name: z
			.string()
			.min(3, { message: t('page.pamGroup.edit.form.name.Error', { minLength: 3 }) })
			.max(64, { message: t('page.pamGroup.edit.form.name.ErrorMaxLength', { maxLength: 64 }) }),
		description: z
			.string()
			.max(255, { message: t('page.pamGroup.edit.form.description.ErrorMaxLength', { maxLength: 255 }) })
			.optional(),
		textColor: z
			.string()
			.max(7, { message: t('page.pamGroup.edit.form.textColor.ErrorMaxLength', { maxLength: 6 }) })
			.optional(),
		bgColor: z
			.string()
			.max(7, { message: t('page.pamGroup.edit.form.backgroundColor.ErrorMaxLength', { maxLength: 6 }) })
			.optional(),
		borderColor: z
			.string()
			.max(7, { message: t('page.pamGroup.edit.form.borderColor.ErrorMaxLength', { maxLength: 6 }) })
			.optional(),
	});

export const targetGroupSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	color: z.string().optional(),
	targets: z.array(
		z
			.object({
				id: z.number(),
				type: z.string(),
				name: z.string(),
				description: z.string().optional(),
			})
			.optional(),
	),
});

export const targetListSchema = z.object({
	id: z.number(),
	name: z.string(),
	type: z.string(),
	hostname: z.string(),
	port: z.number(),
	description: z.string().optional(),
	groups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
				description: z.string().optional(),
				color: z.string().optional(),
			})
			.optional(),
	),
});
