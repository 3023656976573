export enum ETenantSections {
	GENERAL = 'GENERAL',
	IDENTITY_STORES = 'IDENTITY_STORES',
	USERS = 'USERS',
	INVITATIONS = 'INVITATIONS',
	NETWORK = 'NETWORK',
}

export enum ETableActions {
	SINGLE_DELETE = 'SINGLE_DELETE',
	MULTIPLE_DELETE = 'MULTIPLE_DELETE',
	SINGLE_REINVITE = 'SINGLE_REINVITE',
	MULTIPLE_REINVITE = 'MULTIPLE_REINVITE',
}
