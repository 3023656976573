import { FC, useState } from 'react';
import { Marker } from '@vis.gl/react-google-maps';

import { Circle } from './Circle';
import { GeofenceCircleProps } from './types';
import { circleOptions, circleSystemOptions } from './settings';

export const GeofenceCircle: FC<GeofenceCircleProps> = ({
	geofence,
	markerVisible,
	enableEdit = true,
	onChangeCallback,
}) => {
	const { latitude, longitude, name, isSystem } = geofence;

	const [center, setCenter] = useState<google.maps.LatLngLiteral>({
		lat: latitude,
		lng: longitude,
	});
	const [radius, setRadius] = useState<google.maps.CircleOptions['radius']>(geofence.radius);

	const onPositionChange = ({ lat, lng }: google.maps.LatLngLiteral) => {
		setCenter({ lat, lng });

		onChangeCallback?.({
			...geofence,
			latitude: lat,
			longitude: lng,
		});
	};

	const onDragMarker = (e: google.maps.MapMouseEvent) => {
		if (!e.latLng) {
			return;
		}

		onPositionChange({
			lat: e.latLng.lat(),
			lng: e.latLng.lng(),
		});
	};

	const onCircleCenterChanged = (newCenter: google.maps.LatLng | null) => {
		if (!newCenter) {
			return;
		}

		onPositionChange({
			lat: newCenter.lat(),
			lng: newCenter.lng(),
		});
	};

	const onRadiusChanged = (newRadius: number) => {
		if (newRadius === radius) {
			return;
		}

		setRadius(newRadius);

		onChangeCallback?.({
			...geofence,
			radius: newRadius,
		});
	};

	const config = isSystem ? circleSystemOptions : circleOptions;

	return (
		<>
			<Marker
				draggable
				visible={markerVisible}
				position={center}
				label={{
					text: name,
					color: 'white',
					fontWeight: 'bold',
					fontSize: '14px',
				}}
				icon={{
					url: 'data:image/svg+xml;utf-8,',
					size: new google.maps.Size(1, 1),
					scaledSize: new google.maps.Size(1, 1),
					anchor: new google.maps.Point(0, 0),
					labelOrigin: isSystem ? new google.maps.Point(0, 0) : new google.maps.Point(0, -16),
				}}
				onDrag={onDragMarker}
			/>
			<Circle
				{...config}
				editable={!isSystem && enableEdit}
				center={center}
				radius={radius}
				onCenterChanged={onCircleCenterChanged}
				onRadiusChanged={onRadiusChanged}
			/>
		</>
	);
};
