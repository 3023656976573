import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ConfirmationDialogProps } from './types';

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	open,
	text,
	title,
	disableCancel,
	cancelText,
	confirmText,
	onClose,
	onConfirm,
}): JSX.Element => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				{disableCancel ? null : (
					<Button onClick={onClose} color='primary'>
						{cancelText ? cancelText : 'Cancel'}
					</Button>
				)}

				<Button onClick={onConfirm} color='primary'>
					{confirmText ? confirmText : 'Confirm'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
