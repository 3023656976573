import { getTenantFromPath } from '../utils/Routing';

function isValidQuery(query: string): boolean {
	try {
		new URLSearchParams(query);

		return true;
	} catch {
		return false;
	}
}

export const useRedirect = (): ((to: string, query?: string) => void) => {
	const { tenantID, tenantSlug } = getTenantFromPath();

	return (to: string, query?: string) => {
		if (query && !isValidQuery(query)) {
			console.error('Invalid query string');

			return;
		}

		if (tenantID) {
			window.location.href = `/tenant/${tenantID}${to}${query ? `?${query}` : ''}`;
		} else if (tenantSlug) {
			window.location.href = `/tenant/${tenantSlug}${to}${query ? `?${query}` : ''}`;
		} else {
			window.location.href = `${to}${query ? `?${query}` : ''}`;
		}
	};
};
