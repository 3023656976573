import * as React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WeekdaySelectorProps } from './types';
import { getDays } from './constants';

export const WeekdayPreview: React.FC<WeekdaySelectorProps> = ({ value }) => {
	const { t } = useTranslation();

	const DAYS = React.useMemo(() => getDays(t), [t]);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
			<ToggleButtonGroup value={value} disabled sx={{ display: 'flex', flexFlow: 'row wrap' }}>
				{DAYS.map((day, index) => {
					return (
						<Tooltip
							arrow
							placement='top'
							title={t(`component.weekdaySelector.body.days.${day.value}.long`)}
							key={`weekday-preview-${index}`}
						>
							<span>
								<ToggleButton value={day.value} sx={{ width: 45 }}>
									{day.label}
								</ToggleButton>
							</span>
						</Tooltip>
					);
				})}
			</ToggleButtonGroup>
		</Box>
	);
};
