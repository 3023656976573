import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Map as GoogleMap, useMap } from '@vis.gl/react-google-maps';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Geofences, MapProps } from './types';
import { GeofenceModel } from '../../api/Api';
import { GeofenceCircle } from './GeofenceCircle';
import { mapRestriction } from './settings';

const INITIAL_MAP_ZOOM = 11;
const DEFAULT_CENTER = { lat: 48.148598, lng: 17.107748 };

const isZoomedIn = (zoom: number) => zoom >= INITIAL_MAP_ZOOM;

const extendBoundsToGeofence = (geofence: GeofenceModel, bounds: google.maps.LatLngBounds) => {
	const { latitude, longitude, radius } = geofence;
	const geofenceCenter = new google.maps.LatLng(latitude, longitude);

	const geofenceCircle = new google.maps.Circle({
		center: geofenceCenter,
		radius: radius,
	});

	const circleBounds = geofenceCircle.getBounds();
	if (circleBounds) {
		bounds.union(circleBounds);
	}
};

export const Map: FC<MapProps> = ({
	geofences,
	systemGeofences,
	defaultGeofence,
	onChangeGeofence,
	disableDefaultUI = false,
	clickableIcons = false,
	fullscreenControl = true,
	streetViewControl = false,
	zoomControlOptions = { position: 8 },
	enableCheckCurrentLocation = true,
	enableEditGeofence = true,
	style = {
		height: '75vh',
		width: '100%',
	},
	...rest
}) => {
	const [centerMapToAll, setCenterMapToAll] = useState(true);
	const [labelsVisible, setLabelsVisible] = useState(isZoomedIn(INITIAL_MAP_ZOOM));

	const { t } = useTranslation();

	const map = useMap();

	const checkCurrentLocation = useCallback(() => {
		if (!map) {
			return;
		}

		if (!('geolocation' in navigator)) {
			enqueueSnackbar(t('component.geolocation.infoMessages.notAvailable'));

			return;
		}
		navigator.geolocation.getCurrentPosition(
			(position) => {
				if (!defaultGeofence) {
					const { latitude, longitude } = position.coords;
					map.setCenter({ lat: latitude, lng: longitude });
				}
			},
			() => {
				enqueueSnackbar(t('component.geolocation.errorMessages.currentLocationError'), {
					variant: 'info',
					persist: false,
				});
			},
		);
	}, [t, defaultGeofence, map]);

	useEffect(() => {
		if (enableCheckCurrentLocation) {
			checkCurrentLocation();
		}
	}, [checkCurrentLocation]);

	useEffect(() => {
		if (defaultGeofence && map) {
			const { latitude, longitude } = defaultGeofence;
			map.setCenter({ lat: latitude, lng: longitude });
		}
	}, [defaultGeofence, map]);

	const geofenceEntities = useMemo<Geofences>(() => {
		if (!geofences && !systemGeofences) {
			return [];
		}

		const geofenceEntities: Geofences = [
			...(geofences?.map((entity) => ({
				...entity,
				editable: true,
			})) ?? []),
			...(systemGeofences?.map((entity) => ({ ...entity, editable: false })) ?? []),
		];

		if (geofenceEntities.length === 0) {
			return [];
		}

		return geofenceEntities;
	}, [geofences, systemGeofences]);

	useEffect(() => {
		if (!centerMapToAll || !map || geofenceEntities.length === 0 || defaultGeofence) {
			return;
		}

		const bounds = new google.maps.LatLngBounds();

		geofenceEntities.forEach((geofence) => {
			extendBoundsToGeofence(geofence, bounds);
		});

		map.fitBounds(bounds);

		setCenterMapToAll(false);
	}, [geofenceEntities, centerMapToAll, defaultGeofence]);

	return (
		<GoogleMap
			clickableIcons={clickableIcons}
			defaultCenter={DEFAULT_CENTER}
			defaultZoom={INITIAL_MAP_ZOOM}
			disableDefaultUI={disableDefaultUI}
			fullscreenControl={fullscreenControl}
			streetViewControl={streetViewControl}
			zoomControlOptions={zoomControlOptions}
			style={style}
			onZoomChanged={(e) => setLabelsVisible(isZoomedIn(e.detail.zoom))}
			restriction={mapRestriction}
			{...rest}
		>
			{geofenceEntities.length > 0 &&
				geofenceEntities.map((geofence) => {
					const { id, isSystem } = geofence;
					const key = isSystem ? `${id}-system-geofence-circle` : `${id}-geofence-circle`;

					return (
						<GeofenceCircle
							key={key}
							geofence={geofence}
							markerVisible={labelsVisible}
							enableEdit={enableEditGeofence}
							onChangeCallback={onChangeGeofence}
						/>
					);
				})}
		</GoogleMap>
	);
};
