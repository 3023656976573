import { useNavigate as useRouterNavigate, NavigateOptions, To } from 'react-router-dom';

import { getTenantFromPath } from '../utils/Routing';

export const useNavigate = (): ((to: To | number, options?: NavigateOptions) => void) => {
	const routerNavigate = useRouterNavigate();
	const { tenantID, tenantSlug } = getTenantFromPath();

	return (to: To | number, options?: NavigateOptions) => {
		if (tenantID && typeof to !== 'number') {
			routerNavigate(`/tenant/${tenantID}${to}`, options);
		} else if (tenantSlug && typeof to !== 'number') {
			routerNavigate(`/tenant/${tenantSlug}${to}`, options);
		} else {
			routerNavigate(to as To, options);
		}
	};
};
