import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, FormGroup, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ManageAccounts as ManageAccountsIcon } from '@mui/icons-material';
import { LanguageSwitcher } from '../../components/LanguageSwitcher/LanguageSwitcher';
import { Heading } from '../../components/Heading/Heading';
import { PageHeader } from '../../components/PageHeader/PageHeader';

export const UserSettingsPage: React.FC = (): JSX.Element => {
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const { t } = useTranslation();

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	return (
		<Box>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.userSettings.edit.title')}
						description={t('page.userSettings.edit.description')}
						icon={ManageAccountsIcon}
					/>
					<FormGroup>
						<Stack
							spacing={1}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Heading label={t('page.userSettings.edit.subtitle.userInterface')} />
							<LanguageSwitcher isCompactMode={false} />
						</Stack>
					</FormGroup>
				</Stack>
			</Paper>
		</Box>
	);
};
