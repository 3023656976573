import * as React from 'react';
import { Box } from '@mui/material';
import { useApiIsLoaded, useMap } from '@vis.gl/react-google-maps';
import { LocatoinSectionMapProps } from './types';
import { Map } from '../../../../components/Map/Map';
import { GeofenceModel } from '../../../../api/Api';

export const LocationSectionMap: React.FC<LocatoinSectionMapProps> = ({ location, systemGeofences }) => {
	const map = useMap();
	const googleApiLoaded = useApiIsLoaded();

	const [mapInitialized, setMapInitialized] = React.useState(false);
	const [geofences, setGeofences] = React.useState<GeofenceModel[]>([]);

	React.useEffect(() => {
		if (map) {
			setMapInitialized(true);
		}
	}, [map]);

	React.useEffect(() => {
		if (mapInitialized) {
			setGeofences([location, ...systemGeofences.map((geofence) => ({ ...geofence, isSystem: true }))]);
		}
	}, [mapInitialized]);

	React.useEffect(() => {
		if (!googleApiLoaded) {
			return;
		}

		const container = document.createElement('div');

		return () => {
			container.remove();
		};
	}, [googleApiLoaded]);

	return (
		<Box>
			<Map
				geofences={geofences}
				enableCheckCurrentLocation={false}
				defaultGeofence={location}
				enableEditGeofence={false}
				style={{ height: '60vh' }}
			></Map>
		</Box>
	);
};
