import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MaterialReactTable,
} from 'material-react-table';
import { Typography, Tooltip, Box, Avatar, Chip, IconButton, Paper, Stack } from '@mui/material';
import {
	SaveAlt as SaveAltIcon,
	Download as DownloadIcon,
	GetAppOutlined as GetAppOutlinedIcon,
	PlaylistPlay as PlaylistPlayIcon,
	PlayArrow as PlayArrowIcon,
	Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { calculateDuration, formatDurationToString } from '../../utils/SessionDateHelpers';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PagedResultPartialPamSessionDto, PartialPamSessionDto } from '../../api/Api';
import { sessionListSchema } from './schema';
import { getSessionTargetLogo } from './utils';
import { SessionPlayerDialog } from '../../components/Dialog/SessionPlayerDialog/SessionPlayerDialog';
import { useNavigate } from '../../hooks/useNavigate';

import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { useFormatDate } from '../../hooks/useFormatDate';
import { ESessionTarget } from '../../enums/session/ESessionTarget';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';

export const SessionListPage: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const formatDate = useFormatDate();
	const reactQueryClient = useReactQueryClient();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [hasRunningSession, setHasRunningSession] = React.useState(false);
	const [durationIncrement, setDurationIncrement] = React.useState(0);
	const [openPlayerDialog, setOpenPlayerDialog] = React.useState(false);
	const [recordingUrl, setRecordingUrl] = React.useState('');

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['targetName', 'startAt', 'endAt', 'accountName', 'userFullName']);
	const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

	const FETCH_INTERVAL = 5000;

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultPartialPamSessionDto>({
		queryKey: [EQueryKey.SESSION_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.pam.getAllPamSessions(query);
				response.data.entities.forEach((session) => {
					sessionListSchema.parse(session);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const getTypescriptUrl = React.useMemo(
		() =>
			(id: number | undefined): string => {
				if (!id) {
					return '';
				}

				return `/api/v1/pam/sessions/${id}/typescript`;
			},
		[],
	);
	const handlePreview = React.useCallback(
		(sessionID: number | undefined) => (event: React.MouseEvent) => {
			//TODO: Handle Session Preview
			event.stopPropagation();
		},
		[],
	);

	const handlePlay = React.useCallback(
		(sessionPath: string | undefined) => (event: React.MouseEvent) => {
			event.stopPropagation();
			if (!sessionPath) {
				return;
			}

			setRecordingUrl(sessionPath);
			setOpenPlayerDialog(true);
		},
		[],
	);

	const handleDownload = React.useCallback(
		(sessionPath: string | undefined) => (event: React.MouseEvent) => {
			event.stopPropagation();

			if (!sessionPath || !isAllowed([EPermission.PAM_RECORDINGS_READ])) {
				return;
			}

			try {
				const link = document.createElement('a');

				link.href = sessionPath;
				link.setAttribute('download', 'true');

				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
			} catch (error) {
				console.error(error);
			}
		},
		[isAllowed],
	);

	const handleRedirect = React.useCallback(
		(sessionID: number | undefined) => () => {
			navigate(`/pam/sessions/${sessionID}`);
		},
		[],
	);

	const handleOnClose = React.useCallback(() => {
		setOpenPlayerDialog(false);
	}, []);

	const checkHasRunningSession = React.useCallback((sessions: PartialPamSessionDto[]): boolean => {
		if (sessions.length === 0) {
			return false;
		}

		let refreshNeeded = false;

		sessions.forEach((session) => {
			if (!session.endAt) {
				refreshNeeded = true;
			}
		});

		setHasRunningSession(refreshNeeded);

		return refreshNeeded;
	}, []);

	const renderDuration = React.useMemo(() => {
		const renderSessionDuration = (startAt: string | undefined, endAt: string | undefined | null): string => {
			const duration = calculateDuration(startAt, endAt, durationIncrement);
			const durationString = formatDurationToString(duration);

			return durationString;
		};

		return renderSessionDuration;
	}, [durationIncrement]);

	React.useEffect(() => {
		if (!entities) {
			return;
		}

		checkHasRunningSession(entities);
	}, [entities]);

	React.useEffect(() => {
		if (!entities) {
			return;
		}

		const handleDurationIncrement = () => {
			if (!hasRunningSession) {
				return;
			}
			intervalRef.current = setInterval(() => {
				setDurationIncrement((prevValue) => prevValue + 1000);
			}, 1000);
		};

		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		if (!intervalRef.current && durationIncrement === 0) {
			handleDurationIncrement();
		}
	}, [hasRunningSession]);

	React.useEffect(() => {
		if (!durationIncrement) {
			return;
		}

		if (durationIncrement > FETCH_INTERVAL) {
			reactQueryClient.invalidateQueries();
			setDurationIncrement(0);
		}
	}, [durationIncrement]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRTColumnDef<Partial<PartialPamSessionDto>>[]>(
		() => [
			{
				accessorKey: 'targetName',
				accessorFn: (session) => session.targetName,
				header: t('page.sessions.table.header.target'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Tooltip title={row.original.targetType} placement='left' enterDelay={500} arrow>
							<img
								alt='Logo'
								height={42}
								src={getSessionTargetLogo(row.original.targetType)}
								loading='lazy'
								style={{ borderRadius: 'none' }}
							/>
						</Tooltip>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'userFullName',
				accessorFn: (user) => `${user.userName} ${user.userSurname ? user.userSurname : ''}`,
				header: t('page.sessions.table.header.userName'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={`${renderedCellValue}'s avatar.`} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'accountName',
				header: t('page.sessions.table.header.account'),
			},
			{
				accessorKey: 'startAt',
				accessorFn: (row) => `${formatDate(row.startAt, true)}`,
				filterVariant: 'datetime-range',
				header: t('page.sessions.table.header.start'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{renderedCellValue}
					</Box>
				),
			},
			{
				accessorKey: 'endAt',
				accessorFn: (row) => `${formatDate(row.endAt, true)}`,
				filterVariant: 'datetime-range',
				header: t('page.sessions.table.header.end'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{row.original.endAt ?
							renderedCellValue
						:	<Chip
								label={t('page.sessions.table.body.running')}
								variant='outlined'
								color='success'
								sx={{ width: '100px' }}
							/>
						}
					</Box>
				),
			},
			{
				header: t('page.sessions.table.header.duration'),
				Cell: ({ row }) => <Box>{renderDuration(row.original.startAt, row.original.endAt)}</Box>,
				enableSorting: false,
				enableColumnActions: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
			},
			{
				accessorKey: 'edit',
				header: '',
				enableSorting: false,
				enableColumnActions: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				Cell: ({ row }) => {
					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
							}}
						>
							<Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
								{!row.original.endAt && (
									<>
										{isAllowed([EPermission.PAM_RECORDINGS_READ]) && (
											<Tooltip
												title={t('page.sessions.tooltips.preview')}
												placement='left'
												enterDelay={500}
												arrow
											>
												<IconButton onClick={handlePreview(row.original.id)}>
													<VisibilityIcon />
												</IconButton>
											</Tooltip>
										)}
									</>
								)}
								{row.original.endAt && row.original.recordingPath && (
									<>
										{isAllowed([EPermission.PAM_RECORDINGS_READ], false) && (
											<Tooltip
												title={t('page.sessions.tooltips.playRecording')}
												placement='left'
												enterDelay={500}
												arrow
											>
												<IconButton onClick={handlePlay(row.original.recordingPath)}>
													<PlayArrowIcon color={'success'} />
												</IconButton>
											</Tooltip>
										)}
										{isAllowed([EPermission.PAM_RECORDINGS_READ], false) && (
											<Tooltip
												title={t('page.sessions.tooltips.downloadRecording')}
												placement='bottom'
												enterDelay={500}
												arrow
											>
												<IconButton onClick={handleDownload(row.original.recordingPath)}>
													<DownloadIcon />
												</IconButton>
											</Tooltip>
										)}
										{isAllowed(
											[EPermission.PAM_SESSIONS_READ_ALL, EPermission.PAM_SESSIONS_READ_OWN],
											false,
										) &&
											row.original.targetType === ESessionTarget.SSH && (
												<Tooltip
													title={t('page.sessions.tooltips.downloadTypescript')}
													placement='bottom'
													enterDelay={500}
													arrow
												>
													<IconButton
														onClick={handleDownload(getTypescriptUrl(row.original.id))}
													>
														<GetAppOutlinedIcon />
													</IconButton>
												</Tooltip>
											)}
									</>
								)}
							</Box>
						</Box>
					);
				},
			},
		],
		[isAllowed, entities],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.sessions.titleList')}
						description={t('page.sessions.descriptionList')}
						icon={PlaylistPlayIcon}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
						<MaterialReactTable
							columns={columns}
							data={entities}
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination,
								showProgressBars: isRefetching,
								columnFilters,
								globalFilter,
								sorting,
								columnVisibility,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error}</>,
							}}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onPaginationChange={setPagination}
							onColumnVisibilityChange={setColumnVisibility}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							renderToolbarInternalActions={({ table }) => (
								<Box>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip title={t('page.sessions.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 120,
									enableHiding: true,
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTableBodyRowProps={({ row }) => ({
								onClick:
									(
										isAllowed(
											[EPermission.PAM_SESSIONS_READ_ALL, EPermission.PAM_SESSIONS_READ_OWN],
											false,
										)
									) ?
										handleRedirect(row.original.id)
									:	undefined,
								sx: { cursor: 'pointer' },
							})}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1100 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							localization={MRTLocalization}
						/>
					</LocalizationProvider>
				</Stack>
			</Paper>
			{isAllowed([EPermission.PAM_RECORDINGS_READ]) && recordingUrl.length > 0 && (
				<SessionPlayerDialog open={openPlayerDialog} src={recordingUrl} onClose={handleOnClose} />
			)}
		</Box>
	);
};
