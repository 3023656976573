import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as OriginalSelect, Typography } from '@mui/material';

import { SelectProps } from './types';

export const Select = <
	T extends string | number,
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>,
>({
	control,
	name,
	label,
	options,
	helperText,
	error,
	disabled = false,
	fullWidth = true,
	required = false,
	onChangeCallback,
	...restProps
}: SelectProps<T, TFieldValues, TName>): JSX.Element => {
	const {
		field: { onChange, ...restField },
	} = useController({ control, name });

	const id = name ? `${name}-select` : 'select';
	const describedBy = name ? `${name}-helper-text` : 'helper-text';

	return (
		<FormControl disabled={disabled} error={!!error} fullWidth={fullWidth} required={required}>
			{label && <InputLabel htmlFor={id}>{label}</InputLabel>}
			<OriginalSelect
				{...restProps}
				{...restField}
				id={id}
				aria-describedby={describedBy}
				label={label}
				name={name}
				onChange={(event) => {
					onChange(event);
					onChangeCallback?.(event);
				}}
			>
				{options.map((option) => (
					<MenuItem key={`${option.value}`} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</OriginalSelect>
			<FormHelperText id={describedBy}>
				<Typography component='span' sx={{ fontSize: 12 }}>
					{error?.message ?? helperText}
				</Typography>
			</FormHelperText>
		</FormControl>
	);
};
