import { z } from 'zod';

export const userListSchema = z.object({
	id: z.number(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	email: z.string().min(5).max(64),
	status: z.string(),
	createdAt: z.string(),
	lastLoggedAt: z.string().optional(),
	groups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
				description: z.string().optional(),
			})
			.optional(),
	),
});

export const userSchema = z.object({
	id: z.number(),
	uuid: z.string(),
	tenantID: z.number().nullable(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	email: z.string().min(5).max(64),
	phoneNumber: z.string().optional(),
	countryCode: z.string().optional(),
	status: z.string(),
	createdAt: z.string(),
	lastLoggedAt: z.string().optional(),
	identityStoreID: z.number().optional(),
});

export const userDetailSchema = z.object({
	id: z.number(),
	uuid: z.string(),
	tenantID: z.number().nullable().optional(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	email: z.string().min(5).max(64),
	phoneNumber: z.string().optional(),
	countryCode: z.string().optional(),
	roleID: z.number().optional(),
	roleName: z.string().optional(),
	status: z.string(),
	createdAt: z.string(),
	lastLoggedAt: z.string().optional(),
	userGroups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
				description: z.string().optional(),
				isBuiltIn: z.boolean().optional(),
			})
			.optional(),
	),
});
