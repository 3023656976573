import * as React from 'react';
import { useEffect } from 'react';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SeekProgress } from './SeekProgress';
import { useTranslation } from 'react-i18next';
import { MessageProps } from './types';

export const Message: React.FC<MessageProps> = ({ visibility, status, text, progress, onPlay, onCancelSeek }) => {
	const { t } = useTranslation();

	const renderMessage = (): JSX.Element | null => {
		switch (status) {
			case 'unloaded':
				return <CircularProgress size={69} sx={{ color: 'white' }} />;
			case 'loaded':
				return (
					<Tooltip title={t('component.videoplayer.body.play')}>
						<IconButton aria-label='play button' onClick={onPlay}>
							<PlayArrowIcon sx={{ color: 'white', fontSize: '50px' }} />
						</IconButton>
					</Tooltip>
				);
			case 'ended':
				return (
					<Tooltip title={t('component.videoplayer.body.replay')}>
						<IconButton aria-label='replay button' onClick={onPlay}>
							<ReplayIcon sx={{ color: 'white', fontSize: '50px' }} />
						</IconButton>
					</Tooltip>
				);
			case 'seek':
				return (
					<Box
						sx={{
							padding: '20px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							background: 'rgba(0, 0, 0, 0.7)',
							borderRadius: '5px',
							gap: '10px',
						}}
					>
						<SeekProgress current={progress.current} total={progress.total} />
						<Typography variant='h6' component='h2'>
							{text}
						</Typography>
						<Tooltip title={t('component.videoplayer.body.cancelSeek')}>
							<Button variant='contained' aria-label='cancel seek button' onClick={onCancelSeek}>
								{t('component.videoplayer.body.cancelSeek')}
							</Button>
						</Tooltip>
					</Box>
				);
			case 'error':
				return (
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white' }}>
						<ErrorOutlineIcon sx={{ color: 'white', fontSize: '50px' }} />
						<Typography variant='h6' component='h2'>
							{t('component.videoplayer.body.unableToPlay')}
						</Typography>
					</Box>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		renderMessage();
	}, [status]);

	return (
		<Box
			onClick={onPlay}
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '100%',
				color: 'white',
			}}
		>
			{visibility && renderMessage()}
			{status === 'error' && <Box sx={{ color: 'white' }}>{text}</Box>}
		</Box>
	);
};
