import { TFunction } from 'i18next';
import { z } from 'zod';

import { EInvitationStatus } from '../../api/Api';

//  expired: boolean;
// 	disabled: boolean;
// 	surname: string;
// 	name: string;
// 	username: string;
// 	email: string;
// 	uuid: string;

export const identityStoreUserListSchema = z.object({
	uuid: z.string(),
	email: z.string(),
});

export const invitationListSchema = z.object({
	id: z.number(),
	userID: z.number(),
	status: z.enum([
		EInvitationStatus.Accepted,
		EInvitationStatus.Dismissed,
		EInvitationStatus.Failed,
		EInvitationStatus.Pending,
		EInvitationStatus.Sent,
		EInvitationStatus.Blocked,
	]),
	createdAt: z.string(),
	email: z.string(),
	phoneNumber: z.string().optional(),
});
