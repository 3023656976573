import * as React from 'react';
import { TextField, Popover, Box, InputAdornment, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ColorResult, TwitterPicker } from 'react-color';

import { ColorPickerProps } from './types';
import { flatColors } from './constants';

export const ColorPicker: React.FC<ColorPickerProps> = (props): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

	const elementId = React.useMemo(
		() => `colorPicker-${props.name}-${Math.random().toString(36).substring(7)}`,
		[props.name],
	);

	const handleOnClick = React.useCallback(() => {
		if (document.activeElement?.id === elementId) {
			setAnchorEl((prev) => (prev ? null : (document.activeElement as HTMLDivElement)));
		}
	}, [elementId]);

	const handleOnClose = React.useCallback(() => {
		setAnchorEl(null);
	}, []);

	const open = Boolean(anchorEl);

	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({ field: { onChange, value } }) => {
				const handleOnColorChange = React.useCallback((updatedColor: ColorResult) => {
					onChange(updatedColor.hex);
					handleOnClose();
				}, []);

				return (
					<Box position='relative'>
						<TextField
							id={elementId}
							fullWidth
							label={props.label}
							value={value}
							onClick={handleOnClick}
							inputProps={{ readOnly: true }}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Box
											style={{
												width: 34,
												height: 34,
												backgroundColor: value,
												border: '1px solid #abb8c3',
												borderRadius: 4,
											}}
										/>
									</InputAdornment>
								),
							}}
							InputLabelProps={{ shrink: true }}
							helperText={
								props.error && typeof props.error.message === 'string' ?
									<Typography component='span' color='error' sx={{ fontSize: 12 }}>
										{props.error.message}
									</Typography>
								:	props.helperText
							}
						/>
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handleOnClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
						>
							<TwitterPicker colors={flatColors} color={value} onChange={handleOnColorChange} />
						</Popover>
					</Box>
				);
			}}
		/>
	);
};
