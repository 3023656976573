import * as React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { Remove as RemoveIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { RulesPreviewProps } from './types';
import { LabeledBox } from '../../../../components/LabeledBox/LabeledBox';

export const TimePreview: React.FC<RulesPreviewProps> = ({ data }): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Paper
			elevation={3}
			sx={{
				display: 'flex',
				flexWrap: { xs: 'wrap', md: 'nowrap' },
				width: '100%',
				gap: 2,
				p: 2,
			}}
		>
			<LabeledBox label={t('page.securityPolicy.detail.rules.body.rule')} sx={{ flex: '0 0 180px' }}>
				{t('page.securityPolicy.detail.rules.title.time')}
			</LabeledBox>
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				{data.timeRanges.map((range, index) => (
					<Box
						key={`time-range-${index}`}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
							gap: 2,
							mb: 2,
							maxWidth: '100%',
						}}
					>
						{index > 0 && (
							<Box
								sx={{
									position: { xs: 'relative', md: 'absolute' },
									top: 0,
									bottom: 0,
									left: { xs: 0, md: '-50px' },
									display: 'flex',
									width: { xs: '100%', md: 'auto' },
								}}
							>
								<Typography variant='body1' sx={{ marginY: '17px' }}>
									{t('page.ruleSet.form.body.or')}
								</Typography>
							</Box>
						)}
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '50%' }}>
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.from')}
								sx={{ width: { xs: '100%', md: '50%' }, textAlign: 'center' }}
							>
								<Typography variant='body1' px={1}>
									{range.from}
								</Typography>
							</LabeledBox>
							<RemoveIcon />
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.to')}
								sx={{ width: { xs: '100%', md: '50%' }, textAlign: 'center' }}
							>
								<Typography variant='body1' px={1}>
									{range.to}
								</Typography>
							</LabeledBox>
						</Box>
					</Box>
				))}
			</Box>
		</Paper>
	);
};
