import { useCurrentLanguage } from '../contexts/LocalizationContext/LocalizationContext';
import { MRT_Localization } from 'material-react-table';
import { MRT_Localization_SK } from 'material-react-table/locales/sk';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { ELanguageCode } from '../api/Api';

export const useMRTLocalization = (): { MRTLocalization: MRT_Localization } => {
	const lang = useCurrentLanguage();

	switch (lang) {
		case ELanguageCode.Sk:
			return { MRTLocalization: MRT_Localization_SK };
		case ELanguageCode.En:
			return { MRTLocalization: MRT_Localization_EN };
		default:
			console.warn('Unknown language code, using English as default fallback', { lang });

			return { MRTLocalization: MRT_Localization_EN };
	}
};
