import * as React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { getTenantFromPath } from '../../utils/Routing';

export const Link: React.FC<LinkProps> = (props): JSX.Element => {
	const { tenantID, tenantSlug } = getTenantFromPath();

	let to = props.to;
	if (tenantSlug) {
		to = `/tenant/${tenantSlug}${to}`;
	} else if (tenantID) {
		to = `/tenant/${tenantID}${to}`;
	}

	const nextProps = {
		...props,
		to,
	};

	return <RouterLink {...nextProps} />;
};
