import * as React from 'react';
import { Box, Card, CardContent, Typography, Stack } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutCardProps } from './types';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

export const DoughnutCard: React.FC<DoughnutCardProps> = ({ image, title, label, data }) => {
	return (
		<Card>
			<CardContent>
				<Stack direction='column' spacing={2}>
					<Stack direction='row' spacing={1} justifyContent='center'>
						{image && (
							<Box
								component='img'
								sx={{
									width: 32,
									height: 32,
								}}
								src={image}
							/>
						)}
						<Typography>{title}</Typography>
					</Stack>

					<Doughnut
						data={{
							labels: [...data.keys()],
							datasets: [
								{
									label,
									data: [...data.values()],
									borderWidth: 1,
									backgroundColor: [
										'rgb(255, 99, 132)',
										'rgb(54, 162, 235)',
										'rgb(255, 205, 86)',
										'rgb(255, 99, 132)',
										'rgb(75, 192, 192)',
										'rgb(153, 102, 255)',
										'rgb(255, 159, 64)',
										'rgb(143, 68, 173)',
										'rgb(139, 195, 74)',
										'rgb(255, 0, 255)',
									],
								},
							],
						}}
						options={{
							plugins: {
								legend: {
									display: true,
									position: 'right',
								},
							},
						}}
					/>
				</Stack>
			</CardContent>
		</Card>
	);
};
