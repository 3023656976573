import { useRef, useEffect } from 'react';

export const usePreviousValue = (currentValue: any): any => {
	const ref = useRef();
	useEffect(() => {
		ref.current = currentValue;
	}, [currentValue]);

	return ref.current;
};
