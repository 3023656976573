import { Checkbox as OriginalCheckbox, FormControlLabel, FormHelperText, Box } from '@mui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';

import { CheckboxProps } from './types';

export const Checkbox = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
	control,
	error,
	helperText,
	label,
	name,
	onChangeCallback,
	...rest
}: CheckboxProps<TFieldValues, TName>) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value } }) => {
				return (
					<Box>
						<FormControlLabel
							control={
								<OriginalCheckbox
									checked={value}
									onChange={(e) => {
										const newValue = e.target.checked;

										onChange(newValue);
										onChangeCallback?.(newValue);
									}}
									{...rest}
								/>
							}
							label={label}
						/>
						<Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
							{error && typeof error.message === 'string' ?
								<FormHelperText error id={`switch-${name}`}>
									{error.message}
								</FormHelperText>
							:	<FormHelperText>{helperText}</FormHelperText>}
						</Box>
					</Box>
				);
			}}
		/>
	);
};
