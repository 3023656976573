import * as React from 'react';
import { Grid, Typography, Box, Stepper, Step, StepButton } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { DeploymentFinalization } from './components/DeploymentFinalization';
import { CSRSetup } from './components/CSRSetup';
import { ServerSetup } from './components/ServerSetup';
import { IdentityStoreSetup } from './components/IdentityStoreSetup';
import { SMTPSetup } from './components/SMTPSetup';
import { Verification } from './components/Verification';
import { useDeviceDetect } from '../../hooks/useDeviceDetect';
import { steps } from './constants';
import { Preloader } from '../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { SetupWizardProgressState } from './types';
import { ESetupSteps } from './enums';
import { useCurrentLanguage } from '../../contexts/LocalizationContext/LocalizationContext';

export const StyledStepper = styled(Stepper)({
	'.Mui-completed': {
		color: 'green !important',
	},
});

export const SetupWizardPage: React.FC = (): JSX.Element => {
	const device = useDeviceDetect();
	const currentLanguage = useCurrentLanguage();
	const api = useSwaggerApi();
	const [initialSetupIndex, setInitialSetupIndex] = React.useState<ESetupSteps>(ESetupSteps.CSR);
	const [indexPage, setIndexPage] = React.useState<number>(1);
	const activeStepStepper = indexPage - 1;
	const { t } = useTranslation();
	const [setupWizardProgress, setSetupWizardProgress] = React.useState<SetupWizardProgressState>({
		checking: false,
		checked: false,
		error: null,
	});

	const getCurrentStep = (setupStep: ESetupSteps) => {
		if (setupStep === ESetupSteps.CSR) {
			return 1;
		} else if (setupStep === ESetupSteps.SERVER_SETUP) {
			return 2;
		} else if (setupStep === ESetupSteps.SMTP) {
			return 3;
		} else if (setupStep === ESetupSteps.SMTP_VERIFY) {
			return 4;
		} else if (setupStep === ESetupSteps.IDENTITY_STORE) {
			return 5;
		} else if (
			setupStep === ESetupSteps.IDENTITY_STORE_AUTHENTICATION ||
			setupStep === ESetupSteps.DEPLOYMENT_FINALIZATION ||
			setupStep === ESetupSteps.SETUP_COMPLETED
		) {
			return 6;
		}

		return 1;
	};

	const getLabel = React.useCallback(
		(index: number) => {
			if (device !== 'desktop') {
				return activeStepStepper === index ? t(steps[index]) : '';
			}

			return t(steps[index]);
		},
		[activeStepStepper, t, device],
	);

	React.useEffect(() => {
		const checkSetupWizardProgress = async () => {
			setSetupWizardProgress({
				checking: true,
				checked: false,
				error: null,
			});
			try {
				const response = await api.setup.getSetupProgress();

				setInitialSetupIndex(response.data as ESetupSteps);
				setIndexPage(getCurrentStep(response.data as ESetupSteps));
				setSetupWizardProgress({
					checked: true,
					checking: false,
					error: null,
				});
			} catch (error) {
				setSetupWizardProgress({
					checked: false,
					checking: false,
					error: error as AxiosError,
				});
			}
		};
		if (!setupWizardProgress.checked && !setupWizardProgress.checking && !setupWizardProgress.error) {
			checkSetupWizardProgress();
		}
	}, [api, setupWizardProgress]);

	const languageChange = async () => {
		const resp = await api.setup.changeSystemLanguage({ language: currentLanguage });
	};

	React.useEffect(() => {
		languageChange();
	}, [currentLanguage]);

	return setupWizardProgress.checking || !setupWizardProgress.checked ?
			<Preloader percentageHeight={100} />
		:	<Box>
				<Grid
					container
					spacing={2}
					justifyContent='center'
					sx={{
						padding: 2,
					}}
				>
					<Grid item xs={12}>
						<Typography variant='h5' align='center'>
							{t('page.setupWizard.mainTitle')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<StyledStepper activeStep={activeStepStepper}>
							{steps.map((_, index) => (
								<Step key={`setup-wizard-step-${index}`}>
									<StepButton>{getLabel(index)}</StepButton>
								</Step>
							))}
						</StyledStepper>
					</Grid>

					<Grid item xs={12} md={8} lg={6}>
						{indexPage === 1 && <CSRSetup setIndexPage={setIndexPage} getCurrentStep={getCurrentStep} />}
						{indexPage === 2 && <ServerSetup setIndexPage={setIndexPage} getCurrentStep={getCurrentStep} />}
						{indexPage === 3 && <SMTPSetup setIndexPage={setIndexPage} getCurrentStep={getCurrentStep} />}
						{indexPage === 4 && (
							<Verification setIndexPage={setIndexPage} getCurrentStep={getCurrentStep} />
						)}
						{indexPage === 5 && (
							<IdentityStoreSetup
								setInitialSetupIndex={setInitialSetupIndex}
								setIndexPage={setIndexPage}
								getCurrentStep={getCurrentStep}
							/>
						)}
						{indexPage === 6 && (
							<DeploymentFinalization
								setInitialSetupIndex={setInitialSetupIndex}
								setIndexPage={setIndexPage}
								getCurrentStep={getCurrentStep}
								initialSetupIndex={initialSetupIndex}
							/>
						)}
					</Grid>
				</Grid>
			</Box>;
};
