import parse, { Units } from 'parse-duration';
import { intervalToDuration, formatDuration, format, isValid } from 'date-fns';

export const getDurationInUnits = (time: string, format: Units): number => {
	try {
		const result = Math.round(parse(time, format) ?? 0);
		if (!result) {
			return 0;
		}

		return result >= 1 ? result : 0;
	} catch {
		return 0;
	}
};

export const getDurationInString = (time: number, multiplier = 1000): string => {
	const duration = intervalToDuration({ start: 0, end: time * multiplier });
	const { years, months, days, hours, minutes, seconds } = duration;

	return formatDuration({ years, months, days, hours, minutes, seconds });
};

export const formatDateTime = (date: Date | string | undefined, includeTime = false) => {
	if (!date) {
		return 'N/A';
	}

	const parsedDate = new Date(date);

	if (!isValid(parsedDate)) {
		return 'N/A';
	}

	const formatString = includeTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';

	return format(parsedDate, formatString);
};
