import * as React from 'react';
import { Typography } from '@mui/material';
import { HeadingProps } from './types';

export const Heading: React.FC<HeadingProps> = ({ label, fontSize = 20, ...props }) => {
	return (
		<Typography variant='subtitle1' {...props} sx={{ fontSize: fontSize, paddingBottom: 1 }}>
			{label}
		</Typography>
	);
};
