import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import copy from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import { Certificate } from '../../components/Certificate/Certificate';
import { CertificateInfoDto } from '../../api/Api';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import CertificateSkeleton from '../../components/Certificate/CertificateSkeleton';

import { ECertificateType } from '../../enums/saml/ECertificateType';

export const SAMLIdentityProviderConfiguration: React.FC = (): JSX.Element => {
	const [signingCertificate, setSigningCertificate] = React.useState<CertificateInfoDto>();
	const [encryptionCertificate, setEncryptionCertificate] = React.useState<CertificateInfoDto>();
	const [IdPEntityID, setIdPEntityID] = React.useState<string>('https://dev.xclbr.com');
	const [metadataURL, setMetadataURL] = React.useState<string>('https://dev.xclbr.com/api/saml/idp/metadata');

	const api = useSwaggerApi();

	React.useEffect(() => {
		(async () => {
			try {
				const [signingCertificate, encryptionCertificate] = await Promise.all([
					api.saml.getSigningCertificate(),
					api.saml.getEncryptionCertificate(),
				]);

				setSigningCertificate(signingCertificate.data);
				setEncryptionCertificate(encryptionCertificate.data);
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	const handleCopyIdpEntityId = (e: any) => {
		setIdPEntityID(e.target.value);
	};

	const handleCopyMetadataURL = (e: any) => {
		setMetadataURL(e.target.value);
	};

	const copyToClipboardIdpEntityId = () => {
		copy(IdPEntityID);
		enqueueSnackbar('Copied to clipboard');
	};

	const copyToClipboardMetadataURL = () => {
		copy(metadataURL);
		enqueueSnackbar('Copied to clipboard');
	};

	return (
		<Box component={'form'} noValidate autoComplete='off'>
			<Box
				sx={{
					paddingBottom: 1,
				}}
			>
				<Typography variant='h5'>Identify Provider Configuration</Typography>
			</Box>

			<Paper
				elevation={3}
				sx={{
					paddingBottom: 2,
				}}
			>
				<Box
					sx={{
						padding: 2,
					}}
				>
					<Typography variant='subtitle1'>General</Typography>
				</Box>

				<FormGroup>
					<Grid
						container
						spacing={2}
						sx={{
							padding: 2,
						}}
					>
						<Grid item xs={12} md={12} lg={6}>
							<TextField
								label='IdP entity ID'
								variant='outlined'
								type='text'
								helperText='Excalibur Identity Provider Entity ID'
								value={IdPEntityID}
								onChange={handleCopyIdpEntityId}
								InputProps={{
									readOnly: true,
									endAdornment: (
										<IconButton aria-label='copy' onClick={copyToClipboardIdpEntityId}>
											<ContentCopyIcon />
										</IconButton>
									),
								}}
								sx={{
									width: '100%',
								}}
							/>
						</Grid>
					</Grid>
				</FormGroup>

				<Box
					sx={{
						padding: 2,
					}}
				>
					<Typography variant='subtitle1'>Metadata</Typography>
				</Box>

				<FormGroup>
					<Grid
						container
						spacing={2}
						sx={{
							padding: 2,
						}}
					>
						<Grid item xs={12} md={12} lg={6}>
							<TextField
								label='URL'
								variant='outlined'
								type='text'
								helperText='URL to metadata XML file'
								onChange={handleCopyMetadataURL}
								value={metadataURL}
								InputProps={{
									readOnly: true,
									endAdornment: (
										<IconButton aria-label='copy' onClick={copyToClipboardMetadataURL}>
											<ContentCopyIcon />
										</IconButton>
									),
								}}
								sx={{
									width: '100%',
								}}
							/>
						</Grid>

						<Box
							sx={{
								width: '100%',
							}}
						></Box>

						<Grid item>
							<Button variant='contained' startIcon={<ContentCopyIcon />}>
								Copy metadata XML
							</Button>
						</Grid>

						<Grid item>
							<Button variant='contained' startIcon={<FileDownloadIcon />}>
								Download metadata XML file
							</Button>
						</Grid>
					</Grid>
				</FormGroup>

				<Box
					sx={{
						padding: 2,
					}}
				>
					<Typography variant='subtitle1'>Certificates</Typography>
				</Box>

				<FormGroup>
					<Grid container spacing={2} padding={2}>
						<Grid item xs={12} md={12} lg={6}>
							{signingCertificate ?
								<Certificate
									title='Signing certificate'
									subject={signingCertificate.subject}
									issuer={signingCertificate.issuer}
									algorithm={signingCertificate.signatureAlgorithm}
									fingerprint={signingCertificate.fingerprint}
									expiration={new Date(signingCertificate.validity.notAfter)}
									content={signingCertificate.content}
									type={ECertificateType.SIGNIN}
								/>
							:	<CertificateSkeleton listsToRender={5} />}
						</Grid>
						<Grid item xs={12} md={12} lg={6}>
							{encryptionCertificate ?
								<Certificate
									title='Encryption certificate'
									subject={encryptionCertificate.subject}
									issuer={encryptionCertificate.issuer}
									algorithm={encryptionCertificate.signatureAlgorithm}
									fingerprint={encryptionCertificate.fingerprint}
									expiration={new Date(encryptionCertificate?.validity.notAfter)}
									content={encryptionCertificate.content}
									type={ECertificateType.ENCRYPTION}
								/>
							:	<CertificateSkeleton listsToRender={5} />}
						</Grid>
					</Grid>
				</FormGroup>
			</Paper>
		</Box>
	);
};
