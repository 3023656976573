import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { PartialTenantModel } from '../../api/Api';
import { TenantSelection } from './types';
import { ConfirmationDialog } from '../Dialog/ConfirmationDialog/ConfirmationDialog';

export const TenantSwitcher: React.FC = (): JSX.Element => {
	const authContext = useAuthContext();
	const { t } = useTranslation();
	const isTenantAdmin = !authContext.user.user?.tenantID;
	const [open, setOpen] = React.useState(false);
	const [tenantToSwitch, setTenantToSwitch] = React.useState<number | null>(null);
	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	const handleOnTenantToConfirm = React.useCallback(
		(tenantID: number | null) => {
			if (authContext.userTenants.activeTenantID !== tenantID) {
				setTenantToSwitch(tenantID);
				setOpen(true);
			}
		},
		[authContext],
	);

	const handleOnConfirmSwitchTenant = React.useCallback(() => {
		authContext.handleOnSwitchToTenant(tenantToSwitch);
		setOpen(false);
		setTenantToSwitch(null);
	}, [tenantToSwitch]);

	const options: TenantSelection[] = React.useMemo(() => {
		const optns = authContext.userTenants.tenants.map((tenant: PartialTenantModel) => {
			const option: TenantSelection = {
				label: tenant.name ? tenant.name : '',
				id: tenant.id ? tenant.id : 0,
			};

			return option;
		});
		if (isTenantAdmin) {
			optns.unshift({
				label: 'System',
				id: 'all',
			});
		}

		return optns;
	}, [authContext.userTenants, isTenantAdmin]);

	const defaultSelectedOption = React.useMemo(() => {
		if (authContext.userTenants.activeTenantID === null && isTenantAdmin) {
			return options.find((option) => option.id === 'all');
		} else if (!authContext.userTenants.activeTenantID) {
			return options[0];
		}

		return options.find((option) => option.id === authContext.userTenants.activeTenantID);
	}, [options, authContext.userTenants.activeTenantID, isTenantAdmin]);

	const handleOnSwitchTenant = React.useCallback(
		(_: React.SyntheticEvent, value: TenantSelection | null) => {
			const id = value && typeof value.id === 'number' ? value.id : null;
			handleOnTenantToConfirm(id);
		},
		[authContext.handleOnSwitchToTenant],
	);

	return (
		<>
			<Autocomplete
				disablePortal
				disableClearable
				defaultValue={defaultSelectedOption}
				id='tenant-switcher'
				onChange={handleOnSwitchTenant}
				options={options}
				sx={{ maxWidth: 200, width: '100%' }}
				value={defaultSelectedOption}
				renderInput={(params) => <TextField {...params} label='Tenants' variant='standard' />}
			/>
			<ConfirmationDialog
				onClose={handleClose}
				open={open}
				onConfirm={handleOnConfirmSwitchTenant}
				title={t('component.tenantSwitcher.confirmation.title')}
				text={t('component.tenantSwitcher.confirmation.text', {
					tenantName: options.find((option) => option.id === tenantToSwitch)?.label ?? 'System',
				})}
				cancelText={t('component.tenantSwitcher.confirmation.cancelText')}
				confirmText={t('component.tenantSwitcher.confirmation.confirmText')}
			/>
		</>
	);
};
