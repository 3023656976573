import * as React from 'react';
import Dropzone from 'react-dropzone';
import { FileUploaderProps } from './type';
import { useTheme } from '@mui/material/styles';
import { Box, FormHelperText, Grid, Tooltip, Typography } from '@mui/material';
import { FileUpload as FileUploadIcon, Clear as ClearIcon } from '@mui/icons-material';
import { Controller, FieldValues, FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const FileUploader = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
	props: FileUploaderProps<TFieldValues, TName>,
) => {
	const { control, name, error, helperText, accept, disabled = false, multiple = false } = props;

	const { t } = useTranslation();
	const theme = useTheme();

	const formatBytes = React.useCallback((bytes: number, decimals: number): string => {
		if (!bytes) {
			return '0 Bytes';
		}

		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	}, []);

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => {
				const handleOnDropFile = React.useCallback(
					(acceptedFiles: File[]) => {
						/* Using un-destructured props to enable type inferrence for onChangeCallback */
						if (props.multiple) {
							if (!field.value) {
								field.onChange(acceptedFiles);
								props.onChangeCallback?.(acceptedFiles);

								return;
							}
							const currentFiles = Array.isArray(field.value) ? field.value : [field.value];
							const newFiles = acceptedFiles.filter((file: File) => {
								return !currentFiles.some((currentFile: File) => currentFile.name === file.name);
							});
							field.onChange([...currentFiles, ...newFiles]);
							props.onChangeCallback?.([...currentFiles, ...newFiles]);

							return;
						}
						field.onChange(acceptedFiles[0]);
						props.onChangeCallback?.(acceptedFiles[0]);
					},
					[field.value],
				);

				const handleOnRemoveFile = React.useCallback(
					(fileName: string) => (event: React.MouseEvent) => {
						event.stopPropagation();
						if (!field.value) {
							return;
						}

						const currentFiles: File[] = Array.isArray(field.value) ? field.value : [field.value];
						const newFiles = currentFiles.filter((file: File) => file.name !== fileName);
						field.onChange(newFiles.length > 0 ? newFiles : null);

						/* Using un-destructured props to enable type inferrence for onChangeCallback */
						props.multiple ?
							props.onChangeCallback?.(newFiles.length > 0 ? newFiles : [])
						:	props.onChangeCallback?.(null);
					},
					[field.value],
				);

				return (
					<Dropzone multiple={multiple} onDrop={handleOnDropFile} accept={accept}>
						{({ getRootProps, getInputProps, isDragActive }) => (
							<>
								<Box
									{...getRootProps()}
									sx={{
										border: '1px dashed',
										borderColor:
											isDragActive ? theme.palette.text.secondary : theme.palette.text.secondary,
										background: isDragActive ? theme.palette.action.hover : 'inherit',
										borderRadius: 1,
										padding: 2,
										textAlign: 'center',
										cursor: 'pointer',
										width: '100%',
										minHeight: 110,
										overflow: 'hidden',
										'&:hover': {
											borderColor: theme.palette.text.secondary,
											background: theme.palette.action.hover,
										},
									}}
								>
									<input {...getInputProps()} disabled={disabled} />
									<Box>
										{field.value ?
											(Array.isArray(field.value) ? field.value : [field.value]).map(
												(file: File, index: number) => (
													<Grid container key={`${index}-${name}`} alignItems={'center'}>
														<Grid item xs={9}>
															<Tooltip title={file.name}>
																<Typography
																	variant='body2'
																	sx={{
																		textAlign: 'left',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		whiteSpace: 'nowrap',
																	}}
																>
																	{file.name}
																</Typography>
															</Tooltip>
														</Grid>
														<Grid item xs={2}>
															<Typography variant='body2'>
																{formatBytes(file.size, 2)}
															</Typography>
														</Grid>
														<Grid item xs={1}>
															<Tooltip
																title={t('component.fileUploader.body.tooltip.remove')}
															>
																<ClearIcon onClick={handleOnRemoveFile(file.name)} />
															</Tooltip>
														</Grid>
													</Grid>
												),
											)
										:	<Box>
												<FileUploadIcon
													sx={{ fontSize: 40, color: theme.palette.action.active }}
												/>
												<Typography variant='body1' sx={{ color: theme.palette.action.active }}>
													{t('component.fileUploader.body.text')}
												</Typography>
											</Box>
										}
									</Box>
								</Box>
								<Box sx={{ margin: '3px 14px' }}>
									{error && typeof error.message === 'string' ?
										<FormHelperText error id={`${name}-error`}>
											{error.message}
										</FormHelperText>
									:	<FormHelperText>{helperText}</FormHelperText>}
								</Box>
							</>
						)}
					</Dropzone>
				);
			}}
		/>
	);
};
