import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';

export const useReactQueryClient = () => {
	const queryClient = useQueryClient();

	const getActiveQueries = React.useCallback(() => {
		const uniqueQueries = new Set(
			queryClient
				.getQueryCache()
				.findAll()
				.map((query) => query.queryKey[0]),
		);

		return Array.from(uniqueQueries);
	}, [queryClient]);

	const invalidateQueries = React.useCallback(() => {
		const activeQueries = getActiveQueries();
		activeQueries.forEach((queryKey) => {
			queryClient.invalidateQueries({
				queryKey: [queryKey],
			});
		});
	}, [queryClient, getActiveQueries]);

	const unmountReactQuery = React.useCallback(
		(queryKey?: string) => {
			// if (queryKey) {
			// 	queryClient.removeQueries({
			// 		queryKey: [queryKey],
			// 	});
			// 	return;
			// }
			// queryClient.removeQueries();
		},
		[queryClient],
	);

	return {
		queryClient,
		invalidateQueries,
		unmountReactQuery,
	};
};
