import * as React from 'react';

import { AuthContext } from '../contexts/AuthContext/AuthContext';
import { acl } from '../utils/ACL';
import { EPermission } from '../enums/permission/EPermission';

type UseACL = { isAllowed: (expectedPermissions: string[], strictMode?: boolean) => boolean };

export const useACL = (strictScope?: string): UseACL => {
	const authContext = React.useContext(AuthContext);
	let scope = authContext.user.user?.tenantID ? 'tenant' : 'system';
	scope = strictScope ? strictScope : scope;
	const systemPermissions = authContext.userRole.role?.systemPermissions || [];
	const tenantPermissions = authContext.userRole.role?.tenantPermissions || [];
	const permissions = scope === 'system' ? systemPermissions : tenantPermissions;

	const isAllowed = React.useCallback(
		(expectedPermissions: string[], strictMode = true): boolean => {
			const userPermissions: EPermission[] = [];
			for (const permission of permissions) {
				userPermissions.push(permission as EPermission);
			}

			return acl(userPermissions, expectedPermissions, strictMode);
		},
		[authContext],
	);

	return {
		isAllowed,
	};
};
