import * as React from 'react';
import { Grid, InputLabel, MenuItem, Typography, Box, FormControl, Select } from '@mui/material';
import { StatisticsCard } from '../../components/StatisticsCard/StatisticsCard';
import { datasetsIncident, datasetsUsersWithIncidents } from './data/statisticsData';
import { ChartLine } from '../../components/Chart/ChartLine';
import {
	dataMostIncidents,
	dataMostSevereIncidents,
	dataTopIncidentsByUser,
	dataTopIncidentsOnClient,
	optionsMostIncidents,
	optionsMostSevereIncidents,
	optionsTopIncidentsByUser,
	optionsTopIncidentsOnClient,
} from './data/incidentsData';
import { ChartBar } from '../../components/Chart/ChartBar';

export const IncidentsPage: React.FC = () => {
	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					paddingBottom: 1,
				}}
			>
				<Typography variant='h5'>Incidents statistics</Typography>

				<FormControl>
					<InputLabel id='date-range-select-label'>Date range</InputLabel>
					<Select
						labelId='date-range-select-label'
						id='date-range-select'
						value='10'
						label='Date range'
						//onChange={handleChange}
					>
						<MenuItem value={10}>This week</MenuItem>
						<MenuItem value={20}>Last week</MenuItem>
						<MenuItem value={30}>This month</MenuItem>
						<MenuItem value={40}>Last month</MenuItem>
						<MenuItem value={50}>This year</MenuItem>
						<MenuItem value={60}>Last year</MenuItem>
						<MenuItem value={70} disabled>
							Custom
						</MenuItem>
					</Select>
				</FormControl>
			</Box>

			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Users with incidents'
							count={1}
							prevCount={91}
							negativeIsGood
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='All Incidents'
							count={12}
							prevCount={1567}
							negativeIsGood
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Critical Incidents'
							count={5}
							prevCount={10}
							negativeIsGood
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='High Incidents'
							count={2}
							prevCount={60}
							negativeIsGood
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Medium Incidents'
							count={3}
							prevCount={60}
							negativeIsGood
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Low Incidents'
							count={2}
							prevCount={60}
							negativeIsGood
						/>
					</Grid>
				</Grid>
			</Box>

			<Box py={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Incidents' dataSet={datasetsIncident} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Users with incidents' dataSet={datasetsUsersWithIncidents} />
					</Grid>
				</Grid>
			</Box>

			<Box marginTop={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartBar options={optionsMostIncidents} data={dataMostIncidents} title='Most incidents' />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartBar
							options={optionsMostSevereIncidents}
							data={dataMostSevereIncidents}
							title='Most severe incidents'
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartBar
							options={optionsTopIncidentsByUser}
							data={dataTopIncidentsByUser}
							title='Top incidents by user'
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartBar
							options={optionsTopIncidentsOnClient}
							data={dataTopIncidentsOnClient}
							title='Top incidents on client'
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
