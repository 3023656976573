import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

interface StatisticsSkeletonProps {
	listsToRender: number;
}

export const StatisticsSkeleton: React.FC<StatisticsSkeletonProps> = ({ listsToRender }) => {
	return (
		<Box>
			<Skeleton
				variant='text'
				sx={{
					width: '100%',
					fontSize: '2rem',
				}}
			/>

			{Array.from({ length: listsToRender }).map((_, index) => (
				<Skeleton
					key={index}
					variant='text'
					width='inherit'
					style={{
						fontSize: '3rem',
					}}
				/>
			))}
		</Box>
	);
};
