import { Controller } from 'react-hook-form';
import { AutocompleteProps, OptionBase } from './types';
import {
	Autocomplete as OriginalAutocomplete,
	AutocompleteRenderInputParams,
	TextField,
	Typography,
} from '@mui/material';
import { EFieldVariant } from './enums';

export const Autocomplete = <T extends OptionBase>({
	control,
	name,
	label,
	options,
	optionLabelKey,
	helperText,
	error,
	disabled = false,
	fullWidth = true,
	multiple = true,
	variant = EFieldVariant.OUTLINED,
}: AutocompleteProps<T>): JSX.Element => {
	const validOptions = Array.isArray(options) ? options.filter((option) => option[optionLabelKey] !== undefined) : [];

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<OriginalAutocomplete
					{...field}
					multiple={multiple}
					options={validOptions}
					getOptionLabel={(option) => (option ? option[optionLabelKey] ?? '' : '')}
					value={(field.value || []).map((value: number) => options.find((option) => option.id === value))}
					onChange={(_, data) => field.onChange(data.map((option: T) => option?.id))}
					renderInput={(params: AutocompleteRenderInputParams) => (
						<TextField
							{...params}
							label={label}
							error={!!error}
							disabled={disabled}
							variant={variant}
							fullWidth={fullWidth}
							helperText={
								error && typeof error.message === 'string' ?
									<Typography component='span' color='error' sx={{ fontSize: 12 }}>
										{error.message}
									</Typography>
								:	helperText
							}
						/>
					)}
				/>
			)}
		/>
	);
};
