export const steps = [
	'page.setupWizard.steps.csrSetup',
	'page.setupWizard.steps.serverSetup',
	'page.setupWizard.steps.smtpSetup',
	'page.setupWizard.steps.verification',
	'page.setupWizard.steps.identityStore',
	'page.setupWizard.steps.finalization',
];

export const SUPPORTED_FILE_TYPES_CA_CERTIFICATE = {
	'application/octet-stream': ['.pem', '.cer'],
};

export const SUPPORTED_FILE_TYPES_LICENSE = {
	'application/octet-stream': ['.lic'],
};
