import * as React from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';
import { MenuItem as MenuItemSidebar, MenuItemProps } from 'react-pro-sidebar';
import { TooltipProps } from './types';
import { ETooltipPlacement } from './enums';

export const MenuItem: React.FC<MenuItemProps & TooltipProps> = ({
	tooltipTitle,
	tooltipDelay = 1000,
	tooltipPlacement = ETooltipPlacement.RIGHT,
	...rest
}): JSX.Element => {
	const [open, setOpen] = React.useState(false);
	const timer = React.useRef<NodeJS.Timeout | null>(null);

	const handleOnMouseEnter = React.useCallback(() => {
		timer.current = setTimeout(() => {
			setOpen(true);
		}, tooltipDelay);
	}, [tooltipDelay, timer.current]);

	const handleOnMouseLeave = React.useCallback(() => {
		if (timer.current) {
			clearTimeout(timer.current);
		}
		setOpen(false);
	}, [timer.current]);

	return (
		<Tooltip
			title={tooltipTitle}
			enterNextDelay={tooltipDelay}
			placement={tooltipPlacement}
			arrow
			open={open}
			onClose={handleOnMouseLeave}
			PopperProps={{
				sx: {
					[`& .${tooltipClasses.arrow}`]: {
						left: '0 !important',
						top: '0 !important',
					},
				},
			}}
		>
			<span>
				<MenuItemSidebar {...rest} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} />
			</span>
		</Tooltip>
	);
};
