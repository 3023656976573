import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Box, IconButton, Typography, Tooltip, Stack } from '@mui/material';
import { Link } from '../../components/Link/Link';
import {
	SaveAlt as SaveAltIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Groups as GroupsIcon,
} from '@mui/icons-material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
	MRT_ToggleDensePaddingButton,
	type MRT_ColumnDef,
	MRT_ToggleFullScreenButton,
	MRT_ToggleFiltersButton,
	MRT_ShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton,
	MRT_PaginationState,
	MRT_RowSelectionState,
	MRT_Row,
	MaterialReactTable,
	MRT_ColumnFiltersState,
	MRT_SortingState,
	MRT_TableInstance,
} from 'material-react-table';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { EPermission } from '../../enums/permission/EPermission';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { PagedResultGetRoleResponseDto, GetRoleResponseDto } from '../../api/Api';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { roleListSchema } from './schema';
import { useACL } from '../../hooks/useACL';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';

export const RolesPage: React.FC = (): JSX.Element => {
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const reactQueryClient = useReactQueryClient();
	const { MRTLocalization } = useMRTLocalization();

	const [sorting] = React.useState<MRT_SortingState>([]);
	const [globalFilter] = React.useState('');
	const [columnFilters] = React.useState<MRT_ColumnFiltersState>([]);
	const [open, setOpen] = React.useState(false);
	const [roleIDToDelete, setRoleIDToDelete] = React.useState<number | null>(null);
	const [confirmationText, setConfirmationText] = React.useState('');
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>({});
	const [multiRoleIDsToDelete, setMultiRoleIDsToDelete] = React.useState<number[]>([]);
	const [pagination, setPagination] = React.useState<MRT_PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultGetRoleResponseDto>({
		queryKey: [
			EQueryKey.ROLE_LIST_QUERY,
			columnFilters,
			globalFilter,
			pagination.pageIndex,
			pagination.pageSize,
			sorting,
		],
		queryFn: async () => {
			try {
				const response = await api.roles.getRoles({
					limit: pagination.pageSize,
					offset: pagination.pageIndex * pagination.pageSize,
				});
				response.data.entities.forEach((role) => {
					roleListSchema.parse(role);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.roles.list.confirmation.delete.title'));
				setConfirmationText(t('page.roles.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.roles.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.roles.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const hadleOnOpenRoleDeleteDialog = React.useCallback(
		(id: number) => () => {
			setRoleIDToDelete(id);
			setOpen(true);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[isAllowed],
	);

	const handleOnConfirmRoleDelete = React.useCallback(async (): Promise<void> => {
		if (roleIDToDelete) {
			try {
				await api.roles.deleteRole(roleIDToDelete);
				enqueueSnackbar(t('page.role.list.actionMessages.roletSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setRoleIDToDelete(null);
			} catch (error) {
				console.error(error);
			}
		} else if (multiRoleIDsToDelete.length > 0) {
			try {
				await api.roles.deleteRoles({ ids: multiRoleIDsToDelete });
				enqueueSnackbar(t('page.roles.list.actionMessages.rolesSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiRoleIDsToDelete([]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [roleIDToDelete, multiRoleIDsToDelete, isAllowed]);

	const handleOnOpenMultipleRolesDeleteDialog = React.useCallback(
		(selectedRows: MRT_RowSelectionState, table: MRT_TableInstance<GetRoleResponseDto>) => () => {
			const selectedRowIDs = Object.keys(selectedRows);

			const selectedRoleIDs = selectedRowIDs.reduce((ids: number[], rowID: string) => {
				const selectedRow = table.getRow(rowID);
				if (selectedRow && selectedRow.original.id !== undefined) {
					return [...ids, selectedRow.original.id];
				}

				return ids;
			}, []);

			if (selectedRoleIDs.length === 0) {
				enqueueSnackbar(t('page.roles.list.actionMessages.noRolesSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}
			setOpen(true);
			if (Array.isArray(selectedRoleIDs)) {
				setMultiRoleIDsToDelete(selectedRoleIDs);
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[confirmationText, confirmationTitle, enqueueSnackbar, isAllowed],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRT_ColumnDef<GetRoleResponseDto>[]>(
		() => [
			{
				accessorKey: 'name',
				header: t('page.roles.list.columns.name'),
			},
			{
				accessorKey: 'description',
				header: t('page.roles.list.columns.description'),
			},
		],
		[],
	);

	return (
		<Box component={'form'} noValidate autoComplete='off' sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.roles.list.title')}
						description={t('page.roles.list.description')}
						icon={GroupsIcon}
					/>
					<MaterialReactTable
						columns={columns}
						data={entities}
						state={{
							isLoading,
							showAlertBanner: error !== null,
							pagination,
							rowSelection,
							showProgressBars: isRefetching,
						}}
						enableEditing
						editDisplayMode='modal'
						rowCount={total}
						manualPagination
						onRowSelectionChange={setRowSelection}
						initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
						enableRowSelection={(row: MRT_Row<GetRoleResponseDto>) => {
							return !row.original.isBuiltIn && isAllowed([EPermission.ROLES_DELETE]);
						}}
						onPaginationChange={setPagination}
						positionActionsColumn='last'
						muiToolbarAlertBannerProps={{
							color: 'error',
							children: <>{error}</>,
						}}
						renderToolbarInternalActions={({ table }) => {
							return (
								<Box sx={{ display: 'flex', gap: '1rem' }}>
									<MRT_ToggleGlobalFilterButton table={table} />
									<MRT_ToggleFiltersButton table={table} />
									<MRT_ShowHideColumnsButton table={table} />
									{/* <Tooltip arrow title={t('page.roles.list.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									{isAllowed([EPermission.ROLES_DELETE]) && (
										<Tooltip title={t('page.roles.list.tooltips.removeSelected')} enterDelay={500}>
											<span>
												<IconButton
													color='error'
													disabled={
														!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
													}
													onClick={handleOnOpenMultipleRolesDeleteDialog(rowSelection, table)}
												>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									<MRT_ToggleDensePaddingButton table={table} />
									<MRT_ToggleFullScreenButton table={table} />
								</Box>
							);
						}}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								muiTableHeadCellProps: {
									align: 'center',
								},
								size: 120,
								enableHiding: true,
							},
							'mrt-row-select': {
								enableHiding: true,
								visibleInShowHideMenu: false,
							},
						}}
						muiTablePaperProps={({ table }) => ({
							style: {
								zIndex: table.getState().isFullScreen ? 1100 : undefined,
								boxShadow: 'none',
								outline: '1px solid #e0e0e0',
							},
						})}
						muiSelectCheckboxProps={() => ({
							sx: {
								width: '50px',
								height: '50px',
							},
						})}
						muiSelectAllCheckboxProps={() => ({
							sx: {
								width: '50px',
								height: '50px',
							},
						})}
						muiTableHeadCellProps={() => ({
							sx: {
								verticalAlign: 'baseline',
							},
						})}
						renderRowActions={({ row }) => (
							<Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
								{isAllowed([EPermission.ROLES_UPDATE]) && !row.original.isBuiltIn && (
									<Tooltip
										arrow
										placement='left'
										title={t('page.roles.list.tooltips.edit')}
										enterDelay={500}
									>
										<span>
											<IconButton
												disabled={row.original.isBuiltIn}
												component={Link}
												to={`/security/roles/edit/${row.original.id}`}
											>
												<EditIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
								{isAllowed([EPermission.ROLES_DELETE]) && !row.original.isBuiltIn && (
									<Tooltip
										arrow
										placement='right'
										title={t('page.roles.list.tooltips.delete')}
										enterDelay={500}
									>
										<span>
											<IconButton
												disabled={row.original.isBuiltIn}
												onClick={hadleOnOpenRoleDeleteDialog(row.original.id)}
												color='error'
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
							</Box>
						)}
						localization={MRTLocalization}
					/>
					{isAllowed([EPermission.ROLES_CREATE]) && (
						<Link to='/security/roles/new'>
							<FloatingButtonAdd ariaLabel={'Add Role'} />
						</Link>
					)}
				</Stack>
			</Paper>
			{isAllowed([EPermission.ROLES_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmRoleDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.roles.list.confirmation.delete.cancel')}
					confirmText={t('page.roles.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
