import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AddTargetDialogProps } from './types';
import { EPAMTargetType } from '../../api/Api';
import { Link } from '../../components/Link/Link';
import RdpIcon from '../../assets/images/rdp-icon.png';
import SshIcon from '../../assets/images/ssh-icon.png';
import VncIcon from '../../assets/images/vnc-icon.png';

const ICON_HEIGHT = 42;

const StyledLink = styled(Link)`
	text-decoration: none;
	color: inherit;
	width: 100%;

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		color: inherit;
	}
`;

const getTargetItemByType = (type: EPAMTargetType) => {
	switch (type) {
		case EPAMTargetType.RDP:
			return {
				icon: RdpIcon,
				key: 'rdp',
				to: '/pam/rdp/new',
			};
		case EPAMTargetType.SSH:
			return {
				icon: SshIcon,
				key: 'ssh',
				to: '/pam/ssh/new',
			};
		case EPAMTargetType.VNC:
			return {
				icon: VncIcon,
				key: 'vnc',
				to: '/pam/vnc/new',
			};
		default:
			throw new Error(`Unknown platform: ${type}`);
	}
};

export const AddTargetDialog = ({ onClose, open }: AddTargetDialogProps) => {
	const { t } = useTranslation();

	const options: Array<EPAMTargetType> = [EPAMTargetType.RDP, EPAMTargetType.SSH];

	return (
		<Dialog onClose={onClose} open={open}>
			<DialogTitle>{t('page.pam.list.addDialog.title')}</DialogTitle>
			<DialogContent>
				<DialogContentText>{t('page.pam.list.addDialog.description')}</DialogContentText>
			</DialogContent>
			<List sx={{ py: 0 }}>
				{options.map((type) => {
					const { icon, key, to } = getTargetItemByType(type);

					return (
						<ListItem disableGutters key={`add-${key}-target`}>
							<StyledLink to={to}>
								<ListItemButton onClick={onClose} sx={{ px: 3 }}>
									<ListItemAvatar sx={{ height: `${ICON_HEIGHT}px` }}>
										<img
											alt='avatar'
											height={ICON_HEIGHT}
											src={icon}
											loading='lazy'
											style={{ borderRadius: 'none' }}
										/>
									</ListItemAvatar>
									<ListItemText
										primary={t('page.pam.list.addDialog.add', { type: key.toUpperCase() })}
									/>
								</ListItemButton>
							</StyledLink>
						</ListItem>
					);
				})}
			</List>
			<DialogActions>
				<Button onClick={onClose}>{t('page.pam.list.addDialog.cancel')}</Button>
			</DialogActions>
		</Dialog>
	);
};
