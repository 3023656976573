import { tenantIDPathRegex, tenantSlugPathRegex } from './Tenant';

export const hasPath = (pathCollection: string[], pathname: string): boolean => {
	let has = false;
	pathCollection.forEach((path) => {
		if (pathname.includes(path)) {
			has = true;
		}
	});

	return has;
};

export const getPathName = (): string => {
	const pathname = window.location.pathname;

	return pathname.replace(tenantIDPathRegex, '/').replace(tenantSlugPathRegex, '/');
};

export const getTenantFromPath = (): { tenantID: number | null; tenantSlug: string | null } => {
	const tenantIDMatch = window.location.pathname.match(tenantIDPathRegex);
	const tenantSlugMatch = window.location.pathname.match(tenantSlugPathRegex);

	return {
		tenantID: tenantIDMatch ? Number(tenantIDMatch[1]) : null,
		tenantSlug: tenantSlugMatch ? tenantSlugMatch[1] : null,
	};
};
