import * as React from 'react';
import {
	Typography,
	TextField,
	FormControlLabel,
	Switch,
	InputLabel,
	FormControl,
	MenuItem,
	Select,
} from '@mui/material';
import { ManualConfigProviderProps, INameIdFormats } from './types';

export const ManualConfigProvider: React.FC<ManualConfigProviderProps> = ({ register }) => {
	const [file, setFile] = React.useState(null);

	const nameIdFormats: INameIdFormats[] = [
		{ id: 1, value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified' },
		{ id: 2, value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress' },
		{ id: 3, value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:transient' },
		{ id: 4, value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent' },
		{ id: 5, value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:WindowsDomainQualifiedName' },
		{ id: 6, value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:kerberos' },
		{ id: 7, value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:entity' },
		{ id: 8, value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName' },
	];

	const handleChange = (file: any) => {
		setFile(file);
	};

	return (
		<>
			<TextField
				label='EntityId'
				variant='outlined'
				type='text'
				helperText='The unique identifier for the service provider. This value is typically a URL or a URN.'
				fullWidth
				required
				{...register('entityId')}
			/>

			<TextField
				label='Attribute Consume Service Endpoint (HTTP-POST)'
				variant='outlined'
				type='url'
				helperText='The URL where the service provider expects to receive SAML attribute requests via HTTP-POST binding.'
				fullWidth
				required
				{...register('consumeServiceEndpoint')}
			/>

			<TextField
				label='Single Logout Service Endpoint (HTTP-REDIRECT)'
				variant='outlined'
				type='url'
				helperText='The URL where the service provider expects to receive SAML single logout requests via HTTP-REDIRECT binding. This field is optional.'
				fullWidth
				{...register('logoutServiceEndpoint')}
			/>

			<FormControl fullWidth>
				<InputLabel id='nameid-format-select-label'>NameId Format</InputLabel>
				<Select
					labelId='nameid-format-select-label'
					id='nameid-format-select-label'
					// value={age}
					{...register('nameIdFormat')}
					label='nameid-format-select-label'
					onChange={handleChange}
				>
					{nameIdFormats.map((format) => (
						<MenuItem key={format.id} value={format.value}>
							{format.value}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<FormControlLabel
				control={<Switch {...register('authnRequestsSignedIn')} />}
				label='Authn requests signed'
			/>
			<FormControlLabel control={<Switch {...register('assertionsSigned')} />} label='Assertions signed' />

			<Typography variant='subtitle1'>Organization info (optional)</Typography>

			<TextField
				label='Name'
				variant='outlined'
				type='text'
				helperText='Enter the official name of your organization as you want it to appear in SAML metadata.'
				fullWidth
				{...register('organizationName')}
			/>
			<TextField
				label='DisplayName'
				variant='outlined'
				type='text'
				helperText='Provide a user-friendly name for your organization that will be displayed in the SAML authentication interfaces.'
				fullWidth
				{...register('organizationDisplayName')}
			/>
			<TextField
				label='URL'
				variant='outlined'
				type='url'
				helperText="Provide the URL where more information about your organization can be found. It should be a complete URL, including 'http://' or 'https://'."
				fullWidth
				{...register('organizationURL')}
			/>

			<Typography variant='subtitle1'>Technical Contact (optional)</Typography>

			<TextField
				label='Given Name'
				variant='outlined'
				type='text'
				helperText='Enter the given name of the person who should be contacted for technical issues.'
				fullWidth
				{...register('technicalGivenName')}
			/>

			<TextField
				label='Email'
				variant='outlined'
				type='email'
				helperText='Enter the email address of the person who should be contacted for technical issues.'
				fullWidth
				{...register('technicalEmail')}
			/>

			<Typography variant='subtitle1'>Support Contact (optional)</Typography>

			<TextField
				label='Given name'
				variant='outlined'
				type='text'
				helperText='Enter the given name of the person who should be contacted for support issues.'
				fullWidth
				{...register('supportGivenName')}
			/>

			<TextField
				label='Email'
				variant='outlined'
				type='email'
				helperText='Enter the email address of the person who should be contacted for support issues.'
				fullWidth
				{...register('supportEmail')}
			/>

			<TextField
				label='Private key'
				variant='outlined'
				type='text'
				multiline
				minRows={10}
				maxRows={10}
				fullWidth
				inputProps={{
					spellCheck: false,
					style: {
						fontFamily: 'monospace',
						fontSize: '0.875rem',
						whiteSpace: 'nowrap',
					},
				}}
				{...register('supportPrivateKey')}
			/>
			<TextField
				label='X.509 certificate'
				variant='outlined'
				type='text'
				multiline
				minRows={10}
				maxRows={10}
				fullWidth
				inputProps={{
					spellCheck: false,
					style: {
						fontFamily: 'monospace',
						fontSize: '0.875rem',
						whiteSpace: 'nowrap',
					},
				}}
				{...register('supportCertificate')}
			/>
		</>
	);
};
