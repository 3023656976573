import { MRT_ColumnFiltersState, MRT_SortingState, MRT_VisibilityState } from 'material-react-table';
import {
	generateQueryObject,
	QueryOptions,
	FilterCondition,
	EFilterOperator,
	EFilterType,
	EFilterSort,
	FilterSort,
} from '@excalibur-enterprise/query-filter';
import { isDayjs } from 'dayjs';

export type SwaggerQuery = {
	filter: string[];
	limit: number | undefined;
	offset: number | undefined;
	columns: string[];
	sort: string[];
};

const processColumnFilter = (columnFilter: MRT_ColumnFiltersState[0]): FilterCondition[] => {
	const conditions: FilterCondition[] = [];

	if (typeof columnFilter.value === 'string') {
		conditions.push({
			column: columnFilter.id,
			value: columnFilter.value,
			operator: EFilterOperator.Like,
		});
	} else if (isDayjs(columnFilter.value)) {
		const clonedValue = columnFilter.value.clone();
		const clonedValuePlusOneDay = clonedValue.add(1, 'day');
		conditions.push(
			{
				column: columnFilter.id,
				value: columnFilter.value.toISOString(),
				operator: EFilterOperator.GreaterOrEqual,
			},
			{
				column: columnFilter.id,
				value: clonedValuePlusOneDay.toISOString(),
				operator: EFilterOperator.LessOrEqual,
			},
		);
	} else if (Array.isArray(columnFilter.value) && columnFilter.value.length === 2) {
		if (columnFilter.value[0] && isDayjs(columnFilter.value[0]) && columnFilter.value[0].isValid()) {
			conditions.push({
				column: columnFilter.id,
				value: columnFilter.value[0].toISOString(),
				operator: EFilterOperator.GreaterOrEqual,
			});
		}
		if (columnFilter.value[1] && isDayjs(columnFilter.value[1]) && columnFilter.value[1].isValid()) {
			conditions.push({
				column: columnFilter.id,
				value: columnFilter.value[1].toISOString(),
				operator: EFilterOperator.LessOrEqual,
			});
		}
	} else {
		console.warn(`Unknown column filter value type (${typeof columnFilter.value}). Ignoring it.`, { columnFilter });
	}

	return conditions;
};

export const getQueryObject = (
	globalFilter: string,
	columnFilters: MRT_ColumnFiltersState,
	sorting: MRT_SortingState,
	pageIndex: number,
	pageSize: number,
	columnVisibility: MRT_VisibilityState,
	globalFilterFields: string[],
	identifier = 'id',
): SwaggerQuery => {
	const columnFilterConditions: FilterCondition[] = [];
	const globalFilterConditions: FilterCondition[] = [];
	const sort = [] as FilterSort[];
	const conditions: FilterCondition = {
		conditions: [],
	};
	const options: QueryOptions = {
		limit: pageSize,
		offset: pageIndex * pageSize,
		columns: [] as string[],
		sort: [] as FilterSort[],
	};
	for (const _sort of sorting) {
		sort.push({
			column: _sort.id,
			direction: _sort.desc ? EFilterSort.Desc : EFilterSort.Asc,
		});
	}
	for (const visibility of Object.keys(columnVisibility)) {
		const field = visibility as string;
		if (columnVisibility[field] && options.columns && globalFilterFields.includes(field)) {
			options.columns.push(visibility as string);
		}
	}
	if (columnFilters.length > 0) {
		for (const columnFilter of columnFilters) {
			const columnFiltersProcessed = processColumnFilter(columnFilter);
			columnFilterConditions.push(...columnFiltersProcessed);
		}
	}

	if (globalFilter) {
		for (const globalFilterField of globalFilterFields) {
			globalFilterConditions.push({
				column: globalFilterField,
				value: globalFilter,
				operator: EFilterOperator.Like,
			});
		}
	}

	if (columnFilterConditions.length > 0 && globalFilterConditions.length > 0 && conditions.conditions) {
		const globalFilterCondition: FilterCondition = {
			type: EFilterType.Or,
			conditions: globalFilterConditions,
		};
		const columnFilterCondition: FilterCondition = {
			conditions: columnFilterConditions,
		};

		if (columnFilterConditions.length > 1) {
			columnFilterCondition.type = EFilterType.And;
		}

		options.conditions = {
			type: EFilterType.And,
			conditions: [
				columnFilterCondition.conditions?.length === 1 ?
					columnFilterCondition.conditions[0]
				:	columnFilterCondition,
				globalFilterCondition.conditions?.length === 1 ?
					globalFilterCondition.conditions[0]
				:	globalFilterCondition,
			],
		};
	} else if (columnFilterConditions.length === 0 && globalFilterConditions.length > 0 && conditions.conditions) {
		const globalFilterCondition: FilterCondition = {
			conditions: globalFilterConditions,
		};
		if (globalFilterConditions.length > 1) {
			globalFilterCondition.type = EFilterType.Or;
		}

		options.conditions = globalFilterConditions.length === 1 ? globalFilterConditions[0] : globalFilterCondition;
	} else if (columnFilterConditions.length > 0 && globalFilterConditions.length === 0 && conditions.conditions) {
		const columnFilterCondition: FilterCondition = {
			conditions: columnFilterConditions,
		};
		if (columnFilterConditions.length > 1) {
			columnFilterCondition.type = EFilterType.And;
		}

		options.conditions = columnFilterConditions.length === 1 ? columnFilterConditions[0] : columnFilterCondition;
	}

	if (sort.length > 0) {
		options.sort = sort;
	}

	if (options.columns?.find((column) => column === identifier) === undefined) {
		options.columns?.push(identifier);
	}

	return generateQueryObject(options);
};
