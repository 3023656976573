import * as React from 'react';
import {
	Box,
	Paper,
	FormGroup,
	Typography,
	Stack,
	Fab,
	useTheme,
	useMediaQuery,
	Tooltip,
	Button,
	IconButton,
	TextField as MUITextField,
} from '@mui/material';
import {
	MRT_ColumnDef as MRTColumnDef,
	MaterialReactTable,
	useMaterialReactTable,
	MRT_PaginationState as MRTPaginationState,
	MRT_ToggleGlobalFilterButton,
	MRT_RowSelectionState,
	MRT_RowData as MRTRowData,
} from 'material-react-table';
import {
	Add as AddIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	PlaylistAdd as PlaylistAddIcon,
} from '@mui/icons-material';
import urlSlug from 'url-slug';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { Preloader } from '../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useNavigate } from '../../hooks/useNavigate';
import { FormValues, NetworkAddress, RenderToolbarProps, SubmitFormState, TenantState } from './types';
import { getTenantFormSchema } from './schema';
import { CreateTenantRequestDto, UpdateTenantRequestDto } from '../../api/Api';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { Heading } from '../../components/Heading/Heading';
import { NetworkAddressesImportModal } from '../../components/NetworkAddressesImportModal/NetworkAddressesImportModal';
import { TextField } from '../../components/FormFields/TextField/TextField';
import { Textarea } from '../../components/FormFields/Textarea/Textarea';
import { FloatingButtonBack } from '../../components/Buttons/FloatingButton/FloatingButtonBack';
import { FloatingButtonSave } from '../../components/Buttons/FloatingButton/FloatingButtonSave';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';

export const TenantAddEdit: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const authContext = useAuthContext();
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const [pagination, setPagination] = React.useState<MRTPaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const { MRTLocalization } = useMRTLocalization();

	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [validationErrors, setValidationErrors] = React.useState<Record<string, string | undefined>>({});
	const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>({});
	const [importModalOpen, setImportModalOpen] = React.useState(false);

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});
	const [tenantState, setTenantState] = React.useState<TenantState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});
	const [networkAddresses, setNetworkAddresses] = React.useState<string[]>([]);

	const {
		handleSubmit,
		register,
		control,
		reset,
		formState: { errors },
		watch,
		setValue,
		trigger,
	} = useForm<FormValues>({
		mode: 'all',
		resolver: zodResolver(getTenantFormSchema(t, id !== undefined)),
	});

	const tenantName = watch('name');

	const file = useWatch({ control: control, name: 'file', defaultValue: null });

	const navigateBack = React.useCallback(
		(path: string) => {
			const { tab, return: returnTo } = location.state || {};

			const to = returnTo || path;
			const options = returnTo && tab ? { state: { tab } } : undefined;

			navigate(to, options);
		},
		[location, navigate],
	);

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData): Promise<void> => {
			if (submitFormState.submitting) {
				return;
			}
			const requestBody: CreateTenantRequestDto = {
				name: formData.name,
				description: formData.description,
				slug: formData.slug,
				networkAddresses: networkAddresses,
			};
			const requestBodyUpdate: UpdateTenantRequestDto = {
				name: formData.name,
				description: formData.description,
				networkAddresses: networkAddresses,
			};

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});
				if (id) {
					await api.tenants.updateTenant(parseInt(id), requestBodyUpdate);
					enqueueSnackbar(t('page.tenants.edit.actionMessages.tenantSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
				} else {
					await api.tenants.createTenant(requestBody);
					enqueueSnackbar(t('page.tenants.edit.actionMessages.tenantSuccessfullyCreated'), {
						variant: 'success',
						persist: false,
					});
					authContext.handleOnLoadUserTenants();
				}
				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
				reactQueryClient.invalidateQueries();
				navigateBack('/tenants');
			} catch (error: unknown) {
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});

				console.error(error);
			}
		},

		[
			api.oauthClient,
			enqueueSnackbar,
			setSubmitFormState,
			navigateBack,
			id,
			submitFormState,
			networkAddresses,
			reactQueryClient,
		],
	);

	const getStackWidth = (): string => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const getTenant = React.useCallback(
		async (tenantID: number): Promise<void> => {
			setTenantState({
				loading: true,
				loaded: false,
				data: null,
				error: null,
			});
			try {
				const response = await api.tenants.getTenant(tenantID);
				setTenantState({
					loading: false,
					loaded: true,
					data: response.data,
					error: null,
				});
				setNetworkAddresses(response.data.networkAddresses ?? []);

				reset({
					name: response.data.name,
					description: response.data.description,
				});
			} catch (error) {
				setTenantState({
					loading: false,
					loaded: false,
					data: null,
					error: error as AxiosError,
				});

				console.error(error);
			}
		},
		[api.tenants, enqueueSnackbar, setTenantState, reset, t],
	);

	React.useEffect(() => {
		if (id && !tenantState.loaded && !tenantState.loading && !tenantState.error) {
			getTenant(parseInt(id));
		}
	}, [id, tenantState]);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !tenantState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || tenantState.loading)) {
			setShouldDisableForm(true);
		}
	}, [tenantState, submitFormState, shouldDisableForm]);

	const validateRow = React.useCallback(
		(networkAddress: string) => {
			if (networkAddress === '') {
				setValidationErrors((prev) => ({
					...prev,
					networkAddress: t('page.tenants.networkAddresses.validationMessages.networkAddress'),
				}));

				return false;
			}

			setValidationErrors((prev) => ({
				...prev,
				networkAddress: undefined,
			}));

			return true;
		},
		[setValidationErrors],
	);

	const columns = React.useMemo<MRTColumnDef<NetworkAddress>[]>(
		() => [
			{
				header: t('page.tenants.networkAddresses.title'),
				accessorKey: 'networkAddress',
				muiEditTextFieldProps: () => ({
					type: 'text',
					required: true,
					error: !!validationErrors?.networkAddress,
					helperText: validationErrors?.networkAddress,
					onBlur: (event) => {
						const newValue = event.currentTarget.value;
						validateRow(newValue);
					},
				}),
			},
		],
		[validationErrors, validateRow],
	);

	React.useEffect(() => {
		if (tenantName === undefined || tenantName === '') {
			return;
		}

		const newSlug = urlSlug(tenantName);
		setValue('slug', newSlug);
		trigger('name'); // NOTE: This is needed to flush errors in the name. If name is entered and then is cleared then errors should be updated via this trigger
		trigger('slug');
	}, [tenantName]);

	const handleDeleteNetworkAddresses = React.useCallback(
		(deletedNetworkAddresses: string[]) => {
			setNetworkAddresses((prev) =>
				prev.filter((networkAddress, index) => !deletedNetworkAddresses.includes(index.toString())),
			);
			setRowSelection({});
		},
		[setNetworkAddresses, setRowSelection, networkAddresses],
	);

	const handleOnClickBack = React.useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const handleOnImportModalToggle = React.useCallback(
		(isOpen: boolean) => () => {
			setImportModalOpen(isOpen);
		},
		[],
	);

	const renderToolbar = React.useMemo(() => {
		return <TData extends MRTRowData>({ table }: RenderToolbarProps<TData>) => (
			<Box sx={{ display: 'flex', gap: '1rem' }}>
				<MRT_ToggleGlobalFilterButton table={table} />

				<Tooltip arrow placement='top' title={t('page.tenants.edit.tooltips.importNetworkAddresses')}>
					<Button variant='outlined' onClick={handleOnImportModalToggle(true)}>
						{t('page.tenants.networkAddresses.modal.title')}
					</Button>
				</Tooltip>
				<Tooltip arrow placement='top' title={t('page.tenants.edit.tooltips.deleteSelected')}>
					<span>
						<Button
							color='error'
							variant='contained'
							disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
							onClick={() => handleDeleteNetworkAddresses(Object.keys(rowSelection))}
							sx={{
								display: 'flex',
								alignItems: 'flex-start',
								gap: '0.5rem',
								width: 130,
							}}
						>
							<DeleteIcon />
							{t('page.tenants.networkAddresses.delete.title')}
						</Button>
					</span>
				</Tooltip>
			</Box>
		);
	}, [rowSelection]);

	const memoizedData = React.useMemo(
		() => networkAddresses.map((networkAddress, index) => ({ id: index, networkAddress })),
		[networkAddresses],
	);

	const ipRangeTable = useMaterialReactTable({
		columns,
		layoutMode: 'grid',
		data: memoizedData,
		createDisplayMode: 'row',
		editDisplayMode: 'row',
		enableColumnPinning: true,
		enableEditing: true,
		enableRowActions: true,
		enableRowSelection: true,
		enableClickToCopy: true,
		state: {
			pagination,
			rowSelection,
		},
		muiTableContainerProps: {
			sx: {
				minHeight: '500px',
			},
		},
		initialState: {
			columnPinning: {
				right: ['mrt-row-actions'],
			},
		},
		displayColumnDefOptions: {
			'mrt-row-actions': {
				header: t('page.tenants.list.table.columns.actions'),
			},
		},
		muiSelectCheckboxProps: {
			sx: {
				width: '50px',
				height: '50px',
			},
		},
		muiSelectAllCheckboxProps: {
			sx: {
				width: '50px',
				height: '50px',
			},
		},
		muiTableHeadCellProps: {
			sx: {
				verticalAlign: 'baseline',
			},
		},
		renderToolbarInternalActions: renderToolbar,
		onRowSelectionChange: setRowSelection,
		onPaginationChange: setPagination,
		onCreatingRowCancel: () => setValidationErrors({}),
		onCreatingRowSave: ({ exitCreatingMode, values }) => {
			if (
				!validateRow(values.networkAddress) ||
				!isAllowed([EPermission.TENANTS_CREATE, EPermission.NETWORK_POLICY_CREATE], false)
			) {
				return;
			}

			setNetworkAddresses((prevState) => [...prevState, values.networkAddress]);
			exitCreatingMode();
		},
		onEditingRowCancel: () => setValidationErrors({}),
		onEditingRowSave: ({ exitEditingMode, values, row }) => {
			if (
				!validateRow(values.networkAddress) ||
				!isAllowed([EPermission.TENANTS_UPDATE, EPermission.NETWORK_POLICY_UPDATE], false)
			) {
				return;
			}

			setNetworkAddresses((prevState) => {
				const updatedNetworkAddresses = [...prevState];
				updatedNetworkAddresses[row.index] = values.networkAddress;

				return updatedNetworkAddresses;
			});
			exitEditingMode();
		},
		renderRowActions: ({ row, table }) => (
			<Box sx={{ display: 'flex', gap: '1rem' }}>
				{isAllowed([EPermission.TENANTS_UPDATE]) && (
					<Tooltip arrow placement='bottom' title={t('page.tenants.edit.tooltips.edit')}>
						<IconButton onClick={() => table.setEditingRow(row)}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}
				{isAllowed([EPermission.TENANTS_DELETE]) && (
					<Tooltip arrow placement='bottom' title={t('page.tenants.edit.tooltips.delete')}>
						<IconButton color='error' onClick={() => handleDeleteNetworkAddresses([row.index.toString()])}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}
			</Box>
		),
		renderTopToolbarCustomActions: ({ table }) => (
			<>
				{isAllowed([EPermission.TENANTS_CREATE, EPermission.TENANTS_UPDATE], false) && (
					<Tooltip arrow placement='top' title={t('page.tenants.edit.tooltips.addNetworkAddress')}>
						<Button
							startIcon={<AddIcon />}
							variant='outlined'
							onClick={() => {
								table.setCreatingRow(true);
							}}
						>
							{t('page.tenants.networkAddresses.add')}
						</Button>
					</Tooltip>
				)}
			</>
		),
		muiTablePaperProps: ({ table }) => ({
			style: {
				zIndex: table.getState().isFullScreen ? 1100 : undefined,
				boxShadow: 'none',
				outline: '1px solid #e0e0e0',
			},
		}),
		localization: MRTLocalization,
	});
	const handleImportNetworkAddresses = React.useCallback(
		(importedNetworkAddresses: string[]) => {
			setNetworkAddresses((prev) => [...prev, ...importedNetworkAddresses]);
		},
		[setNetworkAddresses],
	);

	return (
		<Box
			sx={{
				paddingBottom: 10,
			}}
			component={'form'}
			noValidate
			onSubmit={handleSubmit(onSubmit)}
		>
			{id && (tenantState.loading || !tenantState.loaded) ?
				<Preloader />
			:	<Paper elevation={3}>
					<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
						<PageHeader
							title={id ? t('page.tenants.edit.title') : t('page.tenants.add.title')}
							description={id ? t('page.tenants.edit.description') : t('page.tenants.add.description')}
							icon={PlaylistAddIcon}
						/>
					</Box>
					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Stack
							spacing={1}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Heading label={t('page.tenants.edit.subtitle.general')} />
							<Stack spacing={2}>
								<TextField
									name={'name'}
									register={register}
									label={t('page.tenants.edit.form.name.label')}
									error={errors.name}
									disabled={shouldDisableForm}
									helperText={t('page.tenants.edit.form.name.helperText')}
								/>
								{!id && (
									<MUITextField
										label={t('page.tenants.edit.form.slug.label')}
										variant='outlined'
										fullWidth
										error={!!errors.slug}
										disabled={shouldDisableForm}
										{...register('slug')}
										InputLabelProps={{
											shrink: true,
										}}
										helperText={
											errors.slug && typeof errors.slug.message === 'string' ?
												<Typography component='span' color='error' sx={{ fontSize: 12 }}>
													{errors.slug.message}
												</Typography>
											:	t('page.tenants.edit.form.slug.helperText')
										}
									/>
								)}
								<Textarea
									name={'description'}
									register={register}
									label={t('page.tenants.edit.form.description.label')}
									error={errors.description}
									disabled={shouldDisableForm}
									helperText={t('page.tenants.edit.form.description.helperText')}
								/>
							</Stack>
						</Stack>
						<Box sx={{ mt: 2 }}>
							<Heading label={t('page.tenants.edit.subtitle.networkAddresses')} />
							<Typography variant='body1' sx={{ paddingBottom: 2 }}>
								{t('page.tenants.edit.table.description.networkAddresses')}
							</Typography>
							<Paper elevation={3}>
								<MaterialReactTable table={ipRangeTable} />
							</Paper>
						</Box>
					</FormGroup>

					<FloatingButtonBack
						right={'100px'}
						onClick={handleOnClickBack}
						ariaLabel={t('page.tenants.edit.ariaLabel.back')}
						tooltipTitle={t('page.tenants.edit.tooltips.back')}
					/>
					{isAllowed([EPermission.TENANTS_CREATE, EPermission.TENANTS_UPDATE], false) && (
						<FloatingButtonSave
							type='submit'
							disabled={shouldDisableForm}
							ariaLabel={t('page.tenants.edit.ariaLabel.save')}
							tooltipTitle={
								id ? t('page.tenants.edit.tooltips.save') : t('page.tenants.edit.tooltips.create')
							}
						/>
					)}
				</Paper>
			}
			<NetworkAddressesImportModal
				open={importModalOpen}
				control={control}
				name={'file'}
				value={file}
				error={errors.file}
				disabled={
					shouldDisableForm || !isAllowed([EPermission.TENANTS_CREATE, EPermission.TENANTS_UPDATE], false)
				}
				trigger={trigger}
				onClose={handleOnImportModalToggle(false)}
				onImport={handleImportNetworkAddresses}
			/>
		</Box>
	);
};
