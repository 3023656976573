import React from 'react';
import { FormControl, FormHelperText, InputLabel, OutlinedInput, OutlinedInputProps, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

import { TextMaskProps } from './types';

const TextMask = React.forwardRef<HTMLInputElement, TextMaskProps>(({ onChange, ...other }, ref) => (
	<IMaskInput
		{...other}
		mask='#####-#####-#####-#####-#####'
		definitions={{ '#': /[a-zA-Z0-9]/ }}
		inputRef={ref}
		onAccept={(value: any) => onChange({ target: { name: other.name, value } })}
		overwrite
	/>
));

const HelperText: React.FC<{ id?: string; text?: string }> = ({ text }) => (
	<FormHelperText>
		<Typography component='span' color='error' sx={{ fontSize: 12 }}>
			{text}
		</Typography>
	</FormHelperText>
);

export const LicenseKeyInput = React.forwardRef<
	HTMLInputElement,
	Omit<OutlinedInputProps, 'error'> & { error?: FieldError }
>(({ error, disabled = false, fullWidth = true, required = false, type = 'text', ...props }, ref) => {
	const id = `${props.name}-input`;
	const describedBy = `${props.name}-helper-text`;

	return (
		<FormControl disabled={disabled} error={!!error} fullWidth={fullWidth} required={required}>
			{props.label && <InputLabel htmlFor={id}>{props.label}</InputLabel>}
			<OutlinedInput
				{...props}
				ref={ref}
				id={id}
				aria-describedby={describedBy}
				inputComponent={TextMask as never}
				type={type}
			/>

			{error && <HelperText id={describedBy} text={error.message} />}
		</FormControl>
	);
});
