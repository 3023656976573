import 'dayjs/locale/en';
import 'dayjs/locale/sk';

import { useCurrentLanguage } from '../contexts/LocalizationContext/LocalizationContext';

export const useMRTDateAdapterLocale = () => {
	const currentLanguage = useCurrentLanguage();

	return { MRTDateAdapterLocale: currentLanguage };
};
