import { red, green, orange, lightBlue, pink } from '@mui/material/colors';
import { alpha } from '@mui/material';

const colorLightBlue = lightBlue[500];
const colorPink = pink[500];

export const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const options = {
	indexAxis: 'y' as const,
	elements: {
		bar: {
			borderWidth: 1,
		},
	},
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
	},
};

export const optionsByUser = {
	indexAxis: 'y' as const,
	elements: {
		bar: {
			borderWidth: 1,
		},
	},
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
	},
};

export const data = {
	labels,
	datasets: [
		{
			label: 'User 1',
			data: [65, 59, 80, 81, 56],
			borderColor: colorPink,
			backgroundColor: alpha(colorPink, 0.1),
		},
		{
			label: 'User 2',
			data: [100, 2, 80, 81, 90],
			borderColor: colorLightBlue,
			backgroundColor: alpha(colorLightBlue, 0.1),
		},
	],
};
