import * as React from 'react';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Paper, Box, Fab, FormGroup, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Policy as PolicyIcon, Save as SaveIcon } from '@mui/icons-material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '../../hooks/useNavigate';
import { Preloader } from '../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { FormValues, SecurityPolicyGroupsState, SecurityPolicyState, SubmitFormState } from './types';
import { getSecurityPolicySchema, pamTargetGroupsSchema, userGroupsSchema, ruleSetListSchema } from './schema';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useACL } from '../../hooks/useACL';
import {
	CreateSecurityPolicyRequestDto,
	GetPAMTargetGroupsResponseDto,
	PagedResultUserGroupDto,
	PagedResultGetRuleSetCollectionDto,
} from '../../api/Api';
import { Heading } from '../../components/Heading/Heading';
import { Autocomplete } from '../../components/FormFields/Autocomplete/Autocomplete';
import { Textarea } from '../../components/FormFields/Textarea/Textarea';
import { TextField } from '../../components/FormFields/TextField/TextField';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../components/Buttons/FloatingButton/FloatingButtonSave';
import { EPermission } from '../../enums/permission/EPermission';

export const AddSecurityPolicy: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [shouldDisableFormGroups, setShouldDisableFormGroups] = React.useState<boolean>(false);

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const [securityPolicyState, setSecurityPolicyState] = React.useState<SecurityPolicyState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [securityPolicyGroupsState, setSecurityPolicyGroupsState] = React.useState<SecurityPolicyGroupsState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const {
		handleSubmit,
		register,
		control,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getSecurityPolicySchema(t)),
		defaultValues: {
			userGroups: [],
			pamTargetGroups: [],
			ruleSets: [],
		},
	});

	const {
		data: ruleSets,
		isError: isRuleSetsError,
		isRefetching: isRuleSetsRefetching,
		isLoading: isRuleSetsLoading,
		error: ruleSetsError,
	} = useQuery<PagedResultGetRuleSetCollectionDto>({
		queryKey: [EQueryKey.RULE_SET_LIST_QUERY],
		queryFn: async () => {
			try {
				const query = {
					limit: 2000,
					offset: 0,
				};

				const response = await api.ruleSets.getRuleSetCollection(query);
				response.data.entities.forEach((ruleSet) => {
					ruleSetListSchema.parse(ruleSet);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	const {
		data: userGroups,
		isError: isUserGroupsError,
		isRefetching: isUserGroupsRefetching,
		isLoading: isUserGroupsLoading,
		error: userGroupsError,
	} = useQuery<PagedResultUserGroupDto>({
		queryKey: [EQueryKey.USER_GROUP_LIST_QUERY],
		queryFn: async () => {
			try {
				const query = {
					limit: 2000,
					offset: 0,
				};

				const response = await api.userGroups.getUserGroups(query);
				response.data.entities.forEach((group) => {
					userGroupsSchema.parse(group);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	const {
		data: pamTargetGroups,
		isError: isPamTargetGroupsError,
		isRefetching: isPamTargetGroupsRefetching,
		isLoading: isPamTargetGroupsLoading,
		error: pamTargetGroupsError,
	} = useQuery<GetPAMTargetGroupsResponseDto>({
		queryKey: [EQueryKey.PAM_GROUP_LIST_QUERY],
		queryFn: async () => {
			try {
				const query = {
					limit: 2000,
					offset: 0,
				};

				const response = await api.pamGroups.getPamTargetGroups(query);
				response.data.entities.forEach((target) => {
					pamTargetGroupsSchema.parse(target);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData): Promise<void> => {
			const requestBodyGroup: CreateSecurityPolicyRequestDto = {
				name: formData.name,
				description: formData.description,
				userGroups: formData.userGroups,
				pamTargetGroups: formData.pamTargetGroups,
				ruleSets: formData.ruleSets,
			};

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				if (id) {
					await api.securityPolicies.updateSecurityPolicy(Number(id), requestBodyGroup);
					enqueueSnackbar(t('page.securityPolicy.edit.actionMessages.securityPolicySuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
					navigate(`/security/securityPolicy/${id}`);
				} else {
					const response = await api.securityPolicies.createSecurityPolicy(requestBodyGroup);
					enqueueSnackbar(t('page.securityPolicy.edit.actionMessages.securityPolicySuccessfullyCreated'), {
						variant: 'success',
						persist: false,
					});
					navigate(`/security/securityPolicy/${response.data.id}`);
				}
				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
			} catch (error: unknown) {
				console.error(error);
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
			}
		},
		[id],
	);

	const getSecurityPolicy = React.useCallback(async (securityPolicyID: number): Promise<void> => {
		setSecurityPolicyState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const response = await api.securityPolicies.getSecurityPolicy(securityPolicyID);

			setSecurityPolicyState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});

			reset({
				name: response.data.name,
				description: response.data.description,
			});
		} catch (error) {
			console.error(error);
			setSecurityPolicyState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}

		setSecurityPolicyGroupsState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const query = {
				limit: 2000,
				offset: 0,
			};

			const [userGroupsData, pamTargeGroupsData, ruleSetsData] = await Promise.all([
				api.securityPolicy.getUserGroupsForSecurityPolicy(securityPolicyID, query),
				api.securityPolicy.getPamTargetGroupsForSecurityPolicy(securityPolicyID, query),
				api.securityPolicy.getRuleSetsForSecurityPolicy(securityPolicyID, query),
			]);

			const groupsResponse = {
				userGroups: userGroupsData.data.entities,
				pamTargetGroups: pamTargeGroupsData.data.entities,
				ruleSets: ruleSetsData.data.entities,
			};

			setSecurityPolicyGroupsState({
				loading: false,
				loaded: true,
				data: groupsResponse,
				error: null,
			});

			reset({
				userGroups: userGroupsData.data.entities.map((group) => group.id),
				pamTargetGroups: pamTargeGroupsData.data.entities.map((group) => group.id),
				ruleSets: ruleSetsData.data.entities.map((ruleSet) => ruleSet.id),
			});
		} catch (error) {
			console.error(error);
			setSecurityPolicyGroupsState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}
	}, []);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	React.useEffect(() => {
		if (id && !securityPolicyState.loading && !securityPolicyState.loaded && !securityPolicyState.error) {
			getSecurityPolicy(Number(id));
		}
	}, [id, securityPolicyState]);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !securityPolicyState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || securityPolicyState.loading)) {
			setShouldDisableForm(true);
		}
	}, [securityPolicyState, submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		if (shouldDisableFormGroups && !submitFormState.submitting && !securityPolicyGroupsState.loading) {
			setShouldDisableFormGroups(false);
		} else if (!shouldDisableFormGroups && (submitFormState.submitting || securityPolicyGroupsState.loading)) {
			setShouldDisableFormGroups(true);
		}
	}, [securityPolicyGroupsState, submitFormState, shouldDisableFormGroups]);

	return (
		<Box
			component={'form'}
			noValidate
			autoComplete='off'
			onSubmit={handleSubmit(onSubmit)}
			sx={{ marginBottom: 10 }}
		>
			<Paper elevation={3} sx={{ padding: 2 }}>
				<Box sx={{ marginBottom: 2 }}>
					<PageHeader
						title={id ? t('page.securityPolicy.edit.title') : t('page.securityPolicy.add.title')}
						description={
							id ? t('page.securityPolicy.edit.description') : t('page.securityPolicy.add.description')
						}
						icon={PolicyIcon}
					/>
				</Box>

				{id && (securityPolicyState.loading || !securityPolicyState.loaded) ?
					<Preloader />
				:	<Stack
						spacing={2}
						sx={{
							width: getStackWidth(),
						}}
					>
						<FormGroup>
							<Stack spacing={1}>
								<Heading label={t('page.securityPolicy.edit.subtitle.general')} />
								<TextField
									name={'name'}
									register={register}
									label={t('page.securityPolicy.edit.form.name.label')}
									error={errors.name}
									disabled={shouldDisableForm}
									helperText={t('page.securityPolicy.edit.form.name.helperText')}
								/>

								<Textarea
									name={'description'}
									register={register}
									label={t('page.securityPolicy.edit.form.description.label')}
									error={errors.description}
									disabled={shouldDisableForm}
									helperText={t('page.securityPolicy.edit.form.description.helperText')}
									rows={4}
								/>
							</Stack>
						</FormGroup>
						<FormGroup>
							<Heading label={t('page.securityPolicy.edit.subtitle.userGroups')} />
							<Autocomplete
								control={control}
								name='userGroups'
								label={t('page.securityPolicy.edit.form.userGroups.label')}
								options={userGroups?.entities || []}
								optionLabelKey={'name'}
								helperText={t('page.securityPolicy.edit.form.userGroups.helperText.select')}
								error={errors.userGroups}
								disabled={shouldDisableFormGroups}
							/>
						</FormGroup>
						<FormGroup>
							<Heading label={t('page.securityPolicy.edit.subtitle.pamTargetGroups')} />
							<Autocomplete
								control={control}
								name='pamTargetGroups'
								label={t('page.securityPolicy.edit.form.pamTargetGroups.label')}
								options={pamTargetGroups?.entities || []}
								optionLabelKey={'name'}
								helperText={t('page.securityPolicy.edit.form.pamTargetGroups.helperText.select')}
								error={errors.pamTargetGroups}
								disabled={shouldDisableFormGroups}
							/>
						</FormGroup>
						<FormGroup>
							<Heading label={t('page.securityPolicy.edit.subtitle.ruleSets')} />
							<Autocomplete
								control={control}
								name='ruleSets'
								label={t('page.securityPolicy.edit.form.ruleSets.label')}
								options={ruleSets?.entities || []}
								optionLabelKey={'name'}
								helperText={t('page.securityPolicy.edit.form.ruleSets.helperText.select')}
								error={errors.ruleSets}
								disabled={shouldDisableFormGroups}
							/>
						</FormGroup>
						{isAllowed(
							[EPermission.SECURITY_POLICIES_CREATE, EPermission.SECURITY_POLICIES_UPDATE],
							false,
						) && (
							<FloatingButtonSave
								type='submit'
								disabled={shouldDisableForm}
								ariaLabel={t('page.securityPolicy.edit.ariaLabel.save')}
								tooltipTitle={
									id ?
										t('page.securityPolicy.edit.tooltips.save')
									:	t('page.securityPolicy.edit.tooltips.create')
								}
							/>
						)}
					</Stack>
				}
			</Paper>
		</Box>
	);
};
