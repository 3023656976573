import { z } from 'zod';
import { TFunction } from 'i18next';

export const getGeoEditationDialogFormSchema = (t: TFunction) =>
	z.object({
		name: z
			.string()
			.min(3, { message: t('component.geoEditationDialog.errorMessages.emptyName', { minLength: 3 }) })
			.max(64, { message: t('component.geoEditationDialog.errorMessages.maxLength', { maxLength: 64 }) }),
	});
