import { TFunction } from 'i18next';

import { ERevType } from './enums';
import { AuditLogData, AuditLogFieldValue, AuditLogValueFields, FormatAuditLogFieldValueParams } from './types';

export const getBgColor = (revType: ERevType) => {
	switch (revType) {
		case ERevType.Create:
			return 'success.light';
		case ERevType.Update:
			return 'primary.light';
		case ERevType.Delete:
			return 'error.light';
	}
};

export const getAction = (revType: ERevType, t: TFunction) => {
	switch (revType) {
		case ERevType.Create:
			return t('component.auditLogTimeline.actions.createdBy');
		case ERevType.Update:
			return t('component.auditLogTimeline.actions.updatedBy');
		case ERevType.Delete:
			return t('component.auditLogTimeline.actions.deletedBy');
	}
};

export const pickValueFields = (auditLog: AuditLogData): Record<string, AuditLogFieldValue> => {
	const { modifiedAt, modifiedBy, revID, revType, ...rest } = auditLog;

	return rest;
};

export const omitNullFields = (fields: AuditLogValueFields) => {
	const nonNullFields: Array<keyof typeof fields> = [];

	(Object.keys(fields) as Array<keyof typeof fields>).forEach((key) => {
		if (fields[key] !== null) {
			nonNullFields.push(key);
		}
	});

	return nonNullFields;
};

export const formatFieldValue = ({ value, t }: FormatAuditLogFieldValueParams) => {
	if (typeof value === 'boolean') {
		return value ? t('component.auditLogTimeline.values.yes') : t('component.auditLogTimeline.values.no');
	}

	if (typeof value === 'string') {
		if (!value) {
			return t('component.auditLogTimeline.values.empty');
		}

		return value;
	}

	if (value === null || value === undefined) {
		return t('component.auditLogTimeline.values.empty');
	}

	return value;
};
