import * as React from 'react';
import { Grid, Button, Stack, Typography, useMediaQuery, useTheme, Box, Tooltip } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { useParams } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { SMTP_FORM_INIT_VALUES } from '../SmtpInitialValues';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { EmailConfigurationStepProps, FormEmailVerificationValues, SubmitFormState } from '../types';
import { getEmailVerificationFormSchema } from '../schema';
import { CreateSMTPMailConfigurationRequest } from '../../../api/Api';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { useACL } from '../../../hooks/useACL';
import { EPermission } from '../../../enums/permission/EPermission';

export const EmailVerificationStep: React.FC<EmailConfigurationStepProps> = ({
	setActiveStepEmailSetup,
	formDataSmtp,
	setFormDataSmtp,
	setCreatedConfigId,
}) => {
	const api = useSwaggerApi();
	const { t } = useTranslation();
	const { id } = useParams();
	const theme = useTheme();
	const { isAllowed } = useACL();

	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const [shouldDisableForm, setShouldDisableForm] = React.useState(false);
	const [shouldDisableButton, setShouldDisableButton] = React.useState(true);
	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const getStackWidth = (): string => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const {
		handleSubmit,
		register,
		trigger,
		watch,
		formState: { errors },
	} = useForm<FormEmailVerificationValues>({
		mode: 'onChange',
		resolver: zodResolver(getEmailVerificationFormSchema(t)),
	});

	const email = watch('email');
	const retypeEmail = watch('retypeEmail');

	const handleBackEmailSetup = React.useCallback(() => {
		setActiveStepEmailSetup((prevActiveStepEmailSetup) => prevActiveStepEmailSetup - 1);
	}, [setActiveStepEmailSetup]);

	const onSubmit = React.useCallback<SubmitHandler<FormEmailVerificationValues>>(
		async (formData): Promise<void> => {
			if (submitFormState.submitting) {
				return;
			}

			const requestBody: CreateSMTPMailConfigurationRequest = {
				...formDataSmtp,
				email: formData.email,
			};

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				if (id) {
					await api.settings.updateSmtpConfiguration(Number(id), requestBody);
					enqueueSnackbar(t('page.smtp.edit.actionMessages.SMTPSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
					setCreatedConfigId(Number(id));
				} else {
					const result = await api.settings.createSmtpConfiguration(requestBody);
					enqueueSnackbar(t('page.smtp.edit.actionMessages.SMTPSuccessfullyCreated'), {
						variant: 'success',
						persist: false,
					});
					setCreatedConfigId(result.data);
				}
				setFormDataSmtp(SMTP_FORM_INIT_VALUES);
				setActiveStepEmailSetup((prevActiveStepEmailSetup) => prevActiveStepEmailSetup + 1);
				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
			} catch (error: unknown) {
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[api.settings, formDataSmtp, enqueueSnackbar, setSubmitFormState, setFormDataSmtp, setActiveStepEmailSetup, id],
	);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && submitFormState.submitting) {
			setShouldDisableForm(true);
		}
	}, [submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		if (email === undefined || retypeEmail === undefined) {
			setShouldDisableButton(true);

			return;
		}

		if (email !== retypeEmail || retypeEmail.length === 0 || email.length === 0) {
			setShouldDisableButton(true);
		} else {
			setShouldDisableButton(false);
		}
	}, [email, retypeEmail]);

	React.useEffect(() => {
		if (retypeEmail && retypeEmail.length > 0) {
			trigger('retypeEmail');
		}
	}, [email]);

	return (
		<Box component={'form'} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
			<Stack
				direction='column'
				spacing={1}
				sx={{
					width: getStackWidth(),
				}}
			>
				<Typography>{t('page.smtp.edit.subtitle.emailVerification')}</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name={'email'}
							register={register}
							label={t('page.smtp.edit.form.email.label')}
							error={errors.email}
							disabled={shouldDisableForm}
							helperText={t('page.smtp.edit.form.email.helperText')}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name={'retypeEmail'}
							register={register}
							label={t('page.smtp.edit.form.retypeEmail.label')}
							error={errors.retypeEmail}
							disabled={shouldDisableForm}
							helperText={t('page.smtp.edit.form.retypeEmail.helperText')}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<Stack spacing={2} direction={'row'} justifyContent='flex-end'>
							{isAllowed([EPermission.SMTP_CREATE, EPermission.SMTP_UPDATE], false) && (
								<Tooltip
									arrow
									placement='bottom'
									title={t('page.smtp.edit.tooltips.back')}
									enterDelay={500}
								>
									<Button variant='contained' onClick={handleBackEmailSetup}>
										{t('page.smtp.edit.body.button.back')}
									</Button>
								</Tooltip>
							)}

							{isAllowed([EPermission.SMTP_CREATE, EPermission.SMTP_UPDATE], false) && (
								<Tooltip
									arrow
									placement='bottom'
									title={t('page.smtp.edit.tooltips.next')}
									enterDelay={500}
								>
									<span>
										<Button
											variant='contained'
											type='submit'
											disabled={shouldDisableButton || shouldDisableForm}
										>
											{t('page.smtp.edit.body.button.next')}
										</Button>
									</span>
								</Tooltip>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</Box>
	);
};
