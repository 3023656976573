import * as React from 'react';
import { EClientType } from '@excalibur-enterprise/excalibur-v4-qr-codes';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import { useNavigate } from '../../../../hooks/useNavigate';

import SSHIcon from '../../../../assets/images/ssh-icon.png';
import RDPIcon from '../../../../assets/images/rdp-icon.png';
import VNCIcon from '../../../../assets/images/vnc-icon.png';
import PersonIcon from '../../../../assets/images/person-logo.png';
import ExcaliburIcon from '../../../../assets/images/excalibur-logo.png';

import { TargetProps } from './types';

export const Target: React.FC<TargetProps> = (props): JSX.Element => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { targetID, targetType, targetName } = props;
	const icon = React.useMemo(() => {
		switch (targetType) {
			case -1:
				return SSHIcon;
			case 0:
				return ExcaliburIcon;
			case 1:
				return PersonIcon;
			case 2:
				return RDPIcon;
			case 3:
				return VNCIcon;
			default:
				return ExcaliburIcon;
		}
	}, [targetType]);

	const handleOnTargetClick = React.useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (targetType === EClientType.PAM) {
				event.stopPropagation();
				event.preventDefault();
				navigate(`/pam/${targetID}`);
			}
		},
		[targetType, targetID],
	);

	return (
		<Grid container spacing={1} onClick={handleOnTargetClick}>
			<Grid item width={50} alignContent='center'>
				<img
					alt={t('page.action.list.table.body.target')}
					height={30}
					src={icon}
					loading='lazy'
					style={{ borderRadius: '4px' }}
				/>
			</Grid>
			<Grid item flex={1} alignContent='center' justifyContent={'start'}>
				<Typography variant='body1' fontSize='14px'>
					{targetName}
				</Typography>
			</Grid>
		</Grid>
	);
};
