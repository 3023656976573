import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SeekProgressType } from './types';

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress
				size={75}
				style={{
					color: 'white',
				}}
				variant='determinate'
				{...props}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderColor: 'white',
				}}
			>
				<Typography
					variant='caption'
					component='div'
					color='white'
					fontSize={20}
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
};

export const SeekProgress: React.FC<SeekProgressType> = ({ current, total }) => {
	const [progress, setProgress] = React.useState(0);

	React.useEffect(() => {
		if (!current || !total) {
			return;
		}

		const result = Math.ceil((current / total) * 100);

		if (result >= 0 && result <= 100) {
			setProgress(result);
		}
	}, [current, total]);

	return <CircularProgressWithLabel value={progress} />;
};
