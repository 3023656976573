import { Webhook as WebhookIcon } from '@mui/icons-material';
import { Box, Paper } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Preloader } from '../../../components/Preloader/Preloader';
import { WebhookForm } from './WebhookForm/WebhookForm';
import { UpdateWebhookRequestDto, WebhookModel } from '../../../api/Api';
import { FormValues } from './WebhookForm/types';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../../enums/permission/EPermission';
import { useACL } from '../../../hooks/useACL';

const getWebhookID = (strWebhookID?: string): WebhookModel['id'] | undefined => {
	return strWebhookID ? Number(strWebhookID) : undefined;
};

export const EditWebhook: React.FC = () => {
	const { isAllowed } = useACL();
	const { webhookID: strWebhookID } = useParams();
	const webhookID = getWebhookID(strWebhookID);

	const { t } = useTranslation();

	const api = useSwaggerApi();

	const { data, isLoading } = useQuery({
		queryKey: [EQueryKey.OAUTH_WEBHOOK_DETAIL_QUERY, webhookID],
		queryFn: async () => {
			try {
				const { data } = await api.oauthClient.getWebhookById(webhookID as WebhookModel['id']);

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
		enabled: !!webhookID,
	});

	const { mutate, isPending } = useMutation({
		mutationFn: async ({
			webhook,
			webhookID,
		}: {
			webhookID: WebhookModel['id'];
			webhook: UpdateWebhookRequestDto;
		}) => {
			const { data } = await api.oauthClient.updateWebhook(webhookID, webhook);

			return data;
		},
	});

	const onSubmit = (formValues: FormValues) => {
		if (!webhookID) {
			return;
		}

		mutate(
			{ webhookID, webhook: formValues },
			{
				onSuccess: () => {
					enqueueSnackbar(t('page.oAuthClients.webhooks.edit.actionMessages.webhookUpdated'), {
						variant: 'success',
					});
				},
			},
		);
	};

	const formID = 'update-webhook-form';
	const defaultValues: FormValues | undefined =
		data ?
			{
				caCertificate: data.caCertificate,
				eventTypeIDs: data.eventTypes.map(({ id }) => id),
				secret: data.secret,
				signatureMethod: data.signatureMethod,
				url: data.url,
				useTLS: data.useTLS,
			}
		:	undefined;

	return (
		<Box>
			{isLoading && <Preloader />}
			{!isLoading && (
				<Paper elevation={3}>
					<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
						<PageHeader
							title={t('page.oAuthClients.webhooks.edit.title')}
							description={t('page.oAuthClients.webhooks.edit.description')}
							icon={WebhookIcon}
						/>
					</Box>
					<WebhookForm id={webhookID} defaultValues={defaultValues} formID={formID} onSubmit={onSubmit} />
					{isAllowed([EPermission.OAUTH_CLIENTS_UPDATE]) && (
						<FloatingButtonSave
							type='submit'
							disabled={isPending}
							ariaLabel={t('page.oAuthClients.webhooks.edit.ariaLabel')}
							tooltipTitle={t('page.oAuthClients.webhooks.edit.tooltips.save')}
							formID={formID}
						/>
					)}
				</Paper>
			)}
		</Box>
	);
};
