import * as React from 'react';
import { SEOProps } from './types';
import { Helmet } from 'react-helmet-async';
import { BASE_HEADER_TITLE } from '../PageHeader/constants';

export const SEO: React.FC<SEOProps> = ({ title, description }): JSX.Element => {
	return (
		<Helmet
			title={`${BASE_HEADER_TITLE} - ${title}`}
			meta={[
				{
					name: 'description',
					content: description,
				},
			]}
		/>
	);
};
