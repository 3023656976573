import * as React from 'react';
import { TextField as OriginalTextField, Typography } from '@mui/material';
import { TextFieldProps } from './types';
import { EFieldVariant, EFieldType } from './enums';
import { Controller, FieldError } from 'react-hook-form';

export const TextField: React.FC<TextFieldProps> = (props): JSX.Element => {
	const {
		name,
		label,
		error,
		helperText,
		InputProps,
		disabled = false,
		fullWidth = true,
		InputLabelProps,
		variant = EFieldVariant.OUTLINED,
		type = EFieldType.TEXT,
		placeholder,
	} = props;

	const getTextFieldProps = React.useCallback(
		(fieldError: FieldError | undefined) => ({
			label,
			error: !!fieldError,
			disabled,
			type,
			variant,
			fullWidth,
			placeholder,
			InputLabelProps,
			InputProps,
			helperText:
				fieldError && typeof fieldError.message === 'string' ?
					<Typography component='span' color='error' sx={{ fontSize: 12 }}>
						{fieldError.message}
					</Typography>
				:	helperText,
		}),
		[label, helperText, disabled, fullWidth, variant, type, placeholder, InputProps],
	);

	if (props.register) {
		const { register } = props;

		return <OriginalTextField {...(register && register(name))} {...getTextFieldProps(error)} />;
	} else if (props.control) {
		const { control, rules } = props;

		return (
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field, fieldState }) => (
					<OriginalTextField
						id={`${name}-textfield`}
						value={field.value || ''}
						onChange={field.onChange}
						{...getTextFieldProps(fieldState.error)}
					/>
				)}
			/>
		);
	}

	return <OriginalTextField {...getTextFieldProps(error)} />;
};
