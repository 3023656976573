import axios from 'axios';

const PLACES = 'places';
const GEOMETRY = 'geometry';
export const LIBRARIES = [PLACES, GEOMETRY];

export const validationPath =
	'https://maps.googleapis.com/maps/api/staticmap?center=40.714%2c%20-73.998&zoom=12&size=400x400&key=';

export const validateGoogleApiKey = async (key: string) => {
	try {
		const response = await axios.get(`${validationPath}${key}`);
		if (response.status === 200) {
			return true;
		}

		return false;
	} catch (error) {
		return false;
	}
};
