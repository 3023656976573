import { Suspense } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from './contexts/AuthContext/AuthContext';
import { SwaggerApiProvider } from './contexts/SwaggerApiContext/SwaggerApiContext';
import { LocalizationProvider } from './contexts/LocalizationContext/LocalizationContext';
import { GeofenceContextProvider } from './contexts/GeofenceContext/GeofenceContext';
import { SessionProvider } from './contexts/SessionContext/SessionContext';

import { Router } from './services/Router/Router';
import { NetworkContextProvider } from './contexts/NetworkContext/NetworkContext';

const queryClient = new QueryClient();

export const App = (): JSX.Element => {
	return (
		<Suspense fallback={<></>}>
			<QueryClientProvider client={queryClient}>
				<ProSidebarProvider>
					<BrowserRouter>
						<LocalizationProvider>
							<NetworkContextProvider>
								<SwaggerApiProvider>
									<AuthProvider>
										<SessionProvider>
											<GeofenceContextProvider>
												<Router />
											</GeofenceContextProvider>
										</SessionProvider>
									</AuthProvider>
								</SwaggerApiProvider>
							</NetworkContextProvider>
						</LocalizationProvider>
					</BrowserRouter>
				</ProSidebarProvider>
			</QueryClientProvider>
		</Suspense>
	);
};
