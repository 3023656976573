import * as React from 'react';
import { Autocomplete, TextField, Box, Tooltip } from '@mui/material';
import { Close, ViewList } from '@mui/icons-material';

import { TableSelectOption, TableSelectProps } from './types';
import { usePreviousValue } from '../../hooks/usePreviousValue';

const optionValidator = (option: TableSelectOption, value: TableSelectOption) => {
	if (option.id === null) {
		return true;
	}

	return option.id === value.id;
};

export const TableSelect: React.FC<TableSelectProps> = (props): JSX.Element => {
	const Icon = React.useMemo(() => props.icon || ViewList, [props.icon]);
	const [visible, setVisible] = React.useState(props.alwaysVisible ? props.alwaysVisible : false);
	const previousIsAlwaysVisible = usePreviousValue(props.alwaysVisible);

	const handleToggle = React.useCallback(() => {
		if (props.alwaysVisible) {
			return;
		}

		setVisible(!visible);
	}, [visible, props]);

	React.useEffect(() => {
		if (props.alwaysVisible !== previousIsAlwaysVisible) {
			setVisible(props.alwaysVisible ? props.alwaysVisible : false);
		}
	}, [props.alwaysVisible, previousIsAlwaysVisible]);

	return (
		<Box
			sx={{
				position: 'relative',
				minWidth: visible ? 220 : 40,
				paddingLeft: 1,
				height: '40px',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			{visible && (
				<Autocomplete
					disablePortal
					disableClearable
					defaultValue={props.defaultValue}
					id={props.identifier}
					onChange={props.onChange}
					options={props.options}
					componentsProps={{
						popper: {
							sx: { zIndex: 99999 },
						},
					}}
					sx={{
						minWidth: 220,
					}}
					value={props.value}
					isOptionEqualToValue={optionValidator}
					renderInput={(params) => (
						<TextField
							{...params}
							variant='standard'
							InputProps={{
								...params.InputProps,
								disableUnderline: true,
								sx: { padding: 0, height: '38px' },
							}}
						/>
					)}
				/>
			)}
			{!props.alwaysVisible && (
				<>
					{visible ?
						<Close onClick={handleToggle} sx={{ height: 40, color: '#878787' }} />
					:	<>
							{props.tooltip ?
								<Tooltip arrow title={props.tooltip}>
									<span>
										<Icon
											onClick={handleToggle}
											sx={{ height: 40, color: '#878787', cursor: 'pointer' }}
										/>
									</span>
								</Tooltip>
							:	<Icon onClick={handleToggle} sx={{ height: 40, color: '#878787', cursor: 'pointer' }} />}
						</>
					}
				</>
			)}
		</Box>
	);
};
