import { ESessionTarget } from '../../enums/session/ESessionTarget';
import sshLogo from '../../assets/images/ssh-icon.png';
import rdpLogo from '../../assets/images/rdp-icon.png';
import vncLogo from '../../assets/images/vnc-icon.png';

export const getSessionTargetLogo = (target: string | undefined): string => {
	switch (target) {
		case ESessionTarget.SSH:
			return sshLogo;
		case ESessionTarget.RDP:
			return rdpLogo;
		case ESessionTarget.VNC:
			return vncLogo;
		default:
			return '';
	}
};
