import * as React from 'react';
import {
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MaterialReactTable,
	MRT_TableInstance,
	MRT_Row,
} from 'material-react-table';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from '../../hooks/useNavigate';
import {
	Paper,
	Box,
	FormGroup,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
	IconButton,
	Tooltip,
	Button,
	Avatar,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Workspaces as WorkspacesIcon } from '@mui/icons-material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Preloader } from '../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { FormValues, SubmitFormState, UsersInGroupState } from './types';
import { getUserGroupSchema, userListSchema } from './schema';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useACL } from '../../hooks/useACL';
import { EPermission } from '../../enums/permission/EPermission';
import {
	CreateUserGroupRequestDto,
	UserDetailDto,
	PartialGetUserResponseDto,
	UserDetailModel,
	PagedResultUserDetailModel,
	GroupDto,
} from '../../api/Api';
import { Heading } from '../../components/Heading/Heading';
import { ChipArray } from '../../components/ChipArray/ChipArray';
import { TextField } from '../../components/FormFields/TextField/TextField';
import { Textarea } from '../../components/FormFields/Textarea/Textarea';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../components/Buttons/FloatingButton/FloatingButtonSave';
import { useTableQuery } from '../../hooks/useTableQuery';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { useFetchUserGroup } from '../../hooks/query/userGroups/useFetchUserGroup';

export const UserGroupAddEdit: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const copyFromId = location.state?.copyFromId;
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const { MRTLocalization } = useMRTLocalization();

	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [selectedUserList, setSelectedUserList] = React.useState<PartialGetUserResponseDto[]>([]);
	const [allUserList, setAllUserList] = React.useState<PartialGetUserResponseDto[]>([]);
	const [usersToUpdate, setUsersToUpdate] = React.useState<{ add: number[]; delete: number[] }>({
		add: [],
		delete: [],
	});
	const [rowSelectionSelected, setRowSelectionSelected] = React.useState<MRTRowSelectionState>({});

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const [usersInGroupState, setUsersInGroupState] = React.useState<UsersInGroupState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const loadUsersInGroup = React.useCallback(async () => {
		if (usersInGroupState.loading) {
			return;
		}
		try {
			setUsersInGroupState({
				loading: true,
				loaded: false,
				data: null,
				error: null,
			});
			const query = {
				limit: 2000,
				offset: 0,
			};
			const response = await api.userGroups.getUsersInUserGroup(Number(id), query);
			setSelectedUserList(response.data.entities);
			setUsersInGroupState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});
		} catch (error) {
			setUsersInGroupState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, [usersInGroupState, id]);

	React.useEffect(() => {
		if (id) {
			loadUsersInGroup();
		}
	}, [id]);

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getUserGroupSchema(t)),
		defaultValues: {
			name: '',
			description: '',
		},
	});

	const {
		rowSelection: rowSelectionAll,
		setRowSelection: setRowSelectionAll,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'surname', 'email', 'userFullName']);

	const {
		data: userList,
		isError: isUserListError,
		isRefetching: isUserListRefetching,
		isLoading: isUserListLoading,
		error: userListError,
	} = useQuery<PagedResultUserDetailModel>({
		queryKey: [EQueryKey.USER_GROUP_USER_LIST_QUERY, swaggerQuery, id],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.users.getUserDetails(query);
				response.data.entities.forEach((user) => {
					userListSchema.parse(user);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = userList ? userList : {};

	const {
		data: userGroup,
		isLoading: isLoadingUserGroup,
		isSuccess: isLoadedUserGroup,
	} = useFetchUserGroup(
		id ? Number(id)
		: copyFromId ? Number(copyFromId)
		: undefined,
	);

	React.useEffect(() => {
		if (userGroup) {
			if (copyFromId) {
				reset({
					name: `copy of ${userGroup.name}`,
					description: userGroup.description,
				});
			} else {
				reset({
					name: userGroup.name,
					description: userGroup.description,
				});
			}
		}
	}, [userGroup, copyFromId]);

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData): Promise<void> => {
			const requestBodyGroup: CreateUserGroupRequestDto = {
				name: formData.name,
				description: formData.description,
			};
			const requestBodyUsers: number[] = selectedUserList.map((user) => user.id as number);

			let createdGroupID: number | undefined = undefined;

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				if (id && !userGroup?.isBuiltIn) {
					await api.userGroups.updateUserGroup(Number(id), requestBodyGroup);
				}
				if (!id && !userGroup?.isBuiltIn) {
					const response = await api.userGroups.createUserGroup(requestBodyGroup);
					createdGroupID = response.data.id;
				}
			} catch (error: unknown) {
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
				console.error(error);

				return;
			}

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				if (id) {
					if (usersToUpdate.delete.length === 1) {
						await api.userGroups.deleteUserFromUserGroup(Number(id), usersToUpdate.delete[0]);
					} else if (usersToUpdate.delete.length > 1) {
						await api.userGroups.deleteUsersFromUserGroup(Number(id), {
							userIDs: usersToUpdate.delete,
						});
					}

					if (usersToUpdate.add.length === 1) {
						await api.userGroups.addUserToUserGroup(Number(id), usersToUpdate.add[0]);
					} else if (usersToUpdate.add.length > 1) {
						await api.userGroups.addUsersToUserGroup(Number(id), usersToUpdate.add);
					}
					enqueueSnackbar(t('page.userGroups.edit.actionMessages.groupSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
				} else {
					if (requestBodyUsers.length === 1) {
						await api.userGroups.addUserToUserGroup(Number(createdGroupID), requestBodyUsers[0]);
					} else if (requestBodyUsers.length > 1) {
						await api.userGroups.addUsersToUserGroup(Number(createdGroupID), requestBodyUsers);
					}
					enqueueSnackbar(t('page.userGroups.edit.actionMessages.groupSuccessfullyCreated'), {
						variant: 'success',
						persist: false,
					});
				}
				navigate('/users/userGroups');

				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
			} catch (error: unknown) {
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[id, selectedUserList, usersToUpdate],
	);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const updateRowSelectionAll = React.useCallback(
		(addedIds: number[]) => {
			if (Object.keys(rowSelectionAll).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelectionAll };
			addedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelectionAll(newRowSelection);
		},
		[rowSelectionAll],
	);

	const updateRowSelectionSelected = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelectionSelected).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelectionSelected };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelectionSelected(newRowSelection);
		},
		[rowSelectionSelected],
	);

	const handleOnAddEntity = React.useCallback(
		(userId: number | undefined) => () => {
			if (!userId) {
				return;
			}

			const userToAdd = allUserList.find((user) => user.id === userId);
			if (userToAdd) {
				setAllUserList((prevList) =>
					prevList.map((user) => (user.id === userId ? { ...user, disabled: true } : user)),
				);
				setSelectedUserList((prevList) => [...prevList, userToAdd]);
				updateRowSelectionAll([userId]);
			}
		},
		[allUserList, rowSelectionAll],
	);

	const handleOnRemoveEntity = React.useCallback(
		(userId: number | undefined) => () => {
			if (!userId) {
				return;
			}

			const userToRemove = selectedUserList.find((user) => user.id === userId);
			if (userToRemove) {
				setAllUserList((prevList) =>
					prevList.map((user) => (user.id === userId ? { ...user, disabled: false } : user)),
				);
				setSelectedUserList((prevList) => prevList.filter((user) => user.id !== userToRemove.id));
				updateRowSelectionSelected([userId]);
			}
		},
		[selectedUserList, rowSelectionSelected],
	);

	const handleOnAddEntities = React.useCallback(
		(table: MRT_TableInstance<Partial<Record<string, any>>>) => () => {
			if (!rowSelectionAll) {
				return;
			}

			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			const usersToAdd = allUserList.filter((user) => selectedRowsOnActivePageIds.includes(user.id as number));
			if (usersToAdd) {
				setSelectedUserList((prevList) => [...prevList, ...usersToAdd]);
				updateRowSelectionAll(selectedRowsOnActivePageIds);
			}
		},
		[allUserList, rowSelectionAll],
	);

	const handleOnRemoveEntities = React.useCallback(
		(table: MRT_TableInstance<Partial<Record<string, any>>>) => () => {
			if (!rowSelectionSelected) {
				return;
			}

			const rowsOnPage = table.getRowModel().rows.map((row) => Number(row.original.id));
			const allSelectedRows = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));
			const selectedRowsOnActivePageIds = allSelectedRows.filter((id) => rowsOnPage.includes(id));

			const usersToRemove = selectedUserList.filter((user) =>
				selectedRowsOnActivePageIds.includes(user.id as number),
			);
			if (usersToRemove) {
				setSelectedUserList((prevList) => prevList.filter((user) => !usersToRemove.includes(user)));
				updateRowSelectionSelected(selectedRowsOnActivePageIds);
			}
		},
		[selectedUserList, rowSelectionSelected],
	);

	const handleOnChipClick = React.useCallback(
		(group: GroupDto) => {
			if (!isAllowed([EPermission.USER_GROUPS_READ])) {
				return;
			}
			navigate(`/users/userGroups/${group.id}`);
		},
		[isAllowed],
	);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !isLoadingUserGroup) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || isLoadingUserGroup)) {
			setShouldDisableForm(true);
		}
	}, [isLoadingUserGroup, submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		if (!id || !isLoadedUserGroup) {
			return;
		}

		const usersToAdd = selectedUserList.filter(
			(user) => !usersInGroupState.data?.entities.includes(user as UserDetailDto),
		);

		setUsersToUpdate((prevUpdateUsers) => {
			return { ...prevUpdateUsers, add: usersToAdd.map((user) => user.id as number) };
		});

		if (!userGroup || usersInGroupState.data?.entities.length === 0) {
			return;
		}

		const usersToDelete = usersInGroupState.data?.entities.filter((user) => !selectedUserList.includes(user)) || [];

		setUsersToUpdate((prevUpdateUsers) => {
			return { ...prevUpdateUsers, delete: usersToDelete.map((user) => user.id) };
		});
	}, [selectedUserList, usersInGroupState]);

	React.useEffect(() => {
		if (!selectedUserList || isUserListLoading) {
			return;
		}

		setAllUserList(entities);
	}, [selectedUserList, entities]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	React.useEffect(() => {
		if (!isUserListError) {
			return;
		}

		if (userListError instanceof AxiosError) {
			const errorResponse =
				userListError.response?.data.message ? userListError.response?.data.message : userListError.message;
			enqueueSnackbar(errorResponse, {
				variant: 'error',
				persist: false,
			});
		} else {
			enqueueSnackbar((userListError as any).toString(), {
				variant: 'error',
				persist: false,
			});
		}
	}, [isUserListError]);

	const columns: MRTColumnDef<Partial<UserDetailModel>>[] = React.useMemo(
		() => [
			{
				accessorFn: (user) =>
					`${user.title ? `${user.title} ` : ''}${user.name} ${user.surname ? user.surname : ''}`,
				accessorKey: 'userFullName',
				grow: 1,
				header: t('page.userGroups.edit.table.header.name'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={String(renderedCellValue)} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.email}`,
				accessorKey: 'email',
				grow: 1,
				header: t('page.userGroups.edit.table.header.email'),
			},
			{
				accessorFn: (row) => `${row.groups}`,
				accessorKey: 'groups',
				grow: 3,
				header: t('page.userGroups.edit.table.header.groups'),
				Cell: ({ row }) => (
					<ChipArray
						wrap
						chipList={isAllowed([EPermission.USER_GROUPS_READ]) ? row.original.groups : undefined}
						limitTags={3}
						onChipClick={handleOnChipClick}
					/>
				),
			},
		],
		[isAllowed],
	);

	return (
		<Box
			component={'form'}
			noValidate
			autoComplete='off'
			onSubmit={handleSubmit(onSubmit)}
			sx={{ marginBottom: 10 }}
		>
			{id && (isLoadingUserGroup || !isLoadedUserGroup) ?
				<Preloader />
			:	<Paper elevation={3}>
					<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
						<PageHeader
							title={id ? t('page.userGroups.edit.title') : t('page.userGroups.add.title')}
							description={
								id ? t('page.userGroups.edit.description') : t('page.userGroups.add.description')
							}
							icon={WorkspacesIcon}
						/>
					</Box>
					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Stack
							spacing={1}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Heading label={t('page.userGroups.edit.subtitle.general')} />
							<TextField
								name={'name'}
								register={register}
								label={t('page.userGroups.edit.form.name.label')}
								error={errors.name}
								disabled={(!copyFromId && userGroup?.isBuiltIn) || shouldDisableForm}
								helperText={t('page.userGroups.edit.form.name.helperText')}
								InputLabelProps={{ shrink: true }}
							/>
							<Textarea
								name={'description'}
								register={register}
								label={t('page.userGroups.edit.form.description.label')}
								error={errors.description}
								disabled={(!copyFromId && userGroup?.isBuiltIn) || shouldDisableForm}
								helperText={t('page.userGroups.edit.form.description.helperText')}
								rows={4}
							/>
						</Stack>
					</FormGroup>

					<FormGroup sx={{ padding: 2 }}>
						<Box maxWidth={'100%'}>
							<Stack spacing={2}>
								<Stack>
									<Heading label={t('page.userGroups.edit.subtitle.selectedUsers')} />
									<Typography variant='body1' sx={{ paddingBottom: 2 }}>
										{t('page.userGroups.edit.table.description.selectedUsers')}
									</Typography>
									<MaterialReactTable
										columns={columns}
										data={selectedUserList}
										enableStickyHeader={false}
										state={{
											rowSelection: rowSelectionSelected,
										}}
										initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
										enableRowActions
										enableRowSelection
										onRowSelectionChange={setRowSelectionSelected}
										getRowId={(originalRow) => originalRow.id?.toString() || ''}
										renderRowActions={({ row }) => (
											<Box>
												{isAllowed(
													[EPermission.USER_GROUPS_CREATE, EPermission.USER_GROUPS_UPDATE],
													false,
												) && (
													<Tooltip
														arrow
														placement='left'
														title={t('page.userGroups.edit.tooltips.removeUser')}
														enterDelay={500}
													>
														<IconButton
															color='error'
															onClick={handleOnRemoveEntity(row.original.id)}
														>
															<DeleteIcon />
														</IconButton>
													</Tooltip>
												)}
											</Box>
										)}
										renderToolbarInternalActions={({ table }) => (
											<Box sx={{ display: 'flex', gap: '1rem' }}>
												<MRTToggleGlobalFilterButton table={table} />
												<MRTShowHideColumnsButton table={table} />
												{isAllowed(
													[EPermission.USER_GROUPS_CREATE, EPermission.USER_GROUPS_UPDATE],
													false,
												) && (
													<Tooltip
														arrow
														placement='top'
														title={t('page.userGroups.edit.tooltips.removeSelected')}
														enterDelay={500}
													>
														<span>
															<Button
																color='error'
																variant='contained'
																disabled={table.getSelectedRowModel().rows.length === 0}
																onClick={handleOnRemoveEntities(table)}
																sx={{
																	display: 'flex',
																	alignItems: 'flex-start',
																	gap: '0.5rem',
																	width: 130,
																}}
															>
																<DeleteIcon />
																{t('page.userGroups.edit.table.button.remove')}
															</Button>
														</span>
													</Tooltip>
												)}
											</Box>
										)}
										displayColumnDefOptions={{
											'mrt-row-actions': {
												header: t('page.userGroups.edit.table.header.actions'),
												muiTableHeadCellProps: {
													align: 'center',
												},
												size: 120,
												enableHiding: true,
											},
											'mrt-row-select': {
												enableHiding: true,
												visibleInShowHideMenu: false,
											},
										}}
										muiTablePaperProps={({ table }) => ({
											style: {
												zIndex: table.getState().isFullScreen ? 1100 : undefined,
												boxShadow: 'none',
												outline: '1px solid #e0e0e0',
											},
										})}
										muiTableHeadCellProps={() => ({
											sx: {
												paddingLeft: 2,
												paddingBottom: 2,
											},
										})}
										muiTableBodyCellProps={() => ({
											sx: {
												paddingLeft: 2,
											},
										})}
										editDisplayMode='modal'
										positionActionsColumn='last'
										localization={MRTLocalization}
										layoutMode='grid-no-grow'
									/>
								</Stack>
								<Stack>
									<Heading label={t('page.userGroups.edit.subtitle.allUsers')} />
									<Typography variant='body1' sx={{ paddingBottom: 2 }}>
										{t('page.userGroups.edit.table.description.allUsers')}
									</Typography>
									<MaterialReactTable
										columns={columns}
										data={allUserList}
										enableStickyHeader={false}
										enableRowActions
										enableRowSelection={(row: MRT_Row<PartialGetUserResponseDto>) => {
											const found = selectedUserList.find((user) => user.id === row.original.id);

											return !found;
										}}
										state={{
											isLoading: isUserListLoading,
											showAlertBanner: userListError !== null,
											pagination,
											rowSelection: rowSelectionAll,
											showProgressBars: isUserListRefetching,
											globalFilter,
											sorting,
											columnFilters,
											columnVisibility,
										}}
										muiToolbarAlertBannerProps={{
											color: 'error',
											children: <>{userListError}</>,
										}}
										initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
										rowCount={total}
										manualPagination
										manualFiltering
										manualSorting
										onSortingChange={setSorting}
										onGlobalFilterChange={setGlobalFilter}
										onColumnFiltersChange={setColumnFilters}
										onPaginationChange={setPagination}
										onColumnVisibilityChange={setColumnVisibility}
										onRowSelectionChange={setRowSelectionAll}
										getRowId={(originalRow) => originalRow.id?.toString() || ''}
										renderRowActions={({ row }) => {
											const found = selectedUserList.find((user) => user.id === row.original.id);

											return found ? null : (
													<Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
														{isAllowed(
															[
																EPermission.USER_GROUPS_CREATE,
																EPermission.USER_GROUPS_UPDATE,
															],
															false,
														) && (
															<Tooltip
																arrow
																placement='left'
																title={t('page.userGroups.edit.tooltips.addUser')}
																enterDelay={500}
															>
																<IconButton
																	color='success'
																	onClick={handleOnAddEntity(row.original.id)}
																>
																	<AddIcon />
																</IconButton>
															</Tooltip>
														)}
													</Box>
												);
										}}
										renderToolbarInternalActions={({ table }) => (
											<Box sx={{ display: 'flex', gap: '1rem' }}>
												<MRTToggleGlobalFilterButton table={table} />
												<MRTShowHideColumnsButton table={table} />
												{isAllowed(
													[EPermission.USER_GROUPS_CREATE, EPermission.USER_GROUPS_UPDATE],
													false,
												) && (
													<Tooltip
														arrow
														placement='top'
														title={t('page.userGroups.edit.tooltips.addSelected')}
														enterDelay={500}
													>
														<span>
															<Button
																color='success'
																variant='contained'
																disabled={table.getSelectedRowModel().rows.length === 0}
																onClick={handleOnAddEntities(table)}
																sx={{
																	display: 'flex',
																	alignItems: 'flex-start',
																	gap: '0.5rem',
																	width: 130,
																}}
															>
																<AddIcon />
																{t('page.userGroups.edit.table.button.add')}
															</Button>
														</span>
													</Tooltip>
												)}
											</Box>
										)}
										displayColumnDefOptions={{
											'mrt-row-actions': {
												header: t('page.userGroups.edit.table.header.actions'),
												muiTableHeadCellProps: {
													align: 'center',
												},
												size: 120,
												enableHiding: true,
											},
											'mrt-row-select': {
												enableHiding: true,
												visibleInShowHideMenu: false,
											},
										}}
										muiTablePaperProps={({ table }) => ({
											style: {
												zIndex: table.getState().isFullScreen ? 1100 : undefined,
												boxShadow: 'none',
												outline: '1px solid #e0e0e0',
											},
										})}
										muiTableHeadCellProps={() => ({
											sx: {
												paddingLeft: 2,
												paddingBottom: 2,
											},
										})}
										muiTableBodyCellProps={() => ({
											sx: {
												paddingLeft: 2,
											},
										})}
										editDisplayMode='modal'
										positionActionsColumn='last'
										localization={MRTLocalization}
										layoutMode='grid-no-grow'
									/>
								</Stack>
							</Stack>
						</Box>
					</FormGroup>

					{isAllowed([EPermission.USER_GROUPS_CREATE, EPermission.USER_GROUPS_UPDATE], false) && (
						<FloatingButtonSave
							type='submit'
							disabled={shouldDisableForm}
							ariaLabel={t('page.userGroups.edit.ariaLabel.save')}
							tooltipTitle={
								id ? t('page.userGroups.edit.tooltips.save') : t('page.userGroups.edit.tooltips.create')
							}
						/>
					)}
				</Paper>
			}
		</Box>
	);
};
