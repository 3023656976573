import * as React from 'react';
import { useNavigate } from '../../hooks/useNavigate';
import { enqueueSnackbar } from 'notistack';
import {
	MRT_ToggleDensePaddingButton,
	type MRT_ColumnDef,
	MRT_ToggleFullScreenButton,
	MRT_ToggleFiltersButton,
	MRT_ShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton,
	MRT_RowSelectionState,
	MaterialReactTable,
	MRT_TableInstance,
} from 'material-react-table';
import {
	ContentCopy as ContentCopyIcon,
	Cast as CastIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	Info as InfoIcon,
	Preview as PreviewIcon,
	SaveAlt as SaveAltIcon,
	List as ListIcon,
} from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Typography, Tooltip, Paper, Stack } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { EPermission } from '../../enums/permission/EPermission';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import {
	CreatePAMSessionRequestDto,
	GetPAMTargetDetailsResponseDto,
	PAMTargetDetailModel,
	PAMTargetGroupModel,
} from '../../api/Api';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { pamTargetListSchema } from './schema';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { useACL } from '../../hooks/useACL';

import RdpIcon from '../../assets/images/rdp-icon.png';
import SshIcon from '../../assets/images/ssh-icon.png';
import VncIcon from '../../assets/images/vnc-icon.png';
import { ChipArray } from '../../components/ChipArray/ChipArray';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { AddTargetDialog } from './AddTargetDialog';

export const PAMPage: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { MRTLocalization } = useMRTLocalization();

	const [confirmationText, setConfirmationText] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [pamTargetIDToDelete, setPamTargetIDToDelete] = React.useState<number | null>(null);
	const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>({});
	const [isLoadingSession, setIsLoadingSession] = React.useState(false);
	const [multiPAMTargetIDsToDelete, setMultiPAMTargetIDsToDelete] = React.useState<number[]>([]);
	const [loadingTargetId, setLoadingTargetId] = React.useState<number | null>(null);
	const reactQueryClient = useReactQueryClient();
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [targetGroupsOptions, setTargetGroupsOptions] = React.useState<string[]>([]);
	const [addTargetModalOpen, setAddTargetModalOpen] = React.useState(false);

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'url']);

	const { data, isRefetching, isLoading, error } = useQuery<GetPAMTargetDetailsResponseDto>({
		queryKey: [EQueryKey.PAM_TARGET_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.pam.getTargetDetails(query);
				response.data.entities.forEach((pamTarget: PAMTargetDetailModel) => {
					pamTargetListSchema.parse(pamTarget);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const getWindowSize = () => {
		const { innerWidth: width, innerHeight: height } = window;

		return { width, height };
	};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleStartSession = React.useCallback(
		(targetID: number) => async (event: React.MouseEvent) => {
			event.stopPropagation();

			setIsLoadingSession(true);
			setLoadingTargetId(targetID);

			const { width, height } = getWindowSize();

			try {
				const pamSession: CreatePAMSessionRequestDto = {
					targetID,
					width,
					height,
				};

				const response = await api.pam.createPamSession(pamSession);

				const data = {
					sessionID: response.data.id,
				};

				const token = JSON.stringify(data);
				const base64Token = btoa(token);
				const encodedToken = encodeURIComponent(base64Token);
				const url = `/pam/client/?token=${encodedToken}`;

				const newTab = window.open(url, '_blank', 'noopener noreferrer');
				newTab?.focus();
			} catch (error: unknown) {
				console.error(error);
			} finally {
				setIsLoadingSession(false);
				setLoadingTargetId(null);
			}
		},
		[],
	);

	const handleOnDuplicate = React.useCallback(
		(type: string, targetID: number) => async (event: React.MouseEvent) => {
			event.stopPropagation();

			try {
				const response = await api.pam.duplicatePamTarget(targetID);

				navigate(`/pam/${type}/edit/${response.data.id}`);
			} catch (error) {
				console.error(error);
			}
		},
		[],
	);

	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.pam.list.confirmation.delete.title'));
				setConfirmationText(t('page.pam.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.pam.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.pam.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenPAMTargetDeleteDialog = React.useCallback(
		(id: number) => (event: React.MouseEvent) => {
			event.stopPropagation();

			setPamTargetIDToDelete(id);
			setOpen(true);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[isAllowed],
	);

	const handleOnConfirmPamTargetDelete = React.useCallback(async (): Promise<void> => {
		if (pamTargetIDToDelete) {
			try {
				await api.pam.deleteTarget(pamTargetIDToDelete);
				enqueueSnackbar(t('page.pam.list.actionMessages.pamTargetSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setPamTargetIDToDelete(null);
				updateRowSelection([pamTargetIDToDelete]);
			} catch (error) {
				console.error(error);
			}
		} else if (multiPAMTargetIDsToDelete.length > 0) {
			try {
				await api.pam.deleteTargets({ ids: multiPAMTargetIDsToDelete });
				enqueueSnackbar(t('page.pam.list.actionMessages.pamTargetsSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiPAMTargetIDsToDelete([]);
				updateRowSelection(multiPAMTargetIDsToDelete);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [pamTargetIDToDelete, multiPAMTargetIDsToDelete, isAllowed]);

	const handleOnOpenMultiplePAMTargetsDeleteDialog = React.useCallback(
		(table: MRT_TableInstance<PAMTargetDetailModel>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.pam.list.actionMessages.noPAMTargetsSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}
			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiPAMTargetIDsToDelete(selectedRowsOnActivePageIds);
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed],
	);

	const handleOnChipClick = React.useCallback(
		(group: PAMTargetGroupModel) => {
			if (!isAllowed([EPermission.PAM_GROUPS_READ])) {
				return;
			}
			navigate(`/pam/groups/${group.id}`);
		},
		[isAllowed],
	);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	const getTargetLogoByType = React.useCallback((type: string): string => {
		const typeLogos = new Map<string, string>([
			['rdp', RdpIcon],
			['ssh', SshIcon],
			['vnc', VncIcon],
		]);

		const logo = typeLogos.get(type);
		if (!logo) {
			throw new Error(`Unknown platform: ${type}`);
		}

		return logo;
	}, []);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	React.useEffect(() => {
		const fetchTargetGroups = async () => {
			try {
				const response = await api.pamGroups.getPamTargetGroups();
				const groups = response.data.entities.map((group) => group.name);
				setTargetGroupsOptions(groups);
			} catch (error) {
				console.error('Failed to fetch target groups', error);
			}
		};

		fetchTargetGroups();
	}, []);

	const columns = React.useMemo<MRT_ColumnDef<PAMTargetDetailModel>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				grow: 1,
				header: t('page.pam.list.columns.name'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<img
							alt='avatar'
							height={42}
							src={getTargetLogoByType(row.original.type.toLocaleLowerCase())}
							loading='lazy'
							style={{ borderRadius: 'none' }}
						/>
						<span>{renderedCellValue}</span>
						{row.original.description && (
							<Tooltip title={row.original.description} placement='right' enterDelay={500} arrow>
								<InfoIcon color='info' />
							</Tooltip>
						)}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.hostname}:${row.port}`,
				accessorKey: 'url',
				grow: 1,
				header: t('page.pam.list.columns.host'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.groups}`,
				accessorKey: 'groups',
				header: t('page.pam.list.columns.groups'),
				filterVariant: 'select',
				filterSelectOptions: targetGroupsOptions,
				grow: 3,
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<ChipArray
							wrap
							chipList={isAllowed([EPermission.PAM_GROUPS_READ]) ? row.original.groups : undefined}
							limitTags={3}
							onChipClick={handleOnChipClick}
						/>
					</Box>
				),
			},
		],
		[isLoadingSession, loadingTargetId, isAllowed, targetGroupsOptions],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.pam.list.title')}
						description={t('page.pam.list.description')}
						icon={ListIcon}
					/>

					<MaterialReactTable
						columns={columns}
						data={entities}
						enableStickyHeader={false}
						state={{
							isLoading: isLoading,
							showAlertBanner: error !== null,
							pagination,
							rowSelection,
							showProgressBars: isRefetching,
							columnFilters,
							globalFilter,
							sorting,
							columnVisibility,
						}}
						muiToolbarAlertBannerProps={{
							color: 'error',
							children: <>{error}</>,
						}}
						initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
						rowCount={total}
						manualPagination
						manualFiltering
						manualSorting
						onSortingChange={setSorting}
						onGlobalFilterChange={setGlobalFilter}
						onColumnFiltersChange={setColumnFilters}
						onPaginationChange={setPagination}
						onColumnVisibilityChange={setColumnVisibility}
						enableRowActions
						enableRowSelection={isAllowed([EPermission.PAM_TARGETS_DELETE])}
						getRowId={(originalRow) => originalRow.id?.toString() || ''}
						onRowSelectionChange={setRowSelection}
						renderRowActions={({ row }) => (
							<Box sx={{ display: 'flex', justifyContent: 'left', gap: '1rem' }}>
								{isAllowed(
									[EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN],
									false,
								) && (
									<Tooltip
										title={t('page.pam.list.tooltips.connect')}
										placement='left'
										enterDelay={500}
										arrow
									>
										<span>
											{isLoadingSession && loadingTargetId === row.original.id ?
												<CircularProgress size={24} sx={{ margin: '0 8px' }} />
											:	<IconButton onClick={handleStartSession(row.original.id)} color='success'>
													<CastIcon />
												</IconButton>
											}
										</span>
									</Tooltip>
								)}
								{isAllowed(
									[EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN],
									false,
								) && (
									<Tooltip
										title={t('page.pam.list.tooltips.detail')}
										placement='bottom'
										enterDelay={500}
										arrow
									>
										<span>
											<IconButton onClick={handleRedirect(`/pam/${row.original.id}`)}>
												<PreviewIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
								{isAllowed([EPermission.PAM_TARGETS_UPDATE]) && (
									<>
										<Tooltip
											title={t('page.pam.list.tooltips.duplicate')}
											placement='bottom'
											enterDelay={500}
											arrow
										>
											<IconButton
												onClick={handleOnDuplicate(
													row.original.type.toLocaleLowerCase(),
													row.original.id,
												)}
											>
												<ContentCopyIcon />
											</IconButton>
										</Tooltip>
										<Tooltip
											title={t('page.pam.list.tooltips.edit')}
											placement='bottom'
											enterDelay={500}
											arrow
										>
											<span>
												<IconButton
													onClick={handleRedirect(
														`/pam/${row.original.type.toLocaleLowerCase()}/edit/${row.original.id}`,
													)}
												>
													<EditIcon />
												</IconButton>
											</span>
										</Tooltip>
									</>
								)}
								{isAllowed([EPermission.PAM_TARGETS_DELETE]) && (
									<Tooltip title={t('page.pam.list.tooltips.delete')} placement='right' arrow>
										<IconButton
											color='error'
											onClick={handleOnOpenPAMTargetDeleteDialog(row.original.id as number)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						)}
						renderToolbarInternalActions={({ table }) => (
							<Box sx={{ display: 'flex', gap: '1rem' }}>
								<MRT_ToggleGlobalFilterButton table={table} />
								<MRT_ToggleFiltersButton table={table} />
								<MRT_ShowHideColumnsButton table={table} />
								{/* <Tooltip title={t('page.pam.list.tooltips.export')} enterDelay={500}>
									<IconButton>
										<SaveAltIcon />
									</IconButton>
								</Tooltip> */}
								{isAllowed([EPermission.PAM_TARGETS_DELETE]) && (
									<Tooltip title={t('page.pam.list.tooltips.removeSelected')} enterDelay={500}>
										<span>
											<IconButton
												color='error'
												disabled={table.getSelectedRowModel().rows.length === 0}
												onClick={handleOnOpenMultiplePAMTargetsDeleteDialog(table)}
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
								<MRT_ToggleDensePaddingButton table={table} />
								<MRT_ToggleFullScreenButton table={table} />
							</Box>
						)}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								header: t('page.pam.list.columns.actions'),
								size: 300,
								enableHiding: true,
							},
							'mrt-row-select': {
								enableHiding: true,
								visibleInShowHideMenu: false,
							},
						}}
						muiTablePaperProps={({ table }) => ({
							style: {
								zIndex: table.getState().isFullScreen ? 1100 : undefined,
								boxShadow: 'none',
								outline: '1px solid #e0e0e0',
							},
						})}
						muiTableHeadCellProps={() => ({
							sx: {
								paddingLeft: 2,
								paddingBottom: 2,
							},
						})}
						muiTableBodyCellProps={() => ({
							sx: {
								paddingLeft: 2,
							},
						})}
						muiTableBodyRowProps={({ row }) => ({
							onClick:
								isAllowed([EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN], false) ?
									handleStartSession(row.original.id)
								:	undefined,
							sx: { cursor: 'pointer' },
						})}
						editDisplayMode='modal'
						positionActionsColumn='last'
						localization={MRTLocalization}
						layoutMode='grid-no-grow'
					/>
					{isAllowed([EPermission.PAM_TARGETS_DELETE]) && (
						<ConfirmationDialog
							onClose={handleClose}
							open={open}
							onConfirm={handleOnConfirmPamTargetDelete}
							title={confirmationTitle}
							text={confirmationText}
							cancelText={t('page.pam.list.confirmation.delete.cancel')}
							confirmText={t('page.pam.list.confirmation.delete.confirm')}
						/>
					)}
				</Stack>
			</Paper>

			{isAllowed([EPermission.PAM_TARGETS_CREATE]) && (
				<>
					<FloatingButtonAdd
						ariaLabel={t('page.pam.list.ariaLabel.addTarget')}
						tooltipTitle={t('page.pam.list.tooltips.add')}
						onClick={() => setAddTargetModalOpen(true)}
					/>
					<AddTargetDialog open={addTargetModalOpen} onClose={() => setAddTargetModalOpen(false)} />
				</>
			)}
		</Box>
	);
};
