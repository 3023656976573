import * as React from 'react';
import Box from '@mui/material/Box';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { StatisticsCard } from '../../components/StatisticsCard/StatisticsCard';
import {
	datasetsAuthentications,
	datasetsAuthorizations,
	datasetsFactorsReset,
	datasetsRegistrations,
	datasetsSetGeofence,
	datasetsShowPasswords,
	datasetsTokenlessAuthentications,
	datasetsVerifications,
} from './data/actionsData';
import { ChartLine } from '../../components/Chart/ChartLine';

export const ActionsPage: React.FC = () => {
	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					paddingBottom: 1,
				}}
			>
				<Typography variant='h5'>Actions statistics</Typography>

				<FormControl>
					<InputLabel id='date-range-select-label'>Date range</InputLabel>
					<Select
						labelId='date-range-select-label'
						id='date-range-select'
						value='10'
						label='Date range'
						//onChange={handleChange}
					>
						<MenuItem value={10}>This week</MenuItem>
						<MenuItem value={20}>Last week</MenuItem>
						<MenuItem value={30}>This month</MenuItem>
						<MenuItem value={40}>Last month</MenuItem>
						<MenuItem value={50}>This year</MenuItem>
						<MenuItem value={60}>Last year</MenuItem>
						<MenuItem value={70} disabled>
							Custom
						</MenuItem>
					</Select>
				</FormControl>
			</Box>

			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Authentications'
							count={1}
							prevCount={91}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Authorizations'
							count={12}
							prevCount={1567}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Verifications'
							count={5}
							prevCount={10}
							negativeIsGood
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Registrations'
							count={2}
							prevCount={60}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Factors reset'
							count={3}
							prevCount={60}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Tokenless Authentications'
							count={2}
							prevCount={60}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box py={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Authentications' dataSet={datasetsAuthentications} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Authorizations' dataSet={datasetsAuthorizations} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Verifications' dataSet={datasetsVerifications} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Registrations' dataSet={datasetsRegistrations} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Factors reset' dataSet={datasetsFactorsReset} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Tokenless Authentications' dataSet={datasetsTokenlessAuthentications} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Show passwords' dataSet={datasetsShowPasswords} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Set geofence' dataSet={datasetsSetGeofence} />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
