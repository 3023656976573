//This helper uses relative luminance calculation and gamma correction algorithms
//to determine the best contrasting color for a given background color

interface RGBColor {
	r: number;
	g: number;
	b: number;
}

const GAMMA_THRESHOLD = 0.03928;
const GAMMA_FACTOR = 12.92;
const WEIGHT_RED = 0.2126;
const WEIGHT_GREEN = 0.7152;
const WEIGHT_BLUE = 0.0722;
const GAMMA_THRESHOLD_ADDITION = 0.055;
const GAMMA_THRESHOLD_DIVISOR = 1.055;
const GAMMA_EXPONENT = 2.4;

export const hexToRgb = (hex: string): RGBColor => {
	const r = parseInt(hex.substring(1, 3), 16);
	const g = parseInt(hex.substring(3, 5), 16);
	const b = parseInt(hex.substring(5, 7), 16);

	return { r, g, b };
};

const componentToHex = (component: number) => {
	const hex = component.toString(16);

	return hex.length === 1 ? `0${hex}` : hex;
};

export const rgbToHex = (r: number, g: number, b: number) => {
	return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

const adjustGamma = (value: number): number => {
	if (value <= GAMMA_THRESHOLD) {
		return value / GAMMA_FACTOR;
	}

	return Math.pow((value + GAMMA_THRESHOLD_ADDITION) / GAMMA_THRESHOLD_DIVISOR, GAMMA_EXPONENT);
};

const calculateRelativeLuminance = (color: RGBColor): number => {
	const { r, g, b } = color;
	const red = adjustGamma(r / 255);
	const green = adjustGamma(g / 255);
	const blue = adjustGamma(b / 255);

	return WEIGHT_RED * red + WEIGHT_GREEN * green + WEIGHT_BLUE * blue;
};

export const getContrastColor = (background: string | undefined): string | undefined => {
	if (background === undefined) {
		return;
	}

	const RGBbackground = hexToRgb(background);

	// Calculate relative luminance of the background color
	const bgLuminance = calculateRelativeLuminance(RGBbackground);

	// Choose text color based on background luminance
	let textColor: RGBColor;
	if (bgLuminance > 0.5) {
		// Light background
		textColor = {
			r: Math.max(0, RGBbackground.r - 170),
			g: Math.max(0, RGBbackground.g - 170),
			b: Math.max(0, RGBbackground.b - 170),
		};
	} else {
		// Dark background
		textColor = {
			r: Math.min(255, RGBbackground.r + 170),
			g: Math.min(255, RGBbackground.g + 170),
			b: Math.min(255, RGBbackground.b + 170),
		};
	}

	return rgbToHex(textColor.r, textColor.g, textColor.b);
};
