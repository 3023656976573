import * as React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { CountUp } from '../CountUp/CountUp';
import { DeviceCountCardProps } from './types';

export const DeviceCountCard: React.FC<DeviceCountCardProps> = ({ count, total, image, title }) => {
	return (
		<Card>
			<CardActionArea>
				<CardContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography variant='h4'>
								<CountUp value={count} duration={3000} />
							</Typography>
							<Typography variant='h6' color='text.secondary'>
								&nbsp;/&nbsp;
							</Typography>
							<Typography variant='h6' color='text.secondary'>
								{total}
							</Typography>
						</Box>
						<Typography variant='caption' color={green.A700}>
							online
						</Typography>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{image && (
								<Box
									component='img'
									src={image}
									alt={title}
									sx={{
										height: 24,
										width: 24,
										marginRight: 1,
									}}
								/>
							)}
							<Typography variant='subtitle1' component='div'>
								{title}
							</Typography>
						</Box>
					</Box>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
