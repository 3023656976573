import { EPermission } from '../enums/permission/EPermission';

export const acl = (userPermissions: EPermission[], expectedPermissions: string[], strictMode = true) => {
	if (!Array.isArray(expectedPermissions) || expectedPermissions.length === 0) {
		return false;
	}

	if (expectedPermissions.length === 1 && expectedPermissions[0].toLowerCase() === EPermission.ALL) {
		return true;
	}

	const userPermissionNames = userPermissions.map((permission) => permission.toLowerCase());
	const expectedPermissionNames = expectedPermissions.map((permission) => permission.toLowerCase());

	return strictMode ?
			expectedPermissionNames.every((permission) => userPermissionNames.includes(permission))
		:	expectedPermissionNames.some((permission) => userPermissionNames.includes(permission));
};
