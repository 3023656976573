export enum ESetupSteps {
	SERVER_SETUP = 'SERVER_SETUP',
	CSR = 'CSR',
	SMTP = 'SMTP',
	SMTP_VERIFY = 'SMTP_VERIFY',
	IDENTITY_STORE = 'IDENTITY_STORE',
	IDENTITY_STORE_AUTHENTICATION = 'IDENTITY_STORE_AUTHENTICATION',
	DEPLOYMENT_FINALIZATION = 'DEPLOYMENT_FINALIZATION',
	SETUP_COMPLETED = 'SETUP_COMPLETED',
}
