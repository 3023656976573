import * as React from 'react';
import { Box, Paper, Typography, Stack, Grid, useMediaQuery, useTheme, Avatar } from '@mui/material';
import { PermIdentity as PermIdentityIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/AuthContext/AuthContext';
import { useFormatDate } from '../../hooks/useFormatDate';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { GetUserResponseDto, GroupDto } from '../../api/Api';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useParams } from 'react-router-dom';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { userSchema } from './schema';
import { RenderChip } from '../../components/RenderChip/RenderChip';
import { useACL } from '../../hooks/useACL';
import { EPermission } from '../../enums/permission/EPermission';
import { useNavigate } from '../../hooks/useNavigate';
import { ChipArray } from '../../components/ChipArray/ChipArray';

export const UserDetailPage: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const authContext = React.useContext(AuthContext);
	const { userID } = useParams();
	const userIDtoFetch = userID || authContext.user.user?.id;
	const { t } = useTranslation();
	const theme = useTheme();
	const formatDate = useFormatDate();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const { isAllowed } = useACL();
	const navigate = useNavigate();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const { data, isError, isRefetching, isLoading, error } = useQuery<GetUserResponseDto>({
		queryKey: [EQueryKey.USER_DETAIL_QUERY, userID],
		queryFn: async () => {
			try {
				const response = await api.users.me();
				userSchema.parse(response.data);

				return response.data;
			} catch (error) {
				console.error('API Error:', error);

				return {} as GetUserResponseDto;
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const handleOnChipClick = React.useCallback(
		(group: GroupDto) => {
			if (!isAllowed([EPermission.USER_GROUPS_READ])) {
				return;
			}
			navigate(`/users/userGroups/${group.id}`);
		},
		[isAllowed],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper
				elevation={3}
				sx={{
					paddingBottom: 2,
				}}
			>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.users.detail.title')}
						description={t('page.users.detail.description')}
						icon={PermIdentityIcon}
					/>
					{data && (
						<Stack
							spacing={3}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
								<Avatar alt={`${data.name} ${data.surname}`} sx={{ height: '60px', width: '60px' }} />
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Typography variant='h6' px={1} sx={{ fontWeight: 'bold' }}>
										{`${data.name} ${data.surname}`}
									</Typography>
									{/* <Typography variant='body1' px={1}>
										{data.roleName}
									</Typography> */}
								</Box>
							</Box>

							<Stack spacing={1}>
								<Grid container gap={2}>
									{data.name && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.users.detail.body.name')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography variant='body1' px={2}>
														{`${data.name}`}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{data.surname && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.users.detail.body.surname')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography variant='body1' px={2}>
														{data.surname}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{data.email && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.users.detail.body.email')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography variant='body1' px={2}>
														{data.email}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{data.phoneNumber && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.users.detail.body.phoneNumber')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography variant='body1' px={2}>
														{data.phoneNumber}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{data.countryCode && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.users.detail.body.countryCode')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography variant='body1' px={2}>
														{data.countryCode}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{data.createdAt && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.users.detail.body.createdAt')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography variant='body1' px={2}>
														{formatDate(data.createdAt, true)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{data.lastLoggedAt && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.users.detail.body.lastLoggedAt')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography variant='body1' px={2}>
														{formatDate(data.lastLoggedAt, true)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{/* {data.status && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.users.detail.body.status')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Box px={2}>
														<RenderChip value={data.status} perspective='userPerspective' />
													</Box>
												</Grid>
											</Grid>
										</Grid>
									)} */}
									{/* {data.userGroups && data.userGroups.length > 0 && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															height: '100%',
														}}
													>
														<Typography variant='body1' px={1} sx={{ fontWeight: 'bold' }}>
															{`${t('page.users.detail.body.userGroups')}: `}
														</Typography>
													</Box>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															height: '100%',
														}}
													>
														<Box px={2}>
															<ChipArray
																chipList={
																	isAllowed([EPermission.USER_GROUPS_READ]) ?
																		data.userGroups
																	:	undefined
																}
																limitTags={4}
																onChipClick={handleOnChipClick}
																wrap={matchesLG}
															/>
														</Box>
													</Box>
												</Grid>
											</Grid>
										</Grid>
									)} */}
								</Grid>
							</Stack>
						</Stack>
					)}
				</Stack>
			</Paper>
		</Box>
	);
};
