import * as React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

import { PageHeaderProps } from './types';
import { SEO } from '../SEO/SEO';

export const PageHeader: React.FC<PageHeaderProps> = ({ title, description, icon: Icon }): JSX.Element => {
	return (
		<Stack spacing={1}>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
				{Icon && <Icon fontSize={'medium'} />}
				<Typography variant='h6'>{title}</Typography>
			</Box>
			{description && (
				<Typography variant='body2' sx={{ paddingX: '10px' }}>
					{description}
				</Typography>
			)}
			<SEO title={title} description={description} />
			<Divider sx={{ paddingY: '10px' }} />
		</Stack>
	);
};
