import * as React from 'react';
import {
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Chip,
	Box,
	IconButton,
	Button,
	FormHelperText,
	SelectChangeEvent,
	Typography,
	Tooltip,
} from '@mui/material';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { FactorRuleProps, FactorItem } from './types';
import { BIOMETRY_TYPE, PIN_TYPE } from './constants';

export const FactorRule: React.FC<FactorRuleProps> = (props): JSX.Element => {
	const { control, name, intialFactors } = props;
	const initializedRef = React.useRef(false);
	const { t } = useTranslation();
	const FACTORS: FactorItem[] = React.useMemo(() => {
		return [
			{ key: PIN_TYPE, label: t('page.ruleSet.form.fields.rules.factors.factor.options.pinCode') },
			{ key: BIOMETRY_TYPE, label: t('page.ruleSet.form.fields.rules.factors.factor.options.phoneBiometry') },
		];
	}, [t]);

	const { fields, append, remove } = useFieldArray({
		control,
		name: `${name}.factors`,
	});

	React.useEffect(() => {
		if (fields && fields.length === 0 && !initializedRef.current) {
			if (intialFactors && intialFactors.length > 0) {
				intialFactors.forEach((factor: string[]) => {
					append({ key: factor });
				});
			} else {
				append({ key: [] });
			}
			initializedRef.current = true;
		}
	}, [append, intialFactors]);

	const validateFactor = React.useCallback(
		(value: string[]) => {
			return value && value.length > 0 ? undefined : t('page.ruleSet.form.errorMessage.noFactorSelected');
		},
		[t],
	);

	const watchedFactors = useWatch({
		control,
		name: `${name}.factors`,
	});

	const shouldRenderAddFactorButton = React.useMemo(() => {
		if (!fields) {
			return true;
		}
		if (fields.length >= FACTORS.length) {
			return false;
		}
		if (!watchedFactors) {
			return true;
		}
		let hasPinType = false;
		let hasBiometryType = false;
		if (watchedFactors && watchedFactors.length === FACTORS.length) {
			return false;
		}
		if (
			watchedFactors &&
			watchedFactors.length > 0 &&
			watchedFactors[watchedFactors.length - 1]?.key?.length === 0
		) {
			return false;
		}
		if (watchedFactors) {
			for (const factors of watchedFactors) {
				if (factors.key?.includes(PIN_TYPE)) {
					hasPinType = true;
				}
				if (factors.key?.includes(BIOMETRY_TYPE)) {
					hasBiometryType = true;
				}
			}
		}

		return !(hasPinType && hasBiometryType);
	}, [watchedFactors, FACTORS, fields]);

	return (
		<Box sx={{ flex: 1 }}>
			{fields.map((field, index) => (
				<Box
					key={field.id}
					sx={{
						position: 'relative',
						display: 'flex',
						alignItems: 'flex-start',
						flexWrap: { xs: 'wrap', sm: 'nowrap' },
						gap: 2,
						mb: 2,
						maxWidth: '100%',
						justifyContent: 'space-between',
					}}
				>
					{index > 0 && (
						<Box
							sx={{
								position: { xs: 'relative', sm: 'absolute' },
								top: 0,
								bottom: 0,
								left: { xs: 0, sm: '-50px' },
								width: { xs: '100%', sm: 'auto' },
							}}
						>
							<Typography variant='body1' sx={{ marginY: '17px' }}>
								{t('page.ruleSet.form.body.or')}
							</Typography>
						</Box>
					)}
					<Controller
						name={`${name}.factors.${index}.key`}
						control={control}
						rules={{ validate: validateFactor }}
						render={({ field: { value, onChange, ...restField }, fieldState }) => (
							<FormControl
								fullWidth
								variant='outlined'
								error={!!fieldState.error}
								sx={{ flex: '1 1 180px' }}
							>
								<InputLabel id={`${name}-label`}>
									{t('page.ruleSet.form.fields.rules.factors.factor.label')}
								</InputLabel>
								<Select
									labelId={`${name}-label`}
									id={`${name}-select`}
									multiple
									value={value || []}
									onChange={(event: SelectChangeEvent<any>) => {
										onChange(event.target.value);
									}}
									{...restField}
									label={t('page.ruleSet.form.fields.rules.factors.factor.label')}
									sx={{ height: '56px', overflow: 'hidden', minWidth: '180px' }}
									renderValue={(selected) => {
										return (
											<Box
												sx={{
													display: 'flex',
													flexWrap: 'wrap',
													gap: 0.5,
													height: '32px',
													overflow: 'hidden',
													alignItems: 'center',
												}}
											>
												{selected.map((value: string) => (
													<Chip
														key={value}
														label={FACTORS.find((factor) => factor.key === value)?.label}
														sx={{
															textOverflow: 'ellipsis',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
														}}
													/>
												))}
											</Box>
										);
									}}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 200,
											},
										},
									}}
								>
									{FACTORS.map((factor) => (
										<MenuItem key={factor.key} value={factor.key}>
											{factor.label}
										</MenuItem>
									))}
								</Select>
								{fieldState.error && typeof fieldState.error.message === 'string' ?
									<FormHelperText error id={`${name}-error`}>
										{fieldState.error.message}
									</FormHelperText>
								:	<FormHelperText>
										{t('page.ruleSet.form.fields.rules.factors.factor.helperText')}
									</FormHelperText>
								}
							</FormControl>
						)}
					/>
					<Tooltip
						title={t('page.ruleSet.form.tooltips.removeFactor')}
						placement='top'
						enterDelay={500}
						arrow
					>
						<IconButton onClick={() => remove(index)} sx={{ flex: '0 1 34px', marginY: '10px' }}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</Box>
			))}
			{shouldRenderAddFactorButton && (
				<Tooltip title={t('page.ruleSet.form.tooltips.addFactor')} placement='top' enterDelay={500} arrow>
					<Button onClick={() => append({ key: [] })} startIcon={<AddIcon />} sx={{ marginY: '10px' }}>
						{t('page.ruleSet.form.body.addFactor')}
					</Button>
				</Tooltip>
			)}
		</Box>
	);
};
