const circleOptions: google.maps.CircleOptions = {
	fillColor: 'coral',
	fillOpacity: 0.3,
	strokeWeight: 2,
	strokeColor: 'coral',
	clickable: true,
	editable: false,
	zIndex: 1,
};

const circleSystemOptions: google.maps.CircleOptions = {
	...circleOptions,
	fillColor: 'darkGreen',
	strokeColor: 'darkGreen',
};

const mapOptions: google.maps.MapOptions = {
	mapId: 'b181cac70f27f5e6',
	disableDefaultUI: false,
	clickableIcons: false,
	fullscreenControl: true,
	streetViewControl: false,
	draggable: true,
	zoomControlOptions: { position: 8 },
	restriction: {
		latLngBounds: {
			north: 85,
			south: -85,
			west: -180,
			east: 180,
		},
		strictBounds: true,
	},
};

export { circleOptions, circleSystemOptions, mapOptions };
