// src/utils/RenderChip.tsx

import * as React from 'react';
import { Chip } from '@mui/material';
import { ChipValue } from './types';
import { EInvitationStatus } from '../../api/Api';
import { t } from 'i18next';

export const RenderChip: React.FC<ChipValue> = ({ value, perspective, ...props }) => {
	const renderChip = React.useCallback((value?: any) => {
		if (!value || value === 'undefined') {
			return;
		}
		let stringValue: string | undefined;

		if (typeof value === 'string') {
			stringValue = value;
		}
		if (typeof value !== 'string' && value?.props) {
			const key = Object.keys(value.props)[0];
			if (!key) {
				return;
			}
			stringValue = value.props[key];
		}

		const labels = {
			[EInvitationStatus.Sent]: t(`page.invitations.table.invitationStatus.${perspective}.sent`),
			[EInvitationStatus.Pending]: t(`page.invitations.table.invitationStatus.${perspective}.pending`),
			[EInvitationStatus.Dismissed]: t(`page.invitations.table.invitationStatus.${perspective}.dismissed`),
			[EInvitationStatus.Failed]: t(`page.invitations.table.invitationStatus.${perspective}.failed`),
			[EInvitationStatus.Accepted]: t(`page.invitations.table.invitationStatus.${perspective}.accepted`),
			[EInvitationStatus.Blocked]: t(`page.invitations.table.invitationStatus.${perspective}.blocked`),
		};

		switch (stringValue) {
			case undefined:
				return;
			case EInvitationStatus.Sent:
				return <Chip label={labels[EInvitationStatus.Sent]} color='success' />;
			case EInvitationStatus.Pending:
				return <Chip label={labels[EInvitationStatus.Pending]} sx={{ bgcolor: '#90caf9', color: 'white' }} />;
			case EInvitationStatus.Dismissed:
				return <Chip label={labels[EInvitationStatus.Dismissed]} color='error' />;
			case EInvitationStatus.Blocked:
				return <Chip label={labels[EInvitationStatus.Blocked]} color='error' />;
			case EInvitationStatus.Failed:
				return <Chip label={labels[EInvitationStatus.Failed]} color='error' />;
			case EInvitationStatus.Accepted:
				return <Chip label={labels[EInvitationStatus.Accepted]} color='success' />;
			case 'verified':
				return (
					<Chip
						label={t(`page.invitations.table.invitationStatus.${perspective}.verified`)}
						color='success'
					/>
				);
			default:
				return <Chip label={value} color='error' />;
		}
	}, []);

	return <div>{renderChip(value)}</div>;
};
