import * as React from 'react';
import { Slider, SliderMarkLabel, Avatar } from '@mui/material';
import { ClickableSliderMarkLabelProps, CustomSliderProps, ProgressProps } from './types';
import { OverrideProps } from '@mui/material/OverridableComponent';

const ClickableSliderMarkLabel: React.FC<ClickableSliderMarkLabelProps> = (props) => {
	const { labelOnChange, ...restProps } = props;

	const stopPropagation = React.useCallback((event: React.SyntheticEvent) => {
		event.stopPropagation();
	}, []);

	const handleOnClick = React.useCallback(
		(event: React.MouseEvent<Element, MouseEvent>) => {
			event.preventDefault();
			event.stopPropagation();

			if (!props || !props.ownerState.marks) {
				return;
			}

			const markId: number = parseInt(props['data-index']);
			const markValue = props.ownerState.marks[markId]?.value;

			if (markValue !== null) {
				labelOnChange(event, markValue);
			}
		},
		[labelOnChange, props],
	);

	return (
		<SliderMarkLabel
			onTouchStart={stopPropagation}
			onMouseDown={stopPropagation}
			onClick={handleOnClick}
			{...restProps}
		/>
	);
};

const CustomSlider = React.forwardRef(function CustomSlider(
	props: OverrideProps<CustomSliderProps, 'span'>,
	ref: React.Ref<any>,
) {
	return <Slider ref={ref} {...props} />;
});

export const Progress: React.FC<ProgressProps> = ({
	currentValue,
	maxValue,
	disabled,
	userInput,
	onChangeProgress,
}) => {
	const handleSliderChange = React.useCallback(
		(event: Event | React.MouseEvent<Element, MouseEvent>, newValue: number | number[]): void => {
			if (typeof newValue === 'number') {
				onChangeProgress(newValue);
			}
		},
		[onChangeProgress],
	);

	const marks = React.useMemo(() => {
		if (!userInput) {
			return [];
		}

		return userInput.map((input) => ({
			value: input.position * 1000,
			label: (
				<Avatar
					sx={{
						width: 20,
						height: 20,
						backgroundColor: '#fff',
						color: 'black',
						fontSize: 14,
						fontWeight: 800,
					}}
				>
					{input.id + 1}
				</Avatar>
			),
		}));
	}, [userInput]);

	return (
		<CustomSlider
			aria-label='volume-indicator'
			size='small'
			value={currentValue}
			min={0}
			max={maxValue}
			disabled={disabled}
			onChange={handleSliderChange}
			marks={marks}
			components={{
				MarkLabel: ClickableSliderMarkLabel,
			}}
			componentsProps={{
				markLabel: {
					labelOnChange: handleSliderChange,
				},
			}}
			sx={{
				margin: '0 10px',
				color: '#fff',
				height: 4,
				width: '100%',
				'& .MuiSlider-thumb': {
					width: 8,
					height: 8,
					transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
					'&::before': {
						boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
					},
					'&:hover, &.Mui-focusVisible': {
						boxShadow: '0px 0px 0px 8px rgb(255 255 255 / 16%)',
					},
					'&.Mui-active': {
						width: 20,
						height: 20,
					},
				},
				'& .MuiSlider-rail': {
					opacity: 0.28,
				},
				'& .MuiSlider-markLabel': {
					top: -10,
				},
			}}
		/>
	);
};
