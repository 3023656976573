import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import { CertificateSkeletonProps } from './types';

export const CertificateSkeleton: React.FC<CertificateSkeletonProps> = ({ listsToRender }) => {
	return (
		<Box>
			<Skeleton
				variant='text'
				sx={{
					width: '30%',
					fontSize: '2rem',
				}}
			/>

			{Array.from({ length: listsToRender }).map((_, index) => (
				<Skeleton
					key={index}
					variant='text'
					width='inherit'
					style={{
						fontSize: '1rem',
					}}
				/>
			))}
		</Box>
	);
};

export default CertificateSkeleton;
