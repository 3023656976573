import { EMailSMTPSecurity } from '../../api/Api';

const smtpSecurityMap: Map<string, EMailSMTPSecurity> = new Map([
	['none', EMailSMTPSecurity.None],
	['starttls', EMailSMTPSecurity.Starttls],
	['tls', EMailSMTPSecurity.Tls],
]);

export function resolveEMailSMTPSecurity(security: string): EMailSMTPSecurity {
	const result = smtpSecurityMap.get(security.toLowerCase());

	if (!result) {
		throw new Error(`Invalid SMTP security mode: ${security}`);
	}

	return result;
}
