import * as React from 'react';
import {
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Chip,
	Box,
	IconButton,
	Button,
	TextField,
	Typography,
	FormHelperText,
	Tooltip,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Controller, useFieldArray } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import { GeofenceRuleProps } from './types';
import { useSwaggerApi } from '../../../../hooks/useSwaggerApi';
import { getTenantFromPath } from '../../../../utils/Routing';
import { useAuthContext } from '../../../../contexts/AuthContext/AuthContext';
import { GeofenceModel, GetGeofenceGroupByIDResponseDto } from '../../../../api/Api';

import { SEARCH_GEOFENSE_TYPE, SEARCH_GEOFENSE_GROUP_TYPE, DEFAULT_AUTOCOMPLETE_VALUE } from './constants';

export const GeofenceRule: React.FC<GeofenceRuleProps> = (props): JSX.Element => {
	const { t } = useTranslation();
	const { control, name, initialRuleSet } = props;
	const authContext = useAuthContext();
	const swaggerApi = useSwaggerApi();
	const [loading, setLoading] = React.useState(false);
	const initializedRef = React.useRef(false);
	const [geofences, setGeofences] = React.useState<{ name: string; id: number }[]>([]);
	const [geofenceGroups, setGeofenceGroups] = React.useState<{ name: string; id: number }[]>([]);
	const [currentType, setCurrentType] = React.useState<Record<number, string>>({});
	const tenantObject = getTenantFromPath();
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: `${name}.geofences`,
	});

	React.useEffect(() => {
		const fetchData = async () => {
			const query = {
				limit: 1000,
				offset: 0,
			};
			try {
				setLoading(true);
				let systemGeofences: GeofenceModel[] = [];
				let systemGeofenceGroups: GetGeofenceGroupByIDResponseDto[] = [];
				const [geofenceResponse, geofenceGroupResponse] = await Promise.all([
					swaggerApi.geofences.getGeofences(query),
					swaggerApi.geofenceGroups.getGeofenceGroups(query),
				]);
				if (authContext.userTenants.activeTenantID !== null) {
					const [systemGeofencesResponse, systemGeofenceGroupsResponse] = await Promise.all([
						swaggerApi.geofences.getSystemGeofences(query),
						swaggerApi.geofenceGroups.getSystemGeofenceGroups(query),
					]);
					systemGeofences = systemGeofencesResponse.data.entities;
					systemGeofenceGroups = systemGeofenceGroupsResponse.data.entities;
				}

				setGeofences([
					...systemGeofences.map((geofence) => ({ name: geofence.name, id: geofence.id })),
					...geofenceResponse.data.entities.map((geofence) => ({ name: geofence.name, id: geofence.id })),
				]);
				const combinedGeofenceGroups = [
					...geofenceGroupResponse.data.entities.map((geofenceGroup) => ({
						name: geofenceGroup.name,
						id: geofenceGroup.id,
					})),
					...systemGeofenceGroups.map((geofenceGroup) => ({
						name: geofenceGroup.name,
						id: geofenceGroup.id,
					})),
				];

				const uniqueGeofenceGroups = Array.from(
					new Map(combinedGeofenceGroups.map((item) => [item.id, item])).values(),
				);
				setGeofenceGroups([...uniqueGeofenceGroups]);
				setLoading(false);
			} catch (error) {
				console.error('error', error);
				setLoading(false);
			}
		};

		fetchData();
	}, [authContext]);

	const handleTypeChange = React.useCallback(
		(index: number, type: string) => {
			setCurrentType((prev) => ({ ...prev, [index]: type }));
			update(index, { type, items: [] });
		},
		[setCurrentType, update],
	);

	React.useEffect(() => {
		if (fields.length === 0 && !initializedRef.current) {
			if (
				(initialRuleSet && initialRuleSet.geofences.length > 0) ||
				(initialRuleSet && initialRuleSet.geofenceGroups.length > 0)
			) {
				initialRuleSet?.geofences.forEach((geofences, index) => {
					append({ type: SEARCH_GEOFENSE_TYPE, items: geofences });
					setCurrentType((prev) => ({ ...prev, [index]: SEARCH_GEOFENSE_TYPE }));
				});

				initialRuleSet?.geofenceGroups.forEach((geofenceGroups, index) => {
					append({ type: SEARCH_GEOFENSE_GROUP_TYPE, items: geofenceGroups });
					setCurrentType((prev) => ({
						...prev,
						[initialRuleSet.geofences.length + index]: SEARCH_GEOFENSE_GROUP_TYPE,
					}));
				});
			} else {
				append({ type: SEARCH_GEOFENSE_TYPE, items: [] });
				setCurrentType((prev) => ({ ...prev, [0]: SEARCH_GEOFENSE_TYPE }));
			}
			initializedRef.current = true;
		}
	}, [fields, append, initialRuleSet]);

	const handleAddGeofence = () => {
		const newIndex = fields.length;
		append({ type: SEARCH_GEOFENSE_TYPE, items: [] });
		setCurrentType((prev) => ({ ...prev, [newIndex]: SEARCH_GEOFENSE_TYPE }));
	};

	return (
		<Box sx={{ flex: 1 }}>
			{fields.map((field, index) => (
				<Box
					key={field.id}
					sx={{
						position: 'relative',
						display: 'flex',
						alignItems: 'flex-start',
						flexWrap: 'wrap',
						gap: 2,
						mb: 2,
						maxWidth: '100%',
						justifyContent: 'space-between',
					}}
				>
					{index > 0 && (
						<Box
							sx={{
								position: { xs: 'relative', md: 'absolute' },
								top: 0,
								bottom: 0,
								left: { xs: 0, md: '-50px' },
								display: 'flex',
								width: { xs: '100%', md: 'auto' },
							}}
						>
							<Typography variant='body1' sx={{ marginY: '17px' }}>
								{t('page.ruleSet.form.body.or')}
							</Typography>
						</Box>
					)}
					<Controller
						name={`${name}.geofences.${index}.type`}
						control={control}
						render={({ field, fieldState }) => (
							<FormControl
								fullWidth
								variant='outlined'
								error={!!fieldState.error}
								sx={{ flex: '1 1 180px', minWidth: '180px' }}
							>
								<InputLabel id={`${name}-type-label`}>
									{t('page.ruleSet.form.fields.rules.geofence.typeSelect.label')}
								</InputLabel>
								<Select
									labelId={`${name}-type-label`}
									id={`${name}-type-select`}
									value={field.value || ''}
									onChange={(e) => {
										field.onChange(e.target.value);
										handleTypeChange(index, e.target.value);
									}}
									label={t('page.ruleSet.form.fields.rules.geofence.typeSelect.label')}
								>
									<MenuItem value={SEARCH_GEOFENSE_TYPE}>
										{t('page.ruleSet.form.fields.rules.geofence.typeSelect.options.geofences')}
									</MenuItem>
									<MenuItem value={SEARCH_GEOFENSE_GROUP_TYPE}>
										{t('page.ruleSet.form.fields.rules.geofence.typeSelect.options.groups')}
									</MenuItem>
								</Select>
								{fieldState.error && typeof fieldState.error.message === 'string' ?
									<FormHelperText error id={`${name}-error`}>
										{fieldState.error.message}
									</FormHelperText>
								:	<FormHelperText>
										{t('page.ruleSet.form.fields.rules.geofence.typeSelect.helperText')}
									</FormHelperText>
								}
							</FormControl>
						)}
					/>
					<Controller
						name={`${name}.geofences.${index}.items`}
						control={control}
						rules={{
							validate: (value) =>
								(value && value.length > 0) ||
								t('page.ruleSet.form.fields.rules.geofence.item.helperText.minLength'),
						}}
						render={({ field, fieldState }) => (
							<Autocomplete
								multiple
								options={currentType[index] === SEARCH_GEOFENSE_TYPE ? geofences : geofenceGroups}
								loading={loading}
								sx={{ flex: '1 1 180px', minWidth: '180px' }}
								value={field.value || DEFAULT_AUTOCOMPLETE_VALUE}
								disabled={!currentType[index]}
								onChange={(_, newValue) => field.onChange(newValue)}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={(params) => (
									<TextField
										{...params}
										variant='outlined'
										label={t('page.ruleSet.form.fields.rules.geofence.item.label')}
										placeholder={t('page.ruleSet.form.fields.rules.geofence.item.placeholder')}
										error={!!fieldState.error}
										helperText={
											fieldState.error && typeof fieldState.error.message === 'string' ?
												<Typography component='span' color='error' sx={{ fontSize: 12 }}>
													{fieldState.error.message}
												</Typography>
											:	t('page.ruleSet.form.fields.rules.geofence.item.helperText.main')
										}
									/>
								)}
								renderTags={(value: { name: string; id: number }[], getTagProps) => {
									return value.map((option: { name: string; id: number }, index: number) => (
										<Chip
											label={option.name}
											{...getTagProps({ index })}
											key={`option-${option.id}-${index}`}
											sx={{
												maxWidth: '100px',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
											}}
										/>
									));
								}}
							/>
						)}
					/>
					<Tooltip
						title={t('page.ruleSet.form.tooltips.removeGeofence')}
						placement='top'
						enterDelay={500}
						arrow
					>
						<IconButton onClick={() => remove(index)} sx={{ flex: '0 1 34px', marginY: '10px' }}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</Box>
			))}
			<Tooltip title={t('page.ruleSet.form.tooltips.addGeofence')} placement='top' enterDelay={500} arrow>
				<Button onClick={handleAddGeofence} startIcon={<AddIcon />} sx={{ marginY: '10px' }}>
					{t('page.ruleSet.form.body.addGeofence')}
				</Button>
			</Tooltip>
		</Box>
	);
};
