import * as React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';
import { ChartLineProps } from './types';

export const ChartLine: React.FC<ChartLineProps> = ({ title, dataSet }) => {
	ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

	const options = {
		responsive: true,
		tension: 0.3,
	};

	const data = {
		labels: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		],
		datasets: dataSet,
	};

	return (
		<Card>
			<CardContent>
				<Stack direction='column' spacing={2}>
					<Typography align='center'>{title}</Typography>

					<Line data={data} options={options} />
				</Stack>
			</CardContent>
		</Card>
	);
};
