import * as React from 'react';
import Button from '@mui/material/Button';
import { Card, CardActions, CardContent, Chip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import humanizeDuration from 'humanize-duration';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
import { AxiosError } from 'axios';

import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { ECertificateType } from '../../enums/saml/ECertificateType';
import { CertificateProps } from './types';

export const Certificate: React.FC<CertificateProps> = ({
	title,
	subject,
	issuer,
	algorithm,
	fingerprint,
	expiration,
	type,
	content,
}) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const { i18n } = useTranslation();
	const { language } = i18n;
	const [certificateContent, setCertificateContent] = React.useState('');

	const api = useSwaggerApi();

	const isValid = (expiration: Date): boolean => {
		const now = new Date();
		const expiresIn = expiration.getTime() - now.getTime();

		const isValid = expiresIn > 0;

		return isValid;
	};

	const getHumanizedExpiration = (expiration: Date, language: string): string => {
		const now = new Date();
		const expirationDate = expiration.toLocaleString(language);
		const expiresIn = expiration.getTime() - now.getTime();
		const isValid = expiresIn > 0;

		const humanized = humanizeDuration(expiresIn, {
			language,
			fallbacks: ['en'],
			round: true,
			largest: 2,
			units: ['y', 'mo', 'd', 'h', 'm', 's'],
			conjunction: ' and ', // TODO Localize
			serialComma: false,
		});

		// TODO Localize 'valid for' and 'expired before'
		const result = `${expirationDate} (${isValid ? 'valid for' : 'expired before'} ${humanized})`;

		return result;
	};

	const downloadCertificate = async () => {
		try {
			setIsLoading(true);

			let content: string;
			let filename: string;

			if (type === ECertificateType.SIGNIN) {
				content = (await api.saml.downloadSigningCertificate({ format: 'blob' })).data;
				filename = 'excalibur-saml-idp-signing-certificate.crt';
			} else if (type === ECertificateType.ENCRYPTION) {
				content = (await api.saml.downloadEncryptionCertificate({ format: 'blob' })).data;
				filename = 'excalibur-saml-idp-encryption-certificate.crt';
			} else if (type === ECertificateType.DEPLOYMENT) {
				content = (await api.settings.downloadDeploymentCertificate({ format: 'blob' })).data;
				filename = 'excalibur-deployment-certificate.crt';
			} else {
				enqueueSnackbar('Invalid certificate type', {
					variant: 'error',
					persist: false,
				});

				return;
			}

			const url = window.URL.createObjectURL(new Blob([content], { type: 'application/x-pem-file' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			link.click();
			window.URL.revokeObjectURL(url); // release to prevent memory leaks
		} catch (error: unknown) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		setCertificateContent(content);
	}, [content]);

	const copyCertificateContent = () => {
		copy(certificateContent);
		enqueueSnackbar('Copied to clipboard!');
	};

	return (
		<Card>
			<CardContent>
				<Stack
					direction='row'
					spacing={1}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Typography
						variant='h6'
						sx={{
							paddingBottom: 1,
						}}
					>
						{title}
					</Typography>

					{isValid(expiration) ?
						<Chip label='Valid' color='success' variant='outlined' />
					:	<Chip label='Invalid' color='error' variant='outlined' />}
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography variant='body2' color='text.primary'>
						Fingerprint:
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						{fingerprint.slice(0, 65)}
					</Typography>
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography variant='body2' color='text.primary'>
						Subject:
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						{subject}
					</Typography>
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography variant='body2' color='text.primary'>
						Issuer:
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						{issuer}
					</Typography>
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography variant='body2' color='text.primary'>
						Algorithm:
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						{algorithm}
					</Typography>
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography variant='body2' color='text.primary'>
						Expiration:
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						{getHumanizedExpiration(expiration, language)}
					</Typography>
				</Stack>
			</CardContent>
			<CardActions
				sx={{
					justifyContent: 'flex-end',
				}}
			>
				<Button size='small' color='primary' startIcon={<ContentCopyIcon />} onClick={copyCertificateContent}>
					Copy
				</Button>

				<LoadingButton
					size='small'
					color='primary'
					loading={isLoading}
					loadingPosition='start'
					startIcon={<FileDownloadIcon />}
					onClick={downloadCertificate}
				>
					Download
				</LoadingButton>
			</CardActions>
		</Card>
	);
};
