import * as React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { Map as MapIcon } from '@mui/icons-material';
import { Box, Stack, Paper, FormGroup, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getMapSettingsSchema } from '../schema';
import { Preloader } from '../../../components/Preloader/Preloader';
import { FormValues, SettingState, SubmitFormState } from './types';
import { EPermission } from '../../../enums/permission/EPermission';
import { useACL } from '../../../hooks/useACL';
import { Heading } from '../../../components/Heading/Heading';
import { useGeofenceContext } from '../../../hooks/useGeofenceContext';
import { SwitchField } from '../../../components/FormFields/Switch/Switch';
import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { usePreviousValue } from '../../../hooks/usePreviousValue';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';

export const MapSettingPage: React.FC = (): JSX.Element => {
	const { handleOnLoadGoogleApiKey, googleMapsApiKey, enabledPlacesApi } = useGeofenceContext();
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const { isAllowed } = useACL();
	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [open, setOpen] = React.useState(false);
	const [settingState, setSettingState] = React.useState<SettingState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
		control,
		watch,
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getMapSettingsSchema(t)),
	});

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await api.settings.getMapSettingsProperties();
				setSettingState({
					loading: false,
					loaded: true,
					data: {
						googleApiKey: response.data.googleApiKey ?? '',
						enabledPlacesApi: response.data.enabledPlacesApi ?? false,
					},
					error: null,
				});

				reset({
					googleApiKey: response.data.googleApiKey ?? '',
					enabledPlacesApi: response.data.enabledPlacesApi ?? false,
				});
			} catch (error) {
				console.error(error);
			}
		};
		fetchData();
	}, []);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !settingState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || settingState.loading)) {
			setShouldDisableForm(true);
		}
	}, [settingState, submitFormState, shouldDisableForm]);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const handleClose = React.useCallback(() => {
		reset({ enabledPlacesApi: false });
		setOpen(false);
	}, []);

	const handleOnConfirmPlacesApi = React.useCallback(() => {
		reset({ enabledPlacesApi: true });
		setOpen(false);
	}, []);
	const previousEnabledPlacesApi = usePreviousValue(watch('enabledPlacesApi'));
	React.useEffect(() => {
		if (previousEnabledPlacesApi === false && watch('enabledPlacesApi')) {
			setOpen(true);
		}
	}, [watch('enabledPlacesApi'), previousEnabledPlacesApi]);

	const handleOnSubmit = React.useCallback(
		async (formValues: FormValues) => {
			if (submitFormState.submitting || !isAllowed([EPermission.SETTINGS_GENERAL_UPDATE])) {
				return;
			}
			try {
				await api.settings.updateMapSettingsProperties({
					googleKey: formValues.googleApiKey,
					enabledPlacesApi: formValues.enabledPlacesApi,
				});
				if (formValues.googleApiKey !== googleMapsApiKey || formValues.enabledPlacesApi !== enabledPlacesApi) {
					await handleOnLoadGoogleApiKey();
				}
				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
				enqueueSnackbar(t('page.mapSettings.edit.actionMessages.success'), {
					variant: 'success',
					persist: false,
				});
			} catch (error) {
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});

				console.error(error);
			}
		},
		[
			api.settings,
			enqueueSnackbar,
			setSubmitFormState,
			isAllowed,
			googleMapsApiKey,
			handleOnLoadGoogleApiKey,
			enabledPlacesApi,
		],
	);

	return (
		<Box component={'form'} noValidate onSubmit={handleSubmit(handleOnSubmit)}>
			{settingState.loading || !settingState.loaded ?
				<Preloader />
			:	<Paper elevation={3}>
					<Stack
						spacing={3}
						sx={{
							padding: 2,
						}}
					>
						<PageHeader
							title={t('page.mapSettings.edit.title')}
							description={t('page.mapSettings.edit.description')}
							icon={MapIcon}
						/>
						<FormGroup>
							<Stack
								spacing={1}
								sx={{
									width: getStackWidth(),
								}}
							>
								<Heading label={t('page.mapSettings.edit.subtitle.mapSettings')} />
								<TextField
									name={'googleApiKey'}
									register={register}
									label={t('page.mapSettings.edit.form.googleKey.label')}
									error={errors.googleApiKey}
									disabled={shouldDisableForm}
									helperText={t('page.mapSettings.edit.form.googleKey.helperText')}
								/>

								<SwitchField
									name='enabledPlacesApi'
									control={control}
									label={t('page.mapSettings.edit.form.enabledPlacesApi.label')}
									error={errors.enabledPlacesApi}
									helperText={t('page.mapSettings.edit.form.enabledPlacesApi.helperText')}
								/>
							</Stack>
						</FormGroup>
					</Stack>

					{isAllowed([EPermission.SETTINGS_MAP_UPDATE]) && (
						<FloatingButtonSave
							type='submit'
							disabled={shouldDisableForm}
							ariaLabel={t('page.mapSettings.ariaLabel.saveSettings')}
							tooltipTitle={t('page.mapSettings.tooltips.saveSettings')}
						/>
					)}
				</Paper>
			}
			<ConfirmationDialog
				onClose={handleClose}
				open={open}
				onConfirm={handleOnConfirmPlacesApi}
				title={t('page.mapSettings.confirmation.placesApi.title')}
				text={t('page.mapSettings.confirmation.placesApi.text')}
				cancelText={t('page.mapSettings.confirmation.placesApi.cancel')}
				confirmText={t('page.mapSettings.confirmation.placesApi.confirm')}
			/>
		</Box>
	);
};
