import { z } from 'zod';
import { TFunction } from 'i18next';

export const roleListSchema = z.object({
	id: z.number(),
	isBuiltIn: z.boolean(),
	name: z.string(),
	description: z.string().optional(),
});

export const getRoleFormSchema = (t: TFunction) => {
	return z.object({
		name: z
			.string()
			.min(3, { message: t('page.roles.roleForm.name.minLength', { minLength: 3 }) })
			.max(64, { message: t('page.roles.roleForm.name.maxLength', { maxLength: 64 }) }),
		description: z
			.string()
			.max(255, { message: t('page.roles.roleForm.description.maxLength', { maxLength: 255 }) })
			.optional(),
	});
};
