import { TFunction } from 'i18next';
import { z } from 'zod';

export const userGroupListSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	totalUsers: z.number(),
	createdAt: z.string(),
	updatedAt: z.string(),
});

export const userListInGroupSchema = z.object({
	id: z.number(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	email: z
		.string()
		.min(1)
		.max(64)
		.refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)),
	groups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
				description: z.string().optional(),
			})
			.optional(),
	),
});

export const userListSchema = z.object({
	id: z.number(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	status: z.string(),
	email: z
		.string()
		.min(1)
		.max(64)
		.refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)),
	createdAt: z.string(),
	lastLoggedAt: z.string().optional(),
	groups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
				description: z.string().optional(),
			})
			.optional(),
	),
});

export const getUserGroupSchema = (t: TFunction) =>
	z.object({
		name: z
			.string()
			.min(3, { message: t('page.pamGroup.edit.form.name.Error', { minLength: 3 }) })
			.max(64, { message: t('page.pamGroup.edit.form.name.ErrorMaxLength', { maxLength: 64 }) }),
		description: z
			.string()
			.max(255, { message: t('page.pamGroup.edit.form.description.ErrorMaxLength', { maxLength: 255 }) })
			.optional(),
	});
