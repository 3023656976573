import { z } from 'zod';

export const tokenListSchema = z.object({
	id: z.number(),
	userID: z.number(),
	userTitle: z.string().optional(),
	userName: z.string(),
	userSurname: z.string().optional(),
	deviceName: z.string(),
	brand: z.string(),
	model: z.string(),
	systemName: z.string(),
	systemVersion: z.string(),
	createdAt: z.string(),
});
