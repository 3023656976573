export const enum EFieldVariant {
	OUTLINED = 'outlined',
	FILLED = 'filled',
	STANDARD = 'standard',
}

export const enum EFieldType {
	TEXT = 'text',
	PASSWORD = 'password',
	EMAIL = 'email',
	NUMBER = 'number',
	TEL = 'tel',
	URL = 'url',
}
