import * as React from 'react';
import { Paper, CircularProgress, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { PaperOwnProps } from '@mui/material/Paper';
import { ExcaliburLogo } from '../ExcaliburLogo/ExcaliburLogo';

import { useDeviceDetect } from '../../hooks/useDeviceDetect';
import { PreloaderProps } from './types';

const StyledPaper = styled(Paper)(({ percentageheight }: PaperOwnProps & { percentageheight: number }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	height: `${(window.innerHeight / 100) * percentageheight}px`,
}));

export const Preloader: React.FC<PreloaderProps> = (props): JSX.Element => {
	const device = useDeviceDetect();
	const percentageHeight = props.percentageHeight ? props.percentageHeight : 80;
	const avatarSize = React.useMemo(() => {
		const size = device === 'mobile' ? 0.15 : 0.22;

		return `${(window.innerHeight / 100) * percentageHeight * size}px`;
	}, [device]);

	return (
		<StyledPaper percentageheight={percentageHeight}>
			{props.showLogo && (
				<>
					<Box sx={{ mb: 2 }}>
						<ExcaliburLogo color={'black'} height={avatarSize} width={avatarSize} />
					</Box>
					<Typography sx={{ mb: 2 }} variant='h6'>
						Excalibur Enterprise
					</Typography>
				</>
			)}
			<Box sx={{ mb: 2 }}>
				<CircularProgress />
			</Box>
		</StyledPaper>
	);
};
