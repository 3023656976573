import React from 'react';

export default function AppStoreIcon() {
	return (
		<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 788.18 225.17'>
			<defs>
				<style>
					{`
      .cls-1 {
        fill: #1c1a16;
      }
      .cls-2 {
        fill: #fff;
      }
    `}
				</style>
			</defs>
			<path
				className='cls-1'
				d='M788.18,202.76a22.38,22.38,0,0,1-22.63,22.41H22.09A21.69,21.69,0,0,1,0,203.05v-181A21.65,21.65,0,0,1,22.09,0H765.55c12.55,0,22.52,9.54,22.52,22.08v181Z'
			/>
			<path
				className='cls-2'
				d='M172.59,111.3c-.17-19.09,15.64-28.39,16.36-28.82-8.95-13.06-22.83-14.84-27.71-15-11.65-1.22-23,7-28.88,7s-15.22-6.86-25.06-6.66c-12.68.21-24.55,7.55-31,18.94C62.83,110,72.84,144.16,85.7,163c6.44,9.2,14,19.48,23.8,19.11,9.62-.4,13.21-6.13,24.84-6.13s14.89,6.13,24.93,5.9c10.33-.17,16.83-9.25,23-18.53a75.76,75.76,0,0,0,10.55-21.47c-.25-.08-20.07-7.64-20.27-30.54'
			/>
			<path
				className='cls-2'
				d='M153.63,55.14c5.18-6.47,8.73-15.29,7.73-24.23-7.49.34-16.86,5.18-22.25,11.52-4.78,5.59-9.06,14.74-8,23.34,8.43.62,17.06-4.25,22.47-10.63'
			/>
			<path
				className='cls-2'
				d='M311.93,180.76H298.48l-7.37-23.18H265.5l-7,23.18h-13.1l25.4-78.86h15.66Zm-23-32.89-6.66-20.59q-1.07-3.15-4-14.85H278q-1.18,5-3.75,14.85l-6.56,20.59Z'
			/>
			<path
				className='cls-2'
				d='M377.22,151.62q0,14.5-7.84,22.93A22.92,22.92,0,0,1,352,182q-11.25,0-16.16-8.07h-.22v29.95H322.94v-61.3q0-9.12-.47-18.72h11.11l.71,9h.24q6.3-10.19,19.18-10.18a21.15,21.15,0,0,1,16.79,8q6.72,8,6.72,20.94m-12.87.46q0-8.29-3.74-13.69a12.93,12.93,0,0,0-11-5.61,13.1,13.1,0,0,0-8.49,3.1,14.44,14.44,0,0,0-5,8.14,16.47,16.47,0,0,0-.58,3.85v9.48a15.17,15.17,0,0,0,3.79,10.47,12.6,12.6,0,0,0,9.89,4.27,12.88,12.88,0,0,0,11.11-5.5q4-5.51,4-14.51'
			/>
			<path
				className='cls-2'
				d='M442.61,151.62q0,14.5-7.84,22.93A22.91,22.91,0,0,1,417.34,182q-11.22,0-16.13-8.07H401v29.95H388.34v-61.3q0-9.12-.47-18.72H399l.71,9h.24q6.29-10.19,19.18-10.18a21.14,21.14,0,0,1,16.79,8q6.72,8,6.71,20.94m-12.86.46q0-8.29-3.75-13.69a12.92,12.92,0,0,0-11-5.61,13.07,13.07,0,0,0-8.48,3.1,14.38,14.38,0,0,0-5,8.14,16,16,0,0,0-.59,3.85v9.48a15.13,15.13,0,0,0,3.8,10.47,12.56,12.56,0,0,0,9.89,4.27,12.89,12.89,0,0,0,11.11-5.5c2.64-3.67,4-8.5,4-14.51'
			/>
			<path
				className='cls-2'
				d='M515.74,158.64c0,6.7-2.32,12.16-7,16.38q-7.71,6.9-21.47,6.9-12.72,0-20.45-4.91l2.93-10.53a34.69,34.69,0,0,0,18.27,5q7.14,0,11.13-3.21a10.56,10.56,0,0,0,4-8.62,11,11,0,0,0-3.27-8.08c-2.18-2.19-5.82-4.22-10.89-6.1q-20.71-7.74-20.7-22.61a20,20,0,0,1,7.25-15.94q7.25-6.21,19.31-6.22,10.77,0,17.89,3.75l-3.15,10.3a31.05,31.05,0,0,0-15.1-3.63q-6.68,0-10.4,3.28a9.36,9.36,0,0,0-3.16,7.13,9.62,9.62,0,0,0,3.62,7.72q3.15,2.8,11.47,6.09,10.17,4.09,15,9.59a20.08,20.08,0,0,1,4.8,13.69'
			/>
			<path
				className='cls-2'
				d='M557.5,133.37H543.58V161q0,10.53,7.38,10.52a22.49,22.49,0,0,0,5.6-.58l.35,9.6a28.56,28.56,0,0,1-9.82,1.4q-7.49,0-11.7-4.56T531.18,162V133.37h-8.31v-9.49h8.31v-10.4l12.4-3.75v14.15H557.5Z'
			/>
			<path
				className='cls-2'
				d='M620.33,151.85q0,13.11-7.48,21.53Q605,182.05,592,182q-12.54,0-19.95-8.3t-7.43-20.93q0-13.25,7.66-21.65T593,122.72q12.51,0,20.13,8.31,7.26,8.08,7.25,20.82m-13.11.41a25.6,25.6,0,0,0-3.39-13.49q-4-6.81-11.34-6.81a12.64,12.64,0,0,0-11.58,6.81,26.08,26.08,0,0,0-3.39,13.73A25.57,25.57,0,0,0,580.91,166q4.08,6.8,11.47,6.8c4.82,0,8.61-2.3,11.33-6.92a25.6,25.6,0,0,0,3.51-13.61'
			/>
			<path
				className='cls-2'
				d='M661.39,135a22.82,22.82,0,0,0-4-.35,12,12,0,0,0-10.31,5A19,19,0,0,0,644,150.91v29.84H631.33l.11-38.95q0-9.84-.46-17.91h11l.46,10.89h.34a19.46,19.46,0,0,1,6.33-9,15.25,15.25,0,0,1,9.13-3,20,20,0,0,1,3.15.23Z'
			/>
			<path
				className='cls-2'
				d='M717.89,149.63a30.87,30.87,0,0,1-.45,5.73h-37.9q.21,8.43,5.49,12.88c3.19,2.65,7.33,4,12.4,4a42.07,42.07,0,0,0,15.33-2.68l2,8.77a47.54,47.54,0,0,1-19.08,3.52q-13.2,0-20.76-7.79t-7.54-20.88q0-12.87,7-21.42,7.36-9.12,19.88-9.11,12.29,0,18.62,9.11,5,7.26,5,17.91m-12-3.28a17.16,17.16,0,0,0-2.44-9.71c-2.19-3.51-5.56-5.26-10.08-5.26a12,12,0,0,0-10.05,5.15,18.63,18.63,0,0,0-3.73,9.82Z'
			/>
			<path
				className='cls-2'
				d='M284.22,53.38q0,10.47-6.26,15.75Q272.15,74,261.47,74a74.09,74.09,0,0,1-9.09-.47V35.45a67.46,67.46,0,0,1,10.69-.8q10.19,0,15.36,4.43,5.79,5,5.79,14.3m-6.54.18c0-4.53-1.21-8-3.6-10.41s-5.88-3.62-10.49-3.62a26.38,26.38,0,0,0-5,.41v29a32.7,32.7,0,0,0,4.19.17c4.75,0,8.43-1.31,11-4s3.9-6.49,3.9-11.55'
			/>
			<path
				className='cls-2'
				d='M318.94,59.48A15.33,15.33,0,0,1,315.26,70,13.12,13.12,0,0,1,305,74.31a12.47,12.47,0,0,1-9.8-4.09,14.83,14.83,0,0,1-3.65-10.28,15.21,15.21,0,0,1,3.77-10.64q3.75-4.14,10.15-4.13a12.71,12.71,0,0,1,9.87,4.08,14.65,14.65,0,0,1,3.58,10.23m-6.44.2a12.72,12.72,0,0,0-1.67-6.63,6.15,6.15,0,0,0-5.58-3.35,6.21,6.21,0,0,0-5.68,3.35,12.76,12.76,0,0,0-1.67,6.74,12.57,12.57,0,0,0,1.67,6.64,6.34,6.34,0,0,0,11.2-.06,12.6,12.6,0,0,0,1.73-6.69'
			/>
			<path
				className='cls-2'
				d='M365.49,45.74l-8.75,27.93h-5.68l-3.62-12.12a92.74,92.74,0,0,1-2.24-9h-.11a69.54,69.54,0,0,1-2.25,9L339,73.67h-5.75L325,45.74h6.38L334.56,59c.77,3.14,1.4,6.12,1.9,9h.12c.46-2.34,1.21-5.31,2.3-8.91l4-13.33h5.05l3.8,13q1.4,4.78,2.24,9.2h.17a86.06,86.06,0,0,1,1.91-9.2l3.4-13Z'
			/>
			<path
				className='cls-2'
				d='M397.68,73.67h-6.21v-16c0-4.94-1.87-7.39-5.63-7.39a5.65,5.65,0,0,0-4.49,2,7.27,7.27,0,0,0-1.72,4.8V73.67h-6.21V53.73q0-3.69-.22-8h5.45l.3,4.37h.17a8.8,8.8,0,0,1,3.22-3.37A10.5,10.5,0,0,1,388,45.16a8.94,8.94,0,0,1,6.5,2.53c2.15,2.07,3.22,5.17,3.22,9.26Z'
			/>
			<rect className='cls-2' x='408.59' y='32.92' width='6.22' height='40.75' />
			<path
				className='cls-2'
				d='M451.36,59.48A15.28,15.28,0,0,1,447.68,70a13.11,13.11,0,0,1-10.24,4.27,12.44,12.44,0,0,1-9.79-4.09A14.83,14.83,0,0,1,424,59.94a15.21,15.21,0,0,1,3.76-10.64q3.76-4.14,10.16-4.13a12.73,12.73,0,0,1,9.87,4.08,14.69,14.69,0,0,1,3.57,10.23m-6.43.2a12.72,12.72,0,0,0-1.67-6.63,6.15,6.15,0,0,0-5.58-3.35,6.26,6.26,0,0,0-5.7,3.35,13,13,0,0,0-1.66,6.74A12.76,12.76,0,0,0,432,66.43a6.29,6.29,0,0,0,5.63,3.34,6.22,6.22,0,0,0,5.59-3.4,12.69,12.69,0,0,0,1.73-6.69'
			/>
			<path
				className='cls-2'
				d='M481.42,73.67h-5.58l-.46-3.21h-.17a9.57,9.57,0,0,1-8.16,3.84,8.41,8.41,0,0,1-6.39-2.53,7.9,7.9,0,0,1-2.17-5.68,8.68,8.68,0,0,1,4.29-7.82c2.84-1.81,6.85-2.68,12-2.65v-.51q0-5.52-5.78-5.52a13.05,13.05,0,0,0-7.29,2.07l-1.26-4.08A17.87,17.87,0,0,1,470,45.17c7.33,0,11,3.85,11,11.55V67a43,43,0,0,0,.41,6.66M475,64.07v-4.3c-6.85-.12-10.28,1.77-10.28,5.63a4.2,4.2,0,0,0,1.21,3.27,4.34,4.34,0,0,0,3,1.09,6.32,6.32,0,0,0,3.8-1.29,5.24,5.24,0,0,0,2.14-3.3,4.49,4.49,0,0,0,.11-1.1'
			/>
			<path
				className='cls-2'
				d='M516.71,73.67h-5.52l-.29-4.48h-.17q-2.64,5.11-9,5.12a10.5,10.5,0,0,1-8.39-4,15.45,15.45,0,0,1-3.33-10.29c0-4.52,1.19-8.18,3.6-11a11.15,11.15,0,0,1,8.63-3.9q5.64,0,7.88,3.79h.12v-16h6.2V66.14q0,4.1.23,7.53m-6.43-11.78V57.24a8.71,8.71,0,0,0-.18-2,6.87,6.87,0,0,0-2.26-3.76A6,6,0,0,0,503.69,50a6.36,6.36,0,0,0-5.45,2.75,11.79,11.79,0,0,0-2,7.06,11,11,0,0,0,1.92,6.73,6.36,6.36,0,0,0,5.41,2.76,6,6,0,0,0,4.91-2.3,7.63,7.63,0,0,0,1.8-5.11'
			/>
			<path
				className='cls-2'
				d='M569.83,59.48A15.25,15.25,0,0,1,566.13,70a13.07,13.07,0,0,1-10.22,4.27,12.45,12.45,0,0,1-9.8-4.09,14.83,14.83,0,0,1-3.65-10.28,15.21,15.21,0,0,1,3.76-10.64,13,13,0,0,1,10.14-4.13,12.81,12.81,0,0,1,9.9,4.08,14.74,14.74,0,0,1,3.57,10.23m-6.45.2a12.63,12.63,0,0,0-1.67-6.63,6.15,6.15,0,0,0-5.57-3.35,6.22,6.22,0,0,0-5.69,3.35,12.86,12.86,0,0,0-1.67,6.74,12.66,12.66,0,0,0,1.67,6.64,6.34,6.34,0,0,0,11.2-.06,12.51,12.51,0,0,0,1.73-6.69'
			/>
			<path
				className='cls-2'
				d='M603.21,73.67H597v-16c0-4.94-1.88-7.39-5.62-7.39a5.61,5.61,0,0,0-4.49,2,7.28,7.28,0,0,0-1.73,4.8V73.67H579V53.73c0-2.46-.09-5.12-.23-8h5.46l.29,4.37h.18a8.7,8.7,0,0,1,3.21-3.37,10.53,10.53,0,0,1,5.63-1.58,8.92,8.92,0,0,1,6.5,2.53c2.14,2.07,3.22,5.17,3.22,9.26Z'
			/>
			<path
				className='cls-2'
				d='M645,50.39h-6.84V64c0,3.45,1.19,5.17,3.62,5.17a11.13,11.13,0,0,0,2.75-.28l.18,4.7a14,14,0,0,1-4.83.7A7.41,7.41,0,0,1,634.14,72c-1.38-1.5-2.07-4-2.07-7.54V50.39H628V45.74h4.09V40.62l6.09-1.83v7H645Z'
			/>
			<path
				className='cls-2'
				d='M677.86,73.67h-6.2V57.79c0-5-1.87-7.5-5.64-7.5-2.86,0-4.84,1.45-5.9,4.34a7.06,7.06,0,0,0-.3,2.24v16.8h-6.21V32.92h6.21V49.76H660a9.33,9.33,0,0,1,8.37-4.6,8.68,8.68,0,0,1,6.39,2.53c2.09,2.11,3.15,5.24,3.15,9.38Z'
			/>
			<path
				className='cls-2'
				d='M711.79,58.38a16,16,0,0,1-.23,2.83H692.93a8.08,8.08,0,0,0,2.7,6.31,9.2,9.2,0,0,0,6.09,2,20.35,20.35,0,0,0,7.53-1.32l1,4.31a23.27,23.27,0,0,1-9.36,1.73c-4.32,0-7.73-1.28-10.21-3.81s-3.7-6-3.7-10.26a16,16,0,0,1,3.45-10.53,11.92,11.92,0,0,1,9.77-4.48q6,0,9.14,4.48a15.11,15.11,0,0,1,2.48,8.79m-5.93-1.6A8.44,8.44,0,0,0,704.65,52a5.42,5.42,0,0,0-4.93-2.59,6,6,0,0,0-5,2.53,9.44,9.44,0,0,0-1.82,4.83Z'
			/>
		</svg>
	);
}
