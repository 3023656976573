import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Paper, Tab, Tabs, useTheme } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { AuditLogsSection } from './AuditLogsSection';
import { GeneralSection } from './GeneralSection';
import { EPAMDetailSections } from './enums';
import { useACL } from '../../hooks/useACL';
import { useNavigate } from '../../hooks/useNavigate';
import { usePathName } from '../../hooks/usePathName';
import { EPermission } from '../../enums/permission/EPermission';

const tabs = (t: TFunction) => [
	{
		value: EPAMDetailSections.GENERAL,
		label: t('page.pam.detail.sections.general.title'),
	},
	{
		value: EPAMDetailSections.AUDIT_LOGS,
		label: t('page.pam.detail.sections.auditLogs.title'),
	},
];

export const PAMDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const pathname = usePathName();
	const { id } = useParams();
	const { isAllowed } = useACL();
	const pamTargetId = id ? Number(id) : null;

	const [currentSection, setCurrentSection] = useState<EPAMDetailSections>(
		location.state?.tab ?? EPAMDetailSections.GENERAL,
	);

	const { t } = useTranslation();

	const theme = useTheme();

	useEffect(() => {
		if (currentSection && location.state?.tab !== currentSection) {
			navigate(`${pathname}`, { state: { ...location.state, tab: currentSection }, replace: true });
		}
	}, []);

	const onChangeTab = useCallback(
		(_: SyntheticEvent<Element, Event>, newValue: EPAMDetailSections) => {
			setCurrentSection(newValue);
			navigate(pathname, { state: { tab: newValue } });
		},
		[pathname, navigate],
	);

	return (
		<Box sx={{ height: '100%', marginBottom: 10 }}>
			<Paper elevation={3}>
				{pamTargetId &&
					isAllowed([EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN], false) && (
						<TabContext value={currentSection}>
							<Box sx={{ borderBottom: 1, borderColor: theme.palette.divider, marginBottom: 2 }}>
								<Tabs
									value={currentSection}
									onChange={onChangeTab}
									TabIndicatorProps={{ style: { transition: 'none' } }}
								>
									{tabs(t).map((tab) => (
										<Tab key={tab.label} label={tab.label} value={tab.value} />
									))}
								</Tabs>
							</Box>

							<TabPanel value={EPAMDetailSections.GENERAL}>
								<GeneralSection id={pamTargetId} />
							</TabPanel>
							<TabPanel value={EPAMDetailSections.AUDIT_LOGS}>
								<AuditLogsSection id={pamTargetId} />
							</TabPanel>
						</TabContext>
					)}
			</Paper>
		</Box>
	);
};
