import { resolveEMailSMTPSecurity } from '../../enums/mail/EMailSMTPSecurity';
import { SmtpFormData } from './types';

export const SMTP_FORM_INIT_VALUES: SmtpFormData = {
	name: '',
	description: '',
	host: '',
	port: 587,
	username: '',
	password: '',
	sender: '',
	email: '',
	security: resolveEMailSMTPSecurity('none'),
};
