import * as React from 'react';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Typography, Grid, Button, Tooltip } from '@mui/material';

import { useNavigate } from '../../../hooks/useNavigate';
import { getVerificationCodeFormSchema } from '../schema';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { VerifySMTPMailConfigurationDto } from '../../../api/Api';
import { Preloader } from '../../../components/Preloader/Preloader';
import { FormVerificationCodeValues, SubmitFormState, VerifyEmailStepProps } from '../types';
import { OneTimePassword } from '../../../components/FormFields/OneTimePassword/OneTimePassword';
import { EPermission } from '../../../enums/permission/EPermission';
import { useACL } from '../../../hooks/useACL';

export const VerifyEmailStep: React.FC<VerifyEmailStepProps> = ({ createdConfigId }) => {
	const api = useSwaggerApi();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isAllowed } = useACL();

	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [shouldDisableButton, setShouldDisableButton] = React.useState<boolean>(true);
	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm<FormVerificationCodeValues>({
		mode: 'onChange',
		resolver: zodResolver(getVerificationCodeFormSchema(t)),
	});

	const verificationCode = watch('verificationCode');

	const onSubmit = React.useCallback<SubmitHandler<FormVerificationCodeValues>>(
		async (formData): Promise<void> => {
			if (submitFormState.submitting) {
				return;
			}

			const requestBody: VerifySMTPMailConfigurationDto = {
				verificationCode: formData.verificationCode,
			};

			const id = createdConfigId;

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				await api.settings.verifySmtpConfiguration(Number(id), requestBody);
				enqueueSnackbar(t('page.smtp.edit.actionMessages.SMTPVerifiedSuccessfully'), {
					variant: 'success',
					persist: false,
				});
				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
				navigate('/settings/general/email/smtp');
			} catch (error: unknown) {
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[api.settings, createdConfigId, enqueueSnackbar, t],
	);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && submitFormState.submitting) {
			setShouldDisableForm(true);
		}
	}, [submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		if (verificationCode === undefined) {
			setShouldDisableButton(true);

			return;
		}

		if (verificationCode.length === 6) {
			setShouldDisableButton(false);
		} else {
			setShouldDisableButton(true);
		}
	}, [verificationCode]);

	return (
		<Box component={'form'} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
			{createdConfigId ?
				<Grid container spacing={2} justifyContent='center'>
					<Grid item xs={8}>
						<Grid container spacing={2}>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									minHeight: '200px',
									width: '600px',
								}}
							>
								<Typography variant='h5' gutterBottom>
									{t('page.smtp.edit.body.text.verify')}
								</Typography>
								<Typography variant='subtitle1' gutterBottom>
									{t('page.smtp.edit.body.text.sentCodeToEmail')}
								</Typography>

								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										width: '75%',
										gap: '1rem',
										marginBottom: '1rem',
									}}
								>
									<OneTimePassword
										name='verificationCode'
										control={control}
										error={errors.verificationCode}
										length={6}
									/>
								</Box>

								{isAllowed([EPermission.SMTP_CREATE, EPermission.SMTP_UPDATE], false) && (
									<Tooltip
										arrow
										placement='bottom'
										title={t('page.smtp.edit.tooltips.verify')}
										enterDelay={500}
									>
										<span>
											<Button
												variant='contained'
												type='submit'
												disabled={shouldDisableButton || shouldDisableForm}
											>
												{t('page.smtp.edit.body.button.verify')}
											</Button>
										</span>
									</Tooltip>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			:	<Preloader />}
		</Box>
	);
};
