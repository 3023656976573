import { z } from 'zod';
import { TFunction } from 'i18next';

const MAX_FILE_SIZE = 500000;

export const getIdentityStoreFormSchema = (t: TFunction, isUpdating: boolean) =>
	z.object({
		name: z
			.string()
			.min(3, { message: t('page.identityStore.edit.all.form.name.Error', { minLength: 3 }) })
			.max(64, { message: t('page.identityStore.edit.all.form.name.ErrorMaxLength', { maxLength: 64 }) }),
		description: z
			.string()
			.max(255, { message: t('page.identityStore.edit.all.form.description.ErrorMaxLength', { maxLength: 255 }) })
			.optional(),
		host: z
			.string()
			.min(3, { message: t('page.identityStore.edit.all.form.host.Error', { minLength: 3 }) })
			.max(255, { message: t('page.identityStore.edit.all.form.host.ErrorMaxLength', { maxLength: 255 }) }),
		port: z.string().optional(),
		...(isUpdating ?
			{
				password: z
					.string()
					.max(64, {
						message: t('page.identityStore.edit.all.form.password.ErrorMaxLength', { maxLength: 64 }),
					})
					.optional(),
			}
		:	{
				password: z
					.string()
					.min(1, { message: t('page.identityStore.edit.all.form.password.Error') })
					.max(64, {
						message: t('page.identityStore.edit.all.form.password.ErrorMaxLength', { maxLength: 64 }),
					}),
			}),
		baseDN: z.string().min(1, { message: t('page.identityStore.edit.all.form.baseDN.Error') }),
		bindDN: z.string().min(1, { message: t('page.identityStore.edit.all.form.bindDN.Error') }),
		caCertificate: z.string().optional(),
		useTLS: z.boolean().optional(),
		file: z
			.instanceof(File)
			.optional()
			.nullable()
			.refine((file) => !file || file.size <= MAX_FILE_SIZE, {
				message: t('page.identityStore.edit.all.form.file.ErrorMaxLength'),
			}),
	});

export const ldapIdentityStoreSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	caCertificate: z.string().optional(),
	useTLS: z.boolean(),
	host: z.string(),
	port: z.number(),
	baseDN: z.string(),
	bindDN: z.string(),
});
