import * as React from 'react';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../AuthContext/AuthContext';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useRequestSent } from '../../hooks/useRequestSent';

import { ACTIVITY_EVENTS, VISIBILITY_CHANGE, INITIAL_SESSION_TIMEOUT } from './constants';
import { SessionContextModel, SessionProviderProps } from './types';
import { getPathName } from '../../utils/Routing';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { EFilterOperator, generateQueryObject } from '@excalibur-enterprise/query-filter';

const defaultSessionContextModel = {
	sessionMaxAge: INITIAL_SESSION_TIMEOUT,
	resetCountdown: false,
	setResetCountdown: () => {},
	isActive: true,
	setIsActive: () => {},
};

export const SessionContext = React.createContext<SessionContextModel>(defaultSessionContextModel);

export const SessionProvider: React.FC<SessionProviderProps> = ({ children }): JSX.Element => {
	const authContext = useAuthContext();
	const swaggerApi = useSwaggerApi();
	const requestSent = useRequestSent();
	const { t } = useTranslation();
	const prevIsAuthenticated = usePreviousValue(authContext.user.isAuthenticated);
	const [sessionMaxAge, setSessionMaxAge] = React.useState(INITIAL_SESSION_TIMEOUT);
	const [sessionStart, setSessionStart] = React.useState(Date.now());
	const [resetCountdown, setResetCountdown] = React.useState(false);
	const [isActive, setIsActive] = React.useState(true);
	const previousIsActive = usePreviousValue(isActive);
	const [sessionFetching, setSessionFetching] = React.useState(false);

	const timeoutIDRef = React.useRef<NodeJS.Timeout | null>(null);

	const fetchSession = React.useCallback(
		async (force = false) => {
			if (sessionFetching) {
				return;
			}
			if (!isActive || force) {
				try {
					setSessionFetching(true);
					const response = await swaggerApi.auth.session();
					const maxAge = response.data;
					setSessionMaxAge(maxAge - 10000);
					setSessionStart(Date.now());
					setSessionFetching(false);
				} catch (error) {
					console.error(error);
					setSessionFetching(false);
				}
			}
		},
		[isActive, swaggerApi, sessionFetching],
	);

	const resetTimeout = React.useCallback(() => {
		if (resetCountdown || !authContext.user.isAuthenticated) {
			return;
		}
		if (timeoutIDRef.current) {
			clearTimeout(timeoutIDRef.current);
		}
		setIsActive(true);
		setResetCountdown(true);
		timeoutIDRef.current = setTimeout(async () => {
			const queryObject = generateQueryObject({
				columns: ['endAt'],
				conditions: {
					caseInsensitive: false,
					column: 'endAt',
					operator: EFilterOperator.Equal,
					value: 'null',
				},
				limit: 1,
				offset: 0,
			});
			const activePamSessions = await swaggerApi.pam.getAllPamSessions({
				filter: queryObject.filter,
			});

			if (activePamSessions.data.total > 0) {
				enqueueSnackbar(t('component.sessionContext.sessionRefreshMessage'), {
					variant: 'info',
					persist: false,
				});
			} else if (Date.now() >= sessionStart + sessionMaxAge) {
				const path = getPathName();
				enqueueSnackbar(t('component.sessionContext.sessionLogoutMessage'), {
					variant: 'info',
					persist: false,
				});
				setTimeout(() => {
					authContext.handleOnLogout(path === '/' ? '/login' : `/login?original=${path}`);
				}, 1400);
			} else {
				setIsActive(false);
			}
		}, sessionMaxAge);
	}, [authContext, sessionMaxAge, sessionStart, timeoutIDRef.current, resetCountdown]);

	React.useEffect(() => {
		if (authContext.user.isAuthenticated) {
			resetTimeout();
		}
	}, [requestSent, authContext.user.isAuthenticated]);

	React.useEffect(() => {
		if (!prevIsAuthenticated && authContext.user.isAuthenticated) {
			fetchSession(true);
		}
	}, [authContext.user.isAuthenticated, prevIsAuthenticated]);

	React.useEffect(() => {
		if (authContext.user.isAuthenticated && isActive && !previousIsActive) {
			fetchSession(true);
		}
	}, [isActive, authContext.user.isAuthenticated, previousIsActive]);

	React.useEffect(() => {
		if (!authContext.user.isAuthenticated) {
			return;
		}

		const handleVisibilityChange = () => {
			if (document.hidden) {
				setIsActive(false);
			} else {
				resetTimeout();
			}
		};
		const handleActivityEvent = () => {
			if (Date.now() >= sessionStart + sessionMaxAge - 10000) {
				resetTimeout();
				fetchSession(true);
			}
		};
		ACTIVITY_EVENTS.forEach((event) => {
			window.addEventListener(event, handleActivityEvent, true);
		});

		document.addEventListener(VISIBILITY_CHANGE, handleVisibilityChange, true);

		return () => {
			ACTIVITY_EVENTS.forEach((event) => {
				window.removeEventListener(event, handleActivityEvent, true);
			});
			document.removeEventListener(VISIBILITY_CHANGE, handleVisibilityChange, true);
		};
	}, [authContext, sessionStart, sessionMaxAge, resetCountdown, timeoutIDRef.current]);

	const sessionContextModel: SessionContextModel = {
		sessionMaxAge,
		resetCountdown,
		setResetCountdown,
		isActive,
		setIsActive,
	};

	return <SessionContext.Provider value={sessionContextModel}>{children}</SessionContext.Provider>;
};
