import { ToggleButton, ToggleButtonGroup as OriginalToggleButtonGroup } from '@mui/material';
import { ToggleButtonGroupProps } from './types';

export const ToggleButtonGroup = <T extends string | number>({
	options,
	value,
	onChange,
	disabled,
	color = 'primary',
	...rest
}: ToggleButtonGroupProps<T>): JSX.Element => {
	return (
		<OriginalToggleButtonGroup
			value={value}
			onChange={onChange}
			color={color}
			exclusive
			{...rest}
			sx={{ paddingBottom: 1 }}
		>
			{options.map((option) => (
				<ToggleButton key={`${option.value}`} value={option.value} disabled={disabled}>
					{option.label}
				</ToggleButton>
			))}
		</OriginalToggleButtonGroup>
	);
};
