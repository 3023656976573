import * as React from 'react';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Paper, Box, Grid, Stack, FormGroup, InputAdornment, Typography } from '@mui/material';
import { FolderShared as FolderSharedIcon } from '@mui/icons-material';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useNavigate } from '../../../hooks/useNavigate';
import { Preloader } from '../../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getIdentityStoreFormSchema } from './schemas';
import { FormValues, LDAPIdentityStoreState, SubmitFormState } from './types';
import { CreateIdentityStoreDto, RequestParams, UpdateIdentityStoreDto } from '../../../api/Api';
import { Heading } from '../../../components/Heading/Heading';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { Textarea } from '../../../components/FormFields/Textarea/Textarea';
import { Checkbox } from '../../../components/FormFields/Checkbox/Checkbox';
import { PasswordField } from '../../../components/FormFields/PasswordField/PasswordField';
import { FileUploader } from '../../../components/FormFields/FileUploader/FileUploader';
import { useTenantBasePath } from '../../../hooks/useTenantBasePath';
import { useAuthContext } from '../../../contexts/AuthContext/AuthContext';
import { FloatingButtonBack } from '../../../components/Buttons/FloatingButton/FloatingButtonBack';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { useACL } from '../../../hooks/useACL';
import { EPermission } from '../../../enums/permission/EPermission';

export const LDAPIdentityStore: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { t } = useTranslation();
	const { id } = useParams();
	const { isAllowed } = useACL();
	const location = useLocation();
	const navigate = useNavigate();
	const authContext = useAuthContext();
	const basePath = useTenantBasePath(location.state?.tenantIdOrSlug);

	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [uploadedCaCertificate, setUploadedCaCertificate] = React.useState<string | null>(null);

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const [identityStoreState, setIdentityStoreState] = React.useState<LDAPIdentityStoreState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const {
		handleSubmit,
		getValues,
		register,
		control,
		trigger,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getIdentityStoreFormSchema(t, !!id)),
		defaultValues: {
			caCertificate: '',
			useTLS: false,
		},
	});

	const file = useWatch({ control: control, name: 'file', defaultValue: null });
	const useTLS = useWatch({ control: control, name: 'useTLS' });

	const getTenantName = React.useCallback(
		(tenantID: number | null) => {
			if (tenantID === null) {
				return 'System';
			}

			return authContext.userTenants.tenants.find((tenant) => tenant.id === tenantID)?.name;
		},
		[authContext.userTenants],
	);

	const pageTitle = React.useMemo(() => {
		const tenantName = getTenantName(parseInt(location.state?.tenantIdOrSlug));

		return (
			location.state?.tenantIdOrSlug ?
				id ? t('page.identityStore.edit.title.tenant', { tenantName })
				:	t('page.identityStore.add.title.tenant', { tenantName })
			: id ? t('page.identityStore.edit.title.main')
			: t('page.identityStore.add.title.main')
		);
	}, [location.state?.tenantIDOrSlug, id, getTenantName, t]);

	function useParsedCertificate(response: string): string {
		const lines = response.split('\\n');

		return lines.join('\n');
	}

	const navigateBack = React.useCallback(
		(path: string) => {
			const { tab, return: returnTo } = location.state || {};

			const to = returnTo || path;
			const options = returnTo && tab ? { state: { tab } } : undefined;

			navigate(to, options);
		},
		[location, navigate],
	);

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData) => {
			let host = formData.host;
			if (formData.host.startsWith('ldap://') || formData.host.startsWith('ldaps://')) {
				host = host.substring(host.indexOf('//') + 2);
			}

			const requestBody: CreateIdentityStoreDto = {
				name: formData.name,
				description: formData.description || '',
				useTLS: formData.useTLS,
				caCertificate: useParsedCertificate(formData.caCertificate as string),
				host: host,
				port: Number(formData.port),
				password: formData.password,
				baseDN: formData.baseDN,
				bindDN: formData.bindDN,
			};

			const requestUpdateBody: UpdateIdentityStoreDto = {};
			if (formData.name !== identityStoreState.data?.name) {
				requestUpdateBody.name = formData.name;
			}
			if (formData.description !== identityStoreState.data?.description) {
				requestUpdateBody.description = formData.description;
			}
			if (formData.host !== identityStoreState.data?.host) {
				requestUpdateBody.host = host;
			}
			if (Number(formData.port) !== identityStoreState.data?.port) {
				requestUpdateBody.port = Number(formData.port);
			}
			if (useTLS !== identityStoreState.data?.useTLS) {
				requestUpdateBody.useTLS = useTLS;
				if (useTLS === false) {
					requestUpdateBody.caCertificate = '';
				} else if (formData.caCertificate) {
					requestUpdateBody.caCertificate = useParsedCertificate(formData.caCertificate);
				}
			}
			if (formData.baseDN !== identityStoreState.data?.baseDN) {
				requestUpdateBody.baseDN = formData.baseDN;
			}
			if (formData.bindDN !== identityStoreState.data?.bindDN) {
				requestUpdateBody.bindDN = formData.bindDN;
			}

			if (formData.password) {
				requestUpdateBody.password = formData.password;
			}

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				const params: RequestParams | undefined = basePath ? { baseURL: basePath } : undefined;

				if (id) {
					await api.identityStores.updateIdentityStore(Number(id), requestUpdateBody, params);
					enqueueSnackbar(t('page.identityStore.edit.all.actionMessages.identityStoreSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
				} else {
					await api.identityStores.createIdentityStore(requestBody, params);
				}
				navigateBack('/identityStores');

				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
			} catch (error: unknown) {
				console.error(error);
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
			}
		},
		[
			api.identityStores,
			enqueueSnackbar,
			setSubmitFormState,
			navigateBack,
			id,
			useTLS,
			identityStoreState,
			basePath,
		],
	);

	const getIdentityStore = React.useCallback(
		async (id: number): Promise<void> => {
			setIdentityStoreState({
				loading: true,
				loaded: false,
				data: null,
				error: null,
			});

			try {
				const params: RequestParams | undefined = basePath ? { baseURL: basePath } : undefined;
				const response: Partial<LDAPIdentityStoreState> = await api.identityStores.getIdentityStore(id, params);
				if (!response.data) {
					enqueueSnackbar(t('page.identityStore.edit.all.errorMessages.fetch'), {
						variant: 'error',
						persist: false,
					});

					return;
				}
				setIdentityStoreState({
					loading: false,
					loaded: true,
					data: response.data,
					error: null,
				});

				reset({
					name: response.data.name,
					description: response.data.description,
					host: response.data.host,
					port: response.data.port?.toString() || '',
					useTLS: response.data.useTLS,
					caCertificate: response.data.caCertificate,
					baseDN: response.data.baseDN,
					bindDN: response.data.bindDN,
					password: '',
				});
			} catch (error) {
				console.error(error);
				setIdentityStoreState({
					loading: false,
					loaded: false,
					data: null,
					error: error as AxiosError,
				});
			}
		},
		[basePath],
	);

	const handleOnClickBack = React.useCallback(() => {
		navigate(-1);
	}, [navigate]);

	React.useEffect(() => {
		if (id && !identityStoreState.loading && !identityStoreState.loaded && !identityStoreState.error) {
			getIdentityStore(Number(id));
		}
	}, [id, identityStoreState]);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !identityStoreState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || identityStoreState.loading)) {
			setShouldDisableForm(true);
		}
	}, [identityStoreState, submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		const currentValues = getValues();
		if (uploadedCaCertificate) {
			reset({ ...currentValues, caCertificate: uploadedCaCertificate });
		} else {
			reset({ ...currentValues, caCertificate: '' });
		}
	}, [uploadedCaCertificate]);

	const handleChangeFile = React.useCallback(
		async (file: File): Promise<void> => {
			if (!(file instanceof File)) {
				enqueueSnackbar(t('page.identityStore.edit.all.errorMessages.noSelectedFile'), {
					variant: 'error',
					persist: false,
				});

				return;
			}

			const isValid = await trigger('file');

			if (!isValid) {
				return;
			}

			const reader = new FileReader();

			reader.onload = (event) => {
				const caCetrificate = event.target?.result;

				if (typeof caCetrificate !== 'string') {
					enqueueSnackbar(t('page.identityStore.edit.all.errorMessages.caCertificateFormat'), {
						variant: 'error',
						persist: false,
					});

					return;
				}

				setUploadedCaCertificate(caCetrificate);
			};

			reader.onerror = (error) => {
				enqueueSnackbar(error.toString(), {
					variant: 'error',
					persist: false,
				});
			};

			reader.readAsText(file);
		},
		[enqueueSnackbar, setUploadedCaCertificate, t],
	);

	React.useEffect(() => {
		if (!file || !!errors.file) {
			return;
		}
		handleChangeFile(file);
	}, [file, errors.file]);

	return (
		<Box component={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
			{id && (identityStoreState.loading || !identityStoreState.loaded) ?
				<Preloader />
			:	<Paper elevation={3}>
					<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
						<PageHeader
							title={pageTitle}
							description={
								id ? t('page.identityStore.edit.description') : t('page.identityStore.add.description')
							}
							icon={FolderSharedIcon}
						/>
					</Box>
					<Grid container>
						<Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
							<FormGroup
								sx={{
									padding: 2,
								}}
							>
								<Heading label={t('page.identityStore.edit.all.subtitle.general')} />
								<Stack spacing={2}>
									<TextField
										name={'name'}
										register={register}
										label={t('page.identityStore.edit.all.form.name.label')}
										error={errors.name}
										disabled={shouldDisableForm}
										helperText={t('page.identityStore.edit.all.form.name.helperText')}
									/>
									<Textarea
										name={'description'}
										register={register}
										label={t('page.identityStore.edit.all.form.description.label')}
										error={errors.description}
										disabled={shouldDisableForm}
										helperText={t('page.identityStore.edit.all.form.description.helperText')}
										rows={5}
									/>
								</Stack>
							</FormGroup>
						</Grid>

						<Grid item xs={12} md={6} order={{ xs: 1, md: 3 }}>
							<FormGroup sx={{ padding: 2 }}>
								<Heading label={t('page.identityStore.edit.all.subtitle.network')} />
								<Typography variant='body1' sx={{ paddingBottom: 2 }}>
									{t('page.identityStore.edit.all.body.description.network')}
								</Typography>
								<Stack spacing={2} maxWidth={'100%'}>
									<Grid item sm={12} md={6}>
										<Checkbox
											name={'useTLS'}
											control={control}
											label={t('page.identityStore.edit.all.form.useTLS.label')}
											disabled={shouldDisableForm}
										/>
									</Grid>
									{useTLS ?
										<Stack spacing={2}>
											<Grid item sm={12}>
												<Textarea
													name={'caCertificate'}
													register={register}
													helperText={t(
														'page.identityStore.edit.all.form.caCertificate.helperText',
													)}
													error={errors.caCertificate}
													disabled={!!file || shouldDisableForm}
													label={t('page.identityStore.edit.all.form.caCertificate.label')}
													rows={6}
												/>
											</Grid>
											<Grid item sm={12}>
												<FileUploader
													control={control}
													name={'file'}
													error={errors.file}
													disabled={!useTLS || shouldDisableForm}
													helperText={t('page.identityStore.edit.all.form.file.helperText')}
													multiple={false}
												/>
											</Grid>
										</Stack>
									:	null}
									<Stack spacing={2}>
										<TextField
											name={'host'}
											register={register}
											label={t('page.identityStore.edit.all.form.host.label')}
											error={errors.host}
											disabled={shouldDisableForm}
											placeholder={'example.sk.cloud'}
											helperText={t('page.identityStore.edit.all.form.host.helperText')}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														{useTLS ? 'ldaps://' : 'ldap://'}
													</InputAdornment>
												),
											}}
										/>
										<TextField
											name={'port'}
											register={register}
											label={t('page.identityStore.edit.all.form.port.label')}
											error={errors.port}
											disabled={shouldDisableForm}
											helperText={t('page.identityStore.edit.all.form.port.helperText')}
										/>
									</Stack>
								</Stack>
							</FormGroup>
						</Grid>

						<Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
							<FormGroup
								sx={{
									padding: 2,
								}}
							>
								<Heading label={t('page.identityStore.edit.all.subtitle.authentication')} />
								<Stack spacing={2}>
									<TextField
										name={'baseDN'}
										register={register}
										label={t('page.identityStore.edit.all.form.baseDN.label')}
										error={errors.baseDN}
										disabled={shouldDisableForm}
										placeholder={'OU=Users,DC=example,DC=sk,DC=cloud'}
										helperText={t('page.identityStore.edit.all.form.baseDN.helperText')}
									/>
									<TextField
										name={'bindDN'}
										register={register}
										label={t('page.identityStore.edit.all.form.bindDN.label')}
										error={errors.bindDN}
										disabled={shouldDisableForm}
										placeholder={'CN=ldap_user,OU=Users,DC=example,DC=sk,DC=cloud'}
										helperText={t('page.identityStore.edit.all.form.bindDN.helperText')}
									/>
									<Grid item xs={12} md={8}>
										<PasswordField
											name={'password'}
											register={register}
											label={t('page.identityStore.edit.all.form.password.label')}
											error={errors.password}
											disabled={shouldDisableForm}
											helperText={t('page.identityStore.edit.all.form.password.helperText')}
										/>
									</Grid>
								</Stack>
							</FormGroup>
						</Grid>
					</Grid>

					<FloatingButtonBack
						right={'100px'}
						onClick={handleOnClickBack}
						ariaLabel={t('page.identityStore.edit.all.ariaLabel.back')}
						tooltipTitle={t('page.identityStore.edit.all.tooltips.back')}
					/>
					{isAllowed([EPermission.NETWORK_POLICY_UPDATE, EPermission.NETWORK_POLICY_CREATE]) && (
						<FloatingButtonSave
							type='submit'
							disabled={shouldDisableForm}
							ariaLabel={t('page.identityStore.edit.all.ariaLabel.save')}
							tooltipTitle={
								id ?
									t('page.identityStore.edit.all.tooltips.save')
								:	t('page.identityStore.edit.all.tooltips.create')
							}
						/>
					)}
				</Paper>
			}
		</Box>
	);
};
