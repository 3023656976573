import { TimezoneOption } from '../components/FormFields/TimezoneSelector/types';
import optionsData from '../components/FormFields/TimezoneSelector/optionsData.json';

export const TIMEZONE_IANA = 'Europe/Bratislava';
export const TIMEZONE_OFFSET = 2;

export function parseTimezone(inputString: string): TimezoneOption {
	const { options } = optionsData;
	const parsedInput = inputString.trim();

	const matchedOption = options.find((option) => option.value === parsedInput);

	if (!matchedOption) {
		return { offset: TIMEZONE_OFFSET, value: TIMEZONE_IANA };
	}

	return matchedOption;
}

export function formatOffset(offset: number): string {
	const sign = offset >= 0 ? '+' : '-';
	const absOffset = Math.abs(offset);
	const hours = String(Math.floor(absOffset)).padStart(2, '0');
	const minutes = String((absOffset % 1) * 60).padStart(2, '0');

	return `${sign}${hours}:${minutes}`;
}
