import { Webhook as WebhookIcon } from '@mui/icons-material';
import { Box, Paper } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { WebhookForm } from './WebhookForm/WebhookForm';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';
import { FormValues } from './WebhookForm/types';
import { useFetchOAuthClient } from '../../../hooks/query/oAuthClients/useFetchOAuthClient';
import { Preloader } from '../../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { CreateWebhookRequestDto } from '../../../api/Api';
import { useNavigate } from '../../../hooks/useNavigate';
import { useACL } from '../../../hooks/useACL';
import { EPermission } from '../../../enums/permission/EPermission';

export const AddWebhook: React.FC = () => {
	const navigate = useNavigate();
	const { isAllowed } = useACL();
	const { id: oAuthClientID } = useParams();

	const { t } = useTranslation();

	const { data, isLoading } = useFetchOAuthClient(oAuthClientID ?? '');

	const api = useSwaggerApi();

	const { mutate, isPending } = useMutation({
		mutationFn: async (webhook: CreateWebhookRequestDto) => {
			const { data } = await api.oauthClient.createWebhook(webhook);

			return data;
		},
	});

	const onSubmit = (formValues: FormValues) => {
		if (!oAuthClientID) {
			return;
		}

		mutate(
			{ ...formValues, oAuthClientID },
			{
				onSuccess: (response) => {
					enqueueSnackbar(t('page.oAuthClients.webhooks.add.actionMessages.webhookCreated'), {
						variant: 'success',
					});
					navigate(`/security/oauth/${oAuthClientID}/edit/${response.webhookID}`);
				},
			},
		);
	};

	const formID = 'add-webhook-form';

	return (
		<Box>
			{isLoading && <Preloader />}
			{!isLoading && data && (
				<Paper elevation={3}>
					<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
						<PageHeader
							title={t('page.oAuthClients.webhooks.add.title', { oAuthClientName: data.name })}
							description={t('page.oAuthClients.webhooks.add.description', {
								oAuthClientName: data.name,
							})}
							icon={WebhookIcon}
						/>
					</Box>
					<WebhookForm formID={formID} onSubmit={onSubmit} />

					{isAllowed([EPermission.OAUTH_CLIENTS_UPDATE]) && (
						<FloatingButtonSave
							type='submit'
							disabled={isPending}
							ariaLabel={t('page.oAuthClients.webhooks.add.ariaLabel')}
							tooltipTitle={t('page.oAuthClients.webhooks.add.tooltips.create')}
							formID={formID}
						/>
					)}
				</Paper>
			)}
		</Box>
	);
};
