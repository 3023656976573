import * as React from 'react';
import { enUS as en, sk } from 'date-fns/locale';
import { Controller } from 'react-hook-form';
import { Box, Typography, FormLabel } from '@mui/material';
import { HorizontalRule as HorizontalRuleIcon } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTimeRangePickerProps } from './types';
import { useTranslation } from 'react-i18next';
import { isValid } from 'date-fns';

import { useCurrentLanguage } from '../../../contexts/LocalizationContext/LocalizationContext';
import { useDeviceDetect } from '../../../hooks/useDeviceDetect';

export const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({
	control,
	rulesStartDate,
	rulesEndDate,
	name,
	label,
	error,
	helperText,
	disabled = false,
}) => {
	const { t } = useTranslation();
	const currentLanguage = useCurrentLanguage();
	const device = useDeviceDetect();

	const getLocale = React.useMemo(() => {
		const locales = {
			en: en,
			sk: sk,
		};

		return locales[currentLanguage] || en;
	}, [currentLanguage]);

	return (
		<Box>
			<FormLabel sx={{ color: 'text.primary' }}>{label}</FormLabel>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flexStart',
					flexFlow: 'row wrap',
					paddingTop: 2,
					gap: device === 'mobile' ? 3 : 1,
				}}
			>
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale}>
					<Controller
						name={`${name}.start`}
						control={control}
						rules={rulesStartDate}
						disabled={disabled}
						render={({ field: { onChange, value }, fieldState }) => {
							const [start] = React.useState<Date>(new Date(value));

							const errorField = error?.start ? error?.start : fieldState.error;

							const handleStartChange = React.useCallback((newStart: Date | null) => {
								if (!newStart || !isValid(newStart)) {
									return;
								}
								onChange(newStart.toISOString());
							}, []);

							return (
								<DateTimePicker
									value={start}
									onChange={handleStartChange}
									label={t('component.dateTimeRangePicker.body.start.label')}
									sx={{ width: '40%' }}
									slotProps={{
										textField: {
											error: !!errorField,
											helperText:
												errorField ?
													errorField.message
												:	t('component.dateTimeRangePicker.body.start.helperText'),
										},
									}}
								/>
							);
						}}
					/>
					{device !== 'mobile' && <HorizontalRuleIcon fontSize='small' sx={{ marginTop: 2 }} />}
					<Controller
						control={control}
						name={`${name}.end`}
						rules={rulesEndDate}
						disabled={disabled}
						render={({ field: { onChange, value }, fieldState }) => {
							const [end] = React.useState<Date>(new Date(value));

							const errorField = error?.end ? error?.end : fieldState.error;

							const handleEndChange = React.useCallback((newEnd: Date | null) => {
								if (!newEnd || !isValid(newEnd)) {
									return;
								}
								onChange(newEnd.toISOString());
							}, []);

							return (
								<DateTimePicker
									value={end}
									onChange={handleEndChange}
									label={t('component.dateTimeRangePicker.body.end.label')}
									sx={{ width: '40%' }}
									slotProps={{
										textField: {
											error: !!errorField,
											helperText:
												errorField ?
													errorField.message
												:	t('component.dateTimeRangePicker.body.end.helperText'),
										},
									}}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			</Box>
			{helperText && (
				<Typography component='span' sx={{ fontSize: 12 }}>
					{helperText}
				</Typography>
			)}
		</Box>
	);
};
