import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_RowSelectionState as MRTRowSelectionState,
	MaterialReactTable,
	MRT_TableInstance,
	MRT_ColumnFiltersState,
} from 'material-react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Box, Tooltip, IconButton, Typography, Chip, Paper, Stack, CircularProgress } from '@mui/material';
import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	SaveAlt as SaveAltIcon,
	Replay as ReplayIcon,
	Done as DoneIcon,
	Close as CloseIcon,
	MoreHoriz as DotsIcon,
	List as ListIcon,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Link } from '../../components/Link/Link';
import { useNavigate } from '../../hooks/useNavigate';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import {
	ETenantStatus,
	PagedResultPartialTenantModel,
	PartialTenantModel,
	RetryCreateTenantDto,
	TenantModel,
} from '../../api/Api';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { tenantListSchema } from './schema';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { EPermission } from '../../enums/permission/EPermission';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useACL } from '../../hooks/useACL';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';

export const TenantList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const reactQueryClient = useReactQueryClient();
	const formatDate = useFormatDate();
	const authContext = useAuthContext();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [open, setOpen] = React.useState(false);
	const [tenantIDToDelete, setTenantIDToDelete] = React.useState<number | null>(null);
	const [multiTenantIDToDelete, setMultiTenantIDToDelete] = React.useState<{ ids: number[] }>({ ids: [] });
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');
	const [isRefreshNeeded, setIsRefreshNeeded] = React.useState(false);
	const [rowSelection, setRowSelection] = React.useState<MRTRowSelectionState>({});

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'slug', 'createdAt', 'updatedAt', 'status']);

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultPartialTenantModel>({
		queryKey: [EQueryKey.TENANT_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.tenants.getTenants(query);
				response.data.entities.forEach((tenant) => {
					tenantListSchema.parse(tenant);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.tenants.list.confirmation.delete.title'));
				setConfirmationText(t('page.tenants.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.tenants.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.tenants.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenTenantDeleteDialog = React.useCallback(
		(tenantID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setOpen(true);
			setTenantIDToDelete(tenantID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[confirmationTitle, confirmationText],
	);

	const handleOnOpenMultipleTenantsDelete = React.useCallback(
		(table: MRT_TableInstance<Partial<TenantModel>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.tenants.list.errorMessages.noTenantsSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiTenantIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[confirmationTitle, confirmationText, enqueueSnackbar],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const handleOnConfirmTenantDelete = React.useCallback(async () => {
		if (tenantIDToDelete) {
			try {
				await api.tenants.deleteTenant(tenantIDToDelete);
				enqueueSnackbar(t('page.tenants.list.actionMessages.tenantSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setTenantIDToDelete(null);
				updateRowSelection([tenantIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiTenantIDToDelete.ids.length > 0) {
			try {
				await api.tenants.deleteTenants(multiTenantIDToDelete);
				enqueueSnackbar(t('page.tenants.list.actionMessages.tenantsSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiTenantIDToDelete({ ids: [] });
				updateRowSelection(multiTenantIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [
		authContext,
		tenantIDToDelete,
		multiTenantIDToDelete,
		reactQueryClient,
		api.tenants,
		confirmationTitle,
		confirmationText,
		enqueueSnackbar,
	]);

	const handleClose = React.useCallback((): void => setOpen(false), []);

	const handleOnRetry = React.useCallback(
		(tenantID: number) => async (event: React.MouseEvent) => {
			try {
				event.stopPropagation();

				const tenantToCreateDto: RetryCreateTenantDto = {
					tenantID,
				};

				await api.tenants.retryCreateTenant(tenantToCreateDto);

				reactQueryClient.invalidateQueries();
				setIsRefreshNeeded(true);
			} catch (error) {
				console.error(error);
			}
		},
		[reactQueryClient],
	);

	const renderChip = React.useMemo(() => {
		const renderStatusChip = (value: ETenantStatus | undefined) => {
			if (!value) {
				return (
					<Chip
						label={t('page.tenants.list.table.body.unknown')}
						sx={{ bgcolor: '#bdbdbd', color: 'white' }}
					/>
				);
			}

			switch (value) {
				case ETenantStatus.Completed:
					return (
						<Chip
							icon={<DoneIcon />}
							label={t('page.tenants.list.table.body.completed')}
							color='success'
							sx={{ width: '100%' }}
						/>
					);
				case ETenantStatus.Pending:
					return (
						<Chip
							icon={<DotsIcon />}
							label={t('page.tenants.list.table.body.pending')}
							color='info'
							variant='outlined'
							sx={{ width: '100%' }}
						/>
					);
				case ETenantStatus.InProgress:
					return (
						<Chip
							icon={<CircularProgress size={15} />}
							label={t('page.tenants.list.table.body.inProgress')}
							color='warning'
							sx={{ width: '100%' }}
						/>
					);
				case ETenantStatus.Failed:
					return (
						<Chip
							icon={<CloseIcon />}
							label={t('page.tenants.list.table.body.failed')}
							color='error'
							sx={{ width: '100%' }}
						/>
					);
				default:
					return (
						<Chip
							label={t('page.tenants.list.table.body.unknown')}
							sx={{ bgcolor: '#bdbdbd', color: 'white', width: '100%' }}
						/>
					);
			}
		};

		return renderStatusChip;
	}, []);

	const checkRefreshNeeded = React.useCallback(
		(tenants: PartialTenantModel[]): boolean => {
			if (tenants.length === 0) {
				return false;
			}

			let refreshNeeded = false;

			tenants.forEach((tenant) => {
				if (tenant.status === ETenantStatus.Pending || tenant.status === ETenantStatus.InProgress) {
					refreshNeeded = true;
				}
			});

			setIsRefreshNeeded(refreshNeeded);

			return refreshNeeded;
		},
		[reactQueryClient, data, ETenantStatus],
	);

	React.useEffect(() => {
		if (!data || !data.entities) {
			return;
		}

		checkRefreshNeeded(data.entities);
	}, [data]);

	React.useEffect(() => {
		if (!data) {
			return;
		}

		const triggerRefresh = checkRefreshNeeded(data.entities);

		if (triggerRefresh) {
			const timeout = setTimeout(() => {
				reactQueryClient.invalidateQueries();
				setIsRefreshNeeded(false);
			}, 5000);

			return () => clearTimeout(timeout);
		}
	}, [isRefreshNeeded, data]);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	const columns = React.useMemo<MRTColumnDef<Partial<TenantModel>>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.tenants.list.table.columns.name'),
			},
			{
				accessorFn: (row) => `${row.slug}`,
				accessorKey: 'slug',
				header: t('page.tenants.list.table.columns.slug'),
			},
			{
				accessorFn: (row) => `${formatDate(row.createdAt, true)}`,
				accessorKey: 'createdAt',
				filterVariant: 'date-range',
				header: t('page.tenants.list.table.columns.createdAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{renderedCellValue}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${formatDate(row.updatedAt, true)}`,
				accessorKey: 'updatedAt',
				filterVariant: 'date-range',
				header: t('page.tenants.list.table.columns.updatedAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{renderedCellValue}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.status}`,
				accessorKey: 'status',
				header: t('page.tenants.list.table.columns.status'),
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Box
							sx={{
								width: 115,
							}}
						>
							{renderChip(row.original.status)}
						</Box>
					</Box>
				),
			},
			{
				accessorKey: 'edit',
				header: t('page.tenants.list.table.columns.actions'),
				enableSorting: false,
				enableGlobalFilter: false,
				enableColumnFilter: false,
				Cell: ({ row }) => {
					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
							}}
						>
							<Tooltip
								arrow
								placement='left'
								title={t('page.tenants.list.tooltips.editTenant')}
								enterDelay={500}
							>
								<IconButton
									disabled={!isAllowed([EPermission.TENANTS_UPDATE])}
									onClick={handleRedirect(`/tenants/edit/${row.original.id}`)}
								>
									<EditIcon />
								</IconButton>
							</Tooltip>
							<Tooltip
								arrow
								placement='right'
								title={t('page.tenants.list.tooltips.removeTenant')}
								enterDelay={500}
							>
								<IconButton
									disabled={!isAllowed([EPermission.TENANTS_DELETE])}
									color='error'
									onClick={handleOnOpenTenantDeleteDialog(row.original.id as number)}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
							{isAllowed([EPermission.TENANTS_CREATE, EPermission.TENANTS_UPDATE], false) &&
								row.original.status === ETenantStatus.Failed && (
									<Tooltip
										arrow
										placement='right'
										title={t('page.tenants.list.tooltips.retry')}
										enterDelay={500}
									>
										<IconButton onClick={handleOnRetry(row.original.id as number)}>
											<ReplayIcon />
										</IconButton>
									</Tooltip>
								)}
						</Box>
					);
				},
			},
		],
		[authContext, isAllowed],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.tenants.list.title')}
						description={t('page.tenants.list.description')}
						icon={ListIcon}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
						<MaterialReactTable
							columns={columns}
							data={entities}
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination,
								rowSelection,
								showProgressBars: isRefetching,
								columnFilters,
								globalFilter,
								sorting,
								columnVisibility,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error}</>,
							}}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onPaginationChange={setPagination}
							onColumnVisibilityChange={setColumnVisibility}
							enableRowSelection={(row) =>
								isAllowed([EPermission.TENANTS_DELETE]) &&
								row.original.id !== authContext.user?.user?.id
							}
							getRowId={(originalRow) => originalRow.id?.toString() || ''}
							onRowSelectionChange={setRowSelection}
							renderToolbarInternalActions={({ table }) => (
								<Box>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip title={t('page.tenants.list.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									{isAllowed([EPermission.TENANTS_DELETE]) && (
										<Tooltip
											title={t('page.tenants.list.tooltips.removeSelected')}
											enterDelay={500}
										>
											<span>
												<IconButton
													color='error'
													disabled={table.getSelectedRowModel().rows.length === 0}
													onClick={handleOnOpenMultipleTenantsDelete(table)}
												>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 120,
									enableHiding: true,
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1100 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							editDisplayMode='modal'
							positionActionsColumn='last'
							muiTableBodyRowProps={({ row }) => ({
								onClick:
									isAllowed([EPermission.TENANTS_READ]) ?
										handleRedirect(`/tenants/${row.original.id}`)
									:	undefined,
								sx: { cursor: 'pointer' },
							})}
							localization={MRTLocalization}
						/>
					</LocalizationProvider>
				</Stack>
			</Paper>

			{isAllowed([EPermission.TENANTS_CREATE]) && (
				<Link to='/tenants/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.tenants.list.ariaLabel.addTenant')}
						tooltipTitle={t('page.tenants.list.tooltips.addTenant')}
					/>
				</Link>
			)}
			{isAllowed([EPermission.TENANTS_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmTenantDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.tenants.list.confirmation.delete.cancel')}
					confirmText={t('page.tenants.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
