import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineItem,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
	TimelineSeparator,
} from '@mui/lab';
import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import { GetModifiedByTextParams, AuditLogTimelineProps } from './types';
import { ERevType } from './enums';
import { format, isSameDay, isToday } from 'date-fns';
import { formatFieldValue, getAction, getBgColor, omitNullFields, pickValueFields } from './utils';

export const getModifiedByText = ({ modifiedBy, revType, t }: GetModifiedByTextParams) => {
	return (
		<Typography fontSize={18}>
			{getAction(revType, t)} <b>{modifiedBy ?? t('component.auditLogTimeline.systemUser')}</b>
		</Typography>
	);
};

export const AuditLogTimeline = ({
	data,
	hasNextPage,
	isFetchingNextPage,
	fetchNextPage,
	translateFieldKey,
}: AuditLogTimelineProps) => {
	const { t } = useTranslation();

	const theme = useTheme();
	const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

	const renderWaypoint = () => {
		if (!isFetchingNextPage && hasNextPage) {
			return <Waypoint onEnter={() => fetchNextPage()} bottomOffset={-300} />;
		} else if (isFetchingNextPage && hasNextPage) {
			return (
				<Typography px={2} pb={1}>
					{t('component.auditLogTimeline.loading')}
				</Typography>
			);
		}
	};

	return (
		<Timeline
			sx={{
				[`& .${timelineOppositeContentClasses.root}`]: {
					flex: matchesMd ? 0.2 : 1,
				},
				p: 0,
			}}
		>
			{data.map((auditLog, index) => {
				const { modifiedAt, modifiedBy, revID, revType } = auditLog;

				const isCreate = revType === ERevType.Create;
				const isFirst = index === 0;
				const isTodayAuditLog = isToday(modifiedAt);
				const isSameDayAuditLog = isFirst ? false : isSameDay(modifiedAt, data[index - 1].modifiedAt);
				const valueFields = pickValueFields(auditLog);
				const valueFieldKeys =
					isCreate ?
						(Object.keys(valueFields) as Array<keyof typeof valueFields>)
					:	omitNullFields(valueFields);

				return (
					<TimelineItem key={revID} sx={{ flexDirection: matchesMd ? 'row' : 'column', my: 1 }}>
						<TimelineOppositeContent
							sx={{ pl: 0, pr: matchesMd ? 2 : 0, py: matchesMd ? 0 : 1, width: '100%' }}
						>
							<Grid container>
								<Grid item xs={8}>
									{!isSameDayAuditLog && (
										<Stack alignItems='flex-start' spacing={0.5}>
											{isTodayAuditLog && (
												<Typography fontSize={18}>
													{t('component.auditLogTimeline.today').toUpperCase()}
												</Typography>
											)}
											<Typography
												color={isTodayAuditLog ? 'grey' : undefined}
												fontSize={18}
												textAlign='left'
											>
												{format(modifiedAt, 'dd MMM yyyy')}
											</Typography>
										</Stack>
									)}
								</Grid>
								<Grid item xs={4} alignItems='flex-end'>
									<Typography fontSize={18}>{format(modifiedAt, 'HH:mm')}</Typography>
								</Grid>
							</Grid>
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineConnector
								sx={{
									width: matchesMd ? 4 : '100%',
									height: matchesMd ? '100%' : 4,
									bgcolor: getBgColor(revType),
								}}
							/>
						</TimelineSeparator>
						<TimelineContent sx={{ bgcolor: '#fafafa' }}>
							<Stack spacing={1}>
								{getModifiedByText({ modifiedBy, revType, t })}
								<Stack spacing={2}>
									{valueFieldKeys.map((fieldKey) => (
										<Typography key={fieldKey} color='grey'>
											{translateFieldKey(fieldKey)}:{' '}
											<Box component='span' color='black' fontWeight='bold'>
												{formatFieldValue({
													t,
													value: auditLog[fieldKey],
												})}
											</Box>
										</Typography>
									))}
								</Stack>
							</Stack>
						</TimelineContent>
					</TimelineItem>
				);
			})}
			{renderWaypoint()}
		</Timeline>
	);
};
