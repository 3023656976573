import * as React from 'react';

type DeviceType = 'mobile' | 'tablet' | 'desktop';

export const useDeviceDetect = (): DeviceType => {
	const [deviceType, setDeviceType] = React.useState<DeviceType>('desktop');

	React.useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			if (width < 768) {
				setDeviceType('mobile');
			} else if (width < 992) {
				setDeviceType('tablet');
			} else {
				setDeviceType('desktop');
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return deviceType;
};
