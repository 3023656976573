import React, { useMemo } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { EPAMTargetType, PAMTargetDetailModel } from '../../api/Api';
import { AuditLogTimeline } from '../../components/AuditLogTimeline/AuditLogTimeline';
import { Preloader } from '../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { Heading } from '../../components/Heading/Heading';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { auditLogListSchema } from './schema';
import { getDurationInString } from '../../utils/DateTime';
import { convertBytesToMB } from '../../utils/FormatSize';

const LIMIT = 10;

export const AuditLogsSection: React.FC<{ id: PAMTargetDetailModel['id'] }> = ({ id }) => {
	const { t } = useTranslation();

	const theme = useTheme();
	const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

	const api = useSwaggerApi();

	const { data, hasNextPage, isFetchingNextPage, isLoading, fetchNextPage } = useInfiniteQuery({
		queryKey: [EQueryKey.PAM_TARGET_AUDIT_LOG_LIST_QUERY, id],
		queryFn: async ({ pageParam = 0 }) => {
			const query = { limit: LIMIT, offset: pageParam as number, sort: ['-modifiedAt'] };

			const { data } = await api.pam.getTargetAuditLogs(id, query);

			return data;
		},
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages) => {
			const entities = allPages.flatMap((p) => p.entities);
			if (entities.length <= lastPage.total - 1) {
				return allPages.length * LIMIT;
			}

			return;
		},
		select: (data) => {
			const entitiesFlat = data.pages.flatMap((p) => p.entities);
			entitiesFlat.forEach((entity) => {
				auditLogListSchema.parse(entity);
			});

			return entitiesFlat;
		},
	});

	const filteredData = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.map((auditLog) => {
			const { targetID, tenantID, type, ...valueFields } = auditLog;

			const recordingRetentionPeriod =
				typeof valueFields.recordingRetentionPeriod === 'number' ?
					getDurationInString(valueFields.recordingRetentionPeriod * 60)
				:	null;

			const fileUploadSizeLimit =
				typeof valueFields.fileUploadSizeLimit === 'number' ?
					`${convertBytesToMB(valueFields.fileUploadSizeLimit)} MB`
				:	null;

			const formattedValueFields = {
				...valueFields,
				recordingRetentionPeriod,
				fileUploadSizeLimit,
			};

			if (type === EPAMTargetType.SSH) {
				const {
					domain,
					remoteApplicationName,
					remoteApplicationWorkingDir,
					remoteApplicationCommandLineArgs,
					securityMode,
					ignoreCertificate,
					...sshTargetValueFields
				} = formattedValueFields;

				return sshTargetValueFields;
			}

			return formattedValueFields;
		});
	}, [data]);

	return (
		<Box>
			{isLoading && <Preloader />}
			{!isLoading && (
				<Box>
					<Stack spacing={matchesMd ? 4 : 2}>
						<Stack>
							<Heading
								label={t('page.pam.detail.sections.auditLogs.title')}
								fontSize={20}
								fontWeight={800}
							/>
							<Typography variant='body1' px={1}>
								{t('page.pam.detail.sections.auditLogs.description')}
							</Typography>
						</Stack>
						<AuditLogTimeline
							data={filteredData}
							fetchNextPage={fetchNextPage}
							hasNextPage={hasNextPage}
							isFetchingNextPage={isFetchingNextPage}
							translateFieldKey={(fieldKey) => t(`page.pam.detail.auditLogs.fields.${fieldKey}`)}
						/>
					</Stack>
				</Box>
			)}
		</Box>
	);
};
