import * as React from 'react';
import { format, isValid } from 'date-fns';

// eslint-disable-next-line no-warning-comments
// TODO Get rid of this hook and use the formatDate function from utils/DateTime - not need to wrap simple functions with useCallback - premature unscientific optimization
export const useFormatDate = () => {
	const formatDate = React.useCallback((date: Date | string | undefined, includeTime = false) => {
		if (!date) {
			return 'N/A';
		}

		const parsedDate = new Date(date);

		if (!isValid(parsedDate)) {
			return 'N/A';
		}

		const formatString = includeTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';

		return format(parsedDate, formatString);
	}, []);

	return formatDate;
};
