import { TFunction } from 'i18next';

export const getDays = (t: TFunction) => [
	{ label: t('component.weekdaySelector.body.days.sunday.short'), value: 'sunday' },
	{ label: t('component.weekdaySelector.body.days.monday.short'), value: 'monday' },
	{ label: t('component.weekdaySelector.body.days.tuesday.short'), value: 'tuesday' },
	{ label: t('component.weekdaySelector.body.days.wednesday.short'), value: 'wednesday' },
	{ label: t('component.weekdaySelector.body.days.thursday.short'), value: 'thursday' },
	{ label: t('component.weekdaySelector.body.days.friday.short'), value: 'friday' },
	{ label: t('component.weekdaySelector.body.days.saturday.short'), value: 'saturday' },
];
