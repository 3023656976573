export function convertMBtoBytes(mb: string): number {
	if (mb === '') {
		return 0;
	}
	const bytesPerMB = 1024 * 1024;

	return parseInt(mb) * bytesPerMB;
}

export function convertBytesToMB(bytes: number): string {
	const bytesPerMB = 1024 * 1024;
	const result = bytes / bytesPerMB;

	return result.toString();
}
