import { z } from 'zod';
import { TFunction } from 'i18next';

/*
 * Validation schema for registration form
 */
export const getRegistrationSchema = (t: TFunction) => {
	return z.object({
		password: z.string().min(1, t('page.registration.form.password.required')),
	});
};
