import * as React from 'react';
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_RowSelectionState as MRTRowSelectionState,
	type MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';
import { Box, Typography } from '@mui/material';
import { SearchSelectorProps, SearchedUserInput } from './types';
import { formatElapsedTime } from '../../utils/SessionDateHelpers';
import { useTranslation } from 'react-i18next';

export const SearchSelector: React.FC<SearchSelectorProps> = ({
	recording,
	userInput,
	actualPosition,
	onSeek,
}): JSX.Element => {
	const { t } = useTranslation();

	const [rowSelection, setRowSelection] = React.useState<MRTRowSelectionState>({});

	const highlightSearchTerm = React.useCallback((text: string, searchTerm: string): JSX.Element => {
		if (!searchTerm) {
			return <>{text}</>;
		}

		const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));

		return (
			<>
				{parts.map((part, index) => {
					if (part.toLowerCase() === searchTerm.toLowerCase()) {
						return (
							<span key={`serch-term-${index}`} style={{ color: 'red' }}>
								{part}
							</span>
						);
					}

					return <span key={`user-input-${index}`}>{part}</span>;
				})}
			</>
		);
	}, []);

	const handleOnChangePosition = React.useCallback(
		(id: number, position: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setRowSelection({ [id]: true });
			if (typeof position === 'number') {
				onSeek(position * 1000);
			}
		},
		[recording],
	);

	const highlightActualPositionInTable = React.useCallback(() => {
		const actualRowSelectionKeys = Object.keys(rowSelection);
		const actualPositionInSec = Math.floor(actualPosition / 1000);

		if (userInput[0].position > actualPositionInSec) {
			if (actualRowSelectionKeys.length > 0) {
				setRowSelection({});
			}

			return;
		}

		userInput.forEach((input, index) => {
			const nextInputPosition = userInput[index + 1]?.position;

			if (
				(input.position <= actualPositionInSec && nextInputPosition > actualPositionInSec) ||
				(input.position <= actualPositionInSec && !nextInputPosition)
			) {
				if (actualRowSelectionKeys[0] !== input.id.toString()) {
					setRowSelection({ [input.id]: true });
				}

				return;
			}
		});
	}, [userInput, actualPosition, rowSelection]);

	React.useEffect(() => {
		highlightActualPositionInTable();
	}, [actualPosition]);

	const columns: MRTColumnDef<SearchedUserInput>[] = React.useMemo(
		() => [
			{
				accessorFn: (row) => `${row.position}`,
				header: t('component.videoplayer.searchSelector.table.header.position'),
				grow: false,
				size: 110,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Typography>{formatElapsedTime(renderedCellValue as number)}</Typography>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.line}`,
				header: t('component.videoplayer.searchSelector.table.header.searchedText'),
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Typography ml='2%'>
							{highlightSearchTerm(row.original.line, row.original.searchTerm)}
						</Typography>
					</Box>
				),
			},
		],
		[],
	);

	const table = useMaterialReactTable({
		columns,
		data: userInput,
		layoutMode: 'grid',
		enableRowNumbers: true,
		editDisplayMode: 'modal',
		enableStickyHeader: true,
		enableTableFooter: false,
		enableTopToolbar: false,
		enableBottomToolbar: false,
		enablePagination: false,
		enableColumnActions: false,
		enableSorting: false,
		initialState: { density: 'compact' },
		muiTableContainerProps: { sx: { maxHeight: 'inherit' } },
		getRowId: (row) => row.id.toString(),
		enableMultiRowSelection: false,
		muiTableBodyRowProps: ({ row }) => ({
			onClick: handleOnChangePosition(row.original.id, row.original.position),
			selected: rowSelection[row.original.id],
			sx: {
				cursor: 'pointer',
			},
		}),
		muiSelectCheckboxProps: {
			sx: {
				width: '50px',
				height: '50px',
			},
		},
		muiSelectAllCheckboxProps: {
			sx: {
				width: '50px',
				height: '50px',
			},
		},
		muiTableHeadCellProps: {
			sx: {
				verticalAlign: 'baseline',
			},
		},
		onRowSelectionChange: setRowSelection,
		state: { rowSelection },
		muiTablePaperProps: ({ table }) => ({
			style: {
				maxHeight: 'inherit',
				zIndex: table.getState().isFullScreen ? 1100 : undefined,
				boxShadow: 'none',
				outline: '1px solid #e0e0e0',
			},
		}),
	});

	return <MaterialReactTable table={table} />;
};
