import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, ListItem, List, Button } from '@mui/material';
import { ChipArrayProps, ChipDataType, ExtendedChipDataType } from './types';

export const ChipArray = <T extends ChipDataType>({
	chipList,
	limitTags = chipList?.length || 0,
	wrap = false,
	onChipClick,
}: ChipArrayProps<T>) => {
	const [showAll, setShowAll] = React.useState(false);
	const { t } = useTranslation();

	const handleOnClick = React.useCallback(
		(args: ExtendedChipDataType<T>) => (event: React.MouseEvent) => {
			event.stopPropagation();
			if (!onChipClick) {
				return;
			}

			onChipClick(args);
		},
		[onChipClick],
	);

	const slicedList = showAll ? chipList : chipList?.slice(0, limitTags);

	const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		setShowAll((prev) => !prev);
	};

	return (
		<List
			sx={{
				display: 'inline-flex',
				flexDirection: 'row',
				flexWrap: wrap ? 'wrap' : 'no-wrap',
				gap: 1,
			}}
		>
			{chipList &&
				slicedList?.map((element, index) => {
					return (
						<ListItem key={element.id} sx={{ padding: 0, width: 'auto' }}>
							<Chip
								key={index}
								label={element.name}
								onClick={handleOnClick(element)}
								color='default'
								variant='outlined'
								sx={{
									color: element.textColor ?? '#000000',
									background: element.bgColor ?? '#dadada',
									borderColor: element.borderColor ?? '#dadada',
									padding: 1,
									cursor: onChipClick ? 'pointer' : 'default',
								}}
							/>
						</ListItem>
					);
				})}
			{chipList && (chipList.length > limitTags || showAll) && (
				<ListItem key='more' sx={{ padding: 0, width: 'auto' }}>
					<Button variant='text' sx={{ textTransform: 'none' }} onClick={onClick}>
						{showAll ?
							t('component.chipArray.body.less')
						:	`+ ${chipList?.length - limitTags} ${t('component.chipArray.body.more')}`}
					</Button>
				</ListItem>
			)}
		</List>
	);
};
