import * as React from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent, FormHelperText, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { resolveEMailSMTPSecurity } from '../../enums/mail/EMailSMTPSecurity';
import { SMTPSecurityProps } from './types';

export const SMTPSecurity: React.FC<SMTPSecurityProps> = ({ register, errors, setSecurity, security, disabled }) => {
	const { t } = useTranslation();

	return (
		<FormControl fullWidth>
			<InputLabel id='select-label' shrink>
				{t('page.setupWizard.texts.SMTP.form.security.label')}
			</InputLabel>
			<Select
				{...register('security')}
				id='select'
				labelId='select-label'
				label={t('page.setupWizard.texts.SMTP.form.security.label')}
				value={security}
				disabled={disabled}
				onChange={(event: SelectChangeEvent<string>) =>
					setSecurity(resolveEMailSMTPSecurity(event.target.value))
				}
				fullWidth
			>
				<MenuItem value='none'>
					{t('page.setupWizard.texts.SMTP.form.security.options.none')}
					<span style={{ fontSize: '0.8rem', color: '#666', marginLeft: '8px' }}>
						{t('page.setupWizard.texts.SMTP.form.security.options.noneHelper')}
					</span>
				</MenuItem>
				<MenuItem value='tls'>
					{t('page.setupWizard.texts.SMTP.form.security.options.tls')}
					<span style={{ fontSize: '0.8rem', color: '#666', marginLeft: '8px' }}>
						{t('page.setupWizard.texts.SMTP.form.security.options.tlsHelper')}
					</span>
				</MenuItem>
				<MenuItem value='starttls'>
					{t('page.setupWizard.texts.SMTP.form.security.options.starttls')}
					<span style={{ fontSize: '0.8rem', color: '#666', marginLeft: '8px' }}>
						{t('page.setupWizard.texts.SMTP.form.security.options.starttlsHelper')}
					</span>
				</MenuItem>
			</Select>
			{errors.security && typeof errors.security?.message === 'string' ?
				<FormHelperText error id='security-error'>
					{errors.security.message}
				</FormHelperText>
			:	<FormHelperText>{t('page.setupWizard.texts.SMTP.form.security.helperText')}</FormHelperText>}
		</FormControl>
	);
};
