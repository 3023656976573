import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { CountUpProps } from './types';

const easeOutQuart = (t: number, b: number, c: number, d: number) => {
	t /= d;
	t--;

	return -c * (t * t * t * t - 1) + b;
};

const easeOutCubic = (t: number, b: number, c: number, d: number) => {
	t /= d;
	t--;

	return c * (t * t * t + 1) + b;
};

export const CountUp: React.FC<CountUpProps> = ({ value, duration = 2000 }) => {
	const [count, setCount] = React.useState(0);

	React.useEffect(() => {
		let raf: number;
		let startTimestamp: number;

		const step = (timestamp: number) => {
			if (!startTimestamp) {
				startTimestamp = timestamp;
			}

			const progress = timestamp - startTimestamp;

			setCount(Math.round(easeOutQuart(progress, 0, value, duration)));

			if (progress < duration) {
				raf = window.requestAnimationFrame(step);
			} else {
				setCount(Math.round(value));
			}
		};
		raf = window.requestAnimationFrame(step);

		return () => cancelAnimationFrame(raf);
	}, [value, duration]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography variant='h4'>{count}</Typography>
		</Box>
	);
};
