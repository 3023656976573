import * as React from 'react';
import { RecordingState } from './useControl';
import { useTranslation } from 'react-i18next';

export type StatusType = 'loading' | 'start' | 'playing' | 'retry' | 'seek' | 'error';

type Props = {
	initState: RecordingState | null;
	message: string | null;
};

export default function useMessage({ initState, message }: Props) {
	const [visibility, setVisibility] = React.useState(false);
	const [text, setText] = React.useState('');

	const { t } = useTranslation();

	React.useEffect(() => {
		if (!initState || initState === 'started') {
			setVisibility(false);
		} else {
			setVisibility(true);
		}

		if (initState === 'seek') {
			setText(t('component.videoplayer.message.playerBuffering'));
		}

		if (initState === 'error') {
			setText(message ? message : t('component.videoplayer.message.notPlayable'));
		}
	}, [initState]);

	return {
		visibility,
		text,
	};
}
