import { z } from 'zod';
import { TFunction } from 'i18next';

import { ESignatureMethod, EventTypeModel } from '../../../../api/Api';

const MAX_FILE_SIZE = 500000;

export const getWebhookFormSchema = (t: TFunction) =>
	z
		.object({
			url: z.string().url(),
			eventTypeIDs: z.array(z.number()),
			signatureMethod: z.nativeEnum(ESignatureMethod),
			secret: z.string().optional(),
			useTLS: z.boolean(),
			caCertificate: z.string().optional(),
			file: z
				.instanceof(File)
				.optional()
				.nullable()
				.refine((file) => !file || file.size <= MAX_FILE_SIZE, {
					message: t('page.oAuthClients.webhooks.form.errorMessages.fileMaxLength'),
				}),
		})
		.refine((data) => (data.signatureMethod === ESignatureMethod.Hmac ? !!data.secret : true), {
			message: t('page.oAuthClients.webhooks.form.errorMessages.requiredSecret'),
			path: ['secret'],
		});

export const webhookSchema = z.object({
	id: z.number(),
	oAuthClientID: z.string(),
	createdAt: z.string(),
	deliveryFailure: z.boolean(),
	caCertificate: z.string().optional(),
	secret: z.string().optional(),
	useTLS: z.boolean(),
	signatureMethod: z.nativeEnum(ESignatureMethod),
	enabled: z.boolean(),
	eventTypes: z.array(
		z.object({
			id: z.number(),
			name: z.string(),
		}) satisfies z.ZodSchema<EventTypeModel>,
	),
	url: z.string(),
});
