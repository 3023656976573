import * as React from 'react';
import { Typography, TextField } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { XMLProviderProps } from './types';
import { SUPPORTED_FILE_TYPE } from './constants';

export const XMLProvider: React.FC<XMLProviderProps> = ({ register }) => {
	const [file, setFile] = React.useState(null);

	const handleChange = (file: any) => {
		setFile(file);
	};

	return (
		<>
			<Typography variant='body1'>
				To configure Service provider you must provide its metadata using one of following ways.
			</Typography>

			<TextField
				label='URL'
				variant='outlined'
				type='text'
				fullWidth
				helperText='URL where service provider metadata resides'
				{...register('url')}
			/>

			<TextField
				label='XML'
				variant='outlined'
				type='text'
				helperText='Service provider XML metadata'
				multiline
				minRows={10}
				maxRows={10}
				fullWidth
				inputProps={{
					spellCheck: false,
					style: {
						fontFamily: 'monospace',
						fontSize: '0.875rem',
						whiteSpace: 'nowrap',
					},
				}}
				{...register('xml')}
			/>

			<FileUploader
				multiple={true}
				handleChange={handleChange}
				name='file'
				label='Upload or drop metadata .XML file'
				types={SUPPORTED_FILE_TYPE}
			/>
		</>
	);
};
