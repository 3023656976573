import * as React from 'react';
import { Box, CardMedia, Stack } from '@mui/material';
import useControl from '../../hooks/useControl';
import useMessage from '../../hooks/useMessage';
import Guacamole from 'guacamole-client';
import { Controls } from './Controls';
import { Message } from './Message';
import screenfull from 'screenfull';
import { SearchSelector } from './SearchSelector';
import { SearchedUserInput } from './types';

type PAMSessionPlayerProps = {
	src: string;
	width?: string | number;
	autoplay?: boolean;
	userInput?: SearchedUserInput[];
	startPosition?: number;
};

export const PAMSessionPlayer: React.FunctionComponent<PAMSessionPlayerProps> = ({
	src,
	width = '100%',
	autoplay = false,
	userInput,
	startPosition,
}) => {
	const [filename, setFilename] = React.useState<string>('File name');
	const [showControls, setShowControls] = React.useState<boolean>(true);

	const guacClientRef = React.useRef<Guacamole.SessionRecording | null>(null);
	const displayRef = React.useRef<HTMLDivElement>(null);
	const cardMediaRef = React.useRef<HTMLDivElement>(null);

	const {
		playbackPosition,
		seekProgress,
		duration,
		isPlaying,
		seek,
		play,
		cancelSeek,
		handleError,
		recordingState,
		errorMessage,
	} = useControl(guacClientRef.current, autoplay, startPosition);

	const { visibility, text } = useMessage({ initState: recordingState, message: errorMessage });

	const extractFilename = React.useCallback((url: string): void => {
		try {
			const parts = url.split('/');
			const filename = parts[parts.length - 1];

			setFilename(filename);
		} catch (error: unknown) {
			handleError('Error occurred while extracting the filename');
		}
	}, []);

	const fitDisplay = React.useCallback((): void => {
		const cardMediaWidth = cardMediaRef.current?.clientWidth;
		const cardMediaHeight = cardMediaRef.current?.clientHeight;

		if (!guacClientRef.current || !cardMediaWidth || !cardMediaHeight) {
			return;
		}

		const currentCanvas = guacClientRef.current.getDisplay();

		if (!currentCanvas) {
			return;
		}

		const canvasWidth = currentCanvas.getWidth();
		const canvasHeight = currentCanvas.getHeight();

		if (canvasWidth === 0 || canvasHeight === 0) {
			return;
		}

		const scaleX = cardMediaWidth / canvasWidth;
		const scaleY = cardMediaHeight / canvasHeight;

		const scaleFactor = Math.min(scaleX, scaleY);

		currentCanvas.scale(scaleFactor);
	}, [cardMediaRef, guacClientRef]);

	const handleFullScreenMode = React.useCallback((): void => {
		if (cardMediaRef && cardMediaRef.current) {
			screenfull.toggle(cardMediaRef.current);

			fitDisplay();
		}
	}, [cardMediaRef]);

	const handleShowControls = React.useCallback(
		(value: boolean) => () => {
			setShowControls(value);
		},
		[],
	);

	const mount = React.useCallback(
		(sessionRecording: Guacamole.SessionRecording): void => {
			if (!sessionRecording.getDuration()) {
				setTimeout(() => mount(sessionRecording), 200);

				return;
			}

			if (!displayRef.current) {
				return;
			}

			displayRef.current.innerHTML = '';
			displayRef.current.appendChild(sessionRecording.getDisplay().getElement());
		},
		[displayRef],
	);

	React.useEffect(() => {
		const httpTunnel = new Guacamole.StaticHTTPTunnel(src);
		const sessionRecording = new Guacamole.SessionRecording(httpTunnel);

		sessionRecording.connect();

		mount(sessionRecording);

		guacClientRef.current = sessionRecording;

		extractFilename(src);
	}, []);

	React.useEffect(() => {
		const updateCardMediaHeight = (): void => {
			if (cardMediaRef.current) {
				const cardMediaWidth = cardMediaRef.current.clientWidth;
				cardMediaRef.current.style.height = `${cardMediaWidth * 0.5625}px`;
			}

			fitDisplay();
		};

		window.addEventListener('resize', updateCardMediaHeight);
		updateCardMediaHeight();

		return () => {
			window.removeEventListener('resize', updateCardMediaHeight);
		};
	}, [cardMediaRef.current?.clientWidth, cardMediaRef.current?.clientHeight]);

	React.useEffect(() => {
		if (displayRef.current) {
			const resizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					const { width, height } = entry.contentRect;

					if (width === 0 || height === 0) {
						return;
					}

					window.requestAnimationFrame(() => {
						fitDisplay();
					});
				}
			});

			resizeObserver.observe(displayRef.current);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [displayRef.current]);

	return (
		<Stack spacing={2} direction='column' sx={{ height: '100%' }}>
			<CardMedia
				style={{
					position: 'relative',
					minWidth: '292px',
					maxWidth: '100%',
					minHeight: '164px',
					width: width,
					height: 'auto',
					display: 'flex',
					alignItems: 'center',
					overflow: 'clip',
					background: 'black',
				}}
				ref={cardMediaRef}
				onMouseEnter={handleShowControls(true)}
				onMouseLeave={handleShowControls(false)}
			>
				<Box
					ref={displayRef}
					style={{
						width: '100%',
						height: 'auto',
						display: 'flex',
						alignItems: 'center',
						filter: recordingState === 'seek' ? 'blur(5px)' : 'none',
					}}
				/>
				<Message
					text={text}
					visibility={visibility}
					status={recordingState}
					progress={seekProgress}
					onPlay={play}
					onCancelSeek={cancelSeek}
				/>
				<Controls
					filename={filename}
					isPlaying={isPlaying}
					position={playbackPosition}
					duration={duration}
					status={recordingState}
					userInput={userInput}
					onPlay={play}
					onSeek={seek}
					showControls={showControls}
					fullScreenMode={handleFullScreenMode}
				/>
			</CardMedia>
			{userInput && (
				<Box sx={{ flexGrow: 1, maxHeight: 250 }}>
					<SearchSelector
						recording={guacClientRef.current}
						userInput={userInput}
						onSeek={seek}
						actualPosition={playbackPosition}
					/>
				</Box>
			)}
		</Stack>
	);
};
