import * as React from 'react';
import { Typography, Grid, Button, Stack, useMediaQuery, useTheme, Box, Tooltip } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';

import { SmtpFormData } from '../types';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { FormMailServerValues, MailServerStepProps, SMTPConfigState, SubmitFormState } from '../types';
import { getMailServerFormSchema } from '../schema';
import { Preloader } from '../../../components/Preloader/Preloader';
import { resolveEMailSMTPSecurity } from '../../../enums/mail/EMailSMTPSecurity';
import { SMTPSecurity } from '../../../components/SMTPSecurity/SMTPSecurity';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { PasswordField } from '../../../components/FormFields/PasswordField/PasswordField';
import { useACL } from '../../../hooks/useACL';
import { EPermission } from '../../../enums/permission/EPermission';

export const MailServerStep: React.FC<MailServerStepProps> = ({
	setActiveStepEmailSetup,
	setFormDataSmtp,
	formDataSmtp,
}) => {
	const api = useSwaggerApi();
	const { t } = useTranslation();
	const { id } = useParams();
	const theme = useTheme();
	const { isAllowed } = useACL();

	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const [shouldDisableForm, setShouldDisableForm] = React.useState(false);
	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitted: false,
		submitting: false,
		error: null,
	});
	const [smtpConfigState, setSmtpConfigState] = React.useState<SMTPConfigState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [security, setSecurity] = React.useState<string>(resolveEMailSMTPSecurity('starttls'));
	const getStackWidth = (): string => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<FormMailServerValues>({
		mode: 'onChange',
		resolver: zodResolver(getMailServerFormSchema(t)),
		defaultValues: {
			name: formDataSmtp.name,
			description: formDataSmtp.description,
			host: formDataSmtp.host,
			port: formDataSmtp.port.toString(),
			username: formDataSmtp.username,
			password: formDataSmtp.password,
			sender: formDataSmtp.sender,
			security: security,
		},
	});

	const onSubmit = React.useCallback<SubmitHandler<FormMailServerValues>>(
		(formData): void => {
			setActiveStepEmailSetup(1);
			setSubmitFormState({
				submitted: false,
				submitting: true,
				error: null,
			});

			setFormDataSmtp((prevState: SmtpFormData) => ({
				...prevState,
				name: formData.name,
				description: formData.description,
				host: formData.host,
				port: Number(formData.port),
				username: formData.username,
				password: formData.password,
				sender: formData.sender,
				security: resolveEMailSMTPSecurity(security),
			}));
			setSubmitFormState({
				submitted: true,
				submitting: false,
				error: null,
			});
		},
		[formDataSmtp, setFormDataSmtp, enqueueSnackbar, setSubmitFormState, setActiveStepEmailSetup, security],
	);

	const getSmtpMailSettings = React.useCallback(
		async (configID: number): Promise<void> => {
			setSmtpConfigState({
				loading: true,
				loaded: false,
				data: null,
				error: null,
			});

			try {
				const response = await api.settings.readSmtpConfiguration(configID);
				setSmtpConfigState({
					loading: false,
					loaded: true,
					data: response.data,
					error: null,
				});

				reset({
					name: response.data.name,
					description: response.data.description,
					host: response.data.host,
					port: response.data.port.toString(),
					username: response.data.username,
					sender: response.data.sender,
				});
				setSecurity(response.data.security);
			} catch (error) {
				setSmtpConfigState({
					loading: false,
					loaded: false,
					data: null,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[api.pam, enqueueSnackbar, reset, setSmtpConfigState, setFormDataSmtp, t],
	);

	React.useEffect(() => {
		if (id && !smtpConfigState.loading && !smtpConfigState.loaded && !smtpConfigState.error) {
			getSmtpMailSettings(Number(id));
		}
	}, [id, setSmtpConfigState]);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !smtpConfigState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || smtpConfigState.loading)) {
			setShouldDisableForm(true);
		}
	}, [setSmtpConfigState, submitFormState, shouldDisableForm]);

	return (
		<Box component={'form'} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
			{id && (smtpConfigState.loading || !smtpConfigState.loaded) ?
				<Preloader />
			:	<Stack
					direction='column'
					sx={{
						width: getStackWidth(),
					}}
				>
					<Typography>{t('page.smtp.edit.subtitle.chooseConfig')}</Typography>
					<Grid container spacing={2} sx={{ paddingTop: 2 }}>
						<Grid item xs={12}>
							<TextField
								name={'name'}
								register={register}
								label={t('page.smtp.edit.form.name.label')}
								error={errors.name}
								disabled={shouldDisableForm}
								helperText={t('page.smtp.edit.form.name.helperText')}
							/>
						</Grid>
						<Grid item xs={12} sx={{ paddingBottom: 2 }}>
							<TextField
								name={'description'}
								register={register}
								label={t('page.smtp.edit.form.description.label')}
								error={errors.description}
								disabled={shouldDisableForm}
								helperText={t('page.smtp.edit.form.description.helperText')}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<TextField
								name={'host'}
								register={register}
								label={t('page.smtp.edit.form.host.label')}
								error={errors.host}
								disabled={shouldDisableForm}
								helperText={t('page.smtp.edit.form.host.helperText')}
							/>
						</Grid>
						<Grid item xs={4} sx={{ paddingBottom: 2 }}>
							<TextField
								name={'port'}
								register={register}
								label={t('page.smtp.edit.form.port.label')}
								error={errors.port}
								disabled={shouldDisableForm}
								helperText={t('page.smtp.edit.form.port.helperText')}
							/>
						</Grid>
						<Grid item xs={12}>
							<SMTPSecurity
								register={register}
								errors={errors}
								setSecurity={setSecurity}
								security={resolveEMailSMTPSecurity(security)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name={'username'}
								register={register}
								label={t('page.smtp.edit.form.username.label')}
								error={errors.username}
								disabled={shouldDisableForm}
								helperText={t('page.smtp.edit.form.username.helperText')}
							/>
						</Grid>
						<Grid item xs={12}>
							<PasswordField
								name={'password'}
								register={register}
								label={t('page.smtp.edit.form.password.label')}
								error={errors.password}
								disabled={shouldDisableForm}
								helperText={t('page.smtp.edit.form.password.helperText')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name={'sender'}
								register={register}
								label={t('page.smtp.edit.form.sender.label')}
								error={errors.sender}
								disabled={shouldDisableForm}
								helperText={t('page.smtp.edit.form.sender.helperText')}
							/>
						</Grid>
					</Grid>
					<Stack direction='row' justifyContent='flex-end'>
						{isAllowed([EPermission.SMTP_CREATE, EPermission.SMTP_UPDATE], false) && (
							<Tooltip
								arrow
								placement='bottom'
								title={t('page.smtp.edit.tooltips.next')}
								enterDelay={500}
							>
								<Button variant='contained' type='submit'>
									{t('page.smtp.edit.body.button.next')}
								</Button>
							</Tooltip>
						)}
					</Stack>
				</Stack>
			}
		</Box>
	);
};
