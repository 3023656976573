import * as React from 'react';
import Guacamole from 'guacamole-client';
import { useTranslation } from 'react-i18next';

export type RecordingState = 'unloaded' | 'loaded' | 'started' | 'ended' | 'error' | 'seek';

export type PlayerState = RecordingState | 'ready';

export type SeekProgressType = {
	current: number;
	total: number;
};

export default function useControl(
	recording: Guacamole.SessionRecording | null,
	autoPlay: boolean,
	startPosition?: number,
) {
	const [recordingState, setRecordingState] = React.useState<RecordingState | null>(null);
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
	const [playbackPosition, setPlaybackPosition] = React.useState(0);
	const [duration, setDuration] = React.useState(0);
	const [seekPosition, setSeekPosition] = React.useState<null | number>(null);
	const [seekProgress, setSeekProgress] = React.useState<SeekProgressType>({ current: 0, total: 0 });
	const [isPlaying, setIsPlaying] = React.useState(false);

	const { t } = useTranslation();

	let currentPlayingState: boolean | null = null;

	const handleError = (message: string): void => {
		setRecordingState('error');
		setErrorMessage(message);
	};

	const handleRecordingState = React.useCallback(
		(state: RecordingState): void => {
			if (errorMessage) {
				return;
			}

			setRecordingState(state);
		},
		[errorMessage],
	);

	const seek = React.useCallback(
		(newValue: number): void => {
			if (!recording) {
				handleError(t('component.videoplayer.message.clientNotInit'));

				return;
			}

			if (newValue === null || newValue === undefined) {
				handleError(t('component.videoplayer.message.clientNotInit'));

				return;
			}

			if (currentPlayingState === null) {
				currentPlayingState = recording.isPlaying();
				recording.pause();
			}

			setPlaybackPosition(newValue);
			handleRecordingState('seek');

			setSeekProgress({ current: 0, total: 0 });

			setSeekPosition(null);

			recording.seek(newValue, (): void => {
				setPlaybackPosition(seekPosition || newValue);

				if (currentPlayingState === true) {
					recording.play();
				}
				handleRecordingState('started');

				currentPlayingState = null;

				return;
			});
		},
		[recording, currentPlayingState],
	);

	const cancelSeek = React.useCallback((): void => {
		recording && recording.cancel();
		setPlaybackPosition(seekPosition || playbackPosition);
	}, [recording, seekPosition, playbackPosition]);

	const play = React.useCallback((): void => {
		if (!recording) {
			handleError(t('component.videoplayer.message.clientNotInit'));

			return;
		}

		if (recordingState === 'seek') {
			return;
		}

		if (isPlaying) {
			recording.pause();
		} else if (playbackPosition === duration) {
			seek(0);
		} else {
			recording.play();
		}
	}, [recording, recordingState, isPlaying, playbackPosition, duration]);

	React.useEffect(() => {
		if (!recording) {
			return;
		}

		setPlaybackPosition(0);
		setDuration(0);
		setIsPlaying(false);
		setSeekProgress({ total: 0, current: 0 });
		setSeekPosition(0);
		handleRecordingState('unloaded');

		recording.onload = () => {
			handleRecordingState('loaded');

			setDuration(recording.getDuration());

			if (startPosition) {
				seek(startPosition);
			}

			autoPlay && recording.play();
		};

		recording.onplay = (): void => {
			setIsPlaying(true);
			handleRecordingState('started');
		};

		recording.onpause = (): void => {
			if (recording.getPosition() === recording.getDuration()) {
				handleRecordingState('ended');
			}
			setIsPlaying(false);
		};

		recording.onseek = (position: number, current: number, total: number): void => {
			if (recording.isPlaying()) {
				setPlaybackPosition(position);
			} else {
				setSeekPosition(position);
				setSeekProgress({ current, total });
			}
		};

		recording.onerror = (message: string) => {
			handleError(message);
		};

		recording.play();

		return () => {
			recording.onplay = null;
			recording.onpause = null;
			recording.onseek = null;
			recording.onerror = null;
		};
	}, [recording, autoPlay, startPosition]);

	return {
		playbackPosition,
		seek,
		play,
		seekPosition,
		seekProgress,
		cancelSeek,
		handleError,
		isPlaying,
		duration,
		recordingState,
		errorMessage,
	};
}
