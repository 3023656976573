import { QueryKey, useMutation } from '@tanstack/react-query';

import { CreateInvitationRequestDto, RequestParams } from '../../../api/Api';
import { useSwaggerApi } from '../../useSwaggerApi';

export const CREATE_INVITATIONS_MK = ['users', 'createInvitations'] as QueryKey;

export const useCreateInvitations = () => {
	const api = useSwaggerApi();

	return useMutation({
		mutationKey: CREATE_INVITATIONS_MK,
		mutationFn: async ({
			data,
			params,
		}: {
			data: CreateInvitationRequestDto;
			params: RequestParams | undefined;
		}) => {
			const response = await api.users.createInvitations(data, params);

			return response.data;
		},
	});
};
