import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Box, Card, CardContent, Typography } from '@mui/material';
import { NetworkContextModel } from './types';
import { Api } from '../../api/Api';
import {
	CONSIDER_API_UNAVAILABLE_AFTER_SEC,
	FREQUENT_CHECK_INTERVAL_SEC,
	NORMAL_CHECK_INTERVAL_SEC,
} from './constants';

export const InitialNetworkContextModel: NetworkContextModel = {
	isNetworkAvailable: true,
	isApiAvailable: true,
	checkApiStatus: () => {},
	checkNetworkStatus: () => {},
};

export const NetworkContext = React.createContext<NetworkContextModel>(InitialNetworkContextModel);

export const NetworkContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [isNetworkAvailable, setIsNetworkAvailable] = React.useState(InitialNetworkContextModel.isNetworkAvailable);
	const [isApiAvailable, setIsApiAvailable] = React.useState(InitialNetworkContextModel.isApiAvailable);
	const api = React.useMemo(() => new Api(), []);
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = React.useState({
		networkError: '',
		apiError: '',
	});

	const checkNetworkStatus = React.useCallback(() => {
		const networkStatus = navigator.onLine;
		setIsNetworkAvailable(networkStatus);
		setErrorMessage((prev) => ({
			...prev,
			networkError: networkStatus ? '' : t('component.networkContext.errorMessages.network'),
		}));

		// If network goes from online to offline mode set api status to false so that the api re-checks will start
		if (!networkStatus) {
			setIsApiAvailable(false);
		}
	}, [t]);

	const checkApiStatus = React.useCallback(async () => {
		try {
			const response = await api.status.getAppStatus({ timeout: CONSIDER_API_UNAVAILABLE_AFTER_SEC * 1000 });
			const apiAvailable = response.status !== 502;
			setIsApiAvailable(apiAvailable);
			setErrorMessage((prev) => ({
				...prev,
				apiError: apiAvailable ? '' : t('component.networkContext.errorMessages.apiServiceUnavailable'),
			}));
		} catch (error) {
			setIsApiAvailable(false);
			setErrorMessage((prev) => ({
				...prev,
				apiError: t('component.networkContext.errorMessages.apiServiceUnavailable'),
			}));
		}
	}, [api, t]);

	React.useEffect(() => {
		const checkStatus = () => {
			checkNetworkStatus();
		};

		window.addEventListener('online', checkStatus);
		window.addEventListener('offline', checkStatus);

		checkStatus();

		const networkInterval = setInterval(checkStatus, NORMAL_CHECK_INTERVAL_SEC * 1000);

		return () => {
			window.removeEventListener('online', checkStatus);
			window.removeEventListener('offline', checkStatus);
			clearInterval(networkInterval);
		};
	}, [checkNetworkStatus]);

	React.useEffect(() => {
		let apiCheckInterval: NodeJS.Timeout | null = null;

		const startApiCheck = () => {
			if (apiCheckInterval) {
				clearInterval(apiCheckInterval);
			}
			apiCheckInterval = setInterval(checkApiStatus, FREQUENT_CHECK_INTERVAL_SEC * 1000);
		};

		const stopApiCheck = () => {
			if (apiCheckInterval) {
				clearInterval(apiCheckInterval);
				apiCheckInterval = null;
			}
		};

		if (!isApiAvailable) {
			startApiCheck();
		}
		if (isApiAvailable) {
			stopApiCheck();
		}

		return stopApiCheck;
	}, [isApiAvailable, checkApiStatus]);

	const contextValue = React.useMemo(
		() => ({
			isNetworkAvailable,
			isApiAvailable,
			checkApiStatus,
			checkNetworkStatus,
		}),
		[isNetworkAvailable, isApiAvailable, checkApiStatus, checkNetworkStatus],
	);

	return (
		<NetworkContext.Provider value={contextValue}>
			{children}
			<Backdrop
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, opacity: 0.8 }}
				open={!isNetworkAvailable || !isApiAvailable}
			>
				<Box
					sx={{
						position: 'fixed',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						textAlign: 'center',
						maxWidth: '80%',
						width: 'fit-content',
					}}
				>
					{(errorMessage.networkError || errorMessage.apiError) && (
						<Card sx={{ backgroundColor: '#fff', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
							<CardContent>
								{errorMessage.networkError && (
									<Typography variant='body1' gutterBottom color='textPrimary'>
										{errorMessage.networkError}
									</Typography>
								)}
								{errorMessage.apiError && (
									<Typography variant='body1' gutterBottom color='textPrimary'>
										{errorMessage.apiError}
									</Typography>
								)}
							</CardContent>
						</Card>
					)}
				</Box>
			</Backdrop>
		</NetworkContext.Provider>
	);
};
