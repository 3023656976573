import * as React from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';
import { ChartBarProps } from './types';

export const ChartBar: React.FC<ChartBarProps> = ({ options, data, title }) => {
	ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

	return (
		<Card>
			<CardContent>
				<Stack direction='column' spacing={2}>
					<Typography align='center'>{title}</Typography>

					<Bar options={options} data={data} />
				</Stack>
			</CardContent>
		</Card>
	);
};
