import * as React from 'react';
import { Location, NavigateFunction, NavigateOptions } from 'react-router-dom';
import { PromiseWrapper } from '@excalibur-enterprise/promise-wrapper';
import { TFunction } from 'i18next';

import { Api } from '../../api/Api';

export const excludedPaths = ['/', '/notFound', '/unauthorized'];

type CustomNavigateFunction = (to: string, options?: NavigateOptions) => void;

export type SwaggerApiClientOptions = {
	disableRedirect?: boolean;
	navigate?: NavigateFunction | CustomNavigateFunction;
	location?: Location<any>;
	pathName?: string;
	handleClearAuthState?: () => void;
	t?: TFunction;
	requestSent?: boolean;
	setRequestSent?: React.Dispatch<React.SetStateAction<boolean>>;
	checkApiStatus?: () => void;
	checkNetworkStatus?: () => void;
};

export type SwaggerApiContextModel = {
	swaggerApi: Api<unknown> | null;
	requestSent: boolean;
};

export type SwaggerApiProviderProps = {
	children: React.ReactNode;
};

export type RequestQueueItem = {
	promiseWrapper: PromiseWrapper<any>;
	request: any;
};
