import * as React from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
	const getInitialValue = (): T => {
		const storedValue = localStorage.getItem(key);

		return storedValue ? JSON.parse(storedValue) : initialValue;
	};

	const [value, setValue] = React.useState<T>(getInitialValue);

	React.useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
};
