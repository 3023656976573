import * as React from 'react';
import {
	Box,
	AppBar,
	Toolbar,
	Typography,
	Container,
	Select,
	MenuItem,
	FormControl,
	useTheme,
	SelectChangeEvent,
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import { US as EN, SK } from 'country-flag-icons/react/3x2';

import { usePathName } from '../hooks/usePathName';
import { useDeviceDetect } from '../hooks/useDeviceDetect';
import { useCurrentLanguage, useChangeLanguage } from '../contexts/LocalizationContext/LocalizationContext';
import { ELanguageCode } from '../api/Api';
import { ExcaliburLogo } from '../components/ExcaliburLogo/ExcaliburLogo';
import { LANGUAGES } from '../contexts/LocalizationContext/constants';

type LanguageSelectorProps = {
	textColor: string;
};

const LanguageSelector: React.FC<LanguageSelectorProps> = React.memo(({ textColor }) => {
	const activeLanguage = useCurrentLanguage();
	const changeLanguage = useChangeLanguage();
	const handleOnLanguageChange = React.useCallback(
		(event: SelectChangeEvent<string>) => {
			changeLanguage(event.target.value as ELanguageCode);
		},
		[changeLanguage],
	);

	const renderFlag = React.useCallback((code: ELanguageCode) => {
		switch (code) {
			case ELanguageCode.En:
				return <EN width={25} />;
			case ELanguageCode.Sk:
				return <SK width={25} />;
			default:
				return null;
		}
	}, []);

	return (
		<Select
			size='small'
			defaultValue={activeLanguage}
			onChange={handleOnLanguageChange}
			value={activeLanguage}
			sx={{
				color: textColor,
				'.MuiSvgIcon-root': { color: textColor },
				'.MuiOutlinedInput-notchedOutline': {
					border: 'none',
				},
				'&:hover .MuiOutlinedInput-notchedOutline': {
					border: 'none',
				},
				'.MuiSelect-select': {
					display: 'flex',
					alignItems: 'center',
					gap: 1,
				},
			}}
		>
			{LANGUAGES.map((lang, index) => (
				<MenuItem value={lang} key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					{renderFlag(lang)} {lang.toLocaleUpperCase()}
				</MenuItem>
			))}
		</Select>
	);
});

export const AuthLayout: React.FC = (): JSX.Element => {
	const theme = useTheme();
	const device = useDeviceDetect();
	const pathname = usePathName();

	const isSetupPage = pathname === '/setup';
	const appBarColor = isSetupPage ? '#ffffff' : '#00172e';
	const textColor = isSetupPage ? '#000000' : '#ffffff';
	const boxShadow = isSetupPage ? 'none' : theme.shadows[4];

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
			}}
		>
			<AppBar position='static' sx={{ backgroundColor: appBarColor, boxShadow: boxShadow }}>
				<Toolbar>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 1,
							padding: 1,
							flexGrow: 1,
						}}
					>
						<ExcaliburLogo color={isSetupPage ? 'black' : 'white'} width={40} height={40} />
						{device !== 'mobile' && (
							<Typography variant='subtitle1' sx={{ color: textColor, marginLeft: 1 }}>
								Excalibur Enterprise
							</Typography>
						)}
					</Box>
					<FormControl sx={{ width: 100 }}>
						<LanguageSelector textColor={textColor} />
					</FormControl>
				</Toolbar>
			</AppBar>
			<Container maxWidth={false} disableGutters sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
				<Outlet />
			</Container>
		</Box>
	);
};
