import { red, green, orange, lightBlue, pink } from '@mui/material/colors';
import { alpha } from '@mui/material';
import {
	authenticationsData,
	authorizationsData,
	verificationsData,
	registrationsData,
	factorResetData,
	TokenlessAuthenticationData,
} from './data';

const colorRed = red[500];
const colorGreen = green[500];
const colorOrange = orange[500];
const colorLightBlue = lightBlue[500];
const colorPink = pink[900];

export const datasetsAuthentications = [authenticationsData];
export const datasetsAuthorizations = [authorizationsData];
export const datasetsVerifications = [verificationsData];
export const datasetsRegistrations = [registrationsData];
export const datasetsFactorsReset = [factorResetData];
export const datasetsTokenlessAuthentications = [TokenlessAuthenticationData];

export const datasetsShowPasswords = [
	{
		label: 'Users',
		data: [...Array(12)].map((e) => ~~(Math.random() * 100)),
		borderColor: colorGreen,
		backgroundColor: alpha(colorGreen, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
];

export const datasetsSetGeofence = [
	{
		label: 'Users',
		data: [...Array(12)].map((e) => ~~(Math.random() * 100)),
		borderColor: colorLightBlue,
		backgroundColor: alpha(colorLightBlue, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
];
