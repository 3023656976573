import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Box,
	CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import { usePreviousValue } from '../../../../hooks/usePreviousValue';
import { GeoEditationDialogProps, SubmitFormState, FormValues } from './types';
import { getGeoEditationDialogFormSchema } from './schemas';
import { useSwaggerApi } from '../../../../hooks/useSwaggerApi';
import { TextField } from '../../../../components/FormFields/TextField/TextField';

export const GeoEditationDialog: React.FC<GeoEditationDialogProps> = ({
	open,
	onClose,
	updateGeofence,
	geofence,
}): JSX.Element => {
	const { t } = useTranslation();
	const swaggerApi = useSwaggerApi();
	const [currentGeofence, setCurrentGeofence] = React.useState(geofence);
	const [loaded, setLoaded] = React.useState(false);
	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		saving: false,
		saved: false,
		error: null,
	});
	const previousGeofence = usePreviousValue(geofence);
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getGeoEditationDialogFormSchema(t)),
		defaultValues: {
			name: geofence?.name ? geofence.name : '',
		},
	});

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		(formData) => {
			if (geofence) {
				setSubmitFormState({
					saving: true,
					saved: false,
					error: null,
				});
				let geofenceToSave = { ...geofence };
				if (currentGeofence) {
					geofenceToSave = {
						...currentGeofence,
						name: formData.name,
					};
				}
				updateGeofence(geofence.id, geofenceToSave, true);
				setTimeout(() => {
					setSubmitFormState({
						saving: false,
						saved: true,
						error: null,
					});
					onClose();
					setLoaded(false);
				}, 800);
			}
		},
		[geofence, currentGeofence],
	);

	const refreshGeofence = React.useCallback(async () => {
		if (geofence?.id && !loaded) {
			const freshGeofenceResponse = await swaggerApi.geofences.getGeofence(geofence?.id);
			if (freshGeofenceResponse.data) {
				setCurrentGeofence(freshGeofenceResponse.data);
				setLoaded(true);
			}
		}
	}, [geofence, loaded]);

	React.useEffect(() => {
		if (!previousGeofence && geofence) {
			reset({
				name: geofence.name,
			});
		}
	}, [geofence, previousGeofence]);

	React.useEffect(() => {
		refreshGeofence();
	}, [loaded, currentGeofence, refreshGeofence]);

	const handleOnClose = React.useCallback(() => {
		setLoaded(false);
		onClose();
	}, []);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{t('component.geoEditationDialog.title')}</DialogTitle>
			<DialogContent sx={{ minWidth: '450px' }}>
				{submitFormState.saving ?
					<Box
						sx={{
							mb: 2,
							width: '100%',
							alignContent: 'center',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
						}}
					>
						<CircularProgress />
					</Box>
				:	<>
						<Box>
							<Typography component='div'>
								{t('component.geoEditationDialog.latitude')}: {geofence?.latitude}
							</Typography>
							<Typography component='div'>
								{t('component.geoEditationDialog.longitude')}: {geofence?.longitude}
							</Typography>
							<br />
						</Box>
						<Box component={'form'} noValidate>
							<TextField
								name={'name'}
								register={register}
								label={t('component.geoEditationDialog.label')}
								error={errors.name}
								disabled={submitFormState.saving}
								helperText={t('component.geoEditationDialog.helperText')}
							/>
						</Box>
					</>
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleOnClose} color='primary'>
					{t('component.geoEditationDialog.cancel')}
				</Button>

				<Button type='submit' onClick={handleSubmit(onSubmit)} color='primary'>
					{t('component.geoEditationDialog.confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
