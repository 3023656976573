import { SwaggerApiClient } from '../contexts/SwaggerApiContext/SwaggerApiClient';

let api: SwaggerApiClient | null = null;

export const tenantIDRegex = /^\d+$/;
export const tenantIDPathRegex = /\/tenant\/(\d+)/;

export const tenantSlugRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9-_]+$/;
export const tenantSlugPathRegex = /\/tenant\/(?=[^/]*[a-za-z])([a-za-z0-9\-_]+)\//;

/**
 * @requires SwaggerApiClient to be configured in advance, if called before configure, it will throw an error
 * @argument slug - The slug to check for uniqueness
 */
export const isSlugUnique = async (slug: string): Promise<boolean> => {
	if (api === null) {
		api = new SwaggerApiClient();
	}
	try {
		await api.tenants.getTenant(slug);

		return false;
	} catch (error) {
		return true;
	}
};

type Function = (...args: any[]) => Promise<any>;
function debouncePromise<T extends Function>(fn: T, wait: number): (...args: Parameters<T>) => ReturnType<T> {
	let timeout: ReturnType<typeof setTimeout> | null = null;
	let promise: ReturnType<T> | null = null;
	let rejectPending: ((reason?: any) => void) | null = null;

	return (...args: Parameters<T>): ReturnType<T> => {
		if (timeout) {
			clearTimeout(timeout);
			if (rejectPending) {
				promise?.catch(() => {});
			}
		}

		promise = new Promise((resolve, reject) => {
			rejectPending = reject;

			timeout = setTimeout(() => {
				fn(...args)
					.then(resolve)
					.catch(reject)
					.finally(() => {
						timeout = null;
						rejectPending = null;
					});
			}, wait);
		}) as ReturnType<T>;

		return promise;
	};
}

export const debouncedIsSlugUnique = debouncePromise(isSlugUnique, 1000);
