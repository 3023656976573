import { format } from 'date-fns';

export const calculateDuration = (
	startDate: string | undefined,
	endDate: string | null | undefined,
	increment: number,
): number => {
	if (!startDate) {
		return 0;
	}

	const start = new Date(startDate);
	const end = endDate ? new Date(endDate) : new Date();

	if (isNaN(end.getTime())) {
		const now = new Date();

		return now.getTime() - start.getTime() + increment;
	}

	return end.getTime() - start.getTime();
};

export const formatDurationToString = (duration: number): string => {
	if (!duration || duration <= 0) {
		return 'Invalid';
	}

	const hours = Math.floor(duration / 1000 / 60 / 60);
	const minutes = Math.floor((duration / 1000 / 60 / 60 - hours) * 60);
	const seconds = Math.floor(((duration / 1000 / 60 / 60 - hours) * 60 - minutes) * 60);

	return `${hours}h ${minutes}m ${seconds}s`;
};

export const formatElapsedTime = (elapsedTime: number): string => {
	const hours = Math.floor(elapsedTime / 3600);
	const minutes = Math.floor((elapsedTime % 3600) / 60);
	const seconds = elapsedTime % 60;

	const formattedTime = format(new Date(0, 0, 0, hours, minutes, seconds), 'HH:mm:ss');

	return formattedTime;
};
