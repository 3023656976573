import { red, green, orange, lightBlue, pink } from '@mui/material/colors';
import { alpha } from '@mui/material';

const colorRed = red[500];
const colorGreen = green[500];
const colorOrange = orange[500];
const colorLightBlue = lightBlue[500];
const colorPink = pink[500];

export const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const optionsMostIncidents = {
	indexAxis: 'y' as const,
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
	},
};

export const dataMostSevereIncidents = {
	labels,
	datasets: [
		{
			label: 'Used wrong credentials',
			data: [100, 2, 80, 81, 90],
			borderColor: colorLightBlue,
			backgroundColor: alpha(colorLightBlue, 0.1),
		},
	],
};

export const optionsMostSevereIncidents = {
	indexAxis: 'y' as const,
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
	},
};

export const dataMostIncidents = {
	labels,
	datasets: [
		{
			label: 'Used wrong credentials',
			data: [100, 2, 80, 81, 90],
			borderColor: colorLightBlue,
			backgroundColor: alpha(colorLightBlue, 0.1),
		},
	],
};

export const optionsTopIncidentsByUser = {
	indexAxis: 'y' as const,
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
	},
};

export const dataTopIncidentsByUser = {
	labels,
	datasets: [
		{
			label: 'User',
			data: [100, 2, 80, 81, 90],
			borderColor: colorGreen,
			backgroundColor: alpha(colorGreen, 0.1),
		},
	],
};

export const dataTopIncidentsOnClient = {
	labels,
	datasets: [
		{
			label: 'Excalibur Dashboard',
			data: [100, 2, 80, 81, 90],
			borderColor: colorPink,
			backgroundColor: alpha(colorPink, 0.1),
		},
	],
};

export const optionsTopIncidentsOnClient = {
	indexAxis: 'y' as const,
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
	},
};
