import { red, green, orange, lightBlue, pink, yellow } from '@mui/material/colors';
import { alpha } from '@mui/material';

const colorRed = red.A700;
const colorGreen = green[500];
const colorOrange = orange[500];
const colorLightBlue = lightBlue[500];
const colorPink = pink[900];

export const authenticationsData = {
	label: 'Authentications',
	data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
	borderColor: colorGreen,
	backgroundColor: alpha(colorGreen, 0.1),
	fill: 'origin',
	borderWidth: 1,
};

export const authorizationsData = {
	label: 'Authorizations',
	data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
	borderColor: colorRed,
	backgroundColor: alpha(colorRed, 0.1),
	fill: 'origin',
	borderWidth: 1,
};

export const verificationsData = {
	label: 'Verifications',
	data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
	borderColor: colorOrange,
	backgroundColor: alpha(colorOrange, 0.1),
	fill: 'origin',
	borderWidth: 1,
};

export const registrationsData = {
	label: 'Registrations',
	data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
	borderColor: colorLightBlue,
	backgroundColor: alpha(colorLightBlue, 0.1),
	fill: 'origin',
	borderWidth: 1,
};

export const factorResetData = {
	label: 'Factor reset',
	data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
	borderColor: colorRed,
	backgroundColor: alpha(colorRed, 0.1),
	fill: 'origin',
	borderWidth: 1,
};

export const TokenlessAuthenticationData = {
	label: 'Tokenless authenticationt',
	data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
	borderColor: colorPink,
	backgroundColor: alpha(colorPink, 0.1),
	fill: 'origin',
	borderWidth: 1,
};
