import * as React from 'react';
import { Box, Button, Fab, FormGroup, Grid, IconButton, Paper, Stack, Tooltip, Typography, Link } from '@mui/material';
import {
	Save as SaveIcon,
	Delete as DeleteIcon,
	Add as AddIcon,
	Info as InfoIcon,
	LensTwoTone as LensTwoToneIcon,
	PlaceOutlined as PlaceOutlinedIcon,
	Workspaces as WorkspacesIcon,
} from '@mui/icons-material';
import {
	type MRT_ColumnDef,
	MRT_ToggleGlobalFilterButton,
	MRT_ShowHideColumnsButton,
	MRT_PaginationState,
	MRT_RowSelectionState,
	MaterialReactTable,
	MRT_TableInstance,
	MRT_Row,
} from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import { GoogleMap } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { EPermission } from '../../../enums/permission/EPermission';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { useACL } from '../../../hooks/useACL';
import { geofenceListSchema, getGeofenceGroupSchema } from './schema';
import { useNavigate } from '../../../hooks/useNavigate';

import { useReactQueryClient } from '../../../hooks/useReactQueryClient';
import { Preloader } from '../../../components/Preloader/Preloader';
import { Heading } from '../../../components/Heading/Heading';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';
import { GetGeofencesResponseDto, GeofenceModel } from '../../../api/Api';
import { useAuthContext } from '../../../contexts/AuthContext/AuthContext';
import { FormValues, GeofenceGroupState, GeofencesInGroupState, SubmitFormState } from './types';

import { useGeofenceContext } from '../../../hooks/useGeofenceContext';
import { mapOptions, circleOptions, circleSystemOptions } from '../mapSettings';
import { LatLngLiteral } from '../../../types/MapTypes';
import { LABEL_VISIBILITY_ZOOM_LEVEL } from '../constants';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { Textarea } from '../../../components/FormFields/Textarea/Textarea';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';
import { useMRTLocalization } from '../../../hooks/useTableLocalization';
import { useTableQuery } from '../../../hooks/useTableQuery';

export const AddEditGeofenceGroup: React.FC = (): JSX.Element => {
	const { googleApiLoaded, initialMapZoom, defaultMapCenter, googleApiKeyValid, checkCurrentLocation } =
		useGeofenceContext();
	const api = useSwaggerApi();
	const authContext = useAuthContext();
	const { t } = useTranslation();
	const { id } = useParams();
	const { isAllowed } = useACL();
	const navigate = useNavigate();
	const reactQueryClient = useReactQueryClient();
	const { MRTLocalization } = useMRTLocalization();

	const circlesRef = React.useRef<google.maps.Circle[]>([]);
	const labelsRef = React.useRef<google.maps.Marker[]>([]);
	const mapRef = React.useRef<google.maps.Map | null>(null);
	const [mapInitialized, setMapInitialized] = React.useState(false);
	const [zoom, setZoom] = React.useState(initialMapZoom);
	const [centerMap, setCenterMap] = React.useState<LatLngLiteral>(defaultMapCenter);
	const [shouldDisableForm, setShouldDisableForm] = React.useState(false);
	const [selectedGeofenceList, setSelectedGeofenceList] = React.useState<GeofenceModel[]>([]);
	const [allGeofenceList, setAllGeofenceList] = React.useState<GeofenceModel[]>([]);
	const [geofencesToUpdate, setGeofencesToUpdate] = React.useState<{ add: number[]; delete: number[] }>({
		add: [],
		delete: [],
	});
	const [rowSelectionSelected, setRowSelectionSelected] = React.useState<MRT_RowSelectionState>({});

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const [geofenceGroupState, setGeofenceGroupState] = React.useState<GeofenceGroupState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [geofencesInGroupState, setGeofencesInGroupState] = React.useState<GeofencesInGroupState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getGeofenceGroupSchema(t)),
	});

	const {
		rowSelection: rowSelectionAll,
		setRowSelection: setRowSelectionAll,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name']);

	const {
		data: geofenceList,
		isError: isGeofenceListtError,
		isRefetching: isGeofenceListRefetching,
		isLoading: isGeofenceListLoading,
		error: geofenceListError,
	} = useQuery<GetGeofencesResponseDto>({
		queryKey: [EQueryKey.GEOFENCE_GROUP_GEOFENCE_LIST_QUERY, geofenceGroupState, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columnsList: swaggerQuery.columns,
					filterList: swaggerQuery.filter,
					sortList: swaggerQuery.sort,
				};

				if (
					geofenceGroupState.loaded &&
					authContext.userTenants.activeTenantID === null &&
					!!geofenceGroupState.data?.tenantID &&
					geofenceGroupState.data !== null &&
					mapInitialized
				) {
					const response = await api.tenants.getTenantGeofences(
						geofenceGroupState.data?.tenantID as number,
						query,
					);
					response.data.entities.forEach((geofence) => {
						geofenceListSchema.parse(geofence);
					});

					return {
						entities: response.data.entities,
						total: response.data.total,
					};
				}
				const response = await api.geofences.getGeofences(query);
				response.data.entities.forEach((geofence) => {
					geofenceListSchema.parse(geofence);
				});

				return {
					entities: response.data.entities,
					total: response.data.total,
				};
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = geofenceList ? geofenceList : {};

	React.useEffect(() => {
		if (googleApiKeyValid) {
			checkCurrentLocation();
		}
	}, [googleApiKeyValid]);

	const loadGeofencesInGroup = React.useCallback(async () => {
		if (geofencesInGroupState.loading || !mapInitialized) {
			return;
		}
		try {
			setGeofencesInGroupState({
				loading: true,
				loaded: false,
				data: null,
				error: null,
			});
			const query = {
				limit: 80,
				offset: 0,
			};

			const response = await api.geofenceGroups.getGeofencesByGroupId(Number(id), query);

			setSelectedGeofenceList(response.data.entities);
			setGeofencesInGroupState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});
		} catch (error) {
			setGeofencesInGroupState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
			console.error('error: ', error);
		}
	}, [geofencesInGroupState, id, mapInitialized]);

	const getGeofenceGroup = React.useCallback(async (geofenceGroupID: number): Promise<void> => {
		setGeofenceGroupState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const response = await api.geofenceGroups.getGeofenceGroupById(geofenceGroupID);

			setGeofenceGroupState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});

			reset({
				name: response.data.name,
				description: response.data.description,
			});
		} catch (error) {
			setGeofenceGroupState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
			console.error('error: ', error);
		}
	}, []);

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData) => {
			if (submitFormState.submitting) {
				return;
			}
			setSubmitFormState({
				submitting: true,
				submitted: false,
				error: null,
			});
			const requestGeofenceGroupBody = {
				name: formData.name,
				description: formData.description,
			};

			try {
				if (id) {
					await api.geofenceGroups.updateGeofenceGroup(Number(id), requestGeofenceGroupBody);
					if (geofencesToUpdate.delete.length > 0) {
						await api.geofenceGroups.removeGeofencesFromGroup(Number(id), {
							ids: geofencesToUpdate.delete,
						});
					}
					if (geofencesToUpdate.add.length > 0) {
						await api.geofenceGroups.setGeofencesForGroup(Number(id), geofencesToUpdate.add);
					}
					enqueueSnackbar(t('page.geofenceGroup.edit.form.actionMessages.groupSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
				} else {
					const geofenceGroupCreateResponse =
						await api.geofenceGroups.createGeofenceGroup(requestGeofenceGroupBody);
					if (selectedGeofenceList.length > 0) {
						await api.geofenceGroups.setGeofencesForGroup(
							geofenceGroupCreateResponse.data.id,
							selectedGeofenceList.map((geofence) => geofence.id as number),
						);
					}
					enqueueSnackbar(t('page.geofenceGroup.edit.form.actionMessages.groupSuccessfullyCreated'), {
						variant: 'success',
						persist: false,
					});
				}
				setSubmitFormState({
					submitting: false,
					submitted: true,
					error: null,
				});
				navigate('/geofences/groups');
			} catch (error) {
				console.error('error: ', error);
				setSubmitFormState({
					submitting: false,
					submitted: false,
					error: error as AxiosError,
				});
			}
		},
		[id, selectedGeofenceList, geofencesToUpdate],
	);

	const onMapLoad = (map: google.maps.Map) => {
		mapRef.current = map;
		setMapInitialized(true);
	};

	const onMapUnmount = React.useCallback(() => {
		mapRef.current = null;
	}, []);

	const updateRowSelectionAll = React.useCallback(
		(addedIds: number[]) => {
			if (Object.keys(rowSelectionAll).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelectionAll };
			addedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelectionAll(newRowSelection);
		},
		[rowSelectionAll],
	);

	const updateRowSelectionSelected = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelectionSelected).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelectionSelected };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelectionSelected(newRowSelection);
		},
		[rowSelectionSelected],
	);

	const renderGeofenceIcon = React.useMemo(
		() => () => {
			const isSystemTenant = authContext.userTenants.activeTenantID === null;
			const hasGroupTenant = geofenceGroupState.data && geofenceGroupState.data?.tenantID !== null;
			if (isSystemTenant && !hasGroupTenant) {
				return <LensTwoToneIcon sx={{ color: 'darkGreen' }} />;
			}

			return <LensTwoToneIcon sx={{ color: 'coral' }} />;
		},
		[authContext, geofenceGroupState.data],
	);

	const handleOnAddEntity = React.useCallback(
		(geofenceId: number | undefined) => () => {
			if (!geofenceId) {
				return;
			}
			const geofenceToAdd = allGeofenceList.find((geofence) => geofence.id === geofenceId);
			if (geofenceToAdd) {
				setAllGeofenceList((prevList) =>
					prevList.map((geofence) => {
						return geofence.id === geofenceId ? { ...geofence, disabled: true } : geofence;
					}),
				);
				setSelectedGeofenceList((prevList) => [...prevList, geofenceToAdd]);
				updateRowSelectionAll([geofenceId]);
			}
		},
		[allGeofenceList, rowSelectionAll],
	);

	const handleOnRedirectToSettings = React.useCallback(() => {
		navigate('/settings/general/map');
	}, []);

	const handleOnRemoveEntity = React.useCallback(
		(geofenceId: number | undefined) => () => {
			if (!geofenceId) {
				return;
			}
			const geofenceToRemove = selectedGeofenceList.find((geofence) => geofence.id === geofenceId);
			if (geofenceToRemove) {
				setAllGeofenceList((prevList) =>
					prevList.map((geofence) => {
						return geofence.id === geofenceId ? { ...geofence, disabled: false } : geofence;
					}),
				);
				setSelectedGeofenceList((prevList) =>
					prevList.filter((geofence) => geofence.id !== geofenceToRemove.id),
				);
				updateRowSelectionSelected([geofenceId]);
			}
		},
		[selectedGeofenceList, rowSelectionSelected],
	);

	const handleOnAddEntities = React.useCallback(
		(table: MRT_TableInstance<Partial<Record<string, any>>>) => () => {
			if (!rowSelectionAll) {
				return;
			}
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			const geofencesToAdd = allGeofenceList.filter((geofence) =>
				selectedRowsOnActivePageIds.includes(geofence.id as number),
			);
			if (geofencesToAdd) {
				setSelectedGeofenceList((prevList) => [...prevList, ...geofencesToAdd]);
				updateRowSelectionAll(selectedRowsOnActivePageIds);
			}
		},
		[allGeofenceList, rowSelectionAll],
	);

	const handleOnRemoveEntities = React.useCallback(
		(table: MRT_TableInstance<Partial<Record<string, any>>>) => () => {
			if (!rowSelectionSelected) {
				return;
			}
			const rowsOnPage = table.getRowModel().rows.map((row) => Number(row.original.id));
			const allSelectedRows = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));
			const selectedRowsOnActivePageIds = allSelectedRows.filter((id) => rowsOnPage.includes(id));

			const geofencesToRemove = selectedGeofenceList.filter((geofence) =>
				selectedRowsOnActivePageIds.includes(geofence.id as number),
			);
			if (geofencesToRemove) {
				setSelectedGeofenceList((prevList) =>
					prevList.filter((geofence) => !geofencesToRemove.includes(geofence)),
				);
				updateRowSelectionSelected(selectedRowsOnActivePageIds);
			}
		},
		[selectedGeofenceList, rowSelectionSelected],
	);

	const extendBoundsToGeofence = (geofence: GeofenceModel, bounds: google.maps.LatLngBounds) => {
		const { latitude, longitude, radius } = geofence;
		const geofenceCenter = new google.maps.LatLng(latitude, longitude);
		const geofenceCircle = new google.maps.Circle({
			center: geofenceCenter,
			radius: radius,
		});

		const circleBounds = geofenceCircle.getBounds();
		if (circleBounds) {
			bounds.union(circleBounds);
		}
	};

	React.useEffect(() => {
		if (id && !geofenceGroupState.loading && !geofenceGroupState.loaded && !geofenceGroupState.error) {
			getGeofenceGroup(Number(id));
		}
	}, [id, geofenceGroupState]);

	React.useEffect(() => {
		if (!mapRef.current) {
			return;
		}
		circlesRef.current.forEach((circle) => {
			circle.setMap(null);
		});
		labelsRef.current.forEach((label) => {
			label.setMap(null);
		});
		if (selectedGeofenceList.length === 0) {
			setCenterMap(defaultMapCenter);
			setZoom(initialMapZoom);

			return;
		}
		let options = circleOptions;
		const isSystemTenant = authContext.userTenants.activeTenantID === null;
		const hasGroupTenant = geofenceGroupState.data && geofenceGroupState.data?.tenantID !== null;
		if (isSystemTenant && hasGroupTenant) {
			options = circleOptions;
		} else if (isSystemTenant && !hasGroupTenant) {
			options = circleSystemOptions;
		} else if (!isSystemTenant && !hasGroupTenant) {
			options = circleOptions;
		}

		const circles = selectedGeofenceList.map((geofence) => {
			const circle = new google.maps.Circle({
				...options,
				editable: false,
				center: {
					lat: geofence.latitude,
					lng: geofence.longitude,
				},
				radius: geofence.radius,
			});
			circle.setMap(mapRef.current);

			const label = new google.maps.Marker({
				position: circle.getCenter(),
				map: mapRef.current,
				label: {
					text: geofence.name,
					color: 'white',
					fontWeight: 'bold',
					fontSize: '14px',
				},
				icon: {
					url: 'data:image/svg+xml;utf-8,',
					size: new google.maps.Size(1, 1),
					scaledSize: new google.maps.Size(1, 1),
					anchor: new google.maps.Point(0, 0),
				},
			});

			label.setVisible(zoom >= LABEL_VISIBILITY_ZOOM_LEVEL);

			return { circle, label };
		});
		circlesRef.current = circles.map((circle) => circle.circle);
		labelsRef.current = circles.map((circle) => circle.label);
		const bounds = new google.maps.LatLngBounds();
		selectedGeofenceList.forEach((geofence) => {
			extendBoundsToGeofence(geofence as GeofenceModel, bounds);
		});
		mapRef.current?.fitBounds(bounds);
		const center = bounds.getCenter();
		setCenterMap({ lat: center.lat(), lng: center.lng() });
	}, [mapRef, selectedGeofenceList, mapInitialized, geofenceGroupState, authContext]);

	React.useEffect(() => {
		if (!labelsRef.current || !mapRef.current) {
			return;
		}

		const zoomChangedListener = google.maps.event.addListener(mapRef.current, 'zoom_changed', () => {
			const zoomValue = mapRef.current?.getZoom();

			if (zoomValue && zoomValue >= LABEL_VISIBILITY_ZOOM_LEVEL) {
				labelsRef.current.map((label) => label.setVisible(true));
			} else {
				labelsRef.current.map((label) => label.setVisible(false));
			}
		});

		return () => {
			google.maps.event.removeListener(zoomChangedListener);
		};
	}, [labelsRef.current]);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !geofenceGroupState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || geofenceGroupState.loading)) {
			setShouldDisableForm(true);
		}
	}, [geofenceGroupState, submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		if (!id || !geofenceGroupState.loaded) {
			return;
		}

		const geofencesToAdd = selectedGeofenceList.filter(
			(geofence) => !geofencesInGroupState.data?.entities.includes(geofence as GeofenceModel),
		);

		setGeofencesToUpdate((prevUpdateGeofences) => {
			return { ...prevUpdateGeofences, add: geofencesToAdd.map((geofence) => geofence.id as number) };
		});

		if (!geofenceGroupState.data || geofencesInGroupState.data?.entities.length === 0) {
			return;
		}

		const geofencesToDelete =
			geofencesInGroupState.data?.entities.filter((geofence) => !selectedGeofenceList.includes(geofence)) || [];

		setGeofencesToUpdate((prevUpdateGeofences) => {
			return { ...prevUpdateGeofences, delete: geofencesToDelete.map((geofence) => geofence.id) };
		});
	}, [selectedGeofenceList, geofencesInGroupState]);

	React.useEffect(() => {
		if (!selectedGeofenceList || isGeofenceListLoading) {
			return;
		}

		setAllGeofenceList(entities as GeofenceModel[]);
	}, [selectedGeofenceList, entities]);

	React.useEffect(() => {
		if (id && mapInitialized) {
			loadGeofencesInGroup();
		}
	}, [id, mapInitialized]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns: MRT_ColumnDef<Partial<GeofenceModel>>[] = React.useMemo(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.geofenceGroup.edit.table.header.geofence'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
							minHeight: '40px',
						}}
					>
						{renderGeofenceIcon()}
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
		],
		[isAllowed, renderGeofenceIcon],
	);

	const columnsSelected: MRT_ColumnDef<Partial<GeofenceModel>>[] = React.useMemo(
		() => [
			...columns,
			{
				accessorFn: (row) => `${row.id}`,
				header: t('page.geofenceGroup.edit.table.header.actions'),
				muiTableHeadCellProps: {
					align: 'right',
				},
				Cell: ({ row }) => {
					return (
						<Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
							{isAllowed(
								[EPermission.GEOFENCE_GROUPS_CREATE, EPermission.GEOFENCE_GROUPS_UPDATE],
								false,
							) && (
								<Tooltip
									title={t('page.geofenceGroup.edit.tooltips.delete')}
									placement='left'
									enterDelay={500}
									arrow
								>
									<IconButton color='error' onClick={handleOnRemoveEntity(row.original.id)}>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							)}
						</Box>
					);
				},
			},
		],
		[columns, selectedGeofenceList, rowSelectionSelected, isAllowed],
	);

	const columnsAll: MRT_ColumnDef<Partial<GeofenceModel>>[] = React.useMemo(
		() => [
			...columns,
			{
				accessorFn: (row) => `${row.id}`,
				header: t('page.userGroups.edit.table.header.actions'),
				enableSorting: false,
				enableColumnFilter: false,
				enableGlobalFilter: false,
				muiTableHeadCellProps: {
					align: 'right',
				},
				Cell: ({ row }) => {
					const found = selectedGeofenceList.find((geofence) => geofence.id === row.original.id);

					return found ? null : (
							<Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
								{isAllowed(
									[EPermission.GEOFENCE_GROUPS_CREATE, EPermission.GEOFENCE_GROUPS_UPDATE],
									false,
								) && (
									<Tooltip
										title={t('page.geofenceGroup.edit.tooltips.add')}
										placement='left'
										enterDelay={500}
										arrow
									>
										<IconButton color='success' onClick={handleOnAddEntity(row.original.id)}>
											<AddIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						);
				},
			},
		],
		[isAllowed, columns, allGeofenceList, selectedGeofenceList, rowSelectionAll],
	);

	return (
		<Box
			component={'form'}
			noValidate
			autoComplete='off'
			onSubmit={handleSubmit(onSubmit)}
			sx={{ marginBottom: 10 }}
		>
			<Paper
				elevation={3}
				sx={{
					padding: 2,
				}}
			>
				<Box sx={{ marginBottom: 2 }}>
					<PageHeader
						title={
							id ?
								t('page.geofenceGroup.edit.form.titleUpdate')
							:	t('page.geofenceGroup.edit.form.titleCreate')
						}
						description={
							id ?
								t('page.geofenceGroup.edit.form.descriptionUpdate')
							:	t('page.geofenceGroup.edit.form.descriptionCreate')
						}
						icon={WorkspacesIcon}
					/>
				</Box>
				{id && (geofenceGroupState.loading || !geofenceGroupState.loaded) ?
					<Preloader />
				:	<>
						{googleApiKeyValid ?
							<>
								<Stack spacing={1}>
									<FormGroup>
										<Heading label={t('page.geofenceGroup.edit.form.subtitle.general')} />
										<Grid container spacing={2}>
											<Grid item xs={12} xl={6}>
												<Stack spacing={1}>
													<TextField
														name={'name'}
														register={register}
														label={t('page.geofenceGroup.edit.form.name.label')}
														error={errors.name}
														disabled={shouldDisableForm}
														helperText={t('page.geofenceGroup.edit.form.name.helperText')}
													/>

													<Textarea
														name={'description'}
														register={register}
														label={t('page.geofenceGroup.edit.form.description.label')}
														error={errors.description}
														disabled={shouldDisableForm}
														helperText={t(
															'page.geofenceGroup.edit.form.description.helperText',
														)}
														rows={4}
													/>
												</Stack>
											</Grid>

											<Grid item xs={12} xl={6} minHeight={500}>
												{googleApiLoaded && (
													<Box sx={{ marginBottom: 1 }}>
														<GoogleMap
															mapContainerStyle={{
																width: '100%',
																minHeight: 500,
															}}
															center={centerMap}
															zoom={zoom}
															onLoad={onMapLoad}
															onUnmount={onMapUnmount}
															options={mapOptions}
														></GoogleMap>
													</Box>
												)}
											</Grid>
										</Grid>
									</FormGroup>

									<FormGroup>
										<Box maxWidth={'100%'}>
											<Grid container spacing={2}>
												<Grid item xs={12} xl={6}>
													<Heading
														label={t(
															'page.geofenceGroup.edit.form.subtitle.selectedGeofences',
														)}
													/>
													<Typography variant='body1' sx={{ paddingBottom: 2 }}>
														{t(
															'page.geofenceGroup.edit.table.description.selectedGeofences',
														)}
													</Typography>
													<MaterialReactTable
														columns={columnsSelected}
														data={selectedGeofenceList}
														enableStickyHeader={false}
														state={{
															rowSelection: rowSelectionSelected,
														}}
														initialState={{
															columnVisibility: { createdAt: false },
															density: 'compact',
														}}
														enableRowSelection={isAllowed(
															[
																EPermission.GEOFENCE_GROUPS_CREATE,
																EPermission.GEOFENCE_GROUPS_UPDATE,
															],
															false,
														)}
														getRowId={(originalRow) => originalRow.id?.toString() || ''}
														onRowSelectionChange={setRowSelectionSelected}
														renderToolbarInternalActions={({ table }) => (
															<Box sx={{ display: 'flex', gap: '1rem' }}>
																<MRT_ToggleGlobalFilterButton table={table} />
																<MRT_ShowHideColumnsButton table={table} />
																{isAllowed(
																	[
																		EPermission.GEOFENCE_GROUPS_CREATE,
																		EPermission.GEOFENCE_GROUPS_UPDATE,
																	],
																	false,
																) && (
																	<Tooltip
																		title={t(
																			'page.geofenceGroup.edit.tooltips.removeSelected',
																		)}
																		placement='top'
																		enterDelay={500}
																	>
																		<span>
																			<Button
																				color='error'
																				variant='contained'
																				disabled={
																					table.getSelectedRowModel().rows
																						.length === 0
																				}
																				onClick={handleOnRemoveEntities(table)}
																				sx={{
																					display: 'flex',
																					alignItems: 'flex-start',
																					gap: '0.5rem',
																					width: 130,
																				}}
																			>
																				<DeleteIcon />
																				{t(
																					'page.geofenceGroup.edit.table.body.remove',
																				)}
																			</Button>
																		</span>
																	</Tooltip>
																)}
															</Box>
														)}
														displayColumnDefOptions={{
															'mrt-row-actions': {
																muiTableHeadCellProps: {
																	align: 'right',
																},
																size: 120,
																enableHiding: true,
															},
															'mrt-row-select': {
																enableHiding: true,
																visibleInShowHideMenu: false,
															},
														}}
														muiTablePaperProps={({ table }) => ({
															style: {
																zIndex:
																	table.getState().isFullScreen ? 1100 : undefined,
																boxShadow: 'none',
																outline: '1px solid #e0e0e0',
															},
														})}
														muiSelectCheckboxProps={() => ({
															sx: {
																width: '50px',
																height: '50px',
															},
														})}
														muiSelectAllCheckboxProps={() => ({
															sx: {
																width: '50px',
																height: '50px',
															},
														})}
														muiTableHeadCellProps={() => ({
															sx: {
																verticalAlign: 'baseline',
															},
														})}
														editDisplayMode='modal'
														positionActionsColumn='last'
														localization={MRTLocalization}
													/>
												</Grid>
												<Grid item xs={12} xl={6}>
													<Heading
														label={t('page.geofenceGroup.edit.form.subtitle.allGeofences')}
													/>
													<Typography variant='body1' sx={{ paddingBottom: 2 }}>
														{t('page.geofenceGroup.edit.table.description.allGeofences')}
													</Typography>
													<MaterialReactTable
														columns={columnsAll}
														data={allGeofenceList}
														enableStickyHeader={false}
														enableRowSelection={(row: MRT_Row<Partial<GeofenceModel>>) => {
															const found = selectedGeofenceList.find(
																(geofence) => geofence.id === row.original.id,
															);

															return (
																	isAllowed(
																		[
																			EPermission.GEOFENCE_GROUPS_CREATE,
																			EPermission.GEOFENCE_GROUPS_UPDATE,
																		],
																		false,
																	)
																) ?
																	!found
																:	false;
														}}
														state={{
															isLoading: isGeofenceListLoading,
															showAlertBanner: geofenceListError !== null,
															pagination,
															rowSelection: rowSelectionAll,
															showProgressBars: isGeofenceListRefetching,
															columnFilters,
															globalFilter,
															sorting,
															columnVisibility,
														}}
														muiToolbarAlertBannerProps={{
															color: 'error',
															children: <>{geofenceListError}</>,
														}}
														initialState={{
															columnVisibility: { createdAt: false },
															density: 'compact',
														}}
														rowCount={total}
														manualPagination
														manualFiltering
														manualSorting
														onSortingChange={setSorting}
														onGlobalFilterChange={setGlobalFilter}
														onColumnFiltersChange={setColumnFilters}
														onPaginationChange={setPagination}
														onColumnVisibilityChange={setColumnVisibility}
														getRowId={(originalRow) => originalRow.id?.toString() || ''}
														onRowSelectionChange={setRowSelectionAll}
														renderToolbarInternalActions={({ table }) => (
															<Box sx={{ display: 'flex', gap: '1rem' }}>
																<MRT_ToggleGlobalFilterButton table={table} />
																<MRT_ShowHideColumnsButton table={table} />
																{isAllowed(
																	[
																		EPermission.GEOFENCE_GROUPS_CREATE,
																		EPermission.GEOFENCE_GROUPS_UPDATE,
																	],
																	false,
																) && (
																	<Tooltip
																		title={t(
																			'page.geofenceGroup.edit.tooltips.addSelected',
																		)}
																		placement='top'
																		enterDelay={500}
																	>
																		<span>
																			<Button
																				color='success'
																				variant='contained'
																				disabled={
																					table.getSelectedRowModel().rows
																						.length === 0
																				}
																				onClick={handleOnAddEntities(table)}
																				sx={{
																					display: 'flex',
																					alignItems: 'flex-start',
																					gap: '0.5rem',
																					width: 130,
																				}}
																			>
																				<AddIcon />
																				{t(
																					'page.geofenceGroup.edit.table.body.add',
																				)}
																			</Button>
																		</span>
																	</Tooltip>
																)}
															</Box>
														)}
														displayColumnDefOptions={{
															'mrt-row-actions': {
																muiTableHeadCellProps: {
																	align: 'center',
																},
																size: 120,
																enableHiding: true,
															},
															'mrt-row-select': {
																enableHiding: true,
																visibleInShowHideMenu: false,
															},
														}}
														muiTablePaperProps={({ table }) => ({
															style: {
																zIndex:
																	table.getState().isFullScreen ? 1100 : undefined,
																boxShadow: 'none',
																outline: '1px solid #e0e0e0',
															},
														})}
														muiSelectCheckboxProps={() => ({
															sx: {
																width: '50px',
																height: '50px',
															},
														})}
														muiSelectAllCheckboxProps={() => ({
															sx: {
																width: '50px',
																height: '50px',
															},
														})}
														muiTableHeadCellProps={() => ({
															sx: {
																verticalAlign: 'baseline',
															},
														})}
														editDisplayMode='modal'
														positionActionsColumn='last'
														localization={MRTLocalization}
													/>
												</Grid>
											</Grid>
										</Box>
									</FormGroup>
								</Stack>

								{isAllowed(
									[EPermission.GEOFENCE_GROUPS_CREATE, EPermission.GEOFENCE_GROUPS_UPDATE],
									false,
								) && (
									<FloatingButtonSave
										type='submit'
										disabled={shouldDisableForm}
										ariaLabel={t('page.geofenceGroup.edit.ariaLabel.saveGroup')}
										tooltipTitle={
											id ?
												t('page.geofenceGroup.edit.tooltips.saveGroup')
											:	t('page.geofenceGroup.edit.tooltips.createGroup')
										}
									/>
								)}
							</>
						:	<Stack
								direction='column'
								spacing={2}
								sx={{
									marginTop: 2,
									paddingTop: 2,
								}}
							>
								<Typography
									align='center'
									sx={{
										paddingTop: 2,
										paddingBottom: 2,
									}}
								>
									{t('page.geofenceGroup.list.text.incorrectGoogleApiKeyFirstHalf')}
									{isAllowed([EPermission.SETTINGS_MAP_UPDATE]) ?
										<>
											<Link
												onClick={handleOnRedirectToSettings}
												sx={{
													cursor: 'pointer',
												}}
											>
												{t('page.geofenceGroup.list.text.here')}
											</Link>
											{t('page.geofenceGroup.list.text.incorrectGoogleApiKeySecondHalf')}
										</>
									:	<>{t('page.geofenceGroup.list.text.incorrectGoogleApiKeyContactAdmin')}</>}
								</Typography>
							</Stack>
						}
					</>
				}
			</Paper>
		</Box>
	);
};
