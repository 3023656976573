import { useEffect, useState } from 'react';

// Custom hook to check registration status
export const useRegistrationStatus = (url: string, startPolling: boolean) => {
	const [isRegistered, setIsRegistered] = useState<boolean>(false);

	useEffect(() => {
		if (!startPolling) {
			return;
		}

		let isComponentMounted = true;

		const checkRegistration = async () => {
			try {
				console.log('Checking registration status...');
				const response = await fetch(url);
				const data = await response.json();
				console.log('Registration status:', data);
				if (data.registered) {
					if (isComponentMounted) {
						setIsRegistered(true);
					}

					// Registration is true, so we don't need to make more requests
					return;
				}

				// Registration is false, instantly make another request
				if (isComponentMounted) {
					checkRegistration();
				}
			} catch (error) {
				console.error('Failed to fetch registration status:', error);
				// In case of error, you might want to retry after a delay or handle the error differently
				if (isComponentMounted) {
					setTimeout(checkRegistration, 5000); // Retry after a delay in case of error
				}
			}
		};

		checkRegistration();

		// Cleanup function to set the flag when the component unmounts
		return () => {
			isComponentMounted = false;
		};
	}, [url, startPolling]); // Depend on URL so the effect reruns if it changes

	return isRegistered;
};
