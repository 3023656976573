import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useTheme, useMediaQuery, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import { FileUploader } from 'react-drag-drop-files';
import { INameIdFormats } from '../types';

import { EPermission } from '../../../enums/permission/EPermission';

export const SAMLEditServiceProvider: React.FC = (): JSX.Element => {
	const SUPPORTED_FILE_TYPE = ['.XML'];

	const theme = useTheme();

	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const [saving, setSaving] = useState(false);
	const [file, setFile] = useState(null);
	const [manualConfiguration, setManualConfiguration] = useState(false);

	const nameIdFormats: INameIdFormats[] = [
		{ id: 1, value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified' },
		{ id: 2, value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress' },
		{ id: 3, value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:transient' },
		{ id: 4, value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent' },
		{ id: 5, value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:WindowsDomainQualifiedName' },
		{ id: 6, value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:kerberos' },
		{ id: 7, value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:entity' },
		{ id: 8, value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName' },
	];

	const handleChange = (file: any) => {
		setFile(file);
	};

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	return (
		<div>
			<Box component={'form'} noValidate autoComplete='off'>
				<Box
					sx={{
						paddingBottom: 1,
					}}
				>
					<Typography variant='h5'>Edit Service Provider</Typography>
				</Box>

				<Paper
					elevation={3}
					sx={{
						paddingBottom: 2,
					}}
				>
					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Stack
							spacing={1}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Typography variant='subtitle1'>General</Typography>

							<TextField
								label='Name'
								variant='outlined'
								type='text'
								helperText='Display name of the Service Provide'
								// value={name}
								minRows={4}
								fullWidth
							/>
							<TextField
								label='Description'
								variant='outlined'
								type='text'
								helperText='Description of the Service Provide'
								multiline
								minRows={4}
								fullWidth
							/>
						</Stack>
					</FormGroup>

					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Stack
							direction='column'
							spacing={1}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Typography variant='subtitle1'>Metadata</Typography>

							<Typography variant='body1'>
								Choose one of the following options to configure Service Provider. You can either
								provide metadata in XML format or configure it manually.
							</Typography>

							<ToggleButtonGroup
								sx={{
									paddingBottom: 2,
								}}
								color='primary'
								disabled={saving}
								value={manualConfiguration}
								onChange={(event, value) => setManualConfiguration(value)}
								exclusive
								aria-label='Manual Configuration'
							>
								<ToggleButton value={false}>XML</ToggleButton>
								<ToggleButton value={true}>Manual configuration</ToggleButton>
							</ToggleButtonGroup>

							{manualConfiguration && (
								<>
									<TextField
										label='EntityId'
										variant='outlined'
										type='text'
										helperText='The unique identifier for the service provider. This value is typically a URL or a URN.'
										fullWidth
										required
									/>

									<TextField
										label='Attribute Consume Service Endpoint (HTTP-POST)'
										variant='outlined'
										type='url'
										helperText='The URL where the service provider expects to receive SAML attribute requests via HTTP-POST binding.'
										fullWidth
										required
									/>

									<TextField
										label='Single Logout Service Endpoint (HTTP-REDIRECT)'
										variant='outlined'
										type='url'
										helperText='The URL where the service provider expects to receive SAML single logout requests via HTTP-REDIRECT binding. This field is optional.'
										fullWidth
									/>

									<FormControl fullWidth>
										<InputLabel id='nameid-format-select-label'>NameId Format</InputLabel>
										<Select
											labelId='nameid-format-select-label'
											id='nameid-format-select-label'
											// value={age}
											label='nameid-format-select-label'
											onChange={handleChange}
										>
											{nameIdFormats.map((format) => (
												<MenuItem key={format.id} value={format.value}>
													{format.value}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									<FormControlLabel control={<Switch />} label='Authn requests signed' />
									<FormControlLabel control={<Switch />} label='Assertions signed' />

									<Typography variant='subtitle1'>Organization info (optional)</Typography>

									<TextField
										label='Name'
										variant='outlined'
										type='text'
										helperText='Enter the official name of your organization as you want it to appear in SAML metadata.'
										fullWidth
									/>
									<TextField
										label='DisplayName'
										variant='outlined'
										type='text'
										helperText='Provide a user-friendly name for your organization that will be displayed in the SAML authentication interfaces.'
										fullWidth
									/>
									<TextField
										label='URL'
										variant='outlined'
										type='url'
										helperText="Provide the URL where more information about your organization can be found. It should be a complete URL, including 'http://' or 'https://'."
										fullWidth
									/>

									<Typography variant='subtitle1'>Technical Contact (optional)</Typography>

									<TextField
										label='Given Name'
										variant='outlined'
										type='text'
										helperText='Enter the given name of the person who should be contacted for technical issues.'
										fullWidth
									/>

									<TextField
										label='Email'
										variant='outlined'
										type='email'
										helperText='Enter the email address of the person who should be contacted for technical issues.'
										fullWidth
									/>

									<Typography variant='subtitle1'>Support Contact (optional)</Typography>

									<TextField
										label='Given name'
										variant='outlined'
										type='text'
										helperText='Enter the given name of the person who should be contacted for support issues.'
										fullWidth
									/>

									<TextField
										label='Email'
										variant='outlined'
										type='email'
										helperText='Enter the email address of the person who should be contacted for support issues.'
										fullWidth
									/>

									<TextField
										label='Private key'
										variant='outlined'
										type='text'
										multiline
										minRows={10}
										maxRows={10}
										fullWidth
										inputProps={{
											spellCheck: false,
											style: {
												fontFamily: 'monospace',
												fontSize: '0.875rem',
												whiteSpace: 'nowrap',
											},
										}}
									/>
									<TextField
										label='X.509 certificate'
										variant='outlined'
										type='text'
										multiline
										minRows={10}
										maxRows={10}
										fullWidth
										inputProps={{
											spellCheck: false,
											style: {
												fontFamily: 'monospace',
												fontSize: '0.875rem',
												whiteSpace: 'nowrap',
											},
										}}
									/>
								</>
							)}
							{!manualConfiguration && (
								<>
									<Typography variant='body1'>
										To configure Service provider you must provide its metadata using one of
										following ways.
									</Typography>

									<TextField
										label='URL'
										variant='outlined'
										type='text'
										fullWidth
										helperText='URL where service provider metadata resides'
									/>

									<TextField
										label='XML'
										variant='outlined'
										type='text'
										helperText='Service provider XML metadata'
										multiline
										minRows={10}
										maxRows={10}
										fullWidth
										inputProps={{
											spellCheck: false,
											style: {
												fontFamily: 'monospace',
												fontSize: '0.875rem',
												whiteSpace: 'nowrap',
											},
										}}
									/>

									<FileUploader
										multiple={true}
										handleChange={handleChange}
										name='file'
										label='Upload or drop metadata .XML file'
										types={SUPPORTED_FILE_TYPE}
									/>
								</>
							)}
						</Stack>
					</FormGroup>

					<Fab
						color='primary'
						type='submit'
						aria-label='add'
						disabled={saving}
						sx={{
							position: 'fixed',
							bottom: 32,
							right: 32,
						}}
					>
						<SaveIcon />
					</Fab>
				</Paper>
			</Box>
		</div>
	);
};
