import * as React from 'react';
import {
	Box,
	Fab,
	Grid,
	Paper,
	Stack,
	Tooltip,
	Checkbox,
	useTheme,
	Accordion,
	FormGroup,
	Typography,
	IconButton,
	useMediaQuery,
	FormControlLabel,
	AccordionDetails,
	AccordionSummary,
} from '@mui/material';
import {
	Info as InfoIcon,
	Save as SaveIcon,
	ExpandMore as ExpandMoreIcon,
	Groups as GroupsIcon,
} from '@mui/icons-material';
import { AxiosError } from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { PermissionGroupModel, PermissionModel, GetRoleResponseDto, CreateRoleRequestDto } from '../../api/Api';
import { PermissionGroupState, RoleFormState, FormValues, RoleDetailState } from './types';
import { TextField } from '../../components/FormFields/TextField/TextField';
import { Textarea } from '../../components/FormFields/Textarea/Textarea';
import { useNavigate } from '../../hooks/useNavigate';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { getRoleFormSchema } from './schema';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { Preloader } from '../../components/Preloader/Preloader';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../components/Buttons/FloatingButton/FloatingButtonSave';

/*
 * Add-Edit Role Form Component
 */
export const AddEditRole: React.FC = (): JSX.Element => {
	const swaggerApi = useSwaggerApi();
	const { t } = useTranslation();
	const { id } = useParams();
	const theme = useTheme();
	const navigate = useNavigate();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const [confirmationOpen, setConfirmationOpen] = React.useState(false);
	const [selectedPermissionIDs, setSelectedPermissionIDs] = React.useState<number[]>([]);
	const [permissionGroupsState, setPermissionGroupsState] = React.useState<PermissionGroupState>({
		loading: false,
		loaded: false,
		permissionGroups: [],
		error: null,
	});
	const [roleDetailState, setRoleDetailState] = React.useState<RoleDetailState>({
		loading: !!id,
		loaded: false,
		role: null,
		error: null,
	});
	const [roleFormState, setRoleFormState] = React.useState<RoleFormState>({
		saving: false,
		saved: false,
		error: null,
	});

	const {
		handleSubmit,
		formState: { errors },
		reset,
		register,
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getRoleFormSchema(t)),
	});

	const handleCloseConfirmationDialog = React.useCallback(() => {
		setConfirmationOpen(false);
	}, []);

	const handleOnLoadRole = React.useCallback(
		async (roleID: number): Promise<void> => {
			if (roleDetailState.loaded || !roleID) {
				return;
			}
			setRoleDetailState({
				...roleDetailState,
				loading: true,
			});
			try {
				const roleResponse = await swaggerApi.roles.getRole(roleID);
				setRoleDetailState({
					loading: false,
					loaded: true,
					role: roleResponse.data as GetRoleResponseDto,
					error: null,
				});
				reset({
					name: roleResponse.data.name,
					description: roleResponse.data.description,
				});
				setSelectedPermissionIDs(roleResponse.data.permissions);
			} catch (error) {
				console.error(error);
				setRoleDetailState({
					loading: false,
					loaded: false,
					role: null,
					error: error as AxiosError,
				});
			}
		},
		[roleDetailState],
	);

	const handleOnLoadPermissionGroups = React.useCallback(async (): Promise<void> => {
		if (permissionGroupsState.loading) {
			return;
		}
		setPermissionGroupsState({
			...permissionGroupsState,
			loading: true,
		});
		try {
			const permissionGroupsResponse = await swaggerApi.permissions.getPermissions();
			setPermissionGroupsState({
				loading: false,
				loaded: true,
				permissionGroups: permissionGroupsResponse.data.entities as PermissionGroupModel[],
				error: null,
			});
		} catch (error) {
			console.error(error);
			setPermissionGroupsState({
				loading: false,
				loaded: false,
				permissionGroups: [],
				error: error as AxiosError,
			});
		}
	}, [permissionGroupsState]);

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formValues): Promise<void> => {
			if (roleFormState.saving) {
				return;
			}
			if (selectedPermissionIDs.length === 0) {
				setConfirmationOpen(true);

				return;
			}
			setRoleFormState({
				...roleFormState,
				saving: true,
			});
			const requestBody: CreateRoleRequestDto = {
				...formValues,
				permissions: selectedPermissionIDs,
			};
			try {
				if (id) {
					await swaggerApi.roles.updateRole(Number(id), requestBody);
					enqueueSnackbar(t('page.roles.roleForm.actionMessages.roleSuccessfullyUpdated'), {
						variant: 'success',
					});
				} else {
					await swaggerApi.roles.createRole(requestBody);
					enqueueSnackbar(t('page.roles.roleForm.actionMessages.roleSuccessfullyCreated'), {
						variant: 'success',
					});
				}
				setRoleFormState({
					saving: false,
					saved: true,
					error: null,
				});
				navigate('/security/roles');
			} catch (error) {
				console.error(error);
				setRoleFormState({
					saving: false,
					saved: false,
					error: error as AxiosError,
				});
			}
		},
		[selectedPermissionIDs, roleFormState, id],
	);

	React.useEffect(() => {
		handleOnLoadPermissionGroups();
		handleOnLoadRole(Number(id));
	}, [id]);

	const handleOnCheckPermission = React.useCallback(
		(id: number) => () => {
			const found = !!selectedPermissionIDs.find((selectedId) => selectedId === id);
			if (found) {
				setSelectedPermissionIDs(selectedPermissionIDs.filter((selectedId) => selectedId !== id));
			} else {
				setSelectedPermissionIDs([...selectedPermissionIDs, id]);
			}
		},
		[selectedPermissionIDs],
	);

	const getStackWidth = React.useCallback(() => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	}, [matchesLG]);

	const shouldDisableForm = React.useMemo(() => {
		if (roleFormState.saving || roleDetailState.loading || permissionGroupsState.loading) {
			return true;
		}

		return false;
	}, [roleFormState.saving, roleDetailState.loading, permissionGroupsState.loading]);

	const getPermisionsGroupResult = React.useCallback(
		(permissions: PermissionModel[] = []): string => {
			const selectedPermissions: PermissionModel[] = [];
			permissions.forEach((permission) => {
				if (selectedPermissionIDs.includes(permission.id)) {
					selectedPermissions.push(permission);
				}
			});

			return `${selectedPermissions.length}/${permissions.length}`;
		},
		[selectedPermissionIDs],
	);

	return (
		<>
			{roleDetailState.loading ?
				<Preloader />
			:	<Box component={'form'} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
					<Paper elevation={3} sx={{ padding: 2 }}>
						<Box sx={{ marginBottom: 2 }}>
							<PageHeader
								title={id ? t('page.roles.roleForm.titleUpdate') : t('page.roles.roleForm.titleCreate')}
								description={
									id ?
										t('page.roles.roleForm.descriptionUpdate')
									:	t('page.roles.roleForm.descriptionCreate')
								}
								icon={GroupsIcon}
							/>
						</Box>
						<FormGroup>
							<Stack
								spacing={1}
								sx={{
									width: getStackWidth(),
									paddingBottom: 2,
								}}
							>
								<TextField
									name={'name'}
									register={register}
									label={t('page.roles.roleForm.name.label')}
									error={errors.name}
									disabled={shouldDisableForm}
								/>

								<Textarea
									name={'description'}
									register={register}
									label={t('page.roles.roleForm.description.label')}
									error={errors.description}
									disabled={shouldDisableForm}
									rows={4}
								/>
							</Stack>
						</FormGroup>
						<Box
							sx={{
								borderTop: '1px solid #e0e0e0',
								paddingTop: 2,
							}}
						>
							<Grid container spacing={2}>
								{permissionGroupsState.permissionGroups.map(
									(permissionGroup: PermissionGroupModel, index: number) => (
										<Grid
											item
											xs={12}
											md={5}
											xl={3}
											key={`permission-group-wrap-${permissionGroup.name}-${index}`}
										>
											<Accordion>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls={`permission-group-content-${permissionGroup.name}-${index}`}
													id={`permission-group-header-${permissionGroup.name}-${index}`}
												>
													{`${permissionGroup.title} - ${getPermisionsGroupResult(permissionGroup.permissions)}`}
												</AccordionSummary>
												<AccordionDetails>
													<Typography
														sx={{
															marginBottom: 2,
														}}
													>
														{permissionGroup.description}
													</Typography>

													{permissionGroup.permissions.map(
														(permission: PermissionModel, index: number) => (
															<Grid
																container
																spacing={2}
																key={`permission-wrap-${permission.name}-${index}`}
															>
																<Grid item xs={1}>
																	<Tooltip title={permission.description}>
																		<IconButton sx={{ marginRight: 1 }}>
																			<InfoIcon />
																		</IconButton>
																	</Tooltip>
																</Grid>
																<Grid item xs={11}>
																	<FormControlLabel
																		id={`permission-${permission.id}`}
																		control={
																			<Checkbox
																				onClick={handleOnCheckPermission(
																					permission.id,
																				)}
																				checked={
																					!!selectedPermissionIDs.find(
																						(selectedId) =>
																							selectedId ===
																							permission.id,
																					)
																				}
																			/>
																		}
																		label={permission.title}
																	/>
																</Grid>
															</Grid>
														),
													)}
												</AccordionDetails>
											</Accordion>
										</Grid>
									),
								)}
							</Grid>

							<FloatingButtonSave
								type='submit'
								disabled={shouldDisableForm}
								ariaLabel={t('page.roles.roleForm.ariaLabel.save')}
								tooltipTitle={
									id ?
										t('page.roles.roleForm.tooltips.save')
									:	t('page.roles.roleForm.tooltips.create')
								}
							/>
						</Box>
					</Paper>
					<ConfirmationDialog
						open={confirmationOpen}
						onClose={handleCloseConfirmationDialog}
						onConfirm={handleCloseConfirmationDialog}
						disableCancel
						confirmText={t('page.roles.roleForm.confirmation.confirmText')}
						text={t('page.roles.roleForm.confirmation.text')}
						title={t('page.roles.roleForm.confirmation.title')}
					/>
				</Box>
			}
		</>
	);
};
