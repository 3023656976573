import { z } from 'zod';

export const actionListSchema = z.object({
	id: z.number(),
	action: z.string(),
	userID: z.number(),
	userTitle: z.string().optional(),
	userName: z.string(),
	userSurname: z.string().optional(),
	accountName: z.string(),
	targetType: z.number(),
	targetID: z.number(),
	targetName: z.string().optional(),
	tokenID: z.number(),
	location: z
		.object({
			id: z.number(),
			typeID: z.number(),
			name: z.string(),
			latitude: z.number(),
			longitude: z.number(),
			radius: z.number(),
			userID: z.number().optional(),
			tenantID: z.number().optional().nullable(),
		})
		.optional(),
	status: z.string(),
	createdAt: z.string(),
});
