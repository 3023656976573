import * as React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Radio, RadioGroup, FormControlLabel, FormLabel, FormHelperText } from '@mui/material';
import { RadioButtonGroupProps } from './types';
import { ERadioButtonGroupOrientation } from './enums';

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
	control,
	rules,
	name,
	label,
	error,
	options,
	orientation = ERadioButtonGroupOrientation.VERTICAL,
	disabled = false,
	helperText,
	defaultValue, // Add defaultValue prop
}) => {
	const labelId = React.useMemo(
		() => `radio-buttons-group-${name}-${Math.random().toString(36).substring(7)}`,
		[name],
	);

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			disabled={disabled}
			render={({ field, fieldState }) => {
				const errorField = error ? error : fieldState.error;

				return (
					<Box sx={{ display: 'flex', flexFlow: 'column', gap: 2 }}>
						<FormLabel id={labelId} sx={{ color: 'text.primary' }}>
							{label}
						</FormLabel>
						<RadioGroup
							row={orientation === ERadioButtonGroupOrientation.HORIZONTAL}
							aria-labelledby={labelId}
							value={field.value ?? defaultValue ?? ''}
							onChange={field.onChange}
						>
							{options.map((option, index) => (
								<FormControlLabel
									key={`${option.value}-${index}`}
									value={option.value}
									control={<Radio />}
									label={option.label}
								/>
							))}
						</RadioGroup>
						{errorField && typeof errorField.message === 'string' ?
							<FormHelperText error id='security-error'>
								{errorField.message}
							</FormHelperText>
						:	<FormHelperText>{helperText}</FormHelperText>}
					</Box>
				);
			}}
		/>
	);
};
