import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { FloatingButtonProps } from './types';
import { ETooltipPlacement } from './enums';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: theme.palette.primary.main,
	borderRadius: '50%',
	width: theme.spacing(7),
	height: theme.spacing(7),
	boxShadow: theme.shadows[6],
	'&:hover': {
		backgroundColor: theme.palette.primary.dark,
		boxShadow: theme.shadows[8],
	},
}));

export const FloatingButtonSave: React.FC<FloatingButtonProps> = ({
	type,
	position = 'fixed',
	top,
	bottom = 32,
	right = 32,
	left,
	ariaLabel = 'add',
	disabled = false,
	tooltipTitle,
	tooltipDelay = 500,
	tooltipPlacement = ETooltipPlacement.LEFT_END,
	formID,
	onClick,
}) => {
	return (
		<Tooltip title={tooltipTitle} enterDelay={tooltipDelay} placement={tooltipPlacement} arrow>
			<Box
				sx={{
					position: position ?? 'auto',
					top: top ?? 'auto',
					bottom: bottom ?? 'auto',
					right: right ?? 'auto',
					left: left ?? 'auto',
					zIndex: 1050,
				}}
			>
				<StyledIconButton
					aria-label={ariaLabel}
					type={type}
					disabled={disabled}
					form={formID}
					onClick={onClick}
				>
					<SaveIcon />
				</StyledIconButton>
			</Box>
		</Tooltip>
	);
};
