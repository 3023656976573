import * as React from 'react';
import { AxiosError } from 'axios';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import SSHIcon from '../../../../assets/images/ssh-icon.png';
import RDPIcon from '../../../../assets/images/rdp-icon.png';
import VNCIcon from '../../../../assets/images/vnc-icon.png';
import PersonIcon from '../../../../assets/images/person-logo.png';
import ExcaliburIcon from '../../../../assets/images/excalibur-logo.png';
import {
	ActionDetailProps,
	ActionDetailState,
	ActionResultCollectionState,
	TokenDetailState,
	UserGroupState,
	GeofenceState,
} from './types';
import { Preloader } from '../../../../components/Preloader/Preloader';
import { Drawer } from '../../../../components/Drawer/Drawer';
import { useSwaggerApi } from '../../../../hooks/useSwaggerApi';
import { useACL } from '../../../../hooks/useACL';
import { EPermission } from '../../../../enums/permission/EPermission';
import { GetActionDetailDto, GeofenceModel } from '../../../../api/Api';
import { EActionDetailSections } from '../../enums';
import { GeneralSection } from './GeneralSection';
import { LocationSection } from './LocationSection';
import { ValidationResultSection } from './ValidationResultSection';
import { usePreviousValue } from '../../../../hooks/usePreviousValue';

export const ActionDetail: React.FC<ActionDetailProps> = ({ actionID, open, onClose }) => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const theme = useTheme();

	const [currentSection, setCurrentSection] = React.useState<EActionDetailSections>(EActionDetailSections.GENERAL);

	const [actionDetailState, setActionDetailState] = React.useState<ActionDetailState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});
	const previousActionDetailLoaded = usePreviousValue(actionDetailState.loaded);

	const [geofenceState, setGeofenceState] = React.useState<GeofenceState>({
		loading: false,
		loaded: false,
		data: [],
		error: null,
	});

	const [actionResultState, setActionResultState] = React.useState<ActionResultCollectionState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [tokenDetailState, setTokenDetailState] = React.useState<TokenDetailState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [userGroupsState, setUserGroupsState] = React.useState<UserGroupState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const handleOnLoadGeofencesBySecurityPolicy = React.useCallback(
		async (securityPolicyID: number): Promise<void> => {
			if (!securityPolicyID || geofenceState.loading || geofenceState.loaded || geofenceState.error) {
				return;
			}
			setGeofenceState({
				loading: true,
				loaded: false,
				data: [],
				error: null,
			});
			try {
				const geofencesResponse = await api.securityPolicy.getGeofencesForSecurityPolicy(securityPolicyID);
				setGeofenceState({
					loading: true,
					loaded: false,
					data: geofencesResponse.data,
					error: null,
				});
			} catch (error) {
				console.error(error);
				setGeofenceState({
					loading: false,
					loaded: false,
					data: [],
					error: error as AxiosError,
				});
			}
		},
		[actionResultState],
	);

	React.useEffect(() => {
		if (actionDetailState.loaded && !previousActionDetailLoaded) {
			handleOnLoadGeofencesBySecurityPolicy(actionDetailState.data?.policyID as number);
		}
	}, [actionDetailState, previousActionDetailLoaded]);

	const getActionDetail = React.useCallback(async (actionID: number): Promise<void> => {
		if (!actionID) {
			return;
		}
		setActionDetailState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});
		let actionDetail: GetActionDetailDto | null = null;
		let tokenID: number | null = null;

		try {
			const response = await api.actions.getActionById(actionID);

			actionDetail = response.data;
			tokenID = response.data.tokenID;

			setActionDetailState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setActionDetailState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}

		if (!actionDetail) {
			return;
		}

		setActionResultState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const response = await api.actions.getActionResultCollection(actionID);

			setActionResultState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setActionResultState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}

		if (actionDetail.userID) {
			setUserGroupsState({
				loading: true,
				loaded: false,
				data: null,
				error: null,
			});

			try {
				const responseTokenDetail = await api.users.getUserGroupsByUserId(actionDetail.userID);

				setUserGroupsState({
					loading: false,
					loaded: true,
					data: responseTokenDetail.data,
					error: null,
				});
			} catch (error) {
				console.error(error);
				setUserGroupsState({
					loading: false,
					loaded: false,
					data: null,
					error: error as AxiosError,
				});
			}
		}

		if (tokenID) {
			setTokenDetailState({
				loading: true,
				loaded: false,
				data: null,
				error: null,
			});

			try {
				const responseTokenDetail = await api.tokens.getToken(tokenID);

				setTokenDetailState({
					loading: false,
					loaded: true,
					data: responseTokenDetail.data,
					error: null,
				});
			} catch (error) {
				console.error(error);
				setTokenDetailState({
					loading: false,
					loaded: false,
					data: null,
					error: error as AxiosError,
				});
			}
		}
	}, []);

	const onChangeTab = React.useCallback(
		(_: React.SyntheticEvent<Element, Event>, newValue: EActionDetailSections) => {
			setCurrentSection(newValue);
		},
		[],
	);

	const capitalizeFirstLetter = React.useCallback((word: string | undefined): string => {
		if (!word) {
			return '';
		}

		return word.charAt(0).toUpperCase() + word.slice(1);
	}, []);

	const tabs = React.useMemo(() => {
		return [
			{
				value: EActionDetailSections.GENERAL,
				label: t('page.action.detail.sections.general.title'),
			},
			{
				value: EActionDetailSections.LOCATION,
				label: t('page.action.detail.sections.location.title'),
			},
			{
				value: EActionDetailSections.VALIDATION_RESULT,
				label: t('page.action.detail.sections.validationResult.title'),
			},
		];
	}, []);

	React.useEffect(() => {
		if (actionID && !actionDetailState.loading && !actionDetailState.loaded && !actionDetailState.error) {
			getActionDetail(actionID);
		}
	}, [actionID, open]);

	React.useEffect(() => {
		if (!open) {
			setCurrentSection(EActionDetailSections.GENERAL);
			setActionDetailState({
				loading: false,
				loaded: false,
				data: null,
				error: null,
			});
			setActionResultState({
				loading: false,
				loaded: false,
				data: null,
				error: null,
			});
			setTokenDetailState({
				loading: false,
				loaded: false,
				data: null,
				error: null,
			});
			setUserGroupsState({
				loading: false,
				loaded: false,
				data: null,
				error: null,
			});
		}
	}, [open]);

	return (
		<Drawer open={open} title={capitalizeFirstLetter(actionDetailState.data?.action)} onClose={onClose}>
			{actionID && (actionDetailState.loading || !actionDetailState.loaded) ?
				<Preloader />
			:	<Box>
					{isAllowed([EPermission.ACTIONS_READ_ALL, EPermission.ACTIONS_READ_OWN], false) && actionID && (
						<TabContext value={currentSection}>
							<Box sx={{ borderBottom: 1, borderColor: theme.palette.divider, marginBottom: 2 }}>
								<Tabs
									value={currentSection}
									onChange={onChangeTab}
									TabIndicatorProps={{ style: { transition: 'none' } }}
								>
									{tabs.map((tab) => (
										<Tab key={tab.label} label={tab.label} value={tab.value} />
									))}
								</Tabs>
							</Box>

							<TabPanel value={EActionDetailSections.GENERAL} sx={{ padding: 0 }}>
								<GeneralSection
									actionDetail={actionDetailState.data}
									tokenDetail={tokenDetailState.data}
									userGroups={userGroupsState.data}
								/>
							</TabPanel>
							<TabPanel value={EActionDetailSections.LOCATION} sx={{ padding: 0 }}>
								<LocationSection
									location={
										actionDetailState.data?.location ?
											{
												...actionDetailState.data.location,
												name: `${actionDetailState.data.userName} token`,
											}
										:	null
									}
									systemGeofences={geofenceState.data}
								/>
							</TabPanel>
							<TabPanel value={EActionDetailSections.VALIDATION_RESULT} sx={{ padding: 0 }}>
								<ValidationResultSection data={actionResultState.data} />
							</TabPanel>
						</TabContext>
					)}
				</Box>
			}
		</Drawer>
	);
};
