import { TFunction } from 'i18next';
import { z } from 'zod';

export const getGeofenceGroupSchema = (t: TFunction) =>
	z.object({
		name: z
			.string()
			.min(3, { message: t('page.geofenceGroup.edit.form.name.minLength', { minLength: 3 }) })
			.max(64, { message: t('page.geofenceGroup.edit.form.name.maxLength', { maxLength: 64 }) }),
		description: z
			.string()
			.max(255, { message: t('page.geofenceGroup.edit.form.description.maxLength', { maxLength: 255 }) })
			.optional(),
	});

export const geofenceListSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	typeID: z.number(),
	latitude: z.number(),
	longitude: z.number(),
	radius: z.number(),
	userID: z.number().optional(),
	groups: z
		.array(
			z
				.object({
					id: z.number(),
					name: z.string(),
					description: z.string().optional(),
				})
				.optional(),
		)
		.optional(),
});

export const geofenceGroupListSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	geofences: z.array(
		z.object({
			id: z.number(),
			name: z.string(),
		}),
	),
});
