import { red, green, orange, lightBlue, pink, yellow } from '@mui/material/colors';
import { alpha } from '@mui/material';
import {
	TokenlessAuthenticationData,
	authenticationsData,
	authorizationsData,
	factorResetData,
	registrationsData,
	verificationsData,
} from './data';

const colorRed = red.A700;
const colorGreen = green[500];
const colorOrange = orange[500];
const colorLightBlue = lightBlue[500];
const colorCriticalIncidient = red.A700;
const colorHighIncidient = red[600];
const colorMediumIncidient = orange[600];
const colorLowIncidient = green[200];

export const datasetsSessions = [
	{
		label: 'Online',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorGreen,
		backgroundColor: alpha(colorGreen, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
	{
		label: 'OTP',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorOrange,
		backgroundColor: alpha(colorOrange, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
	{
		label: 'Manual',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorRed,
		backgroundColor: alpha(colorRed, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
];

export const datasetsIncidents = [
	{
		label: 'Critical',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorCriticalIncidient,
		backgroundColor: alpha(colorCriticalIncidient, 0.5),
		fill: 'origin',
		borderWidth: 1,
	},
	{
		label: 'High',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorHighIncidient,
		backgroundColor: alpha(colorHighIncidient, 0.3),
		fill: 'origin',
		borderWidth: 1,
	},
	{
		label: 'Medium',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorMediumIncidient,
		backgroundColor: alpha(colorMediumIncidient, 0.2),
		fill: 'origin',
		borderWidth: 1,
	},
	{
		label: 'Low',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorLowIncidient,
		backgroundColor: alpha(colorLowIncidient, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
];

export const datasetsActions = [
	authenticationsData,
	authorizationsData,
	verificationsData,
	registrationsData,
	factorResetData,
	TokenlessAuthenticationData,
];

export const datasetsSession = [
	{
		label: 'Online Sessions',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorGreen,
		backgroundColor: alpha(colorGreen, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
];

export const datasetsLoggedUsers = [
	{
		label: 'Users',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorLightBlue,
		backgroundColor: alpha(colorLightBlue, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
];

export const datasetsIncident = [
	{
		label: 'Medium',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorOrange,
		backgroundColor: alpha(colorOrange, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
];

export const datasetsUsersWithIncidents = [
	{
		label: 'Users',
		data: [...Array(12)].map((e) => ~~(Math.random() * 500)),
		borderColor: colorLightBlue,
		backgroundColor: alpha(colorLightBlue, 0.1),
		fill: 'origin',
		borderWidth: 1,
	},
];
