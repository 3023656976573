import React from 'react';
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
	Accordion,
	AccordionDetails,
	Switch,
	Box,
	FormControl,
	Divider,
	AccordionSummary,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CheckBoxGroupProps, CheckBoxItem, CheckBoxItemID } from './types';

const CheckBoxGroup: React.FC<CheckBoxGroupProps> = ({
	group,
	setGroup,
	labelPlacement = 'end',
	controlType = 'checkbox',
	size = 'medium',
	itemTranslationBasePath = undefined,
	groupTranslationBasePath = undefined,
	isExpanded: externalExpanded = undefined,
	setIsExpanded: setExternalExpanded = undefined,
	showSelectedAllNumbers = true,
}) => {
	if ((externalExpanded === undefined) !== (setExternalExpanded === undefined)) {
		throw new Error('Both `setIsExpanded` and `isExpanded` should be provided or none of them');
	}
	const [internalExpanded, setInternalExpanded] = React.useState<boolean>(false);
	const expanded = externalExpanded ?? internalExpanded;
	const setExpanded = setExternalExpanded ?? setInternalExpanded;
	const { t } = useTranslation();

	const selectedItemsCount = React.useMemo(
		() => Array.from(group.checkBoxItemMap.values()).reduce((acc, item) => (item.isChecked ? acc + 1 : acc), 0),
		[group.checkBoxItemMap],
	);
	const totalItemsCount = group.checkBoxItemMap.size;

	const handleCheckAllChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const allIsChecked = event.target.checked;
			const updatedState = new Map(group.checkBoxItemMap);
			group.checkBoxItemMap.forEach((checkBox) => {
				updatedState.set(checkBox.id, {
					...checkBox,
					isChecked: allIsChecked,
				});
			});

			setGroup({
				...group,
				checkBoxItemMap: updatedState,
			});
		},
		[group, setGroup],
	);

	const isAllChecked = React.useMemo(
		() =>
			Array.from(group.checkBoxItemMap.values()).every(
				(checkBox) => group.checkBoxItemMap.get(checkBox.id)?.isChecked,
			),
		[group],
	);

	const indeterminate = React.useMemo(
		() =>
			Array.from(group.checkBoxItemMap.values()).some(
				(checkBox) => group.checkBoxItemMap.get(checkBox.id)?.isChecked,
			) && !isAllChecked,
		[group, isAllChecked],
	);

	const renderControl = React.useCallback(
		(item: CheckBoxItem) => {
			const commonProps = {
				checked: group.checkBoxItemMap.get(item.id)?.isChecked ?? false,
				onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
					setGroup({
						...group,
						checkBoxItemMap: new Map(group.checkBoxItemMap).set(item.id, {
							...item,
							isChecked: event.target.checked,
						}),
					}),
				name: item.name,
				size: size === 'small' ? ('small' as const) : ('medium' as const),
			};

			switch (controlType) {
				case 'switch':
					return <Switch {...commonProps} />;
				case 'checkbox':
				default:
					return <Checkbox {...commonProps} />;
			}
		},
		[group, setGroup, controlType, size],
	);

	const renderCheckAllControl = React.useCallback(() => {
		const commonProps = {
			checked: isAllChecked,
			onChange: handleCheckAllChange,
			size: size === 'small' ? ('small' as const) : ('medium' as const),
		};

		switch (controlType) {
			case 'switch':
				return <Switch {...commonProps} />;
			case 'checkbox':
			default:
				return <Checkbox {...{ ...commonProps, indeterminate }} />;
		}
	}, [isAllChecked, indeterminate, handleCheckAllChange, controlType, size]);

	const handleIconClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		event.stopPropagation();
		setExpanded(!expanded);
	};

	const handleCheckAllClick = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
		event.stopPropagation();
	};

	const handleCheckBoxItemClick = (id: CheckBoxItemID) => {
		const updatedState = new Map(group.checkBoxItemMap);
		const checkBox = updatedState.get(id);
		if (checkBox) {
			updatedState.set(id, {
				...checkBox,
				isChecked: !checkBox.isChecked,
			});

			setGroup({
				...group,
				checkBoxItemMap: updatedState,
			});
		}
	};

	const groupNameVariant: Variant = React.useMemo(
		() =>
			(
				({
					small: 'body1',
					medium: 'h6',
					large: 'h4',
				}) as const
			)[size],
		[size],
	);
	const groupDescriptionVariant: Variant = React.useMemo(
		() =>
			(
				({
					small: 'caption',
					medium: 'body2',
					large: 'body1',
				}) as const
			)[size],
		[size],
	);
	const itemDescriptionVariant: Variant = React.useMemo(
		() =>
			(
				({
					small: 'body2',
					medium: 'body1',
					large: 'h6',
				}) as const
			)[size],
		[size],
	);

	const renderCheckBox = React.useCallback(
		(checkBox: CheckBoxItem) => (
			<React.Fragment key={checkBox.id}>
				<Box
					display='flex'
					alignItems='center'
					width='100%'
					sx={{
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.04)',
						},
					}}
					onClick={() => handleCheckBoxItemClick(checkBox.id)}
				>
					{labelPlacement === 'start' && (
						<FormControl margin='dense' fullWidth>
							<Typography variant={itemDescriptionVariant} noWrap sx={{ userSelect: 'none' }}>
								{itemTranslationBasePath === undefined ?
									checkBox.name
								:	t(`${itemTranslationBasePath}.name.${checkBox.id}`, checkBox.name ?? '')}
							</Typography>
							<Typography variant='caption' color='textSecondary' noWrap sx={{ userSelect: 'none' }}>
								{itemTranslationBasePath === undefined ?
									checkBox.description
								:	t(`${itemTranslationBasePath}.description.${checkBox.id}`, checkBox.description ?? '')}
							</Typography>
						</FormControl>
					)}
					{renderControl(checkBox)}
					{labelPlacement === 'end' && (
						<FormControl margin='dense' fullWidth>
							<Typography variant={itemDescriptionVariant} noWrap sx={{ userSelect: 'none' }}>
								{itemTranslationBasePath === undefined ?
									checkBox.description
								:	t(`${itemTranslationBasePath}.description.${checkBox.id}`, checkBox.description ?? '')}
							</Typography>
							<Typography variant='caption' color='textSecondary' noWrap sx={{ userSelect: 'none' }}>
								{itemTranslationBasePath === undefined ?
									checkBox.name
								:	t(`${itemTranslationBasePath}.name.${checkBox.id}`, checkBox.name)}
							</Typography>
						</FormControl>
					)}
				</Box>
				<Divider />
			</React.Fragment>
		),
		[labelPlacement, renderControl, itemDescriptionVariant, itemTranslationBasePath, handleCheckBoxItemClick, t],
	);

	return (
		<Accordion
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			disableGutters
			sx={{
				overflow: 'hidden',
				border: '1px solid rgba(0, 0, 0, 0.12)',
			}}
		>
			<AccordionSummary sx={{ borderRadius: expanded ? '8px 8px 0 0' : '8px' }}>
				<Box display='flex' flexDirection='column' width='100%'>
					<Box display='flex' justifyContent='space-between' alignItems='center' justifyItems='center'>
						<Typography variant={groupNameVariant}>
							{groupTranslationBasePath === undefined ?
								group.name
							:	t(`${groupTranslationBasePath}.name.${group.id}`, group.name)}
						</Typography>
						{expanded ?
							<ExpandLessIcon onClick={handleIconClick} />
						:	<ExpandMoreIcon onClick={handleIconClick} />}
					</Box>
					<Box
						display='flex'
						flexDirection='row'
						width='100%'
						alignContent='center'
						alignItems='center'
						justifyContent='space-between'
					>
						<Typography variant={groupDescriptionVariant} color='textSecondary'>
							{groupTranslationBasePath === undefined ?
								group.description
							:	t(`${groupTranslationBasePath}.description.${group.id}`, group.description ?? '')}
						</Typography>
						<FormControlLabel
							onClick={handleCheckAllClick}
							control={renderCheckAllControl()}
							label={`${t('component.checkBoxGroup.toggleAll')} ${showSelectedAllNumbers ? ` (${selectedItemsCount}/${totalItemsCount})` : ''}`}
							labelPlacement='start'
						/>
					</Box>
				</Box>
			</AccordionSummary>
			<Divider />
			<AccordionDetails
				sx={{
					borderRadius: '0 0 8px 8px',
					borderTop: '1px solid rgba(0, 0, 0, 0.12)',
				}}
			>
				<FormGroup>{Array.from(group.checkBoxItemMap.values()).map(renderCheckBox)}</FormGroup>
			</AccordionDetails>
		</Accordion>
	);
};

export default CheckBoxGroup;
