export const parseRTFContent = (rtfContent: string): string => {
	const keyRegex = /([A-Z0-9-]+)}/;
	const match: RegExpMatchArray | null = rtfContent.match(keyRegex);

	if (match && match[1]) {
		const extractedKey: string = match[1].trim();

		return extractedKey;
	}

	return rtfContent;
};

export const parsedPemContent = (pemContent: string) => {
	const lines = pemContent.split('\\n');

	return lines.join('\n');
};

interface ParsedLicense {
	companyName: string;
	validFrom: string;
	validTo: string;
	deploymentName: string;
}

export const parseLicenseContent = (licenseContent: string): ParsedLicense => {
	const regex =
		/"company":\s*{\s*"name":\s*"([^"]+)"\s*},\s*"deployment":\s*{\s*"name":\s*"([^"]+)"\s*},\s*"validFrom":\s*"([^"]+)",\s*"validTo":\s*"([^"]+)",\s*"license":\s*"([^"]+)"/;

	const match = licenseContent.match(regex);

	if (!match) {
		throw new Error('Invalid license content format');
	}

	const [, companyName, deploymentName, validFrom, validTo] = match;

	return {
		companyName,
		deploymentName,
		validFrom,
		validTo,
	};
};
