import parse from 'parse-duration';
import { intervalToDuration, formatDuration } from 'date-fns';
import * as React from 'react';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Paper, Box, Fab, FormGroup, Stack, Typography, Grid, InputAdornment } from '@mui/material';
import { PlaylistAdd as PlaylistAddIcon, Save as SaveIcon } from '@mui/icons-material';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useNavigate } from '../../../hooks/useNavigate';
import { getPAMRdpTargetFormSchema } from './schemas';
import { CreatePAMRDPTargetRequestDto, ERDPSecurityMode } from '../../../api/Api';
import { PAM_RDP_TARGET_DEFAULT_PORT } from './constants';
import { Preloader } from '../../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { useAuthContext } from '../../../contexts/AuthContext/AuthContext';
import { FormValues, PAMRdpTargetState, SubmitFormState } from './types';
import { ConfirmDialogState } from '../types';
import { SwitchField } from '../../../components/FormFields/Switch/Switch';
import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { UpdatePAMRDPTargetRequestDto } from '../../../api/Api';
import { usePreviousValue } from '../../../hooks/usePreviousValue';
import { Heading } from '../../../components/Heading/Heading';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { Textarea } from '../../../components/FormFields/Textarea/Textarea';
import { PasswordField } from '../../../components/FormFields/PasswordField/PasswordField';
import { Select } from '../../../components/FormFields/Select/Select';
import { Checkbox } from '../../../components/FormFields/Checkbox/Checkbox';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';
import { convertBytesToMB, convertMBtoBytes } from '../../../utils/FormatSize';
import { useACL } from '../../../hooks/useACL';
import { EPermission } from '../../../enums/permission/EPermission';

export const PAMAddRDPTarget: React.FC = (): JSX.Element => {
	const authContext = useAuthContext();
	const { isAllowed } = useACL();
	const api = useSwaggerApi();
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
	const [confirmDialogText, setConfirmDialogText] = React.useState<ConfirmDialogState>({
		text: '',
		title: '',
	});
	const [isConfirmAnyway, setIsConfirmAnyway] = React.useState(false);
	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const previousIsConfirmAnyway = usePreviousValue(isConfirmAnyway);

	const securityModes = React.useMemo(() => {
		return [
			{
				label: 'Any',
				value: ERDPSecurityMode.Any,
				helperText: t('page.pam.edit.all.form.security.helperText.any'),
			},
			{
				label: 'NLA',
				value: ERDPSecurityMode.Nla,
				helperText: t('page.pam.edit.all.form.security.helperText.nla'),
			},
			{
				label: 'NLA-Ext',
				value: ERDPSecurityMode.NlaExt,
				helperText: t('page.pam.edit.all.form.security.helperText.nlaExt'),
			},
			{
				label: 'TLS',
				value: ERDPSecurityMode.Tls,
				helperText: t('page.pam.edit.all.form.security.helperText.tls'),
			},
			{
				label: 'WM Connect',
				value: ERDPSecurityMode.Wmconnect,
				helperText: t('page.pam.edit.all.form.security.helperText.wmConnect'),
			},
			{
				label: 'RDP',
				value: ERDPSecurityMode.Rdp,
				helperText: t('page.pam.edit.all.form.security.helperText.rdp'),
			},
		];
	}, []);

	const getDurationInMin = React.useCallback((time: string): number => {
		try {
			const result = Math.round(parse(time, 'minute') ?? 0);

			if (!result) {
				return 0;
			}

			return result >= 1 ? result : 0;
		} catch {
			return 0;
		}
	}, []);

	const getDurationInString = React.useCallback((time: number): string => {
		const duration = intervalToDuration({ start: 0, end: time * 60000 });
		const { years, months, days, hours, minutes } = duration;

		return formatDuration({ years, months, days, hours, minutes });
	}, []);

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const [pamRdpTargetState, setPamRdpTargetState] = React.useState<PAMRdpTargetState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const {
		handleSubmit,
		register,
		control,
		reset,
		getValues,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getPAMRdpTargetFormSchema(t, !!id)),
		defaultValues: {
			port: PAM_RDP_TARGET_DEFAULT_PORT,
			recordingRetentionPeriod: '6 months',
			security: ERDPSecurityMode.Any,
			ignoreCertificate: false,
			storeRecordingsPermanently: false,
			enableSessionRecording: true,
			enableFileUpload: false,
			enableFileDownload: false,
			uploadFileSizeLimit: '50',
			enableClipboardCopy: false,
			enableClipboardPaste: false,
			useExcaliburCredentials: false,
		},
	});

	const security = useWatch({ control: control, name: 'security' });
	const ignoreCertificate = useWatch({ control: control, name: 'ignoreCertificate' });
	const storeRecordingsPermanently = useWatch({ control: control, name: 'storeRecordingsPermanently' });
	const enableSessionRecording = useWatch({ control: control, name: 'enableSessionRecording' });
	const enableFileUpload = useWatch({ control: control, name: 'enableFileUpload' });
	const enableClipboardCopy = useWatch({ control: control, name: 'enableClipboardCopy' });
	const enableClipboardPaste = useWatch({ control: control, name: 'enableClipboardPaste' });
	const useExcaliburCredentials = useWatch({ control: control, name: 'useExcaliburCredentials' });
	const selectedMode = React.useMemo(() => {
		return securityModes.find((mode) => mode.value === security);
	}, [security, securityModes]);

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData): Promise<void> => {
			if (submitFormState.submitting) {
				return;
			}
			const timeInMin = formData.enableSessionRecording ? getDurationInMin(formData.recordingRetentionPeriod) : 0;

			const requestData: Omit<CreatePAMRDPTargetRequestDto, 'password'> = {
				name: formData.name,
				description: formData.description,
				hostname: formData.hostname,
				port: Number(formData.port),
				useExcaliburCredentials: formData.useExcaliburCredentials,
				domain: formData.domain,
				username: formData.username,
				security: formData.security,
				ignoreCertificate: formData.ignoreCertificate,
				remoteApplication: {
					name: formData.remoteAppName,
					workingDirectory: formData.remoteAppDirectory,
					commandLineArguments: formData.remoteAppArgs,
				},
				recording: {
					retentionPeriod:
						formData.storeRecordingsPermanently ? null
						: timeInMin >= 1 ? timeInMin
						: null,
					enabled: formData.enableSessionRecording,
				},
				fileTransfer: {
					uploadEnabled: formData.enableFileUpload,
					downloadEnabled: formData.enableFileDownload,
					uploadFileSizeLimit:
						formData.uploadFileSizeLimit === undefined ?
							undefined
						:	convertMBtoBytes(formData.uploadFileSizeLimit),
				},
				clipboard: {
					enablePaste: enableClipboardPaste,
					enableCopy: enableClipboardCopy,
				},
				additionalArguments: [],
			};

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				if (!isConfirmAnyway) {
					const activeTenantID = authContext.userTenants.activeTenantID;
					const { data: verifyPamTargetResponse } = await api.pam.verifyPamTarget({
						networkAddress: formData.hostname,
						port: Number(formData.port),
						tenantID: activeTenantID,
						targetID: id ? Number(id) : null,
					});

					const isPamTargetReachable = verifyPamTargetResponse.reachable;
					const isPamTargetInRange = verifyPamTargetResponse.inRange;

					if (!isPamTargetReachable || !isPamTargetInRange) {
						setIsConfirmDialogOpen(true);
						if (!isPamTargetReachable) {
							setConfirmDialogText({
								text: t('page.pam.confirmation.connectionError.text'),
								title: t('page.pam.confirmation.connectionError.title'),
							});
						} else if (!isPamTargetInRange) {
							setConfirmDialogText({
								text: t('page.pam.confirmation.outOfIPRangeError.text'),
								title: t('page.pam.confirmation.outOfIPRangeError.title'),
							});
						}
						setSubmitFormState({
							submitted: false,
							submitting: false,
							error: null,
						});

						return;
					}
				}

				if (id) {
					const requestBody: UpdatePAMRDPTargetRequestDto = {
						...requestData,
					};
					if (formData.password) {
						requestBody.password = formData.password;
					}

					await api.pam.updateRdpTarget(Number(id), requestBody);
					enqueueSnackbar(t('page.pam.edit.all.actionMessages.pamTargetSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
					navigate(`/pam`);
				} else {
					const requestBody: CreatePAMRDPTargetRequestDto = {
						...requestData,
						password: formData.password,
					};
					await api.pam.createRdpTarget(requestBody);
					navigate(`/pam`);
					enqueueSnackbar(t('page.pam.edit.all.actionMessages.pamTargetSuccessfullyCreated'), {
						variant: 'success',
						persist: false,
					});
				}

				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
			} catch (error: unknown) {
				console.error(error);
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
			}
		},
		[enableClipboardCopy, enableClipboardPaste, submitFormState, id, isConfirmDialogOpen, isConfirmAnyway],
	);

	const getPAMRdpTarget = React.useCallback(
		async (targetID: number): Promise<void> => {
			setPamRdpTargetState({
				loading: true,
				loaded: false,
				data: null,
				error: null,
			});

			try {
				const response = await api.pam.getRdpTarget(targetID);
				setPamRdpTargetState({
					loading: false,
					loaded: true,
					data: response.data,
					error: null,
				});
				const fileSizeLimit = response.data.fileTransfer.uploadFileSizeLimit;
				reset({
					name: response.data.name,
					description: response.data.description,
					hostname: response.data.hostname,
					port: response.data.port.toString(),
					username: response.data.username,
					domain: response.data.domain,
					password: '',
					security: response.data.security,
					useExcaliburCredentials: response.data.useExcaliburCredentials,
					ignoreCertificate: response.data.ignoreCertificate,
					remoteAppName: response.data.remoteApplication?.name ?? '',
					remoteAppDirectory: response.data.remoteApplication?.workingDirectory ?? '',
					remoteAppArgs: response.data.remoteApplication?.commandLineArguments ?? '',
					enableSessionRecording: response.data.recording.enabled,
					storeRecordingsPermanently:
						response.data.recording.enabled &&
						(response.data.recording.retentionPeriod === null ||
							response.data.recording.retentionPeriod === 0),
					recordingRetentionPeriod:
						response.data.recording.retentionPeriod ?
							getDurationInString(response.data.recording.retentionPeriod)
						:	'',
					enableFileUpload: response.data.fileTransfer.uploadEnabled,
					enableFileDownload: response.data.fileTransfer.downloadEnabled,
					uploadFileSizeLimit: fileSizeLimit ? convertBytesToMB(fileSizeLimit) : '',
					enableClipboardCopy: response.data.clipboard.enableCopy,
					enableClipboardPaste: response.data.clipboard.enablePaste,
				});
			} catch (error) {
				console.error(error);
				setPamRdpTargetState({
					loading: false,
					loaded: false,
					data: null,
					error: error as AxiosError,
				});
			}
		},
		[api.pam, enqueueSnackbar, reset, setPamRdpTargetState, t],
	);

	const retrySubmit = React.useCallback(() => {
		setIsConfirmDialogOpen(false);
		setIsConfirmAnyway(true);
	}, []);

	const closeConfirmDialog = React.useCallback(() => {
		setIsConfirmDialogOpen(false);
		setIsConfirmAnyway(false);
		setSubmitFormState({
			submitted: false,
			submitting: false,
			error: null,
		});
	}, []);

	React.useEffect(() => {
		if (!previousIsConfirmAnyway && isConfirmAnyway && !submitFormState.submitting) {
			onSubmit(getValues());
		}
	}, [isConfirmAnyway, submitFormState, previousIsConfirmAnyway]);

	React.useEffect(() => {
		if (id && !pamRdpTargetState.loading && !pamRdpTargetState.loaded && !pamRdpTargetState.error) {
			getPAMRdpTarget(Number(id));
		}
	}, [id, pamRdpTargetState]);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !pamRdpTargetState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || pamRdpTargetState.loading)) {
			setShouldDisableForm(true);
		}
	}, [pamRdpTargetState, submitFormState, shouldDisableForm]);

	return (
		<>
			<Box component={'form'} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
				{id && (pamRdpTargetState.loading || !pamRdpTargetState.loaded) ?
					<Preloader />
				:	<Paper elevation={3}>
						<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
							<PageHeader
								title={id ? t('page.pam.edit.rdp.title') : t('page.pam.add.rdp.title')}
								description={
									id ? t('page.pam.edit.rdp.description') : t('page.pam.add.rdp.description')
								}
								icon={PlaylistAddIcon}
							/>
						</Box>
						<Grid container>
							<Grid item xs={12} md={6}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.general')} />
										<Stack spacing={2}>
											<TextField
												name={'name'}
												register={register}
												label={t('page.pam.edit.all.form.name.label')}
												error={errors.name}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.name.helperText')}
											/>
											<Textarea
												name={'description'}
												register={register}
												label={t('page.pam.edit.all.form.description.label')}
												error={errors.description}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.description.helperText')}
												rows={4}
											/>
										</Stack>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6}>
								<FormGroup sx={{ padding: 2 }}>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.network')} />
										<Stack spacing={2}>
											<TextField
												name={'hostname'}
												register={register}
												label={t('page.pam.edit.all.form.hostname.label')}
												error={errors.hostname}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.hostname.helperText', {
													type: 'RDP',
												})}
											/>
											<TextField
												name={'port'}
												register={register}
												label={t('page.pam.edit.all.form.port.label')}
												error={errors.port}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.port.helperText.rdp')}
											/>
										</Stack>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6}>
								<FormGroup sx={{ p: 2 }}>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.credentials')} />
										<Typography variant='body1' sx={{ paddingBottom: 2 }}>
											{t('page.pam.edit.all.body.description.credentials')}
										</Typography>
										<Stack spacing={2}>
											<SwitchField
												name='useExcaliburCredentials'
												control={control}
												label={t('page.pam.edit.all.useExcaliburCredentials.label')}
												helperText={t('page.pam.edit.all.useExcaliburCredentials.helperText')}
											/>
											<TextField
												name={'username'}
												register={register}
												label={t('page.pam.edit.all.form.username.label')}
												error={errors.username}
												disabled={shouldDisableForm || useExcaliburCredentials}
												helperText={t('page.pam.edit.all.form.username.helperText', {
													type: 'RDP',
												})}
											/>
											<PasswordField
												name={'password'}
												register={register}
												label={t('page.pam.edit.all.form.password.label')}
												error={errors.password}
												disabled={shouldDisableForm || useExcaliburCredentials}
												helperText={t('page.pam.edit.all.form.password.helperText', {
													type: 'RDP',
												})}
											/>
											<TextField
												name={'domain'}
												register={register}
												label={t('page.pam.edit.all.form.domain.label')}
												error={errors.domain}
												disabled={shouldDisableForm || useExcaliburCredentials}
												helperText={t('page.pam.edit.all.form.domain.helperText')}
											/>
										</Stack>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6}>
								<FormGroup sx={{ p: 2 }}>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.remoteApp')} />
										<Typography variant='body1' sx={{ paddingBottom: 2 }}>
											{t('page.pam.edit.all.body.description.remoteApp')}
										</Typography>
										<Stack spacing={2}>
											<TextField
												name={'remoteAppName'}
												register={register}
												label={t('page.pam.edit.all.form.remoteAppName.label')}
												error={errors.remoteAppName}
												disabled={shouldDisableForm}
												placeholder={'||remoteAppName'}
												helperText={t('page.pam.edit.all.form.remoteAppName.helperText')}
											/>
											<TextField
												name={'remoteAppDirectory'}
												register={register}
												label={t('page.pam.edit.all.form.remoteAppDirectory.label')}
												error={errors.remoteAppDirectory}
												disabled={shouldDisableForm}
												placeholder={'C:\\Program Files\\RemoteApp'}
												helperText={t('page.pam.edit.all.form.remoteAppDirectory.helperText')}
											/>
											<TextField
												name={'remoteAppArgs'}
												register={register}
												label={t('page.pam.edit.all.form.remoteAppArgs.label')}
												error={errors.remoteAppArgs}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.remoteAppArgs.helperText')}
											/>
										</Stack>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6}>
								<FormGroup sx={{ p: 2 }}>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.security')} />
										<Typography variant='body1' sx={{ paddingBottom: 1 }}>
											{t('page.pam.edit.all.body.description.security')}
										</Typography>
										<Stack spacing={2}>
											<Checkbox
												name='ignoreCertificate'
												control={control}
												label={t('page.pam.edit.all.form.ignoreCertificate.label')}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.ignoreCertificate.helperText')}
											/>
											<Select
												control={control}
												name='security'
												label={t('page.pam.edit.all.form.security.label')}
												options={securityModes}
												// helperText={t('page.pam.edit.all.form.security.helperText')}
												helperText={
													selectedMode?.helperText ??
													t('page.pam.edit.all.form.security.helperText.default')
												}
												error={errors.security}
												disabled={shouldDisableForm}
											/>
										</Stack>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.recording')} />
										<Typography variant='body1' sx={{ paddingBottom: 1 }}>
											{t('page.pam.edit.all.body.description.recording')}
										</Typography>
										<Checkbox
											name='enableSessionRecording'
											control={control}
											label={t('page.pam.edit.all.form.enableSessionRecording.label')}
											disabled={shouldDisableForm}
										/>
										<Checkbox
											name='storeRecordingsPermanently'
											control={control}
											label={t('page.pam.edit.all.form.storeRecordingsPermanently.label')}
											disabled={!enableSessionRecording || shouldDisableForm}
										/>
										<TextField
											name={'recordingRetentionPeriod'}
											register={register}
											label={t('page.pam.edit.all.form.recordingRetentionPeriod.label')}
											error={errors.recordingRetentionPeriod}
											disabled={
												!enableSessionRecording ||
												storeRecordingsPermanently ||
												shouldDisableForm
											}
											placeholder={'6 months'}
											helperText={t('page.pam.edit.all.form.recordingRetentionPeriod.helperText')}
										/>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.sftp')} />
										<Typography variant='body1' sx={{ paddingBottom: 1 }}>
											{t('page.pam.edit.all.body.description.sftp')}
										</Typography>
										<Checkbox
											name='enableFileDownload'
											control={control}
											label={t('page.pam.edit.all.form.enableFileDownload.label')}
											error={errors.enableFileDownload}
											disabled={shouldDisableForm}
										/>
										<Checkbox
											name='enableFileUpload'
											control={control}
											label={t('page.pam.edit.all.form.enableFileUpload.label')}
											error={errors.enableFileUpload}
											disabled={shouldDisableForm}
										/>
										<TextField
											name={'uploadFileSizeLimit'}
											register={register}
											label={t('page.pam.edit.all.form.uploadFileSizeLimit.label')}
											error={errors.uploadFileSizeLimit}
											disabled={!enableFileUpload || shouldDisableForm}
											helperText={t('page.pam.edit.all.form.uploadFileSizeLimit.helperText')}
											InputProps={{
												endAdornment: <InputAdornment position='end'>{'MB'}</InputAdornment>,
											}}
										/>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.clipboard')} />
										<Typography variant='body1' sx={{ paddingBottom: 1 }}>
											{t('page.pam.edit.all.body.description.clipboard')}
										</Typography>
										<Checkbox
											name='enableClipboardCopy'
											control={control}
											label={t('page.pam.edit.all.form.enableClipboardCopy.label')}
											disabled={shouldDisableForm}
										/>
										<Checkbox
											name='enableClipboardPaste'
											control={control}
											label={t('page.pam.edit.all.form.enableClipboardPaste.label')}
											disabled={shouldDisableForm}
										/>
									</Stack>
								</FormGroup>
							</Grid>

							{isAllowed([EPermission.PAM_TARGETS_CREATE, EPermission.PAM_TARGETS_UPDATE], false) && (
								<FloatingButtonSave
									type='submit'
									disabled={shouldDisableForm}
									ariaLabel={t('page.pam.edit.all.ariaLabel.save')}
									tooltipTitle={
										id ?
											t('page.pam.edit.all.tooltips.save')
										:	t('page.pam.edit.all.tooltips.create')
									}
								/>
							)}
						</Grid>
					</Paper>
				}
			</Box>
			{isAllowed([EPermission.PAM_TARGETS_CREATE, EPermission.PAM_TARGETS_UPDATE], false) && (
				<ConfirmationDialog
					open={isConfirmDialogOpen}
					onClose={closeConfirmDialog}
					onConfirm={retrySubmit}
					text={confirmDialogText.text}
					title={confirmDialogText.title}
				/>
			)}
		</>
	);
};
