import * as React from 'react';
import { CardActionArea, CardContent, Card, CardHeader, Box, Chip, Typography, Stack } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CountUp } from '../CountUp/CountUp';
import { StatisticsCardProps } from './types';

export const StatisticsCard: React.FC<StatisticsCardProps> = ({
	title,
	count,
	prevCount,
	negativeIsGood = false,
	style,
}) => {
	const renderChip = () => {
		const diff = count - prevCount;

		if (diff === 0) {
			return (
				<Typography variant='subtitle2' color='text.secondary'>
					No change
				</Typography>
			);
		}

		let backgroundColor = diff > 0 ? green[100] : red[100];
		let color = diff > 0 ? green[800] : red[800];

		if (negativeIsGood) {
			backgroundColor = diff > 0 ? red[100] : green[100];
			color = diff > 0 ? red[800] : green[800];
		}

		const percentage = Math.abs((diff / prevCount) * 100).toFixed(1);

		return (
			<>
				<Chip
					label={`-${percentage} %`}
					sx={{
						backgroundColor,
						color,
					}}
				/>
				<Typography variant='subtitle2' color='text.primary'>{`from ${prevCount}`}</Typography>
			</>
		);
	};

	return (
		<Card style={style}>
			<CardActionArea
				sx={{
					height: '100%',
				}}
			>
				<CardHeader
					sx={{
						padding: 1,
					}}
					title={title}
					titleTypographyProps={{
						variant: 'subtitle1',
						textAlign: 'center',
					}}
				/>
				<CardContent
					sx={{
						paddingTop: 0,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							padding: 1,
						}}
					>
						<Typography variant='h4'>
							<CountUp value={count} duration={2000} />
						</Typography>
					</Box>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Stack
							spacing={1}
							direction='row'
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{renderChip()}
						</Stack>
						<Typography variant='subtitle2' color='text.secondary'>
							since last month
						</Typography>
					</Box>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
