import * as React from 'react';
import { Box, Fade } from '@mui/material';
import { Header } from './Header';
import { ControlPanel } from './ControlPanel';
import { ControlsProps } from './types';

export const Controls: React.FC<ControlsProps> = ({
	filename,
	isPlaying,
	position,
	duration,
	status,
	onPlay,
	showControls,
	userInput,
	fullScreenMode,
	onSeek,
}) => {
	return (
		<Box>
			<Fade in={showControls || !isPlaying}>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
					}}
				>
					{/* <Header title={filename} /> */}
				</Box>
			</Fade>
			<Fade in={showControls || !isPlaying}>
				<Box
					sx={{
						position: 'absolute',
						bottom: 0,
						left: 0,
						right: 0,
					}}
				>
					<ControlPanel
						isPlaying={isPlaying}
						position={position}
						duration={duration}
						status={status}
						userInput={userInput}
						onPlay={onPlay}
						onSeek={onSeek}
						fullScreenMode={fullScreenMode}
					/>
				</Box>
			</Fade>
		</Box>
	);
};
