/* eslint-disable max-len */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import { Stack, TextField, Button } from '@mui/material';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import { MetadataForm } from '../../components/MetadataForm/MetadataForm';
import { useSnackbar } from 'notistack';

type FormInputs = {
	name?: string;
	description?: string;
	url?: string;
	xml?: string;
	entityId?: string;
	consumeServiceEndpoint?: string;
	logoutServiceEndpoint?: string;
	nameIdFormat?: string;
	authnRequestsSignedIn?: boolean;
	assertionsSigned?: boolean;
	organizationName?: string;
	organizationDisplayName?: string;
	organizationURL?: string;
	technicalGivenName?: string;
	technicalEmail?: string;
	supportGivenName?: string;
	supportEmail?: string;
	supportPrivateKey?: string;
	supportCertificate?: string;
};

export const SAMLAddServiceProvider: React.FC = (): JSX.Element => {
	const form = useForm<FormInputs>({
		defaultValues: {
			name: '',
			description: '',
			url: '',
			xml: '',
			entityId: '',
			consumeServiceEndpoint: '',
			logoutServiceEndpoint: '',
			nameIdFormat: '',
			authnRequestsSignedIn: false,
			assertionsSigned: false,
			organizationName: '',
			organizationDisplayName: '',
			organizationURL: '',
			technicalGivenName: '',
			technicalEmail: '',
			supportGivenName: '',
			supportEmail: '',
			supportPrivateKey: '',
			supportCertificate: '',
		},
	});

	const [saving, setSaving] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { register, handleSubmit, formState, watch, setValue, getValues } = form;
	const { errors } = formState;

	const { ref, ...inputProps } = register('name', {
		required: 'error text',
	});

	const theme = useTheme();

	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const onSubmit = (e: any) => {
		e.preventDefault();
		// eslint-disable-next-line no-alert
		alert(JSON.stringify(getValues()));
	};

	return (
		<Box component={'form'} noValidate>
			<form noValidate>
				<Box
					sx={{
						paddingBottom: 1,
					}}
				>
					<Typography variant='h5'>Add Service Provider</Typography>
				</Box>

				<Paper
					elevation={3}
					sx={{
						paddingBottom: 2,
					}}
				>
					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Stack
							spacing={1}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Typography variant='subtitle1'>General</Typography>

							<TextField
								label='Name'
								variant='outlined'
								type='text'
								helperText='Display name of the Service Provide'
								// value={textValue}
								minRows={4}
								fullWidth
								{...register('name', {
									required: 'Name is required field',
								})}
								// error={errors.name}
								// helperText={errors.name?.message}
							/>
							<TextField
								label='Description'
								variant='outlined'
								type='text'
								helperText='Description of the Service Provide'
								multiline
								minRows={4}
								fullWidth
								{...register('description')}
							/>
						</Stack>
					</FormGroup>

					<MetadataForm register={register} />

					<Fab
						color='primary'
						type='submit'
						aria-label='add'
						disabled={saving}
						onClick={() => onSubmit(event)}
						sx={{
							position: 'fixed',
							bottom: 32,
							right: 32,
						}}
					>
						<SaveIcon />
					</Fab>
				</Paper>
			</form>
		</Box>
	);
};
