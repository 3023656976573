import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MaterialReactTable,
} from 'material-react-table';
import { intervalToDuration, formatDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
	GetAppOutlined as GetAppOutlinedIcon,
	PlayArrow as PlayArrowIcon,
	SaveAlt as SaveAltIcon,
	Download as DownloadIcon,
	Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PAMTargetState } from './types';
import { AxiosError } from 'axios';
import {
	EPAMTargetType,
	GetPAMTargetSessionsResponseDto,
	GetPAMTargetUsersResponseDto,
	GroupDto,
	PAMTargetDetailModel,
	PAMTargetGroupModel,
	PamSessionDto,
	UserDetailModel,
} from '../../api/Api';
import { useTableQuery } from '../../hooks/useTableQuery';
import { useNavigate } from '../../hooks/useNavigate';
import { useFormatDate } from '../../hooks/useFormatDate';
import { calculateDuration, formatDurationToString } from '../../utils/SessionDateHelpers';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';

import { useACL } from '../../hooks/useACL';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { sessionListSchema, userListSchema } from './schema';
import { EPermission } from '../../enums/permission/EPermission';
import { enqueueSnackbar } from 'notistack';
import { ESessionTarget } from '../../enums/session/ESessionTarget';
import { SessionPlayerDialog } from '../../components/Dialog/SessionPlayerDialog/SessionPlayerDialog';
import { ChipArray } from '../../components/ChipArray/ChipArray';
import { Heading } from '../../components/Heading/Heading';
import { getSessionTargetLogo } from '../sessions/utils';
import { SectionConfig } from '../../components/DetailSection/types';
import { DetailSection } from '../../components/DetailSection/DetailSection';
import { LabeledBox } from '../../components/LabeledBox/LabeledBox';
import { Link } from '../../components/Link/Link';
import { FloatingButtonEdit } from '../../components/Buttons/FloatingButton/FloatingButtonEdit';
import { Preloader } from '../../components/Preloader/Preloader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';
import { convertBytesToMB } from '../../utils/FormatSize';

export const GeneralSection: React.FC<{ id: PAMTargetDetailModel['id'] }> = ({ id }) => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const navigate = useNavigate();
	const formatDate = useFormatDate();
	const { t } = useTranslation();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [hasRunningSession, setHasRunningSession] = React.useState(false);
	const [openPlayerDialog, setOpenPlayerDialog] = React.useState(false);
	const [recordingUrl, setRecordingUrl] = React.useState('');
	const [durationIncrement, setDurationIncrement] = React.useState(0);
	const [PAMTargetGroups, setPAMTargetGroups] = React.useState<PAMTargetGroupModel[]>([]);

	const [PAMTargetState, setPAMTargetState] = React.useState<PAMTargetState>({
		loading: false,
		loaded: false,
		type: null,
		data: null,
		error: null,
	});

	const {
		columnFilters: columnFiltersSession,
		setColumnFilters: setColumnFiltersSession,
		sorting: sortingSession,
		setSorting: setSortingSession,
		columnVisibility: columnVisibilitySession,
		setColumnVisibility: setColumnVisibilitySession,
		globalFilter: globalFilterSession,
		setGlobalFilter: setGlobalFilterSession,
		pagination: paginationSession,
		setPagination: setPaginationSession,
		swaggerQuery: swaggerQuerySession,
	} = useTableQuery(['userFullName', 'accountName', 'startAt', 'endAt']);

	const {
		columnFilters: columnFiltersUser,
		setColumnFilters: setColumnFiltersUser,
		sorting: sortingUser,
		setSorting: setSortingUser,
		columnVisibility: columnVisibilityUser,
		setColumnVisibility: setColumnVisibilityUser,
		globalFilter: globalFilterUser,
		setGlobalFilter: setGlobalFilterUser,
		pagination: paginationUser,
		setPagination: setPaginationUser,
		swaggerQuery: swaggerQueryUser,
	} = useTableQuery(['userFullName', 'email']);

	const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

	const FETCH_INTERVAL = 5000;

	const {
		data: sessionList,
		isError: sessionListIsError,
		isRefetching: sessionListIsRefetching,
		isLoading: sessionListIsLoading,
		error: sessionListError,
	} = useQuery<GetPAMTargetSessionsResponseDto>({
		queryKey: [EQueryKey.PAM_TARGET_SESSION_LIST_QUERY, swaggerQuerySession, id],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuerySession.limit,
					offset: swaggerQuerySession.offset,
					columns: swaggerQuerySession.columns,
					filter: swaggerQuerySession.filter,
					sort: swaggerQuerySession.sort,
				};

				const response = await api.pam.getPamTargetSessions(id, query);

				response.data.entities.forEach((session) => {
					sessionListSchema.parse(session);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities: sessionEntities = [], total: sessionTotal = 0 } = sessionList ? sessionList : {};

	const {
		data: userList,
		isError: userListIsError,
		isRefetching: userListIsRefetching,
		isLoading: userListIsLoading,
		error: userListError,
	} = useQuery<GetPAMTargetUsersResponseDto>({
		queryKey: [EQueryKey.PAM_TARGET_USER_LIST_QUERY, swaggerQueryUser, id],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQueryUser.limit,
					offset: swaggerQueryUser.offset,
					columns: swaggerQueryUser.columns,
					filter: swaggerQueryUser.filter,
					sort: swaggerQueryUser.sort,
				};

				const response = await api.pam.getPamTargetUsers(id, query);

				response.data.entities.forEach((group) => {
					userListSchema.parse(group);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities: userEntities = [], total: userTotal = 0 } = userList ? userList : {};

	const getPAMTarget = React.useCallback(async (targetID: number): Promise<void> => {
		setPAMTargetState({
			loading: true,
			loaded: false,
			type: null,
			data: null,
			error: null,
		});

		let type: EPAMTargetType | undefined = undefined;

		try {
			const response = await api.pam.getTargetDetail(targetID);

			type = response.data.type;

			setPAMTargetGroups(response.data.groups);
		} catch (error) {
			console.error(error);
			setPAMTargetGroups([]);
			setPAMTargetState({
				loading: false,
				loaded: false,
				type: null,
				data: null,
				error: error as AxiosError,
			});
		}

		try {
			const fetchFunctions = {
				[EPAMTargetType.RDP]: () => api.pam.getRdpTarget(targetID),
				[EPAMTargetType.SSH]: () => api.pam.getSshTarget(targetID),
				[EPAMTargetType.VNC]: () => {
					const errorMessage = t('page.pam.detail.errorMessages.unsupportedType', { type });
					enqueueSnackbar(errorMessage, {
						variant: 'error',
						persist: false,
					});
					throw new Error(errorMessage);
				},
			};

			if (type === undefined || !(type in fetchFunctions)) {
				const errorMessage = t('page.pam.detail.errorMessages.unsupportedType', { type });
				enqueueSnackbar(errorMessage, {
					variant: 'error',
					persist: false,
				});
				throw new Error(errorMessage);
			}

			const response = await fetchFunctions[type]();

			setPAMTargetState({
				loading: false,
				loaded: true,
				type: type,
				data: response.data,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setPAMTargetGroups([]);
			setPAMTargetState({
				loading: false,
				loaded: false,
				type: null,
				data: null,
				error: error as AxiosError,
			});
		}
	}, []);

	const handleOnTargetGroupChipClick = React.useCallback(
		(group: PAMTargetGroupModel) => {
			if (!isAllowed([EPermission.PAM_GROUPS_READ])) {
				return;
			}
			if (id === group.id) {
				return;
			}
			setPAMTargetState({
				loaded: false,
				type: null,
				data: null,
				error: null,
				loading: false,
			});
			navigate(`/pam/groups/${group.id}`);
		},
		[isAllowed],
	);

	const handleOnUserGroupChipClick = React.useCallback(
		(group: GroupDto) => {
			if (!isAllowed([EPermission.USER_GROUPS_READ])) {
				return;
			}
			if (id === group.id) {
				return;
			}
			setPAMTargetState({
				loaded: false,
				type: null,
				data: null,
				error: null,
				loading: false,
			});
			navigate(`/users/userGroups/${group.id}`);
		},
		[isAllowed],
	);

	const getDurationInString = React.useCallback((time: number): string => {
		const duration = intervalToDuration({ start: 0, end: time * 60000 });
		const { years, months, days, hours, minutes } = duration;

		return formatDuration({ years, months, days, hours, minutes });
	}, []);

	const renderDuration = React.useMemo(() => {
		const renderSessionDuration = (startAt: string | undefined, endAt: string | undefined | null): string => {
			const duration = calculateDuration(startAt, endAt, durationIncrement);
			const durationString = formatDurationToString(duration);

			return durationString;
		};

		return renderSessionDuration;
	}, [durationIncrement]);

	const getTypescriptUrl = React.useMemo(
		() =>
			(id: number | undefined): string => {
				if (!id) {
					return '';
				}

				return `/api/v1/pam/sessions/${id}/typescript`;
			},
		[],
	);

	const handlePreview = React.useCallback(
		(sessionID: number | undefined) => (event: React.MouseEvent) => {
			//TODO: Handle Session Preview
			event.stopPropagation();
		},
		[],
	);

	const handlePlay = React.useCallback(
		(sessionPath: string | undefined) => (event: React.MouseEvent) => {
			event.stopPropagation();
			if (!sessionPath) {
				return;
			}

			setRecordingUrl(sessionPath);
			setOpenPlayerDialog(true);
		},
		[],
	);

	const handleDownload = React.useCallback(
		(sessionPath: string | undefined) => (event: React.MouseEvent) => {
			event.stopPropagation();

			if (!sessionPath || !isAllowed([EPermission.PAM_RECORDINGS_READ])) {
				return;
			}

			try {
				const link = document.createElement('a');

				link.href = sessionPath;
				link.setAttribute('download', 'true');

				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
			} catch (error) {
				console.error(error);
			}
		},
		[isAllowed],
	);

	const handleOnClose = React.useCallback(() => {
		setOpenPlayerDialog(false);
	}, []);

	const checkHasRunningSession = React.useCallback((sessions: PamSessionDto[]): boolean => {
		if (sessions.length === 0) {
			return false;
		}

		let refreshNeeded = false;

		sessions.forEach((session) => {
			if (!session.endAt) {
				refreshNeeded = true;
			}
		});

		setHasRunningSession(refreshNeeded);

		return refreshNeeded;
	}, []);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	React.useEffect(() => {
		if (!sessionEntities) {
			return;
		}

		checkHasRunningSession(sessionEntities);
	}, [sessionEntities]);

	React.useEffect(() => {
		if (!sessionList) {
			return;
		}

		const handleDurationIncrement = () => {
			if (!hasRunningSession) {
				return;
			}
			intervalRef.current = setInterval(() => {
				setDurationIncrement((prevValue) => prevValue + 1000);
			}, 1000);
		};

		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		if (!intervalRef.current && durationIncrement === 0) {
			handleDurationIncrement();
		}
	}, [hasRunningSession]);

	React.useEffect(() => {
		if (!durationIncrement) {
			return;
		}

		if (durationIncrement > FETCH_INTERVAL) {
			reactQueryClient.invalidateQueries();
			setDurationIncrement(0);
		}
	}, [durationIncrement]);

	React.useEffect(() => {
		getPAMTarget(id);
	}, []);

	React.useEffect(() => {
		if (!sessionListIsError) {
			return;
		}

		if (sessionListError instanceof AxiosError) {
			const errorResponse =
				sessionListError.response?.data.message ?
					sessionListError.response?.data.message
				:	sessionListError.message;
			enqueueSnackbar(errorResponse, {
				variant: 'error',
				persist: false,
			});
		} else {
			enqueueSnackbar((sessionListError as any).toString(), {
				variant: 'error',
				persist: false,
			});
		}
	}, [sessionListIsError]);

	React.useEffect(() => {
		if (!userListIsError) {
			return;
		}

		if (userListError instanceof AxiosError) {
			const errorResponse =
				userListError.response?.data.message ? userListError.response?.data.message : userListError.message;
			enqueueSnackbar(errorResponse, {
				variant: 'error',
				persist: false,
			});
		} else {
			enqueueSnackbar((userListError as any).toString(), {
				variant: 'error',
				persist: false,
			});
		}
	}, [userListIsError]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const sections: SectionConfig[] = React.useMemo(() => {
		const data = PAMTargetState.data;
		const type = PAMTargetState.type;

		if (!data || !type) {
			return [];
		}

		return [
			{
				title: t('page.pam.detail.subtitle.general'),
				fields: [
					{
						label: `${t('page.pam.detail.body.target')}: `,
						value: (
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<Tooltip title={type} enterDelay={500} placement='top' arrow>
									<img
										alt='Logo'
										height={28}
										src={getSessionTargetLogo(type)}
										loading='lazy'
										style={{ borderRadius: '4px' }}
									/>
								</Tooltip>
								<Typography variant='body1'>{data.name}</Typography>
							</Box>
						),
					},
					...(data.description ?
						[
							{
								label: `${t('page.pam.detail.body.description')}: `,
								value: data.description,
							},
						]
					:	[]),
				],
			},
			{
				title: t('page.pam.detail.subtitle.network'),
				fields: [
					{
						label: `${t('page.pam.detail.body.hostname')}: `,
						value: data.hostname,
					},
					{
						label: `${t('page.pam.detail.body.port')}: `,
						value: data.port,
					},
				],
			},
			{
				title: t('page.pam.detail.subtitle.credentials'),
				fields: [
					...(type === EPAMTargetType.RDP && 'domain' in data ?
						[
							{
								label: `${t('page.pam.detail.body.domain')}: `,
								value: data.domain,
							},
						]
					:	[]),
					{
						label: `${t('page.pam.detail.body.username')}: `,
						value: data.username,
					},
				],
			},
			{
				title: t('page.pam.detail.subtitle.remoteApp'),
				shouldRender: type === EPAMTargetType.RDP,
				fields: [
					...(type === EPAMTargetType.RDP && 'remoteApplication' in data ?
						[
							{
								label: `${t('page.pam.detail.body.appName')}: `,
								value: data.remoteApplication?.name,
							},
							{
								label: `${t('page.pam.detail.body.workingDir')}: `,
								value: data.remoteApplication?.workingDirectory,
							},
							{
								label: `${t('page.pam.detail.body.commandLineArgs')}: `,
								value: data.remoteApplication?.commandLineArguments,
							},
						]
					:	[]),
				],
			},
			{
				title: t('page.pam.detail.subtitle.security'),
				shouldRender: type === EPAMTargetType.RDP,
				fields: [
					{
						label: `${t('page.pam.detail.body.securityMode')}: `,
						value: 'security' in data && data.security,
					},
					{
						label: `${t('page.pam.detail.body.ignoreCertificate')}: `,
						value:
							'ignoreCertificate' in data && data.ignoreCertificate ?
								t('page.pam.detail.body.yes')
							:	t('page.pam.detail.body.no'),
					},
				],
			},
			{
				title: t('page.pam.detail.subtitle.sftp'),
				fields: [
					{
						label: `${t('page.pam.detail.body.enableDownload')}: `,
						value:
							data.fileTransfer.downloadEnabled ?
								t('page.pam.detail.body.yes')
							:	t('page.pam.detail.body.no'),
					},
					{
						label: `${t('page.pam.detail.body.enableUpload')}: `,
						value:
							data.fileTransfer.uploadEnabled ?
								t('page.pam.detail.body.yes')
							:	t('page.pam.detail.body.no'),
					},
					data.fileTransfer.uploadEnabled && data.fileTransfer.uploadFileSizeLimit ?
						{
							label: `${t('page.pam.detail.body.uploadFileSizeLimit')}: `,
							value: `${convertBytesToMB(data.fileTransfer.uploadFileSizeLimit)} MB`,
						}
					:	null,
					data.fileTransfer.rootDirectory ?
						{
							label: `${t('page.pam.detail.body.fileBrowserRootDir')}: `,
							value: data.fileTransfer.rootDirectory,
						}
					:	null,
				],
			},
			{
				title: t('page.pam.detail.subtitle.recording'),
				fields: [
					{
						label: `${t('page.pam.detail.body.enableRecording')}: `,
						value: data.recording.enabled ? t('page.pam.detail.body.yes') : t('page.pam.detail.body.no'),
					},
					{
						label: `${t('page.pam.detail.body.retentionPeriod')}: `,
						value:
							data.recording.retentionPeriod ? getDurationInString(data.recording.retentionPeriod) : '',
					},
				],
			},
			{
				title: t('page.pam.detail.subtitle.clipboard'),
				fields: [
					{
						label: `${t('page.pam.detail.body.enableCopy')}: `,
						value: data.clipboard.enableCopy ? t('page.pam.detail.body.yes') : t('page.pam.detail.body.no'),
					},
					{
						label: `${t('page.pam.detail.body.enablePaste')}: `,
						value:
							data.clipboard.enablePaste ? t('page.pam.detail.body.yes') : t('page.pam.detail.body.no'),
					},
				],
			},
		];
	}, [t, PAMTargetState]);

	const columnsSessionList = React.useMemo<MRTColumnDef<Partial<PamSessionDto>>[]>(
		() => [
			{
				accessorFn: (user) => `${user.userName} ${user.userSurname ? user.userSurname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.pam.detail.table.header.name'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={`${renderedCellValue}'s avatar.`} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'accountName',
				header: t('page.pam.detail.table.header.account'),
			},
			{
				accessorFn: (row) => `${formatDate(row.startAt, true)}`,
				accessorKey: 'startAt',
				filterVariant: 'date-range',
				header: t('page.pam.detail.table.header.startAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${formatDate(row.endAt ?? undefined, true)}`,
				accessorKey: 'endAt',
				filterVariant: 'date-range',
				header: t('page.pam.detail.table.header.endAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'duration',
				header: t('page.pam.detail.table.header.duration'),
				enableSorting: false,
				enableColumnFilter: false,
				Cell: ({ row }) => <Box>{renderDuration(row.original.startAt, row.original.endAt)}</Box>,
			},
			{
				accessorFn: (row) => `${row.id}`,
				header: t('page.pam.detail.table.header.actions'),
				enableSorting: false,
				enableColumnFilter: false,
				Cell: ({ row }) => {
					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
							}}
						>
							<Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
								{!row.original.endAt && (
									<>
										{isAllowed(
											[EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN],
											false,
										) && (
											<Tooltip
												title={t('page.pam.detail.tooltips.preview')}
												placement='left'
												enterDelay={500}
												arrow
											>
												<IconButton onClick={handlePreview(row.original.id)}>
													<VisibilityIcon />
												</IconButton>
											</Tooltip>
										)}
									</>
								)}
								{row.original.endAt && row.original.recordingPath && (
									<>
										{isAllowed([EPermission.PAM_RECORDINGS_READ]) && (
											<Tooltip
												title={t('page.pam.detail.tooltips.playRecording')}
												placement='left'
												enterDelay={500}
												arrow
											>
												<IconButton onClick={handlePlay(row.original.recordingPath)}>
													<PlayArrowIcon color={'success'} />
												</IconButton>
											</Tooltip>
										)}
										{isAllowed([EPermission.PAM_RECORDINGS_READ]) && (
											<Tooltip
												title={t('page.pam.detail.tooltips.downloadRecording')}
												placement='bottom'
												enterDelay={500}
												arrow
											>
												<IconButton onClick={handleDownload(row.original.recordingPath)}>
													<DownloadIcon />
												</IconButton>
											</Tooltip>
										)}
										{isAllowed(
											[EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN],
											false,
										) &&
											row.original.targetType === ESessionTarget.SSH && (
												<Tooltip
													title={t('page.sessions.tooltips.downloadTypescript')}
													placement='bottom'
													enterDelay={500}
													arrow
												>
													<IconButton
														onClick={handleDownload(getTypescriptUrl(row.original.id))}
													>
														<GetAppOutlinedIcon />
													</IconButton>
												</Tooltip>
											)}
									</>
								)}
							</Box>
						</Box>
					);
				},
			},
		],
		[isAllowed],
	);

	const columnsUserList = React.useMemo<MRTColumnDef<Partial<UserDetailModel>>[]>(
		() => [
			{
				accessorFn: (user) =>
					`${user.title ? `${user.title} ` : ''}${user.name} ${user.surname ? user.surname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.pam.detail.table.header.name'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={`${renderedCellValue}'s avatar.`} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.email}`,
				accessorKey: 'email',
				header: t('page.pam.detail.table.header.email'),
			},
		],
		[isAllowed],
	);

	return (
		<Box>
			{PAMTargetState.loading || !PAMTargetState.loaded ?
				<Preloader />
			:	<Box>
					<Stack spacing={4}>
						<Stack>
							<Heading
								label={t('page.pam.detail.sections.general.title')}
								fontSize={20}
								fontWeight={800}
							/>
							<Typography variant='body1' px={1}>
								{t('page.pam.detail.sections.general.description')}
							</Typography>
						</Stack>
						<Stack spacing={2}>
							<DetailSection sections={sections} responsive={true} />

							<LabeledBox label={t('page.pam.detail.body.targetGroups')} sx={{ padding: 2 }}>
								<ChipArray
									chipList={isAllowed([EPermission.PAM_GROUPS_READ]) ? PAMTargetGroups : undefined}
									onChipClick={handleOnTargetGroupChipClick}
									wrap={true}
								/>
							</LabeledBox>

							<Stack>
								<Heading
									label={t('page.pam.detail.subtitle.sessionList')}
									fontSize={20}
									fontWeight={800}
								/>
								<Typography variant='body1' sx={{ paddingBottom: 2 }}>
									{t('page.pam.detail.table.description.sessionList')}
								</Typography>
								<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
									<MaterialReactTable
										columns={columnsSessionList}
										data={sessionEntities}
										enableStickyHeader={false}
										state={{
											isLoading: sessionListIsLoading,
											showAlertBanner: sessionListError !== null,
											pagination: paginationSession,
											showProgressBars: sessionListIsRefetching,
											columnFilters: columnFiltersSession,
											globalFilter: globalFilterSession,
											sorting: sortingSession,
											columnVisibility: columnVisibilitySession,
										}}
										muiToolbarAlertBannerProps={{
											color: 'error',
											children: <>{sessionListError}</>,
										}}
										initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
										rowCount={sessionTotal}
										manualPagination
										manualFiltering
										manualSorting
										onSortingChange={setSortingSession}
										onGlobalFilterChange={setGlobalFilterSession}
										onColumnFiltersChange={setColumnFiltersSession}
										onPaginationChange={setPaginationSession}
										onColumnVisibilityChange={setColumnVisibilitySession}
										renderToolbarInternalActions={({ table }) => (
											<Box sx={{ display: 'flex', gap: '1rem' }}>
												<MRTToggleGlobalFilterButton table={table} />
												<MRTToggleFiltersButton table={table} />
												<MRTShowHideColumnsButton table={table} />
												{/* <Tooltip title={t('page.pam.detail.tooltips.export')} enterDelay={500}>
													<IconButton>
														<SaveAltIcon />
													</IconButton>
												</Tooltip> */}
												<MRTToggleDensePaddingButton table={table} />
												<MRTFullScreenToggleButton table={table} />
											</Box>
										)}
										displayColumnDefOptions={{
											'mrt-row-actions': {
												muiTableHeadCellProps: {
													align: 'center',
												},
												size: 120,
												enableHiding: true,
											},
											'mrt-row-select': {
												enableHiding: true,
												visibleInShowHideMenu: false,
											},
										}}
										muiTablePaperProps={({ table }) => ({
											style: {
												zIndex: table.getState().isFullScreen ? 1100 : undefined,
												boxShadow: 'none',
												outline: '1px solid #e0e0e0',
											},
										})}
										editDisplayMode='modal'
										positionActionsColumn='last'
										muiTableBodyRowProps={({ row }) => ({
											onClick:
												(
													isAllowed(
														[
															EPermission.PAM_SESSIONS_READ_ALL,
															EPermission.ACTIONS_READ_OWN,
														],
														false,
													)
												) ?
													handleRedirect(`/pam/sessions/${row.original.id}`)
												:	undefined,
											sx: { cursor: 'pointer' },
										})}
										muiSelectCheckboxProps={() => ({
											sx: {
												width: '50px',
												height: '50px',
											},
										})}
										muiSelectAllCheckboxProps={() => ({
											sx: {
												width: '50px',
												height: '50px',
											},
										})}
										muiTableHeadCellProps={() => ({
											sx: {
												verticalAlign: 'baseline',
											},
										})}
										localization={MRTLocalization}
									/>
								</LocalizationProvider>
							</Stack>

							<Stack>
								<Heading
									label={t('page.pam.detail.subtitle.userList')}
									fontSize={20}
									fontWeight={800}
								/>
								<Typography variant='body1' sx={{ paddingBottom: 2 }}>
									{t('page.pam.detail.table.description.userList')}
								</Typography>
								<MaterialReactTable
									columns={columnsUserList}
									data={userEntities}
									enableStickyHeader={false}
									state={{
										isLoading: userListIsLoading,
										showAlertBanner: userListError !== null,
										pagination: paginationUser,
										showProgressBars: userListIsRefetching,
										columnFilters: columnFiltersUser,
										globalFilter: globalFilterUser,
										sorting: sortingUser,
										columnVisibility: columnVisibilityUser,
									}}
									muiToolbarAlertBannerProps={{
										color: 'error',
										children: <>{userListError}</>,
									}}
									initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
									rowCount={userTotal}
									manualPagination
									manualFiltering
									manualSorting
									onSortingChange={setSortingUser}
									onGlobalFilterChange={setGlobalFilterUser}
									onColumnFiltersChange={setColumnFiltersUser}
									onPaginationChange={setPaginationUser}
									onColumnVisibilityChange={setColumnVisibilityUser}
									renderToolbarInternalActions={({ table }) => (
										<Box sx={{ display: 'flex', gap: '1rem' }}>
											<MRTToggleGlobalFilterButton table={table} />
											<MRTToggleFiltersButton table={table} />
											<MRTShowHideColumnsButton table={table} />
											{/* <Tooltip title={t('page.pam.detail.tooltips.export')} enterDelay={500}>
												<IconButton>
													<SaveAltIcon />
												</IconButton>
											</Tooltip> */}
											<MRTToggleDensePaddingButton table={table} />
											<MRTFullScreenToggleButton table={table} />
										</Box>
									)}
									displayColumnDefOptions={{
										'mrt-row-actions': {
											muiTableHeadCellProps: {
												align: 'center',
											},
											size: 120,
											enableHiding: true,
										},
										'mrt-row-select': {
											enableHiding: true,
											visibleInShowHideMenu: false,
										},
									}}
									muiTablePaperProps={({ table }) => ({
										style: {
											zIndex: table.getState().isFullScreen ? 1100 : undefined,
											boxShadow: 'none',
											outline: '1px solid #e0e0e0',
										},
									})}
									muiSelectCheckboxProps={() => ({
										sx: {
											width: '50px',
											height: '50px',
										},
									})}
									muiSelectAllCheckboxProps={() => ({
										sx: {
											width: '50px',
											height: '50px',
										},
									})}
									muiTableHeadCellProps={() => ({
										sx: {
											verticalAlign: 'baseline',
										},
									})}
									editDisplayMode='modal'
									positionActionsColumn='last'
									localization={MRTLocalization}
								/>
							</Stack>

							{isAllowed([EPermission.PAM_RECORDINGS_READ]) && recordingUrl.length > 0 && (
								<SessionPlayerDialog
									open={openPlayerDialog}
									src={recordingUrl}
									onClose={handleOnClose}
								/>
							)}
						</Stack>
					</Stack>

					{isAllowed([EPermission.PAM_TARGETS_UPDATE]) && PAMTargetState.type && (
						<Link to={`/pam/${PAMTargetState.type.toLocaleLowerCase()}/edit/${id}`}>
							<FloatingButtonEdit
								ariaLabel={t('page.pam.detail.ariaLabel.editTarget')}
								tooltipTitle={t('page.pam.detail.tooltips.editTarget')}
							/>
						</Link>
					)}
				</Box>
			}
		</Box>
	);
};
