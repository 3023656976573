import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_ColumnFiltersState as MRTColumnFiltersState,
	MRT_SortingState as MRTSortingState,
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
	MRT_Row,
} from 'material-react-table';
import { Tooltip, Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import {
	SaveAlt as SaveAltIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Dataset as DatasetIcon,
	Rule as RuleIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import { Link } from '../../components/Link/Link';
import { useNavigate } from '../../hooks/useNavigate';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ruleSetListSchema } from './schema';
import { ChipArray } from '../../components/ChipArray/ChipArray';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { GroupDto, PagedResultGetRuleSetCollectionDto, GetRuleSetCollectionDto } from '../../api/Api';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Heading } from '../../components/Heading/Heading';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { useMRTLocalization } from '../../hooks/useTableLocalization';

export const RuleSetList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const authContext = useAuthContext();
	const { MRTLocalization } = useMRTLocalization();

	const [open, setOpen] = React.useState(false);
	const [rowSelection, setRowSelection] = React.useState<MRTRowSelectionState>({});
	const [ruleSetIDToDelete, setRuleSetIDToDelete] = React.useState<number | null>(null);
	const [multiRuleSetIDToDelete, setMultiRuleSetIDToDelete] = React.useState<{ ids: number[] }>({ ids: [] });
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');

	const {
		columnFilters: columnFilterSystemRuleSets,
		setColumnFilters: setColumnFiltersSystemRuleSets,
		sorting: sortingSystemRuleSets,
		setSorting: setSortingSystemRuleSets,
		columnVisibility: columnVisibilitySystemRuleSets,
		setColumnVisibility: setColumnVisibilitySystemRuleSets,
		globalFilter: globalFilterSystemRuleSets,
		setGlobalFilter: setGlobalFilterSystemRuleSets,
		pagination: paginationSystemRuleSets,
		setPagination: setPaginationSystemRuleSets,
		swaggerQuery: swaggerQuerySystemRuleSets,
	} = useTableQuery(['name', 'description']);

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'description']);

	const {
		data: systemData,
		isRefetching: systemIsRefetching,
		isLoading: systemIsLoading,
		error: systemError,
	} = useQuery<PagedResultGetRuleSetCollectionDto>({
		queryKey: [
			EQueryKey.RULE_SET_SYSTEM_LIST_QUERY,
			authContext.userTenants.activeTenantID,
			swaggerQuerySystemRuleSets,
		],
		queryFn: async () => {
			try {
				if (authContext.userTenants.activeTenantID !== null) {
					const query = {
						filterList: swaggerQuerySystemRuleSets.filter,
						sortList: swaggerQuerySystemRuleSets.sort,
						columnsList: swaggerQuerySystemRuleSets.columns,
						offset: swaggerQuerySystemRuleSets.offset,
						limit: swaggerQuerySystemRuleSets.limit,
					};

					const ruleSetSystemCollectionResponse = await api.ruleSets.getSystemRuleSetCollection(query);

					ruleSetSystemCollectionResponse.data.entities.forEach((ruleSet) => {
						ruleSetListSchema.parse(ruleSet);
					});

					return ruleSetSystemCollectionResponse.data;
				}

				return { entities: [], total: 0 };
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities: systemEntities = [], total: systemTotal = 0 } = systemData ? systemData : {};

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultGetRuleSetCollectionDto>({
		queryKey: [EQueryKey.RULE_SET_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filters: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const ruleSetCollectionResponse = await api.ruleSets.getRuleSetCollection(query);

				ruleSetCollectionResponse.data.entities.forEach((ruleSet) => {
					ruleSetListSchema.parse(ruleSet);
				});

				return ruleSetCollectionResponse.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.ruleSet.list.confirmation.delete.title'));
				setConfirmationText(t('page.ruleSet.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.ruleSet.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.ruleSet.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.DELETE_UNAVAILABLE:
				setConfirmationTitle(t('page.ruleSet.list.confirmation.delete.titleAssigned'));
				setConfirmationText(t('page.ruleSet.list.confirmation.delete.textAssigned'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const checkRuleSetsAssigned = React.useCallback(
		(ruleSetIDs: number[]) => {
			const ruleSets = entities
				.filter((ruleSet) => ruleSetIDs.includes(ruleSet.id) && ruleSet.securityPoliciesAllCount > 0)
				.map((ruleSet) => ruleSet.name);

			if (ruleSets.length > 0) {
				setOpen(true);
				handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_UNAVAILABLE);

				return true;
			}

			return false;
		},
		[entities],
	);

	const handleOnOpenRuleSetDeleteDialog = React.useCallback(
		(ruleSetID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();

			if (checkRuleSetsAssigned([ruleSetID])) {
				return;
			}

			setOpen(true);
			setRuleSetIDToDelete(ruleSetID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[isAllowed, entities],
	);

	const handleOnOpenMultipleRuleSetsDelete = React.useCallback(
		(table: MRTTableInstance<Partial<GetRuleSetCollectionDto>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.ruleSet.list.errorMessages.noRuleSetSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			if (checkRuleSetsAssigned(selectedRowsOnActivePageIds)) {
				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiRuleSetIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed, entities],
	);

	const handleOnConfirmRuleSetDelete = React.useCallback(async () => {
		if (ruleSetIDToDelete) {
			try {
				await api.ruleSets.deleteRuleSet(ruleSetIDToDelete);
				enqueueSnackbar(t('page.ruleSet.list.actionMessages.ruleSetSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				reactQueryClient.invalidateQueries();
				setOpen(false);
				setRuleSetIDToDelete(null);
				updateRowSelection([ruleSetIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiRuleSetIDToDelete.ids.length > 0) {
			try {
				await api.ruleSets.deleteRuleSets(multiRuleSetIDToDelete);
				enqueueSnackbar(t('page.ruleSet.list.actionMessages.ruleSetsSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});

				reactQueryClient.invalidateQueries();
				setOpen(false);
				setMultiRuleSetIDToDelete({ ids: [] });
				updateRowSelection(multiRuleSetIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else {
			setOpen(false);
		}
	}, [ruleSetIDToDelete, multiRuleSetIDToDelete, isAllowed]);

	const handleClose = React.useCallback((): void => setOpen(false), []);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	const handleOnChipClick = React.useCallback(
		(securityPolicy: GroupDto) => {
			if (!isAllowed([EPermission.SECURITY_POLICIES_READ])) {
				return;
			}
			navigate(`/security/securityPolicy/${securityPolicy.id}`);
		},
		[isAllowed],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const systemColumns = React.useMemo<MRTColumnDef<Partial<GetRuleSetCollectionDto>>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.ruleSet.list.table.header.name'),
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
							minHeight: '40px',
						}}
					>
						<DatasetIcon />
						{row.original.name}
					</Box>
				),
			},
			{
				accessorKey: 'description',
				header: t('page.ruleSet.list.table.header.description'),
			},
			{
				accessorFn: (row) => `${row.securityPolicies}`,
				header: t('page.ruleSet.list.table.header.securityPolicies'),
				accessorKey: 'securityPolicies',
				enableSorting: false,
				enableColumnFilter: false,
				enableHiding: false,
				Cell: ({ row }) => (
					<ChipArray
						wrap
						chipList={
							isAllowed([EPermission.SECURITY_POLICIES_READ]) ? row.original.securityPolicies : undefined
						}
						limitTags={3}
						onChipClick={handleOnChipClick}
					/>
				),
			},
		],
		[isAllowed],
	);

	const columns = React.useMemo<MRTColumnDef<Partial<GetRuleSetCollectionDto>>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				grow: 1,
				header: t('page.ruleSet.list.table.header.name'),
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
							minHeight: '40px',
						}}
					>
						<DatasetIcon />
						{row.original.name}
					</Box>
				),
			},
			{
				accessorKey: 'description',
				grow: 1,
				header: t('page.ruleSet.list.table.header.description'),
			},
			{
				accessorFn: (row) => `${row.securityPolicies}`,
				header: t('page.ruleSet.list.table.header.securityPolicies'),
				accessorKey: 'securityPolicies',
				enableSorting: false,
				enableColumnFilter: false,
				grow: 3,
				Cell: ({ row }) => (
					<ChipArray
						wrap
						chipList={
							isAllowed([EPermission.SECURITY_POLICIES_READ]) ? row.original.securityPolicies : undefined
						}
						limitTags={3}
						onChipClick={handleOnChipClick}
					/>
				),
			},
		],
		[isAllowed, entities],
	);

	// TODO: Just for temporary testing
	const shouldDeleteRuleSet = (row: MRT_Row<Partial<GetRuleSetCollectionDto>>) => {
		const protectedRuleSetIDs = [1, 2, 3];

		return !protectedRuleSetIDs.includes(row.original.id as number);
	};

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.ruleSet.list.title')}
						description={t('page.ruleSet.list.description.general')}
						icon={RuleIcon}
					/>
					<Stack
						sx={{
							display: authContext.userTenants.activeTenantID === null ? 'none' : 'flex',
							paddingBottom: authContext.userTenants.activeTenantID === null ? 0 : 3,
							width: authContext.userTenants.activeTenantID === null ? '0px' : 'auto',
							height: authContext.userTenants.activeTenantID === null ? '0px' : 'auto',
						}}
					>
						<Heading label={t('page.ruleSet.list.systemTitle')} />
						<Typography variant='body2' sx={{ paddingBottom: 2 }}>
							{t('page.ruleSet.list.description.systemRuleSets')}
						</Typography>
						<MaterialReactTable
							columns={systemColumns}
							data={systemEntities}
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination: paginationSystemRuleSets,
								showProgressBars: systemIsRefetching,
								columnFilters: columnFilterSystemRuleSets,
								globalFilter: globalFilterSystemRuleSets,
								sorting: sortingSystemRuleSets,
								columnVisibility: columnVisibilitySystemRuleSets,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error}</>,
							}}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onPaginationChange={setPagination}
							onColumnVisibilityChange={setColumnVisibility}
							getRowId={(originalRow) => originalRow.id?.toString() || ''}
							renderToolbarInternalActions={({ table }) => (
								<Box sx={{ display: 'flex', gap: '1rem' }}>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip title={t('page.ruleSet.list.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 120,
									enableHiding: true,
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1100 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							editDisplayMode='modal'
							localization={MRTLocalization}
							positionActionsColumn='last'
							// muiTableBodyRowProps={({ row }) => ({
							// 	onClick:
							// 		isAllowed([EPermission.SECURITY_POLICIES_READ]) ?
							// 			handleRedirect(`/security/ruleSets/${row.original.id}`)
							// 		:	undefined,
							// 	sx: { cursor: 'pointer' },
							// })}
						/>
					</Stack>
					<Stack>
						<Heading label={t('page.ruleSet.list.title')} />
						<Typography variant='body2' sx={{ paddingBottom: 2 }}>
							{t('page.ruleSet.list.description.ruleSets')}
						</Typography>
						<MaterialReactTable
							columns={columns}
							data={entities}
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination,
								rowSelection,
								showProgressBars: isRefetching,
								columnFilters,
								globalFilter,
								sorting,
								columnVisibility,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error}</>,
							}}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onPaginationChange={setPagination}
							onColumnVisibilityChange={setColumnVisibility}
							enableRowActions
							enableRowSelection={isAllowed([EPermission.SECURITY_POLICIES_DELETE])}
							getRowId={(originalRow) => originalRow.id?.toString() || ''}
							onRowSelectionChange={setRowSelection}
							renderRowActions={({ row }) => (
								<Box sx={{ display: 'flex', justifyContent: 'left', gap: '1rem' }}>
									{isAllowed([EPermission.SECURITY_POLICIES_UPDATE]) && (
										<Tooltip
											title={t('page.ruleSet.list.tooltips.editRuleSet')}
											placement='left'
											enterDelay={500}
											arrow
										>
											<IconButton
												onClick={handleRedirect(`/security/ruleSets/edit/${row.original.id}`)}
											>
												<EditIcon />
											</IconButton>
										</Tooltip>
									)}
									{isAllowed([EPermission.SECURITY_POLICIES_DELETE]) && shouldDeleteRuleSet(row) && (
										<Tooltip
											title={t('page.ruleSet.list.tooltips.removeRuleSet')}
											placement='right'
											enterDelay={500}
											arrow
										>
											<IconButton
												color='error'
												onClick={handleOnOpenRuleSetDeleteDialog(row.original.id as number)}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									)}
								</Box>
							)}
							renderToolbarInternalActions={({ table }) => (
								<Box sx={{ display: 'flex', gap: '1rem' }}>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip title={t('page.ruleSet.list.tooltips.export')} enterDelay={500}>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									{isAllowed([EPermission.SECURITY_POLICIES_DELETE]) && (
										<Tooltip
											title={t('page.ruleSet.list.tooltips.removeSelected')}
											enterDelay={500}
										>
											<span>
												<IconButton
													color='error'
													disabled={table.getSelectedRowModel().rows.length === 0}
													onClick={handleOnOpenMultipleRuleSetsDelete(table)}
												>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									header: t('page.ruleSet.list.table.header.actions'),
									size: 120,
									enableHiding: true,
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1100 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									paddingLeft: 2,
									paddingBottom: 2,
								},
							})}
							muiTableBodyCellProps={() => ({
								sx: {
									paddingLeft: 2,
								},
							})}
							editDisplayMode='modal'
							positionActionsColumn='last'
							layoutMode='grid-no-grow'
							localization={MRTLocalization}
							// muiTableBodyRowProps={({ row }) => ({
							// 	onClick:
							// 		isAllowed([EPermission.SECURITY_POLICIES_READ]) ?
							// 			handleRedirect(`/security/ruleSets/${row.original.id}`)
							// 		:	undefined,
							// 	sx: { cursor: 'pointer' },
							// })}
						/>
					</Stack>
				</Stack>
			</Paper>

			{isAllowed([EPermission.SECURITY_POLICIES_CREATE]) && (
				<Link to='/security/ruleSets/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.ruleSet.list.ariaLabel.addRuleSet')}
						tooltipTitle={t('page.ruleSet.list.tooltips.addRuleSet')}
					/>
				</Link>
			)}

			{isAllowed([EPermission.SECURITY_POLICIES_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmRuleSetDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.ruleSet.list.confirmation.delete.cancel')}
					confirmText={t('page.ruleSet.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
