import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { NetworkAddressesImportModalProps } from './types';
import { FileUploader } from '../FormFields/FileUploader/FileUploader';

export const NetworkAddressesImportModal: React.FC<NetworkAddressesImportModalProps> = ({
	open,
	control,
	name,
	value,
	error,
	disabled = false,
	trigger,
	onClose,
	onImport,
}) => {
	const [fileContent, setFileContent] = useState<string>('');

	const handleFileChange = React.useCallback(
		async (file: File): Promise<void> => {
			if (!file) {
				return;
			}

			const isValid = await trigger(name);
			if (!isValid) {
				return;
			}

			const selectedFile = file;

			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target) {
					const content = e.target.result as string;
					setFileContent(content);
				}
			};
			reader.onerror = (error) => {
				enqueueSnackbar(error.toString(), { variant: 'error' });
			};
			reader.readAsText(selectedFile);
		},
		[setFileContent],
	);

	const handleImport = React.useCallback(() => {
		const commaSeparatedRanges = fileContent.split(',').map((range) => range.trim());
		const networkAddresses = commaSeparatedRanges.flatMap((range) =>
			range.split(/\r?\n/).map((line) => line.trim()),
		);
		const filteredNetworkAddresses = networkAddresses.filter((range) => range.length > 0);
		onImport(filteredNetworkAddresses);
		onClose();
	}, [fileContent, onImport, onClose]);

	React.useEffect(() => {
		if (!value || !!error) {
			return;
		}
		handleFileChange(value);
	}, [value, error]);

	return (
		<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={onClose}>
			<DialogTitle>{t('page.tenants.networkAddresses.modal.title')}</DialogTitle>
			<DialogContent>
				<FileUploader
					control={control}
					name={name}
					error={error}
					disabled={disabled}
					helperText={t('page.tenants.edit.form.file.helperText')}
					multiple={false}
				/>
				<pre>{fileContent}</pre>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color='primary'>
					{t('page.tenants.list.confirmation.delete.cancel')}
				</Button>
				<Button onClick={handleImport} color='primary'>
					{t('page.tenants.list.confirmation.delete.confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
