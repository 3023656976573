import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { hocACL } from '../../hocs/hocACL/hocACL';
import { routingConfig } from './config';
import { GroupRoute, PageRoute } from './types';

export const Router: React.FC = (): JSX.Element => {
	return (
		<HelmetProvider>
			<Routes>
				{routingConfig.map((groupRoute: GroupRoute): JSX.Element => {
					const {
						path: groupPath,
						index: { element: IndexElement },
						routes,
						element: GroupElement,
					} = groupRoute;

					return (
						<Route key={`group-route-${groupPath}`} path={groupPath} element={<GroupElement />}>
							<Route index element={<IndexElement />} />
							{routes &&
								routes.map((pageRoute: PageRoute): JSX.Element => {
									const { path: pagePath, element: PageElement, permissions } = pageRoute;
									const SecuredPageElement =
										permissions ? hocACL(permissions, false)(PageElement) : PageElement;

									return (
										<Route
											key={`page-route-${groupPath}-${pagePath}`}
											path={pagePath}
											element={<SecuredPageElement />}
										/>
									);
								})}
						</Route>
					);
				})}
			</Routes>
		</HelmetProvider>
	);
};
