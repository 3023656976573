import { z } from 'zod';

export const sessionListSchema = z.object({
	id: z.number(),
	recordingPath: z.string().optional(),
	targetType: z.string(),
	targetName: z.string(),
	userID: z.number(),
	userName: z.string(),
	userSurname: z.string().optional(),
	accountName: z.string().optional(),
	startAt: z.string(),
	endAt: z.string().optional(),
});

export const sessionDetailSchema = z.object({
	id: z.number(),
	targetType: z.string(),
	targetName: z.string(),
	userID: z.number(),
	userName: z.string(),
	userSurname: z.string().optional(),
	accountName: z.string(),
	startAt: z.string(),
	endAt: z.string().optional(),
});

export const fileTransferSchema = z.object({
	id: z.number(),
	fileName: z.string(),
	fileExtension: z.string(),
	type: z.string(),
	createdAt: z.string(),
	fileSize: z.number(),
});
