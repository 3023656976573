import * as React from 'react';

import { Controller } from 'react-hook-form';
import { Box, FormControlLabel, FormHelperText, Switch } from '@mui/material';

import { SwitchProps } from './types';

export const SwitchField: React.FC<SwitchProps> = (props): JSX.Element => {
	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({ field: { onChange, value } }) => {
				return (
					<>
						<FormControlLabel
							control={
								<Switch
									checked={value}
									onChange={(e) => onChange(e.target.checked)}
									disabled={props.disabled}
								/>
							}
							label={props.label}
						/>
						<Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
							{props.error && typeof props.error.message === 'string' ?
								<FormHelperText error id={`switch-${props.name}`}>
									{props.error.message}
								</FormHelperText>
							:	<FormHelperText>{props.helperText}</FormHelperText>}
						</Box>
					</>
				);
			}}
		/>
	);
};
