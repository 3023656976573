import * as React from 'react';
import { Grid, InputLabel, MenuItem, Typography, Box, FormControl, Select } from '@mui/material';
import { StatisticsCard } from '../../components/StatisticsCard/StatisticsCard';
import { datasetsActions, datasetsIncidents, datasetsSessions } from './data/statisticsData';
import { ChartLine } from '../../components/Chart/ChartLine';
import { Link } from '../../components/Link/Link';

export const StatisticsPage: React.FC = () => {
	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					paddingBottom: 1,
				}}
			>
				<Typography variant='h5'>Overall statistics</Typography>

				<FormControl>
					<InputLabel id='date-range-select-label'>Date range</InputLabel>
					<Select
						labelId='date-range-select-label'
						id='date-range-select'
						value='10'
						label='Date range'
						//onChange={handleChange}
					>
						<MenuItem value={10}>This week</MenuItem>
						<MenuItem value={20}>Last week</MenuItem>
						<MenuItem value={30}>This month</MenuItem>
						<MenuItem value={40}>Last month</MenuItem>
						<MenuItem value={50}>This year</MenuItem>
						<MenuItem value={60}>Last year</MenuItem>
						<MenuItem value={70} disabled>
							Custom
						</MenuItem>
					</Select>
				</FormControl>
			</Box>

			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StatisticsCard
							style={{
								height: '100%',
							}}
							title='Registered users'
							count={106}
							prevCount={91}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Link to='/overview/sessions' style={{ textDecoration: 'none' }}>
							<StatisticsCard
								style={{
									height: '100%',
								}}
								title='Sessions'
								count={1243}
								prevCount={1567}
							/>
						</Link>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Link to='/overview/incidents' style={{ textDecoration: 'none' }}>
							<StatisticsCard
								style={{
									height: '100%',
								}}
								title='Incidents'
								count={5}
								prevCount={10}
								negativeIsGood
							/>
						</Link>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Link to='/overview/actions' style={{ textDecoration: 'none' }}>
							<StatisticsCard
								style={{
									height: '100%',
								}}
								title='Actions'
								count={60}
								prevCount={60}
							/>
						</Link>
					</Grid>
				</Grid>
			</Box>

			<Box
				sx={{
					paddingTop: 2,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Sessions' dataSet={datasetsSessions} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Incidents' dataSet={datasetsIncidents} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ChartLine title='Actions' dataSet={datasetsActions} />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};
