import { z } from 'zod';

export const tenantListSchema = z.object({
	id: z.number(),
	name: z.string(),
	slug: z.string().optional(),
	description: z.string().optional(),
	createdAt: z.string(),
	updatedAt: z.string(),
	status: z.string(),
	errorLog: z.string().optional(),
});
