import { useQuery } from '@tanstack/react-query';

import { useSwaggerApi } from '../../useSwaggerApi';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';

export const useFetchOAuthClient = (clientID: string) => {
	const api = useSwaggerApi();

	return useQuery({
		queryKey: [EQueryKey.OAUTH_CLIENT_DETAIL_QUERY, clientID],
		queryFn: async () => {
			try {
				const { data } = await api.oauthClient.getOneByClientId(clientID);

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
		enabled: !!clientID,
	});
};
