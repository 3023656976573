import * as React from 'react';

import { NetworkContext } from '../contexts/NetworkContext/NetworkContext';
import { NetworkContextModel } from '../contexts/NetworkContext/types';

export const useNetworkContext = (): NetworkContextModel => {
	const networkContext = React.useContext(NetworkContext);

	return networkContext;
};
