import * as React from 'react';
import { Container, Typography, Button, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Link } from '../../components/Link/Link';
import { SEO } from '../../components/SEO/SEO';

export const UnauthorizedPage: React.FC = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Container
			sx={{
				height: '75vh',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				alignContent: 'center',
				alignSelf: 'center',
			}}
		>
			<SEO title={t('page.unauthorized.title')} description={t('page.unauthorized.description')} />
			<Stack direction='column' spacing={4}>
				<Typography
					variant='h3'
					sx={{
						textAlign: 'center',
					}}
				>
					{t('page.unauthorized.text')}
				</Typography>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Button
						variant='contained'
						color='primary'
						sx={{
							paddingLeft: 2,
							paddingRight: 2,
						}}
					>
						<Link
							to='/'
							style={{
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
							{t('page.notFound.button')}
						</Link>
					</Button>
				</Box>
			</Stack>
		</Container>
	);
};
