import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { GetPAMTargetDetailsResponseDto, GetPAMTargetGroupResponseDto } from '../../../api/Api';
import { useSwaggerApi } from '../../useSwaggerApi';
import { SwaggerQuery } from '../../../utils/Query';
import { targetListSchema } from '../../../pages/pam/groups/schema';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';

export const useFetchPamTargetDetailsInGroup = ({
	pamTargetGroupID,
	query,
	config,
}: {
	pamTargetGroupID: GetPAMTargetGroupResponseDto['id'] | undefined;
	query?: SwaggerQuery;
	config?: Omit<
		UseQueryOptions<GetPAMTargetDetailsResponseDto, Error, GetPAMTargetDetailsResponseDto, QueryKey>,
		'queryKey' | 'queryFn'
	>;
}) => {
	const api = useSwaggerApi();

	return useQuery({
		queryKey: [EQueryKey.PAM_GROUP_TARGET_LIST_QUERY, 'detailsInGroup', pamTargetGroupID, query],
		queryFn: async () => {
			try {
				const { data } = await api.pamGroups.getPamTargetDetailsInGroup(
					pamTargetGroupID as GetPAMTargetGroupResponseDto['id'],
					query,
				);

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		enabled: !!pamTargetGroupID,
		select: (data) => {
			data.entities.forEach((target) => {
				targetListSchema.parse(target);
			});

			return data;
		},
		...config,
	});
};
