import { keepPreviousData, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { PagedResultPartialDetailedInvitationDto, RequestParams } from '../../../api/Api';
import { useSwaggerApi } from '../../useSwaggerApi';
import { SwaggerQuery } from '../../../utils/Query';
import { invitationListSchema } from '../../../pages/invitations/schema';
import { invitationListSchema as tenantInvitationListSchema } from '../../../pages/tenants/schema';

const DETAILED_INVITATIONS_QK: QueryKey = ['users', 'detailedInvitations'];

export function useFetchDetailedInvitations(
	{
		basePath,
		identityStoreId,
		query,
	}: {
		query: SwaggerQuery;
		basePath?: string;
		identityStoreId?: number;
	},
	config?: Omit<
		UseQueryOptions<
			PagedResultPartialDetailedInvitationDto,
			unknown,
			PagedResultPartialDetailedInvitationDto,
			QueryKey
		>,
		'queryKey' | 'queryFn'
	>,
) {
	const api = useSwaggerApi();

	const params: RequestParams | undefined =
		basePath ?
			{
				baseURL: basePath,
			}
		:	undefined;

	return useQuery({
		queryKey: [...DETAILED_INVITATIONS_QK, basePath, query, identityStoreId] as QueryKey,
		queryFn: async () => {
			try {
				if (identityStoreId) {
					const { data } = await api.identityStores.getDetailedInvitations(identityStoreId, query, params);

					return data;
				}

				const { data } = await api.users.getDetailedInvitations(query, params);

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
		select: (data) => {
			data.entities.forEach((invitation) => {
				if (basePath) {
					tenantInvitationListSchema.parse(invitation);
				} else {
					invitationListSchema.parse(invitation);
				}
			});

			return data;
		},
		...config,
	});
}
