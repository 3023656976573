import * as React from 'react';

import { SwaggerApiContext } from '../contexts/SwaggerApiContext/SwaggerApiContext';
import { Api } from '../api/Api';

export const useSwaggerApi = (): Api<unknown> => {
	const swaggerApiClient = React.useContext(SwaggerApiContext).swaggerApi as Api<unknown>;

	return swaggerApiClient;
};
