import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ServerPage: React.FC = () => {
	return (
		<Box>
			<Typography>ServerPage</Typography>
		</Box>
	);
};
