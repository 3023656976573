import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

import { PagedResultUserGroupDto, UserGroupDto } from '../../../api/Api';
import { useSwaggerApi } from '../../useSwaggerApi';
import { Option } from '../../../types/util';
import { userGroupListSchema } from '../../../pages/userGroups/schema';
import { userGroupListSchema as tenantUserGroupListSchema } from '../../../pages/tenants/schema';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';

const LIMIT = 10;

export function useFetchUserGroupsOptionsPaginated(
	tenantId?: number,
	config?: Omit<
		UseInfiniteQueryOptions<
			PagedResultUserGroupDto,
			unknown,
			Array<Option<UserGroupDto['id']>>,
			PagedResultUserGroupDto,
			QueryKey
		>,
		'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam'
	>,
) {
	const api = useSwaggerApi();

	return useInfiniteQuery({
		queryKey: tenantId ? [EQueryKey.USER_GROUP_LIST_QUERY, tenantId] : [EQueryKey.USER_GROUP_LIST_QUERY],
		queryFn: async ({ pageParam = 0 }) => {
			const query = { limit: LIMIT, offset: pageParam as number };

			if (tenantId) {
				const { data } = await api.tenants.getTenantUserGroups(tenantId, query);

				return data;
			}

			const { data } = await api.userGroups.getUserGroups(query);

			return data;
		},
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages) => {
			const entities = allPages.flatMap((p) => p.entities);
			if (entities.length <= lastPage.total - 1) {
				return allPages.length * LIMIT;
			}

			return;
		},
		select: (data) => {
			const entitiesFlat = data.pages.flatMap((p) => p.entities);
			entitiesFlat.forEach((group) => {
				if (tenantId) {
					tenantUserGroupListSchema.parse(group);
				} else {
					userGroupListSchema.parse(group);
				}
			});

			return entitiesFlat.map((group) => ({ label: group.name, id: group.id, desc: group.description }));
		},
		...config,
	});
}
