import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import {
	AppBar,
	Avatar,
	Badge,
	Box,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../../hooks/useNavigate';
import { ConfirmationDialog } from '../Dialog/ConfirmationDialog/ConfirmationDialog';
import { AuthContext } from '../../contexts/AuthContext/AuthContext';
import { GetUserResponseDto } from '../../api/Api';
import { useUsername } from '../../hooks/useUsername';
import { useDeviceDetect } from '../../hooks/useDeviceDetect';
import { TenantSwitcher } from '../TenantSwitcher/TenantSwitcher';
import { CustomBreadcrumbs } from '../Breadcrumbs/CustomBreadcrumbs';

const settings = ['Logout'];

export const Topbar: React.FunctionComponent = () => {
	const authContext = React.useContext(AuthContext);
	const username = useUsername();
	const { toggleSidebar, collapseSidebar, collapsed, broken } = useProSidebar();
	const navigate = useNavigate();
	const device = useDeviceDetect();
	const { t } = useTranslation();
	const { id } = useParams();
	const [open, setOpen] = React.useState(false);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

	const handleOnRedirectHome = React.useCallback(() => {
		navigate('/me');
	}, []);

	const handleOnCloseConfirmationDialog = React.useCallback(() => {
		setOpen(false);
	}, []);

	const handleOnConfirmLogout = React.useCallback(() => {
		authContext.handleOnLogout();
	}, [authContext]);

	const handleOpenUserMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	}, []);

	const handleCloseUserMenu = React.useCallback((): void => {
		setAnchorElUser(null);
	}, []);

	const handleOnOpenConfirmationDialog = React.useCallback(() => {
		setOpen(true);
		handleCloseUserMenu();
	}, []);

	const menuIconClicked = React.useCallback(() => {
		if (broken) {
			toggleSidebar();
		} else {
			collapseSidebar();
		}
	}, [broken]);

	const getInitials = React.useCallback((authUser: GetUserResponseDto) => {
		return `${authUser.name[0].toUpperCase()}${authUser?.surname ? authUser.surname[0]?.toUpperCase() : ''}`;
	}, []);

	return (
		<>
			<AppBar
				position='sticky'
				elevation={0}
				color='transparent'
				sx={{
					boxShadow: 'unset',
					maxWidth: '100vw',
					backgroundColor: 'rgb(240 242 245)',
				}}
			>
				<Toolbar>
					<Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
						<IconButton
							size='large'
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={menuIconClicked}
						>
							{collapsed ?
								<MenuOpenIcon />
							:	<MenuIcon />}
						</IconButton>
						{device !== 'mobile' && <CustomBreadcrumbs />}
					</Box>
					<Box sx={{ marginLeft: 2, marginRight: 2, width: device === 'mobile' ? 130 : 200 }}>
						<TenantSwitcher />
					</Box>
					<Divider sx={{ my: 0.5 }} />
					<Box sx={{ flexGrow: 0 }}>
						<Tooltip title='Open settings'>
							<IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
								{/* <Badge badgeContent={17} color='error'> */}
								{authContext.user.user ?
									<Avatar alt={username}>{getInitials(authContext.user.user)}</Avatar>
								:	<Avatar />}
								{/* </Badge> */}
							</IconButton>
						</Tooltip>
						<Menu
							sx={{ mt: '45px' }}
							id='menu-appbar'
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={anchorElUser !== null}
							onClose={handleCloseUserMenu}
						>
							<MenuItem onClick={handleCloseUserMenu}>
								<Typography textAlign='center' onClick={handleOnRedirectHome}>
									{username}
								</Typography>
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />
							{settings.map((setting) => (
								<MenuItem key={setting} onClick={handleOnOpenConfirmationDialog}>
									{setting === 'Logout' ?
										<Typography textAlign='center'>{setting}</Typography>
									:	<Typography textAlign='center'>{setting}</Typography>}
								</MenuItem>
							))}
						</Menu>
					</Box>
				</Toolbar>
			</AppBar>
			<ConfirmationDialog
				onClose={handleOnCloseConfirmationDialog}
				open={open}
				onConfirm={handleOnConfirmLogout}
				title={t('component.topBar.menu.logOut.title')}
				text={t('component.topBar.menu.logOut.message')}
				cancelText={t('component.topBar.menu.logOut.cancel')}
				confirmText={t('component.topBar.menu.logOut.confirm')}
			/>
		</>
	);
};

export default Topbar;
