import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { LabeledBoxProps } from './types';

export const LabeledBox: React.FC<LabeledBoxProps> = ({ label, children, sx, ...rest }) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				border: '1px solid',
				borderColor: theme.palette.divider,
				borderRadius: 1,
				height: '100%',
				position: 'relative',
				padding: theme.spacing(2),
				...sx,
			}}
			{...rest}
		>
			<Typography
				variant='subtitle1'
				sx={{
					position: 'absolute',
					top: -14,
					left: 10,
					backgroundColor: theme.palette.background.paper,
					paddingX: 1,
				}}
			>
				{label}
			</Typography>
			{children}
		</Box>
	);
};
