import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton,
	type MRT_ColumnDef,
	MRT_ToggleFullScreenButton,
	MRT_ToggleFiltersButton,
	MRT_ShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton,
	MRT_PaginationState,
	MRT_SortingState,
	MRT_RowSelectionState,
	MRT_ColumnFiltersState,
	MaterialReactTable,
} from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import { Typography, Tooltip, Box } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import macosLogo from '../../assets/images/macos-logo.png';
import windowsLogo from '../../assets/images/windows-logo.png';
import linuxLogo from '../../assets/images/linux-logo.png';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { PagedResultPartialClientTableRowDto, PartialClientTableRowDto } from '../../api/Api';

export const ClientsPage: React.FC = (): JSX.Element => {
	const { t, i18n } = useTranslation();
	const { language } = i18n;
	const api = useSwaggerApi();

	const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>({});

	const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
	const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>([]);
	const [globalFilter, setGlobalFilter] = React.useState('');
	const [pagination, setPagination] = React.useState<MRT_PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultPartialClientTableRowDto>({
		queryKey: [
			EQueryKey.CLIENTS_LIST_QUERY,
			columnFilters,
			globalFilter,
			pagination.pageIndex,
			pagination.pageSize,
			sorting,
		],
		queryFn: async () => {
			try {
				const response = await api.clients.getAll({
					limit: pagination.pageSize,
					offset: pagination.pageIndex * pagination.pageSize,
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	const { entities = [], total = 0 } = data ? data : {};

	const getClientLogoByPlatform = (platform: string): string => {
		const platformLogos: Record<string, string> = {
			macos: macosLogo,
			windows: windowsLogo,
			linux: linuxLogo,
		};

		const logo = platformLogos[platform];
		if (!logo) {
			throw new Error(`Unknown platform: ${platform}`);
		}

		return logo;
	};

	const columns = React.useMemo<MRT_ColumnDef<PartialClientTableRowDto>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				header: 'Name',
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<img
							alt='avatar'
							height={42}
							src={getClientLogoByPlatform(row.original.platform as string)}
							loading='lazy'
							style={{ borderRadius: 'none' }}
						/>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'version',
				header: 'Version',
			},
			{
				accessorKey: 'system',
				header: 'System',
			},
			{
				accessorKey: 'lastConnectedAt',
				header: t('page.users.list.table.header.lastConnectedAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{typeof renderedCellValue === 'string' ?
							new Date(renderedCellValue).toLocaleString(language)
						:	renderedCellValue}
					</Box>
				),
			},
			{
				accessorKey: 'createdAt',
				header: t('page.users.list.table.header.createdAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{typeof renderedCellValue === 'string' ?
							new Date(renderedCellValue).toLocaleString(language)
						:	renderedCellValue}
					</Box>
				),
			},
		],
		[],
	);

	return (
		<Box>
			<Box
				sx={{
					paddingBottom: 1,
				}}
			>
				<Typography variant='h5'>Clients</Typography>
			</Box>
			<MaterialReactTable
				columns={columns}
				data={entities}
				enableStickyHeader={false}
				state={{
					isLoading: isLoading,
					showAlertBanner: error !== null,
					pagination,
					rowSelection,
					showProgressBars: isRefetching,
				}}
				muiToolbarAlertBannerProps={{
					color: 'error',
					children: <>{error}</>,
				}}
				initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
				rowCount={total}
				manualPagination
				onPaginationChange={setPagination}
				enableRowSelection
				onRowSelectionChange={setRowSelection}
				renderToolbarInternalActions={({ table }) => (
					<Box sx={{ display: 'flex', gap: '1rem' }}>
						<MRT_ToggleGlobalFilterButton table={table} />
						<MRT_ToggleFiltersButton table={table} />
						<MRT_ShowHideColumnsButton table={table} />
						{/* <Tooltip arrow title='Export'>
							<IconButton>
								<SaveAltIcon />
							</IconButton>
						</Tooltip> */}
						<MRT_ToggleDensePaddingButton table={table} />
						<MRT_ToggleFullScreenButton table={table} />
					</Box>
				)}
				displayColumnDefOptions={{
					'mrt-row-actions': {
						muiTableHeadCellProps: {
							align: 'center',
						},
						size: 120,
						enableHiding: true,
					},
					'mrt-row-select': {
						enableHiding: true,
						visibleInShowHideMenu: false,
					},
				}}
				muiTablePaperProps={({ table }) => ({
					style: {
						zIndex: table.getState().isFullScreen ? 1100 : undefined,
						boxShadow: 'none',
						outline: '1px solid #e0e0e0',
					},
				})}
				muiSelectCheckboxProps={() => ({
					sx: {
						width: '50px',
						height: '50px',
					},
				})}
				muiSelectAllCheckboxProps={() => ({
					sx: {
						width: '50px',
						height: '50px',
					},
				})}
				muiTableHeadCellProps={() => ({
					sx: {
						verticalAlign: 'baseline',
					},
				})}
				editDisplayMode='modal'
				enableEditing
				renderRowActions={({ row, table }) => (
					<Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
						<Tooltip arrow placement='right' title='Delete'>
							<IconButton color='error'>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</Box>
				)}
				positionActionsColumn='last'
			/>
		</Box>
	);
};
