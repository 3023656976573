import * as React from 'react';
import { Box, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { OneTimePasswordProps } from './types';

export const OneTimePassword: React.FC<OneTimePasswordProps> = ({ name, control, error, length }): JSX.Element => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<Box sx={{ maxWidth: '500px' }}>
					<MuiOtpInput autoFocus length={length} {...field} />
					{error && typeof error.message === 'string' && (
						<FormHelperText error id={`accountId-${name}-error`}>
							{error.message}
						</FormHelperText>
					)}
				</Box>
			)}
		/>
	);
};
