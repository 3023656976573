import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Preloader } from '../../components/Preloader/Preloader';
import { SwaggerApiClient } from '../SwaggerApiContext/SwaggerApiClient';
import { ClientPaths } from '../SwaggerApiContext/enums';
import { AuthContextProviderProps, UserState, AuthContextModel, UserTenantsState, UserPermissionState } from './types';
import { PUBLIC_ROUTES, DEPLOYMENT_SETUP_ROUTES, PRELOADER_TIMEOUT } from './constants';
import { EAppStatus, ELanguageCode, TenantModel } from '../../api/Api';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { useCurrentLanguage, useChangeLanguage } from '../LocalizationContext/LocalizationContext';
import { useNavigate } from '../../hooks/useNavigate';
import { hasPath } from '../../utils/Routing';
import { useRedirect } from '../../hooks/useRedirect';
import { EPermission } from '../../enums/permission/EPermission';

export const InitialAuthContextModel: AuthContextModel = {
	appStatus: {
		loading: false,
		loaded: false,
		appStatus: null,
		error: null,
	},
	user: {
		loading: false,
		loaded: false,
		user: null,
		error: null,
		isAuthenticated: false,
	},
	userTenants: {
		loading: false,
		loaded: false,
		tenants: [],
		error: null,
		activeTenantID: null,
	},
	userRole: {
		loading: false,
		loaded: false,
		role: null,
		error: null,
	},
	permissions: {
		loading: false,
		loaded: false,
		permissions: [],
		error: null,
	},
	handleOnLoadUser: () => Promise.resolve(),
	handleOnLogout: () => Promise.resolve(),
	handleOnLoadUserTenants: () => Promise.resolve(),
	handleOnLoadPermissions: () => Promise.resolve(),
	handleOnSwitchToTenant: (_: number | null) => Promise.resolve(),
	setShowPreloader: () => {},
};

export const AuthContext = React.createContext<AuthContextModel>(InitialAuthContextModel);

export const useAuthContext = (): AuthContextModel => {
	return React.useContext(AuthContext);
};

export const AuthProvider: React.FC<AuthContextProviderProps> = ({ children }): JSX.Element => {
	const currentLanguage = useCurrentLanguage();
	const changeLanguage = useChangeLanguage();
	const location = useLocation();
	const navigate = useNavigate();
	const redirect = useRedirect();
	const queryParams = new URLSearchParams(location.search);
	const original = queryParams.get('original');
	const { t } = useTranslation();
	const [userState, setUserState] = React.useState<UserState>(InitialAuthContextModel.user);
	const [permissionsState, setPermissionsState] = React.useState<UserPermissionState>(
		InitialAuthContextModel.permissions,
	);
	const [appStatusState, setAppStatusState] = React.useState(InitialAuthContextModel.appStatus);
	const [userTenantsState, setUserTenantsState] = React.useState<UserTenantsState>(
		InitialAuthContextModel.userTenants,
	);
	const [showPreloader, setShowPreloader] = React.useState(true);
	const [userRoleState, setUserRoleState] = React.useState(InitialAuthContextModel.userRole);
	const previousIsAuthenticated = usePreviousValue(userState.isAuthenticated);

	const swaggerApi = new SwaggerApiClient({}, { disableRedirect: hasPath(PUBLIC_ROUTES, location.pathname), t });

	const handleOnLogout = React.useCallback(
		async (path?: string) => {
			try {
				await swaggerApi.auth.logout();
				if (path) {
					redirect(path);
				} else {
					redirect(ClientPaths.LOGIN);
				}
			} catch (error) {
				enqueueSnackbar(t('page.auth.errorMessages.logOutFailed'), {
					variant: 'error',
					persist: false,
				});
			}
		},
		[redirect],
	);

	const handleOnLoadPermissions = React.useCallback(async () => {
		if (permissionsState.loading || !userState.isAuthenticated) {
			return;
		}
		setPermissionsState({
			...permissionsState,
			loading: true,
		});

		try {
			const response = await swaggerApi.users.mePermissions();

			const permissions = response.data as unknown as EPermission[];

			setPermissionsState({
				loading: false,
				loaded: true,
				permissions: permissions,
				error: null,
			});
		} catch (error) {
			setPermissionsState({
				loading: false,
				loaded: false,
				permissions: [],
				error: error as AxiosError,
			});
		}
	}, [permissionsState, userState]);

	const handleOnSwitchToTenant = React.useCallback(
		async (tenantID: number | null) => {
			const tenant = userTenantsState.tenants.find((t) => t.id === tenantID);
			try {
				await swaggerApi.auth.logout();
				window.location.href =
					tenantID ?
						`/tenant/${tenant?.slug ? tenant.slug : tenant?.id}${ClientPaths.LOGIN}`
					:	ClientPaths.LOGIN;
			} catch (error) {
				console.error(error);
			}
		},
		[userTenantsState],
	);

	const handleOnLoadUserTenants = React.useCallback(async () => {
		if (userTenantsState.loading) {
			return;
		}
		setUserTenantsState({
			...userTenantsState,
			loading: true,
		});
		try {
			const activeTenantResponse = await swaggerApi.tenants.active();
			const userTenantsResponse = await swaggerApi.users.meTenants();
			setUserTenantsState({
				loading: false,
				loaded: true,
				tenants: userTenantsResponse.data.entities as TenantModel[],
				error: null,
				activeTenantID: activeTenantResponse.data ? activeTenantResponse.data : null,
			});
			setTimeout(() => {
				setShowPreloader(false);
			}, PRELOADER_TIMEOUT);
		} catch (error) {
			setUserTenantsState({
				loading: false,
				loaded: true,
				tenants: [],
				error: error as AxiosError,
				activeTenantID: null,
			});
			setTimeout(() => {
				setShowPreloader(false);
			}, PRELOADER_TIMEOUT);
		}
	}, [userTenantsState, userRoleState]);

	const handleOnLoadUserRole = React.useCallback(async () => {
		if (userRoleState.loading) {
			return;
		}
		setUserRoleState({
			...userRoleState,
			loading: true,
		});
		handleOnLoadPermissions();
		try {
			const userRoleResponse = await swaggerApi.users.meRole();
			setUserRoleState({
				loading: false,
				loaded: true,
				role: userRoleResponse.data,
				error: null,
			});
			handleOnLoadUserTenants();
		} catch (error) {
			setUserRoleState({
				loading: false,
				loaded: true,
				role: null,
				error: error as AxiosError,
			});
			setTimeout(() => {
				setShowPreloader(false);
			}, PRELOADER_TIMEOUT);
		}
	}, [userState, userRoleState, permissionsState, handleOnLoadPermissions]);

	const handleOnLoadUser = React.useCallback(
		async (force = false) => {
			if ((userState.loading || appStatusState.loading) && !force) {
				return;
			}
			setShowPreloader(true);
			setAppStatusState({
				...appStatusState,
				loading: true,
			});
			const _swaggerApi = new SwaggerApiClient(
				{},
				{ disableRedirect: hasPath(PUBLIC_ROUTES, location.pathname), t },
			);
			setUserState({
				...userState,
				loading: true,
			});
			try {
				const appStatusResponse = await _swaggerApi.status.getAppStatus();
				setAppStatusState({
					loading: false,
					loaded: true,
					appStatus: appStatusResponse.data,
					error: null,
				});
				if (appStatusResponse.data === EAppStatus.DEPOYMENT_SETUP) {
					setTimeout(() => {
						setShowPreloader(false);
					}, PRELOADER_TIMEOUT);

					return;
				}
				const response = await _swaggerApi.users.me();
				if (response.data.tenantSlug && !window.location.pathname.includes(response.data.tenantSlug)) {
					window.location.href = `/tenant/${response.data.tenantSlug}/me`;
				}
				const currentLanguage = await _swaggerApi.localizations.getLanguage();
				changeLanguage(currentLanguage.data as ELanguageCode);
				setUserState({
					loading: false,
					loaded: true,
					user: response.data,
					error: null,
					isAuthenticated: true,
				});
			} catch (error) {
				setUserState({
					loading: false,
					loaded: false,
					user: null,
					error: error as AxiosError,
					isAuthenticated: false,
				});
				setTimeout(() => {
					if (hasPath(PUBLIC_ROUTES, location.pathname)) {
						setShowPreloader(false);
					}
				}, PRELOADER_TIMEOUT);
			}
		},
		[userState, appStatusState, location, currentLanguage],
	);

	React.useEffect(() => {
		if (!previousIsAuthenticated && userState.isAuthenticated) {
			handleOnLoadUserRole();
		}
	}, [userState, previousIsAuthenticated]);

	React.useEffect(() => {
		if (!userState.loaded && !userState.loading && !userState.error) {
			handleOnLoadUser();
		} else if (
			userState.loaded &&
			userState.isAuthenticated &&
			userTenantsState.loaded &&
			hasPath(PUBLIC_ROUTES, location.pathname)
		) {
			navigate(original ? original : ClientPaths.DEFAULT);
			enqueueSnackbar(t('page.auth.actionMessages.loginSuccess'), {
				variant: 'success',
				persist: false,
			});
		} else if (
			appStatusState.loaded &&
			appStatusState.appStatus === EAppStatus.DEPOYMENT_SETUP &&
			!hasPath(DEPLOYMENT_SETUP_ROUTES, location.pathname)
		) {
			navigate('/setup');
		} else if (
			appStatusState.loaded &&
			appStatusState.appStatus !== EAppStatus.DEPOYMENT_SETUP &&
			appStatusState.appStatus !== EAppStatus.DEVELOPMENT &&
			hasPath(DEPLOYMENT_SETUP_ROUTES, location.pathname)
		) {
			navigate('/login');
		}
	}, [handleOnLoadUser, userState, location, userTenantsState, original]);

	const authContextModel: AuthContextModel = {
		appStatus: appStatusState,
		user: userState,
		permissions: permissionsState,
		userTenants: userTenantsState,
		userRole: userRoleState,
		handleOnLoadUser,
		handleOnLogout,
		handleOnSwitchToTenant,
		setShowPreloader,
		handleOnLoadUserTenants,
		handleOnLoadPermissions,
	};

	return (
		<AuthContext.Provider value={authContextModel}>
			{showPreloader ?
				<Preloader percentageHeight={100} showLogo />
			:	children}
		</AuthContext.Provider>
	);
};
