import { useState, useEffect } from 'react';
import { LANGUAGES } from '../contexts/LocalizationContext/constants';

import { ELanguageCode } from '../api/Api';

export const useNavigatorLanguageCode = (): string => {
	const [languageCode, setLanguageCode] = useState<string>(ELanguageCode.En);

	useEffect(() => {
		const getLanguageCode = () => {
			const browserLanguage = navigator.language.split('-')[0];
			if (Object.values(LANGUAGES).includes(browserLanguage as ELanguageCode)) {
				setLanguageCode(browserLanguage);
			} else {
				setLanguageCode(ELanguageCode.En);
			}
		};

		getLanguageCode();
	}, []);

	return languageCode;
};
