import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SwaggerApiClient } from './SwaggerApiClient';
import { Api } from '../../api/Api';
import { PUBLIC_ROUTES } from '../AuthContext/constants';
import { usePathName } from '../../hooks/usePathName';
import { SwaggerApiClientOptions, SwaggerApiContextModel, SwaggerApiProviderProps } from './types';
import { hasPath } from '../../utils/Routing';
import { useNetworkContext } from '../../hooks/useNetworkContext';

const InitialSwaggerApiContextModel: SwaggerApiContextModel = {
	swaggerApi: null,
	requestSent: false,
};

export const SwaggerApiContext = React.createContext<SwaggerApiContextModel>(InitialSwaggerApiContextModel);

export const SwaggerApiProvider: React.FC<SwaggerApiProviderProps> = ({ children }): JSX.Element => {
	const navigate = useNavigate();
	const networkContext = useNetworkContext();
	const location = useLocation();
	const { t } = useTranslation();
	const pathName = usePathName();
	const [requestSent, setRequestSent] = React.useState(false);

	const swaggerApi = React.useMemo(() => {
		const swaggerApiClientOptions: SwaggerApiClientOptions = {
			disableRedirect: hasPath(PUBLIC_ROUTES, pathName),
			t,
			requestSent,
			setRequestSent,
			checkApiStatus: networkContext.checkApiStatus,
			checkNetworkStatus: networkContext.checkNetworkStatus,
		};

		return new SwaggerApiClient({}, swaggerApiClientOptions);
	}, [pathName, navigate, t, location, setRequestSent]);

	const value: SwaggerApiContextModel = {
		swaggerApi,
		requestSent,
	};

	return <SwaggerApiContext.Provider value={value}>{children}</SwaggerApiContext.Provider>;
};
