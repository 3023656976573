import { TFunction } from 'i18next';
import { z } from 'zod';

import { getDurationInUnits } from '../../utils/DateTime';

export const getOAuthClientFormSchema = (t: TFunction) =>
	z.object({
		name: z.string().min(1, { message: t('page.oAuthClients.edit.form.name.Error') }),
		description: z.string().min(1, { message: t('page.oAuthClients.edit.form.description.Error') }),
		accessTokenValidity: z.string().refine(
			(value) => {
				const accessTokenValidityInSeconds = getDurationInUnits(value, 'second');

				return Number.isInteger(accessTokenValidityInSeconds) && accessTokenValidityInSeconds > 0;
			},
			{
				message: t('page.oAuthClients.edit.form.accessTokenValidity.Error'),
			},
		),
	});

export const oAuthClientListSchema = z.object({
	clientID: z.string(),
	name: z.string(),
	createdAt: z.string(),
	expiresAt: z.string().nullable(),
});
