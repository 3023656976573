import { z } from 'zod';
import { TFunction } from 'i18next';

export const getRuleSetSchema = (t: TFunction) => {
	return z.object({
		name: z
			.string()
			.min(3, { message: t('page.securityPolicy.edit.form.name.Error', { minLength: 3 }) })
			.max(64, { message: t('page.securityPolicy.edit.form.name.ErrorMaxLength', { maxLength: 64 }) }),
		description: z.string().optional(),
		type: z.string(),
		timeRange: z
			.object({
				end: z.string(),
				start: z.string(),
			})
			.optional(),
		days: z.array(z.string()).optional(),
		rules: z.array(z.any()),
	});
};

export const ruleSetListSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	tenantID: z.number().optional().nullable(),
	securityPolicies: z.array(z.object({ id: z.number(), name: z.string() })),
	securityPoliciesAllCount: z.number(),
});
