import React from 'react';
import { useAuthContext } from '../contexts/AuthContext/AuthContext';

export const useUsername = (): string => {
	const authContext = useAuthContext();

	const username = React.useMemo(() => {
		return authContext.user.user ?
				`${authContext.user.user.name} ${authContext.user.user.surname ? authContext.user.user.surname : ''}`
			:	'Username';
	}, [authContext.user]);

	return username;
};
