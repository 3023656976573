import * as React from 'react';
import { Box, Divider, Drawer as MUIDrawer, IconButton, Tooltip } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DrawerProps } from './types';
import { Heading } from '../Heading/Heading';
import { useTranslation } from 'react-i18next';

export const Drawer: React.FC<DrawerProps> = ({
	open,
	title,
	anchor = 'right',
	maxWidth = '100vw',
	width = '45vw',
	customWidth = {},
	children,
	onClose,
	...rest
}) => {
	const { t } = useTranslation();

	const handleOnClose = React.useCallback(
		(event: React.MouseEvent) => {
			if (onClose) {
				onClose(event, 'backdropClick');
			}
		},
		[onClose],
	);

	return (
		<MUIDrawer anchor={anchor} open={open} onClose={onClose} {...rest}>
			<Box
				sx={{
					maxWidth: maxWidth,
					minWidth: '260px',
					width: { xs: '100vw', sm: '75vw', md: width, ...customWidth },
				}}
			>
				<Box display='flex' flexDirection='column' padding='24px'>
					<Box display='flex' justifyContent='space-between' alignItems='center'>
						<Heading label={title ? title : ''} paddingTop='8px' />
						<Tooltip
							title={t('component.drawer.tooltips.closeDrawer')}
							placement='top'
							arrow
							enterDelay={500}
						>
							<IconButton onClick={handleOnClose}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</Box>
					<Divider sx={{ marginBottom: '8px' }} />
					{children}
				</Box>
			</Box>
		</MUIDrawer>
	);
};
