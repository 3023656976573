import { useLocation, useParams, Params } from 'react-router-dom';
import { getTenantFromPath } from '../utils/Routing';

export const usePathName = (): string => {
	const location = useLocation();
	const { tenantID, tenantSlug } = getTenantFromPath();

	if (tenantID) {
		return location.pathname.replace(`/tenant/${tenantID}`, '');
	} else if (tenantSlug) {
		return location.pathname.replace(`/tenant/${tenantSlug}`, '');
	}

	return location.pathname;
};
