import * as React from 'react';
import { Typography, FormGroup, useMediaQuery, useTheme, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ManualConfigProvider } from '../ManualConfigProvider/ManualConfigProvider';
import { XMLProvider } from '../XMLProvider/XMLProvider';
import { MetadataFormProps } from './types';

export const MetadataForm: React.FC<MetadataFormProps> = ({ register }) => {
	const [saving, setSaving] = React.useState(false);
	const [manualConfiguration, setManualConfiguration] = React.useState(false);
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	return (
		<FormGroup
			sx={{
				padding: 2,
			}}
		>
			<Stack
				direction='column'
				spacing={1}
				sx={{
					width: getStackWidth(),
				}}
			>
				<Typography variant='subtitle1'>Metadata</Typography>

				<Typography variant='body1'>
					Choose one of the following options to configure Service Provider. You can either provide metadata
					in XML format or configure it manually.
				</Typography>

				<ToggleButtonGroup
					sx={{
						paddingBottom: 2,
					}}
					color='primary'
					disabled={saving}
					value={manualConfiguration}
					onChange={(event, value) => setManualConfiguration(value)}
					exclusive
					aria-label='Manual Configuration'
				>
					<ToggleButton value={false}>XML</ToggleButton>
					<ToggleButton value={true}>Manual configuration</ToggleButton>
				</ToggleButtonGroup>

				{manualConfiguration && <ManualConfigProvider register={register} />}
				{!manualConfiguration && <XMLProvider register={register} />}
			</Stack>
		</FormGroup>
	);
};
