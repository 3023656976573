import { z } from 'zod';
import { TFunction } from 'i18next';
import { EInvitationStatus } from '../../api/Api';
import { debouncedIsSlugUnique, tenantSlugRegex } from '../../utils/Tenant';

const ACCEPTED_FILE_EXTENSIONS = ['.txt'];

export const getTenantFormSchema = (t: TFunction, isEdit: boolean) =>
	z.object({
		name: z
			.string()
			.min(3, { message: t('page.tenants.edit.form.name.Error', { minLength: 3 }) })
			.max(64, { message: t('page.tenants.edit.form.name.ErrorMaxLength', { maxLength: 64 }) }),
		description: z
			.string()
			.max(255, { message: t('page.tenants.edit.form.description.ErrorMaxLength', { maxLength: 255 }) })
			.optional(),
		networkAddresses: z.array(z.string()).optional(),
		file: z
			.instanceof(File)
			.optional()
			.nullable()
			.refine((file) => !file || ACCEPTED_FILE_EXTENSIONS.some((ext) => file.name.endsWith(ext)), {
				message: t('page.tenants.edit.form.file.ErrorExtension'),
			}),
		slug: z
			.string()
			.min(3, { message: t('page.tenants.edit.form.slug.ErrorMinLength', { minLength: 3 }) })
			.max(32, { message: t('page.tenants.edit.form.slug.ErrorMaxLength', { maxLength: 32 }) })
			.regex(tenantSlugRegex, { message: t('page.tenants.edit.form.slug.ErrorInvalid') })
			.optional()
			.refine(
				isEdit ?
					() => true
				:	async (newSlug) => {
						if (newSlug === undefined) {
							return true;
						}
						try {
							const isUnique = await debouncedIsSlugUnique(newSlug);

							return isUnique;
						} catch (error) {
							console.error(error);

							return true;
						}
					},
				{
					message: t('page.tenants.edit.form.slug.ErrorNotUnique'),
				},
			),
	});

export const getTenantNetworkAddressFormSchema = (t: TFunction) =>
	z.object({
		networkAddresses: z.array(z.string()).optional(),
		file: z
			.instanceof(File)
			.optional()
			.nullable()
			.refine((file) => !file || ACCEPTED_FILE_EXTENSIONS.some((ext) => file.name.endsWith(ext)), {
				message: t('page.tenants.edit.form.file.ErrorExtension'),
			}),
	});

export const tenantListSchema = z.object({
	id: z.number(),
	name: z.string(),
	slug: z.string().optional(),
	description: z.string().optional(),
	createdAt: z.string(),
	updatedAt: z.string(),
	status: z.string(),
	errorLog: z.string().optional(),
});

export const userListSchema = z.object({
	id: z.number(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	email: z.string().min(5).max(64),
	status: z.string(),
	createdAt: z.string(),
	lastLoggedAt: z.string().optional(),
	groups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
				description: z.string().optional(),
			})
			.optional(),
	),
});

export const userGroupListSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
});

export const ldapIdentityStoreSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	caCertificate: z.string().optional(),
	useTLS: z.boolean(),
	host: z.string(),
	port: z.number(),
	baseDN: z.string(),
	bindDN: z.string(),
});

export const invitationListSchema = z.object({
	id: z.number(),
	userID: z.number(),
	status: z.enum([
		EInvitationStatus.Accepted,
		EInvitationStatus.Dismissed,
		EInvitationStatus.Failed,
		EInvitationStatus.Pending,
		EInvitationStatus.Sent,
	]),
	createdAt: z.string(),
	email: z.string(),
	phoneNumber: z.string().optional(),
});

export const tenantResourceListSchema = z.object({
	id: z.number(),
	tenantID: z.number().optional(),
	name: z.string(),
	status: z.string(),
	type: z.string(),
	errorLog: z.string().optional(),
	createdAt: z.string(),
});
