import * as React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Autocomplete, TextField, Typography } from '@mui/material';
import { TimezoneOption, TimezoneSelect, TimezoneSelectorProps } from './types';
import optionsData from './optionsData.json';
import { t } from 'i18next';
import { formatOffset, parseTimezone } from '../../../utils/Timezone';

export const TimezoneSelector: React.FC<TimezoneSelectorProps> = ({ control, name, error, disabled, updateValue }) => {
	const [selectedTimezone, setSelectedTimezone] = React.useState<string | TimezoneOption>(
		// eslint-disable-next-line new-cap
		Intl.DateTimeFormat().resolvedOptions().timeZone,
	);
	const labelId = React.useMemo(() => `select-${name}-${Math.random().toString(36).substring(7)}`, [name]);
	const selectOptions = React.useMemo(() => {
		const options: TimezoneSelect[] = [];

		for (const option of optionsData.options) {
			options.push({
				label: `(GMT${formatOffset(option.offset)}) ${option.value}`,
				value: option.value,
				offset: option.offset,
			});
		}

		return options;
	}, [optionsData.options]);

	const defaultValue = React.useMemo(() => {
		if (typeof selectedTimezone !== 'string') {
			return;
		}
		const parsedTimezone = parseTimezone(selectedTimezone);

		return JSON.stringify({
			offset: parsedTimezone?.offset,
			iana: parsedTimezone?.value,
		});
	}, [selectedTimezone]);

	const valueForUpdate = React.useMemo(() => {
		if (!updateValue) {
			return null;
		}

		return JSON.parse(updateValue).iana;
	}, [updateValue]);

	const defaultSelectedOption = React.useMemo(() => {
		if (typeof selectedTimezone === 'string') {
			const value = valueForUpdate ?? selectedTimezone;

			return selectOptions.find((option) => option.value === value) ?? null;
		}

		return selectOptions.find((option) => option.value === selectedTimezone.value) ?? null;
	}, [selectOptions, selectedTimezone, valueForUpdate]);

	return (
		<Box sx={{ width: '100%' }}>
			<Controller
				control={control}
				name={name}
				defaultValue={valueForUpdate ?? defaultValue}
				render={({ field: { onChange, value } }) => {
					const handleOnTimezoneChange = React.useCallback(
						(event: React.SyntheticEvent<Element, Event>, value: string | TimezoneOption | null) => {
							if (!value) {
								return;
							}
							if (typeof value === 'string') {
								const newValue = parseTimezone(value);
								setSelectedTimezone(newValue);
								const timezone = JSON.stringify({
									offset: newValue.offset,
									iana: newValue.value,
								});
								onChange(timezone);
							} else {
								setSelectedTimezone(value);
								const timezone = JSON.stringify({
									offset: value.offset,
									iana: value.value,
								});
								onChange(timezone);
							}
						},
						[onChange, parseTimezone],
					);

					return (
						<>
							<Autocomplete
								id={labelId}
								options={selectOptions}
								disabled={disabled}
								getOptionLabel={(option) => option.label}
								value={defaultSelectedOption}
								onChange={handleOnTimezoneChange}
								renderInput={(params) => (
									<TextField
										{...params}
										name={name}
										label={t('page.setupWizard.texts.serverSetup.timezone.label')}
										helperText={
											error ?
												<Typography component='span' color='error'>
													{error.message}
												</Typography>
											:	t('page.setupWizard.texts.serverSetup.timezone.helperText')
										}
									/>
								)}
							/>
						</>
					);
				}}
			/>
		</Box>
	);
};
