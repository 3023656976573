import * as React from 'react';
import { Navigate } from '../../components/Navigate/Navigate';
import { useParams } from 'react-router-dom';

import { usePreviousValue } from '../../hooks/usePreviousValue';
import { useACL } from '../../hooks/useACL';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { getTenantFromPath } from '../../utils/Routing';

export const hocACL =
	(expectedPermissions: string[] = [], strictMode = true) =>
	<T extends {}>(PageComponent: React.ComponentType<T>): React.FC<T> => {
		const SecuredPageComponent: React.FC<T> = (props): JSX.Element => {
			const { id } = useParams();
			const { isAllowed } = useACL();
			const authContext = useAuthContext();
			const { tenantID: pathTenantID, tenantSlug } = getTenantFromPath();
			const tenantIDFromSlug = authContext.userTenants.tenants.find((tenant) => tenant.slug === tenantSlug)?.id;
			const tenantID = tenantIDFromSlug || pathTenantID;
			const previousActiveTenantID = usePreviousValue(authContext.userTenants.activeTenantID);

			if (tenantID !== authContext.userTenants.activeTenantID) {
				return <Navigate to='/notFound' />;
			}

			if (
				!isAllowed(expectedPermissions, strictMode) &&
				previousActiveTenantID !== undefined &&
				previousActiveTenantID !== authContext.userTenants.activeTenantID
			) {
				return <Navigate to='/me' />;
			}
			if (
				isAllowed(expectedPermissions, strictMode) &&
				id &&
				previousActiveTenantID !== undefined &&
				previousActiveTenantID !== authContext.userTenants.activeTenantID
			) {
				return <Navigate to='/me' />;
			}

			if (!isAllowed(expectedPermissions, strictMode)) {
				return <Navigate to='/unauthorized' />;
			}

			return <PageComponent {...props} />;
		};

		return SecuredPageComponent;
	};
